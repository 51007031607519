import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { SeoFragmentFragment } from '../../seo';
import { getCanonicalUrl } from '../../utils/GetCanonicalUrl';

const Seo = ({
    title,
    seo,
    author,
    children,
}: {
    title?: string;
    seo: SeoFragmentFragment;
    author?: any;
    children?: React.ReactNode;
}) => {
    title = seo?.titleText || title;

    const location = useLocation();
    const imageSocialShareUrl = '/images/dougs-social-share.jpg';
    const data = useStaticQuery(graphql`
        query LayoutQuery {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `);

    const defaultImageUrl = data.site.siteMetadata.siteUrl + imageSocialShareUrl;

    return (
        <>
            <html lang="fr" />
            <meta charSet="utf-8" />
            <link
                rel="canonical"
                href={getCanonicalUrl(location.pathname)}
                data-baseprotocol="https:"
                data-basehost="www.dougs.fr"
            />
            {title && <title>{title}</title>}
            <meta name="description" content={seo?.metaDesc} />

            {seo?.metaRobotsNoindex && <meta name="robots" content="noindex" />}
            {seo?.metaRobotsNoindex && <meta name="googlebot" content="noindex" />}

            <meta property="og:title" content={seo?.opengraphTitle || title} />
            <meta property="og:description" content={seo?.opengraphDescription || seo?.metaDesc} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={defaultImageUrl} />

            <meta name="twitter:title" content={seo?.twitterTitle || title} />
            <meta name="twitter:description" content={seo?.twitterDescription || seo?.metaDesc} />
            <meta name="twitter:creator" content={author?.name || 'Dougs'} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:image" content={defaultImageUrl} />

            <meta name="HandheldFriendly" content="True" />
            <meta name="MobileOptimized" content="320" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5, user-scalable=1" />
            {children}
        </>
    );
};

export default Seo;
