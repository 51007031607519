import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import GuideBanner from '../components/media/GuideBanner';
import MediaSloganBlock from '../components/media/MediaSloganBlock';
import PostHightlight from '../components/media/PostHighlight';
import PostThumb from '../components/media/PostThumb';
import Breadcrumb from '../components/navigation/Breadcrumb';
import Pagination from '../components/Pagination';
import Seo from '../components/seo/Seo';
import { mediaBreadcrumbParser } from '../utils/BreadcrumbParser';
import htmlToText from '../utils/htmlToText';
import { getExcerptContent } from './media';

const CategoryPage = ({
    data,
    pageContext,
}: {
    data: { prismicCategory: Queries.PrismicCategory; allPrismicPost: { nodes: [Queries.PrismicPost] } };
    pageContext;
}) => {
    const category = data.prismicCategory.data;
    const categoryUri = data.prismicCategory.uri;
    const breadcrumb = mediaBreadcrumbParser({ uri: categoryUri, name: category.title });

    const [firstPost, ...otherPosts] = data.allPrismicPost.nodes as Queries.PrismicPost[];
    const title =
        category.title + (pageContext.pagination.current > 1 ? ` - Page ${pageContext.pagination.current}` : '');
    const excerpt = '<p>'.concat(getExcerptContent(firstPost.data)?.slice(0, 400)?.concat('[…]</p>')) || '';

    return (
        <Layout hasHeaderMedia>
            <div className="container">
                <TopPaddingBasedOnHeader
                    padding={{
                        desktop: 0,
                        tablet: 0,
                        mobile: 0,
                    }}
                />
                {breadcrumb && <Breadcrumb items={breadcrumb} />}
                <div className={`panel panel--${category.color} panel--xl mt-5`}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`post-category__name post-category__name--${category.color} mb-1`}>
                                Catégorie
                            </div>
                            <h1>{title}</h1>
                        </div>
                        <div className="col-md-6">
                            <div dangerouslySetInnerHTML={{ __html: category.description.html }}></div>
                        </div>
                    </div>
                </div>
                <PostHightlight
                    key={firstPost.id}
                    post={{
                        ...firstPost,
                        img: firstPost.data.cover.gatsbyImageData,
                        excerpt,
                    }}
                />
                <div className="row thumb-list mb-10">
                    {otherPosts.map((post, index) => (
                        <div className="col-md-4 col-sm-6" key={index}>
                            <PostThumb
                                key={post.id}
                                post={{
                                    ...post,
                                    img: post.data.cover.gatsbyImageData,
                                }}
                            />
                        </div>
                    ))}
                </div>

                {pageContext.pagination.count > 1 && (
                    <Pagination uri={categoryUri} pagination={pageContext.pagination} />
                )}
            </div>
            <GuideBanner />
            <MediaSloganBlock />
        </Layout>
    );
};

export default withPrismicPreview(CategoryPage);

export const Head = ({
    data,
}: {
    data: { prismicCategory: Queries.PrismicCategory; allPrismicPost: { nodes: [Queries.PrismicPost] } };
}) => {
    const category = data.prismicCategory.data;

    return <Seo title={`${category.title} - Dougs Compta`} seo={{ metaDesc: htmlToText(category.description.html) }} />;
};

export const pageQuery = graphql`
    query ($id: String!, $postIds: [String]) {
        prismicCategory(id: { eq: $id }) {
            ...PostCategory
        }
        allPrismicPost(
            filter: { data: { indexed: { eq: true } }, id: { in: $postIds } }
            sort: { data: { published_date: DESC } }
        ) {
            nodes {
                id
                uid
                _previewable
                first_publication_date
                uri
                data {
                    meta_description
                    published_date
                    titre
                    cover {
                        alt
                        gatsbyImageData
                    }
                    category {
                        document {
                            ... on PrismicCategory {
                                id
                                uri
                                data {
                                    title
                                    slug
                                    color
                                }
                            }
                        }
                    }
                    labels {
                        tag {
                            document {
                                ... on PrismicTag {
                                    id
                                    uri
                                    data {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    body {
                        ...PostQuote
                        ...PostImage
                        ...PostOverline
                        ...PostNormalText
                        ...PostLineHorizontal
                        ...PostButton
                        ...PostCard
                        ...PostGuide
                        ...PostTVA
                        ...PostWordpress
                    }
                    author {
                        document {
                            ...PostAuthor
                        }
                    }
                }
            }
        }
    }
`;
