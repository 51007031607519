import * as React from 'react';
import { ReactNode, useRef, useState } from 'react';

import useClickOutside from '../../hooks/useClickOutside';
import ChevronIcon from './../../images/icons/chevron.svg';
import * as styles from './dropdown.module.scss';

const Dropdown = ({ title, children, className }: { title: string; children: ReactNode[]; className?: string }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setIsOpen(false));

    return (
        <div ref={ref} className={`${styles.dropdown} ${isOpen ? 'open' : ''} ${className}`}>
            <div className={styles.dropdownToggle} onClick={() => setIsOpen(!isOpen)}>
                {title}
                <ChevronIcon />
            </div>
            {isOpen && (
                <div className={styles.dropdownMenu}>
                    <ul className={styles.dropdownList}>
                        {children.map((child, id) => (
                            <li key={id}>{child}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
