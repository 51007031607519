import * as React from 'react';
import { useEffect } from 'react';

export default (ref: React.RefObject<HTMLDivElement>, fn: () => void) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                fn();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [fn, ref]);
};
