import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

import { payloadType } from '../meetUs/types/types';

mixpanel.init(process.env.GATSBY_MIXPANEL_API_KEY, { debug: process.env.GATSBY_MIXPANEL_DEBUG_MODE === 'true' });

export const mixpanelEvent = ({ params, options }: { params: string[]; options?: payloadType | any }) => {
    if (mixpanel && params[5] !== 'mixpanelnotrack') {
        if (params[3] === 'click') {
            const data = {
                Platform: 'Marketing WebSite',
                'CTA Category': params[2],
                'CTA Label': params[4],
                status: params[5],
                branch: process.env.BRANCH,
                ...options,
            };
            //delete all personal info
            delete data.email;
            delete data.firstName;
            delete data.lastName;
            delete data.activityFreeInput;
            delete data.client;
            delete data.fetch;
            delete data.phone;
            return mixpanel.track('Revenue Marketing WebSite CTA Clicked', data);
        }
    }
};

const bind = ($el, eventName: string, handler: (this: HTMLElement, e) => void): (() => void) => {
    $el.on(eventName, handler);
    return () => $el.off(eventName, handler);
};

//function wistia(window, $) {
//    return bind($("[data-wistia-id]"), "click", function (e) {
//        e.originalEvent.preventDefault();
//
//        const $this = $(this);
//        const videoId = $this.data("wistia-id");
//
//        if (!$this.data("wistia-loaded")) {
//            $this.data("wistia-loaded", true);
//            $this.addClass("wistia-loading");
//
//            window._wq = window._wq || [];
//            window._wq.push({
//                id: videoId,
//                onReady(video) {
//                    $this.removeClass("wistia-loading");
//                    video.embed.show();
//                },
//            });
//
//            $(`<script src='https://fast.wistia.com/embed/medias/${videoId}.jsonp'></script>`).appendTo($("body"));
//
//            if (!window.Wistia) {
//                $(`<script src='https://fast.wistia.com/assets/external/E-v1.js'></script>`).appendTo($("body"));
//            }
//        }
//    });
//},

const scripts: ((window, $: JQueryStatic) => () => void)[] = [
    function analytics(window, $) {
        return bind($('[data-ga-send]'), 'click', function () {
            const params = $(this).attr('data-ga-send').split(',');
            params.unshift('send');
            if (window.ga) {
                window.ga.apply(window.ga, params);
            }
            if (window.dataLayer) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'tracking_event_old',
                    platform: 'Marketing WebSite',
                    tag_name: params[4],
                });
            }
            if (mixpanel && params[5] !== 'mixpanelnotrack') {
                if (params[3] === 'click') {
                    const data = {
                        Platform: 'Marketing WebSite',
                        'CTA Category': params[2],
                        'CTA Label': params[4],
                        status: params[5],
                        branch: process.env.BRANCH,
                    };
                    mixpanel.track('Revenue Marketing WebSite CTA Clicked', data);
                }
            }
            if (window._paq) {
                window._paq.push(['trackEvent', 'Marketing WebSite', 'Click', params[4]]);
                window._paq.push([
                    'trackContentInteraction',
                    'Click',
                    'Marketing WebSite',
                    params[4],
                    window.location.pathname,
                ]);
            }
        });
    },

    function ga4(window, $) {
        return bind($('[data-ga4]'), 'click', function () {
            const params = $(this).attr('data-ga4');
            if (window.dataLayer) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'tracking_event',
                    platform: 'Marketing WebSite',
                    tag_name: params,
                    origin: window.location.origin,
                });
            }
        });
    },

    function liveCall(window, $) {
        return bind($('[data-livecall-button]'), 'click', e => {
            e.preventDefault();

            const interval = setInterval(() => {
                if (window.livecallClient) {
                    try {
                        window.livecallClient.queryInterest();
                    } catch {
                        setTimeout(() => window.livecallClient.queryInterest(), 500); // Retry once.
                    } finally {
                        clearInterval(interval);
                    }
                }
            }, 100);
        });
    },

    function quote(window, $) {
        let shouldLoop = true;
        let interval;

        function initializeQuotes() {
            $('.li-quotes:not(:first-child)').hide();

            let isMouseOver = false;

            function animQuote(num) {
                $('#common-section-quotes .container-nav .nav').removeClass('active');

                if (!$('.li-quotes:nth-child(' + num + ')').hasClass('active')) {
                    $('.li-quotes').removeClass('active');
                    setTimeout(() => {
                        $(`#common-section-quotes .container-nav .nav:nth-child(${num})`).addClass('active');
                        $('#common-section-quotes .quote').removeClass('show');

                        $('.li-quotes').show();

                        for (let i = 1; i <= $('.li-quotes').length; i++) {
                            if (i != num) $(`.li-quotes:nth-child(${i})`).hide();
                        }

                        $(`.li-quotes:nth-child(${num})`).addClass('active');
                    }, 500);
                }
            }

            if (window.matchMedia('(max-width: 800px)').matches) {
                $('.li-quotes').addClass('active');
            } else {
                let num = 2;
                interval = setInterval(() => {
                    if (shouldLoop && !isMouseOver) {
                        animQuote(num);
                        num = num >= $('#common-section-quotes .container-nav .nav').length ? 1 : (num += 1);
                    }
                }, 5000);
            }

            $('#common-section-quotes .container-nav .nav').click(function () {
                animQuote($(this).attr('data-liquote'));
                shouldLoop = false;
            });
            $('#common-section-quotes .quote-content').click(function () {
                $(this.parentNode).toggleClass('show');
                shouldLoop = false;
            });
            $('#common-section-quotes .quote-content').mouseover(function () {
                isMouseOver = true;
            });
            $('#common-section-quotes .quote-content').mouseleave(function () {
                isMouseOver = false;
            });
        }

        initializeQuotes();

        return () => {
            shouldLoop = false;
            if (interval) clearInterval(interval);
        };
    },

    function viewportChecker(window, $) {
        const $window = $(window);

        function listener() {
            const windowHeight = $window.height();
            $('[data-vpc]').each(function () {
                const $el = $(this);
                const factor = Number($el.attr('data-vpc-factor') || 0);
                if ($window.scrollTop() >= $el.offset().top - windowHeight * (1 - factor)) {
                    if (!$el.hasClass('reach')) $el.addClass('reach');
                    const className = $el.attr('data-vpc');
                    if (className && !$el.hasClass(className)) $el.addClass(className);
                }
            });
        }

        listener();
        window.addEventListener('scroll', listener, { passive: true });
        return () => window.removeEventListener('scroll', listener);
    },
];

const runScripts = (window, $): (() => void)[] => {
    return scripts.map(fn => {
        try {
            return fn(window, $);
        } catch (e) {
            console.log(e);
        }
    });
};

const wait = (window): Promise<(() => void)[]> => {
    return new Promise(resolve => {
        if (!window) return resolve(null);
        if (window.jQuery) {
            return resolve(runScripts(window, window.jQuery));
        } else {
            setTimeout(() => wait(window), 50);
        }
    });
};

export default (): Promise<(() => void)[]> => {
    try {
        return wait(window);
    } catch {
        return null;
    }
};
