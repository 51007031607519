import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import * as React from 'react';

import useRedirect from '../../../hooks/useRedirect';
import { breakpoint } from '../../../styles/theme';
import ArrowButton from '../../UI/Button/ArrowButton';
import { slugify } from '../utils';
import { addUtm } from './Card';

const Container = styled.div`
    margin-bottom: 24px;
    ${breakpoint.laptop} {
        margin-bottom: 32px;
    }
`;

export const Button = ({
    slice,
    index,
}: {
    slice: {
        primary: {
            content_text: {
                richText: RichTextBlock[];
            };
            link: {
                url: string;
            };
        };
    };
    index: string;
}) => {
    const { getUrl } = useRedirect(slice.primary.link.url);
    const url = addUtm(getUrl());
    const label = slice.primary.content_text.richText[0].text;
    return (
        <Container>
            <ArrowButton
                actionType="INTERNAL_LINK"
                trackingId={`blog-cta-${slugify(label)}-${index}`}
                linkOptions={{
                    href: url,
                    target: '_blank',
                }}
            >
                {label}
            </ArrowButton>
        </Container>
    );
};

export const query = graphql`
    fragment VideoDataButton on PrismicVideoDataBodyButton {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
