import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface FormationData {
    masseSalariale: string;
    typeEntreprise: 'moins11' | 'plus11';
    masseSalarialeCDD: string;
    alternanceObjectif: boolean;
}

interface FormationResults {
    cfp: number;
    professionnalisation: number;
    cppCdd: number;
    total: number;
    details: {
        label: string;
        montant: number;
        taux: number;
        description: string;
    }[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const ContributionFormation: React.FC = () => {
    const [formData, setFormData] = useState<FormationData>({
        masseSalariale: '',
        typeEntreprise: 'moins11',
        masseSalarialeCDD: '',
        alternanceObjectif: false,
    });

    const [results, setResults] = useState<FormationResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateContribution = () => {
        const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100;

        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const masseSalarialeCDD = parseFloat(formData.masseSalarialeCDD) || 0;

        // Taux selon l'effectif
        const tauxCFP = formData.typeEntreprise === 'moins11' ? 0.0055 : 0.01;
        const tauxPro = formData.typeEntreprise === 'moins11' ? 0.0055 : 0.013;
        const tauxCPFCDD = 0.01;

        // Calcul des contributions avec arrondi
        const cfp = roundToTwoDecimals(masseSalariale * tauxCFP);
        const professionnalisation = roundToTwoDecimals(masseSalariale * tauxPro);
        const cppCdd = roundToTwoDecimals(masseSalarialeCDD * tauxCPFCDD);

        const total = roundToTwoDecimals(cfp + professionnalisation + cppCdd);

        // Détails des calculs
        const details = [
            {
                label: 'Formation professionnelle',
                montant: cfp,
                taux: roundToTwoDecimals(tauxCFP * 100),
                description: `Contribution principale de ${roundToTwoDecimals(tauxCFP * 100)}% de la masse salariale`,
            },
            {
                label: 'CPF-CDD',
                montant: cppCdd,
                taux: roundToTwoDecimals(tauxCPFCDD * 100),
                description: '1% de la masse salariale des CDD pour leur formation',
            },
        ];

        setResults({
            cfp,
            professionnalisation,
            cppCdd,
            total,
            details,
        });
    };

    const handleSubmit = () => {
        calculateContribution();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'moins11', label: 'Moins de 11 salariés' },
        { value: 'plus11', label: '11 salariés et plus (pendant 5 années civiles consécutives)' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de contribution formation" />
                <SimulatorAlert text="Les contributions relatives aux conventions collectives ne sont pas prises en compte." />

                <RadioButtonGroup
                    label="Nombre de salariés"
                    options={radioOptions}
                    value={formData.typeEntreprise}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, typeEntreprise: value as 'moins11' | 'plus11' }))
                    }
                    direction="column"
                />

                <InputNumber
                    label="Masse salariale brute (exclure les apprentis)"
                    value={Number(formData.masseSalariale) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalariale: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 500000"
                />

                <InputNumber
                    label="Dont masse salariale brute CDD"
                    value={Number(formData.masseSalarialeCDD) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalarialeCDD: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 50000"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.masseSalariale}
                >
                    Calculer la contribution
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Contribution totale',
                                value: `${results.total.toLocaleString('fr-FR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })} €`,
                                highlight: true,
                            },
                            {
                                label: 'Masse salariale',
                                value: `${parseFloat(formData.masseSalariale).toLocaleString('fr-FR')} €`,
                            },
                            ...results.details
                                .map(detail => [
                                    {
                                        label: `${detail.label} (${detail.taux}%)`,
                                        value: `${detail.montant.toLocaleString('fr-FR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })} €`,
                                        description: detail.description,
                                    },
                                ])
                                .flat(),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default ContributionFormation;
