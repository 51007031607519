import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import * as React from 'react';
import Cookies from 'universal-cookie';

import MeetUsContextProvider from './src/contexts/MeetUsContextProvider';
import AuthorPrismicPage from './src/templates/authorPrismic';
import CategoryPage from './src/templates/categoryPrismic';
import Guide from './src/templates/guidePrismic';
import Post from './src/templates/postPrismic';
import TagPage from './src/templates/tagPrismic';
import Video from './src/templates/video';
import { identifyHubspot } from './src/tracking/identifyHubspot';
import { trackCustomBehavioralEventHubspot } from './src/tracking/trackCustomBehavioralEventHubspot';
import { linkResolver } from './src/utils/linkResolver';

export const onInitialClientRender = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    adBlockDetector && adBlockDetector();

    const dataLayer = window.dataLayer || [];
    dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    if (window?.Intercom) {
        window.Intercom('onUserEmailSupplied', function () {
            const intercom_iframe = document?.querySelector('.intercom-messenger-frame')?.children[0];
            const email = intercom_iframe?.contentDocument?.body.querySelector('input[type="email"]')?.value;
            identifyHubspot(email);
            trackCustomBehavioralEventHubspot('pe9258772_marketing_website_intercom_email_supplied', {});
        });
    }
};

export const onRouteUpdate = location => {
    mixpanel.init(process.env.GATSBY_MIXPANEL_API_KEY, { debug: process.env.GATSBY_MIXPANEL_DEBUG_MODE === 'true' });
    if (mixpanel) {
        const cookies = new Cookies();
        const dg_referral = cookies.get('dg_referral');

        mixpanel.track('Revenue Marketing WebSite Page Viewed', {
            Platform: 'Marketing WebSite',
            URL: location.location.pathname,
            dg_referral,
            branch: process.env.BRANCH,
        });
    }
};

export const wrapRootElement = ({ element }) => {
    return (
        <MeetUsContextProvider>
            <PrismicPreviewProvider
                repositoryConfigs={[
                    {
                        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
                        linkResolver: linkResolver,
                        componentResolver: {
                            post: Post,
                            video: Video,
                            tag: TagPage,
                            author: AuthorPrismicPage,
                            guide: Guide,
                            category: CategoryPage,
                        },
                    },
                ]}
            >
                {element}
            </PrismicPreviewProvider>
        </MeetUsContextProvider>
    );
};
