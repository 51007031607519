import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface CapitalData {
    formeJuridique: 'sarl' | 'sas' | 'eurl' | 'sasu';
    activite: 'commerce' | 'service' | 'artisanat' | 'liberal';
    investissementInitial: string;
    besoinTresorerie: string;
}

interface CapitalResults {
    capitalMinimum: number;
    capitalRecommande: number;
    capitalOptimal: number;
    justification: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CapitalSocial: React.FC = () => {
    const [formData, setFormData] = useState<CapitalData>({
        formeJuridique: 'sarl',
        activite: 'commerce',
        investissementInitial: '',
        besoinTresorerie: '',
    });

    const [results, setResults] = useState<CapitalResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCapital = () => {
        const investissement = parseFloat(formData.investissementInitial) || 0;
        const tresorerie = parseFloat(formData.besoinTresorerie) || 0;

        // Calcul du capital minimum légal
        let capitalMinimum = 1; // Par défaut 1€
        if (formData.formeJuridique === 'sarl' && formData.activite === 'liberal') {
            capitalMinimum = 15000; // SELARL
        }

        // Calcul du capital recommandé (basé sur les besoins réels)
        const capitalRecommande = 1000;

        // Calcul du capital optimal (avec marge de sécurité)
        const capitalOptimal = investissement + tresorerie - capitalRecommande;

        // Justifications des recommandations
        const justification = [
            `Le capital minimum légal est de ${capitalMinimum}€ pour votre forme juridique.`,
            `Nous recommandons un capital de ${Math.round(capitalRecommande)}€ pour couvrir 30% des investissements et 50% du besoin en trésorerie.`,
            `Le capital optimal de ${Math.round(capitalOptimal)}€ offre une marge de sécurité supplémentaire de 20%.`,
            capitalOptimal > 50000
                ? 'Un capital social élevé renforce votre crédibilité auprès des banques et partenaires.'
                : 'Un capital social modéré est adapté à votre activité.',
        ].filter(item => item !== '');

        setResults({
            capitalMinimum,
            capitalRecommande,
            capitalOptimal,
            justification,
        });
    };

    const handleSubmit = () => {
        calculateCapital();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de capital social" />
                <SimulatorAlert text="Ce calculateur n'est pas adapté aux sociétés de transport qui sont soumises à des exigences spécifiques de capital social minimum." />

                <InputNumber
                    label="Investissements initiaux prévus"
                    value={Number(formData.investissementInitial) || null}
                    setValue={value => setFormData(prev => ({ ...prev, investissementInitial: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 50000"
                />

                <InputNumber
                    label="Besoin en trésorerie"
                    value={Number(formData.besoinTresorerie) || null}
                    setValue={value => setFormData(prev => ({ ...prev, besoinTresorerie: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 20000"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.investissementInitial || !formData.besoinTresorerie}
                >
                    Calculer le capital social
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Capital recommandé',
                                value: `${Math.round(results.capitalRecommande).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Capital minimum légal',
                                value: `${Math.round(results.capitalMinimum).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Apport en compte courant recommandé',
                                value: `${Math.round(results.capitalOptimal).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CapitalSocial;
