import styled from '@emotion/styled';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import React from 'react';

import { breakpoint, colors } from '../../../styles/theme';

const COLORS = {
    Green: {
        border: '#A5E9D4',
        background: '#E7FAF4',
    },
};

const ICONS = {
    Sirene: '/images/emojis/sirene.png',
    Cadeau: '/images/emojis/cadeau.png',
    Calendrier: '/images/emojis/calendrier.png',
    Confettis: '/images/emojis/confettis.png',
    Diagramme: '/images/emojis/diagramme.png',
    Terre: '/images/emojis/terre.png',
    Exclamation: '/images/emojis/exclamation.png',
    Ampoule: '/images/emojis/ampoule.png',
    Calculatrice: '/images/emojis/calculatrice.png',
    Danger: '/images/emojis/danger.png',
};

const Container = styled.div<{ borderColor: string; backgroundColor: string }>`
    display: flex;
    border: solid 1px ${props => props.borderColor};
    background: ${props => props.backgroundColor};
    border-radius: 16px;
    padding: 24px;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    ${breakpoint.laptop} {
        margin-bottom: 32px;
    }

    p {
        margin-bottom: 0;
    }
`;

const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

const StyledText = styled.p`
    font-size: 16px;
    line-height: 170%;
    color: ${colors.$primary700};
    font-weight: 600;

    ${breakpoint.laptop} {
        font-size: 18px;
    }
`;

const htmlSerializer = (type: string, element, content, children) => {
    if (type === 'paragraph') {
        return <StyledText>{children}</StyledText>;
    }
};

const HighlightCard = ({
    slice,
}: {
    slice: { primary: { text: { richText: RichTextBlock[] }; icon: keyof typeof ICONS; background_color: string } };
}) => {
    const richText = slice.primary.text.richText;
    const icon = slice.primary.icon;
    const color = slice.primary.background_color;

    return (
        <Container borderColor={COLORS[color].border} backgroundColor={COLORS[color].background}>
            <Icon src={ICONS[icon]} />
            <RichText render={richText} htmlSerializer={htmlSerializer} />
        </Container>
    );
};

export default HighlightCard;
