import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useCallback } from 'react';

import { ROUTES } from '../../../constants/const/routes.const';
import { useAddBlockContext } from '../../../contexts/addBlockContextProvider';
import useModal from '../../../hooks/useModal';
import { breakpoint } from '../../../styles/theme';
import HubspotForm from '../../hubspot/HubspotForm';
import Modal from '../../modals/Modal';
import { SeoTitle } from '../../seo/seoTitle';

const FooterNav = styled.div<{ isHidden: boolean }>`
    display: ${props => (props.isHidden ? 'none' : 'flex')} !important;
`;

const Newsletter = styled.div<{ isHidden: boolean }>`
    display: ${props => (props.isHidden ? 'none' : 'block')} !important;
`;

const ContainerLogo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${breakpoint.laptop} {
        flex-direction: row;
    }

    .logo {
        height: 36px;
        margin-bottom: 8px;
    }

    p {
        color: #fff;
        margin-bottom: 80px;
    }
`;

const Footer = ({ isMinimal }: { isMinimal?: boolean }) => {
    const { isModalOpen, openModal, closeModal } = useModal();
    const isAdblockDetected = useAddBlockContext();
    const showCookie = useCallback(() => {
        (window as any).OneTrust.ToggleInfoDisplay();
    }, []);

    return (
        <>
            <Modal isOpen={isModalOpen} close={closeModal} size="small">
                <div>
                    <iframe
                        style={{ border: 0, height: '200px', width: '400px' }}
                        src="https://dougs.matomo.cloud/index.php?module=CoreAdminHome&action=optOut&language=fr"
                    ></iframe>
                </div>
            </Modal>
            <footer id="footer">
                <div className="wrapper">
                    <ContainerLogo>
                        <div>
                            {isMinimal ? (
                                <img
                                    width="140"
                                    height="36"
                                    className="logo"
                                    src="/images/footer/logo-dougs-expert-comptable-en-ligne.svg"
                                    alt="Dougs expert comptable en ligne"
                                />
                            ) : (
                                <Link
                                    data-ga-send="event,button,click,footer-logo"
                                    data-ga4="footer-level1-img-logodougs-click-link"
                                    to={'/'}
                                >
                                    <img
                                        width="140"
                                        height="36"
                                        className="logo"
                                        src="/images/footer/logo-dougs-expert-comptable-en-ligne.svg"
                                        alt="Dougs expert comptable en ligne"
                                    />
                                </Link>
                            )}

                            <p>L'Expert Comptable en ligne qui vous simplifie vraiment la vie.</p>
                        </div>
                        <Newsletter isHidden={isMinimal} className="container-newsletter">
                            <SeoTitle className="mb-2" seoStyle="h4">
                                Inscrivez-vous
                                <br />à la newsletter Dougs !
                            </SeoTitle>
                            <HubspotForm
                                target="newsletter"
                                trackingId="newsletterFooter"
                                formId="082f21eb-87c0-4625-8f8f-ae703005c966"
                            />
                        </Newsletter>
                    </ContainerLogo>
                    <FooterNav isHidden={isMinimal} className="container-ul">
                        <ul>
                            <li>Services</li>
                            <li>
                                <Link
                                    data-ga-send="event,link,click,footer-service-expert-comptable"
                                    data-ga4="footer-level1-text-title1-click-link"
                                    to={'/expert-comptable/'}
                                >
                                    Expert comptable en ligne
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,link,click,footer-service-creation"
                                    data-ga4="footer-level1-text-title2-click-link"
                                    to={ROUTES.COMPANY_CREATION.uri}
                                >
                                    {ROUTES.COMPANY_CREATION.name} en ligne
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-feature-invoincing"
                                    data-ga4="footer-level1-text-title3-click-link"
                                    to={ROUTES.FACTURATION.uri}
                                >
                                    Logiciel de facturation gratuit
                                </Link>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-academy"
                                    data-ga4="footer-level1-text-title4-click-link"
                                    target="_blank"
                                    rel="noopener nofollow"
                                    href="https://formation.dougs.fr/"
                                >
                                    Formation Dougs Academy
                                </a>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-feature-social"
                                    data-ga4="footer-level1-text-title5-click-link"
                                    to={'/fiches-de-paie-en-ligne/'}
                                >
                                    Gestion sociale
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>À Propos</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-contact"
                                    data-ga4="footer-level1-text-title6-click-link"
                                    to={'/contact/'}
                                >
                                    Nous contacter
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-pricing"
                                    data-ga4="footer-level1-text-title7-click-link"
                                    to={ROUTES.PRICES.uri}
                                >
                                    {ROUTES.PRICES.name}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-api"
                                    data-ga4="footer-level2-text-title5-click-link"
                                    to={'/api/'}
                                >
                                    API
                                </Link>
                            </li>
                            <li>
                                <a
                                    rel="nofollow"
                                    data-ga-send="event,button,click,footer-signup"
                                    data-ga4="footer-level1-text-title8-click-link"
                                    href={'https://app.dougs.fr/signup'}
                                >
                                    Inscription
                                </a>
                            </li>

                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-testimony"
                                    data-ga4="footer-level1-text-title9-click-link"
                                    to={'/avis-temoignages/'}
                                >
                                    Témoignages
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-presse"
                                    data-ga4="footer-level1-text-title10-click-link"
                                    to={'/presse/'}
                                >
                                    Presse
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-parrainage"
                                    data-ga4="footer-level1-text-title11-click-link"
                                    to={'/dougs-parrainage/'}
                                >
                                    Parrainage
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-sitemap"
                                    data-ga4="footer-level1-text-title12-click-link"
                                    to={'/plan-du-site/'}
                                >
                                    Plan du site
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-blog"
                                    data-ga4="footer-level1-text-title11-click-link"
                                    to={ROUTES.MEDIA.uri}
                                >
                                    Média
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-video-demo"
                                    data-ga4="footer-level1-text-title12-click-link"
                                    to={'/demo'}
                                >
                                    Vidéo de démonstration
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-blog"
                                    data-ga4="footer-level1-text-title12-click-link"
                                    to={ROUTES.BLOG.uri}
                                >
                                    {ROUTES.BLOG.name}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-guide"
                                    data-ga4="footer-level1-text-title13-click-link"
                                    to={ROUTES.GUIDES.uri}
                                >
                                    {ROUTES.GUIDES.name}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-customer-case"
                                    data-ga4="footer-level1-text-title14-click-link"
                                    to={ROUTES.CUSTOMER_CASES.uri}
                                >
                                    {ROUTES.CUSTOMER_CASES.name}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-webinar"
                                    data-ga4="footer-level1-text-title15-click-link"
                                    to={ROUTES.WEBINAR.uri}
                                >
                                    {ROUTES.WEBINAR.name}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-faq"
                                    data-ga4="footer-level1-text-title15.1-click-link"
                                    to={'/faq/'}
                                >
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-video"
                                    data-ga4="footer-level1-text-title15.2-click-link"
                                    to={'/videos/'}
                                >
                                    Videos
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-partner"
                                    data-ga4="footer-level1-text-title15.3-click-link"
                                    to={ROUTES.PARTNER_CONTENT.uri}
                                >
                                    {ROUTES.PARTNER_CONTENT.name}
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>Dougs Family</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-team"
                                    data-ga4="footer-level1-text-title16-click-link"
                                    to={'/equipe/'}
                                >
                                    L'équipe
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-jobs"
                                    data-ga4="footer-level1-text-title17-click-link"
                                    to={'/jobs/'}
                                >
                                    Jobs
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>Simulateurs et calculs</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-all-simulateurs"
                                    data-ga4="footer-level1-text-title18-1-click-link"
                                    to={'/simulateurs/'}
                                >
                                    Voir tous nos simulateurs
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-outils-simulation"
                                    data-ga4="footer-level1-text-title18-1-click-link"
                                    to={'/outils-simulation/'}
                                >
                                    Outils de simulation
                                </Link>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-TVS"
                                    data-ga4="footer-level1-text-title18-click-link"
                                    href={'https://chez.dougs.fr/checklist-tvs/'}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    Calcul TVS
                                </a>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-IS"
                                    data-ga4="footer-level1-text-title19-click-link"
                                    href={'https://chez.dougs.fr/calendrier-is/'}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    Calcul impôt sur les sociétés
                                </a>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-TVA"
                                    data-ga4="footer-level1-text-title20-click-link"
                                    href={'https://chez.dougs.fr/simulateur-tva-ecommerce/'}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    Simulateur TVA e-commerce
                                </a>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-facture"
                                    data-ga4="footer-level1-text-title21-click-link"
                                    href={'https://chez.dougs.fr/modeles-de-facture/'}
                                    target="_blank"
                                    rel="noopener nofollow"
                                >
                                    Modèle de facture pdf
                                </a>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-cotisations-sociales"
                                    data-ga4="footer-level1-text-title22-click-link"
                                    target="_blank"
                                    rel="noopener nofollow"
                                    href={'https://chez.dougs.fr/simulateur-cotisations-sociales'}
                                >
                                    Simulateur cotisations sociales
                                </a>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-cotisations-ssi"
                                    data-ga4="footer-level1-text-title23-click-link"
                                    target="_blank"
                                    rel="noopener nofollow"
                                    href={'https://chez.dougs.fr/simulateur-cotisations-sociales'}
                                >
                                    Simulateur cotisation ssi
                                </a>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-simulateur-retraite"
                                    data-ga4="footer-level1-text-title24-click-link"
                                    to={'/blog/retraite-des-independants/'}
                                >
                                    Calcul retraite indépendant
                                </Link>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-simulateur-remuneration-dirigeants"
                                    data-ga4="footer-level1-text-title25-click-link"
                                    target="_blank"
                                    rel="noopener nofollow"
                                    href={'https://chez.dougs.fr/simulateur-remuneration-dirigeants'}
                                >
                                    Simulateur de rémunération
                                </a>
                            </li>
                        </ul>
                        <ul>
                            <li>Création d’entreprise</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-creation-sasu"
                                    data-ga4="footer-level1-text-title26-click-link"
                                    to={'/blog/creer-une-sasu/'}
                                >
                                    Créez votre SASU
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-creation-sas"
                                    data-ga4="footer-level1-text-title27-click-link"
                                    to={'/blog/tout-savoir-sur-la-creation-dune-sas/'}
                                >
                                    Créez votre SAS
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-creation-sci"
                                    data-ga4="footer-level1-text-title28-click-link"
                                    to={'/blog/creer-une-sci-tous-nos-conseils-dexperts-pour-la-creer-facilement/'}
                                >
                                    Créez votre SCI
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-creation-sarl"
                                    data-ga4="footer-level1-text-title29-click-link"
                                    to={'/blog/creation-dune-sarl-comment-ca-marche/'}
                                >
                                    Créez votre SARL
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-creation-holding"
                                    data-ga4="footer-level1-text-title30-click-link"
                                    to={'/blog/creer-une-holding-comment-et-pourquoi/'}
                                >
                                    Créez votre holding
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>Expert-comptable</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-expert-comptable-sasu"
                                    data-ga4="footer-level1-text-title31-click-link"
                                    to={'/blog/des-obligations-en-comptabilite-sasu/'}
                                >
                                    Expert comptable sasu
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-expert-comptable-cout"
                                    data-ga4="footer-level1-text-title32-click-link"
                                    to={'/blog/combien-coute-un-expert-comptable-en-ligne/'}
                                >
                                    Combien coûte un comptable
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>Légal</li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-cgu"
                                    data-ga4="footer-level1-text-title33-click-link"
                                    to={'/cgu/'}
                                >
                                    Conditions générales d’utilisation
                                </Link>
                            </li>

                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-cookies-policy"
                                    data-ga4="footer-level1-text-title34-click-link"
                                    to={'/politique-cookies/'}
                                >
                                    Politique des cookies
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-legal-mention"
                                    data-ga4="footer-level1-text-title34-click-link"
                                    to={'/mentions-legales/'}
                                >
                                    Mentions légales
                                </Link>
                            </li>
                            <li>
                                <Link
                                    data-ga-send="event,button,click,footer-confidential-policy"
                                    data-ga4="footer-level1-text-title34.1-click-link"
                                    to={'/politique-confidentialite/'}
                                >
                                    Politique de confidentialité
                                </Link>
                            </li>
                            <li>
                                <a
                                    data-ga-send="event,button,click,footer-footer-cookies"
                                    data-ga4="footer-level1-text-title35-click-link"
                                    role="button"
                                    className="u-clickable"
                                    onClick={showCookie}
                                >
                                    Paramétrer les cookies
                                </a>
                            </li>
                            {!isAdblockDetected && (
                                <li>
                                    <a
                                        data-ga-send="event,button,click,footer-footer-cookies-matomo"
                                        data-ga4="footer-level1-text-title36-click-link"
                                        role="button"
                                        className="u-clickable"
                                        onClick={openModal}
                                    >
                                        Paramétrer le suivi
                                    </a>
                                </li>
                            )}
                        </ul>
                    </FooterNav>
                </div>

                <div className="foot">
                    <div className="container-left">
                        <p>
                            © 2025 Dougs Compta. Cabinet d'expertise-comptable en ligne enregistré à l’Ordre. Tous
                            droits réservés.
                        </p>
                        {!isMinimal && (
                            <div className="li-rs">
                                <a
                                    href="https://twitter.com/dougscompta"
                                    data-ga4="footer-level2-img-logotwitter-click-link"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        width="17"
                                        height="17"
                                        src="/images/common/icn-twitter.svg"
                                        alt="Dougs sur twitter"
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/dougscompta"
                                    data-ga4="footer-level2-img-logofacebook-click-link"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        width="17"
                                        height="17"
                                        src="/images/common/icn-facebook.svg"
                                        alt="Dougs sur facebook"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/dougs-compta"
                                    data-ga4="footer-level2-img-logolinkedin-click-link"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        width="17"
                                        height="17"
                                        src="/images/common/icn-linkedin.svg"
                                        alt="Dougs sur linkedIn"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/dougs.fr"
                                    data-ga4="footer-level2-img-logoInstagram-click-link"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        width="17"
                                        height="17"
                                        src="/images/common/icn-instagram.png"
                                        alt="Dougs sur Instagram"
                                    />
                                </a>
                                <a
                                    href="https://www.youtube.com/@dougs.fr_"
                                    data-ga4="footer-level2-img-logoYoutube-click-link"
                                    target="_blank"
                                    rel="noopener"
                                >
                                    <img
                                        width="17"
                                        height="17"
                                        src="/images/common/icn-youtube.png"
                                        alt="Dougs sur Youtube"
                                    />
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="container-right">
                        <StaticImage
                            src="../../../images/footer/dougs-expert-comptable-en-ligne-pas-cher.png"
                            alt="Expert-comptable en ligne"
                        />
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
