import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface ComparisonData {
    are: 'oui' | 'non';
    activitePrincipale: 'oui' | 'non';
    couvertureSociale: 'importante' | 'standard';
    strategieRemuneration: 'reguliere' | 'dividendes';
}

interface ComparisonResults {
    formeRecommandee: 'SASU' | 'EURL';
    score: {
        sasu: number;
        eurl: number;
    };
    avantages: string[];
    inconvenients: string[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const SasuEurl: React.FC = () => {
    const [formData, setFormData] = useState<ComparisonData>({
        are: 'non',
        activitePrincipale: 'oui',
        couvertureSociale: 'standard',
        strategieRemuneration: 'reguliere',
    });

    const [results, setResults] = useState<ComparisonResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateRecommendation = () => {
        let sasuScore = 0;
        let eurlScore = 0;
        const recommandations: string[] = [];

        // Analyse ARE
        if (formData.are === 'oui') {
            sasuScore += 10; // La SASU permet de cumuler ARE et rémunération
            recommandations.push("La SASU permet de cumuler l'ARE avec une rémunération de dirigeant");
        } else {
            eurlScore += 1;
        }

        // Analyse activité principale
        if (formData.activitePrincipale === 'non') {
            sasuScore += 1; // EURL plus flexible pour activité secondaire
            recommandations.push('La SASU est plus adaptée pour une activité secondaire');
        } else {
            eurlScore += 2; // Plus adapté pour une activité principale
        }

        // Analyse couverture sociale (maintenant séparée)
        if (formData.couvertureSociale === 'importante') {
            sasuScore += 3; // Meilleure protection sociale en SASU
            recommandations.push('La SASU offre une meilleure protection sociale (régime général)');
        } else {
            eurlScore += 1; // EURL plus avantageuse si protection standard
        }

        // Analyse stratégie de rémunération
        if (formData.strategieRemuneration === 'reguliere') {
            eurlScore += 2; // EURL plus avantageuse pour dividendes
            recommandations.push("L'EURL est idéale pour une rémunération régulière (salaire)");
        } else {
            sasuScore += 2; // SASU mieux adaptée pour salaire régulier
            recommandations.push('La SASU est plus avantageuse pour une stratégie "dividendes"');
        }

        const formeRecommandee = sasuScore > eurlScore ? 'SASU' : 'EURL';

        const avantages =
            formeRecommandee === 'SASU'
                ? [
                      'Statut de salarié assimilé',
                      'Meilleure protection sociale',
                      'Cumul possible avec ARE',
                      'Optimisation salaire/dividendes',
                  ]
                : [
                      'Charges sociales TNS plus faibles',
                      'Flexibilité de gestion',
                      'Moins de formalisme',
                      'Coûts de constitution moindres',
                      'Régime fiscal plus simple',
                  ];

        const inconvenients = [];

        setResults({
            formeRecommandee,
            score: { sasu: sasuScore, eurl: eurlScore },
            avantages,
            inconvenients,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateRecommendation();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Comparatif SASU vs EURL" />

                <RadioButtonGroup
                    label="Touchez-vous les ARE (Allocation Retour à l'Emploi) ?"
                    options={[
                        { value: 'oui', label: 'Oui' },
                        { value: 'non', label: 'Non' },
                    ]}
                    value={formData.are}
                    onChange={value => setFormData(prev => ({ ...prev, are: value as 'oui' | 'non' }))}
                    direction="row"
                />

                <RadioButtonGroup
                    label="Cette activité sera-t-elle votre activité principale ?"
                    options={[
                        { value: 'oui', label: 'Oui' },
                        { value: 'non', label: 'Non' },
                    ]}
                    value={formData.activitePrincipale}
                    onChange={value => setFormData(prev => ({ ...prev, activitePrincipale: value as 'oui' | 'non' }))}
                    direction="row"
                />

                <RadioButtonGroup
                    label="Quels sont vos besoins en matière de couverture sociale ?"
                    options={[
                        {
                            value: 'importante',
                            label: 'Couverture importante OU je suis déjà couvert par une autre activité',
                        },
                        { value: 'standard', label: 'Couverture basique' },
                    ]}
                    value={formData.couvertureSociale}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, couvertureSociale: value as 'importante' | 'standard' }))
                    }
                    direction="column"
                />

                <RadioButtonGroup
                    label="Quelle est votre stratégie de rémunération préférée ?"
                    options={[
                        { value: 'reguliere', label: 'Rémunération régulière (mensuelle/bimestrielle)' },
                        { value: 'dividendes', label: 'Principalement sous forme de dividendes' },
                    ]}
                    value={formData.strategieRemuneration}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, strategieRemuneration: value as 'reguliere' | 'dividendes' }))
                    }
                    direction="column"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                >
                    Comparer SASU et EURL
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Forme juridique recommandée',
                                value: results.formeRecommandee,
                                highlight: true,
                            },
                            {
                                description: 'Avantages',
                                label: results.avantages,
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default SasuEurl;
