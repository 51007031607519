import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface InteressementData {
    benefice: string;
    masseSalariale: string;
    effectif: string;
    formule: 'benefice' | 'performance';
    montantParticipation: string;
    tauxInteressement: string;
    plafondIndividuel: string;
}

interface InteressementResults {
    montantGlobal: number;
    montantMoyen: number;
    plafondAtteint: boolean;
    plafondGlobalAtteint: boolean;
    montantAvantPlafonnementGlobal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const Interessement: React.FC = () => {
    const [formData, setFormData] = useState<InteressementData>({
        benefice: '',
        masseSalariale: '',
        effectif: '',
        formule: 'benefice',
        montantParticipation: '',
        tauxInteressement: '20',
        plafondIndividuel: '35325',
    });

    const [results, setResults] = useState<InteressementResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateInteressement = () => {
        const benefice = parseFloat(formData.benefice) || 0;
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const effectif = parseInt(formData.effectif) || 0;
        const tauxInteressement = parseFloat(formData.tauxInteressement) / 100;
        const plafondIndividuel = parseFloat(formData.plafondIndividuel) || 35325;

        let montantGlobal = 0;

        // Calcul selon la formule choisie
        switch (formData.formule) {
            case 'benefice':
                montantGlobal = benefice * tauxInteressement;
                break;
            case 'performance':
                montantGlobal = parseFloat(formData.montantParticipation) || 0;
                break;
        }

        // Plafonnement global (20% de la masse salariale)
        const plafondGlobal = masseSalariale * 0.2;
        const montantAvantPlafonnementGlobal = montantGlobal;
        montantGlobal = Math.min(montantGlobal, plafondGlobal);

        // Montant moyen par salarié (plafonné)
        const montantMoyenAvantPlafonnement = montantGlobal / effectif;
        let montantMoyen = montantMoyenAvantPlafonnement;
        montantMoyen = Math.min(montantMoyen, plafondIndividuel);

        const plafondAtteint = montantMoyen === plafondIndividuel;
        const plafondGlobalAtteint = montantGlobal < montantAvantPlafonnementGlobal;

        const details = [
            {
                label: 'Base de calcul',
                montant: formData.formule === 'benefice' ? benefice : masseSalariale,
                description: formData.formule === 'benefice' ? 'Bénéfice net' : 'Masse salariale',
            },
            {
                label: "Taux d'intéressement",
                montant: tauxInteressement * 100,
                description:
                    formData.formule === 'performance'
                        ? 'Taux réduit de 50% (objectifs non atteints)'
                        : `Taux appliqué : ${tauxInteressement * 100}%`,
            },
            {
                label: 'Plafond global (20% MS)',
                montant: plafondGlobal,
                description: '20% de la masse salariale annuelle',
            },
            {
                label: 'Plafond individuel',
                montant: plafondIndividuel,
                description: 'Plafond par bénéficiaire',
            },
        ];

        // Ajout des notes de plafonnement si nécessaire
        if (plafondGlobalAtteint) {
            details.push({
                label: 'Note plafonnement global',
                montant: montantAvantPlafonnementGlobal,
                description: 'Montant avant plafonnement global (20% MS)',
            });
        }

        if (plafondAtteint) {
            details.push({
                label: 'Note plafonnement individuel',
                montant: montantMoyenAvantPlafonnement,
                description: 'Montant moyen par bénéficiaire avant plafonnement',
            });
        }

        setResults({
            montantGlobal,
            montantMoyen,
            plafondAtteint,
            plafondGlobalAtteint,
            montantAvantPlafonnementGlobal,
            details,
        });
    };

    const handleSubmit = () => {
        calculateInteressement();
        if (!logged) handleOpen();
    };

    const formuleOptions = [
        { value: 'benefice', label: 'Intéressement sur bénéfices (RCAI)' },
        { value: 'performance', label: 'Intéressement sur les performances' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Simulateur d'intéressement" />

                <RadioButtonGroup
                    label="Type de formule"
                    options={formuleOptions}
                    value={formData.formule}
                    onChange={value =>
                        setFormData(prev => ({
                            ...prev,
                            formule: value as 'benefice' | 'performance',
                        }))
                    }
                    direction="column"
                />

                {formData.formule === 'benefice' && (
                    <InputNumber
                        label="Bénéfice net"
                        value={Number(formData.benefice) || null}
                        setValue={value => setFormData(prev => ({ ...prev, benefice: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 100000"
                    />
                )}

                <InputNumber
                    label="Masse salariale"
                    value={Number(formData.masseSalariale) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalariale: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 500000"
                />

                <InputNumber
                    label="Effectif"
                    value={Number(formData.effectif) || null}
                    setValue={value => setFormData(prev => ({ ...prev, effectif: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 25"
                />

                {formData.formule !== 'performance' && (
                    <InputNumber
                        label="Taux d'intéressement"
                        value={Number(formData.tauxInteressement) || null}
                        setValue={value => setFormData(prev => ({ ...prev, tauxInteressement: String(value) }))}
                        endAdornment={<>%</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 20"
                    />
                )}

                {formData.formule === 'performance' && (
                    <InputNumber
                        label="Montant de la participation (si objectifs atteints)"
                        value={Number(formData.montantParticipation) || null}
                        setValue={value => setFormData(prev => ({ ...prev, montantParticipation: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 50000"
                    />
                )}

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.masseSalariale || !formData.effectif}
                >
                    Calculer l'intéressement
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Intéressement global plafonné',
                                value: `${Math.round(results.montantGlobal).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Montant par bénéficiaire',
                                value: `${Math.round(results.montantMoyen).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: [
                                    ...(results.plafondGlobalAtteint
                                        ? [
                                              `Votre intéressement global de ${Math.round(results.montantAvantPlafonnementGlobal).toLocaleString('fr-FR')}€ a été plafonné à 20% de la masse salariale.`,
                                          ]
                                        : []),
                                    ...(formData.formule === 'performance'
                                        ? [
                                              'Si les objectifs ne sont que partiellement atteints, vous pouvez envisager de mettre en place des paliers dans votre accord.',
                                          ]
                                        : []),
                                    'Chaque bénéficiaire est soumis à un plafond individuel de 35 325€ maximum.',
                                    "L'intéressement est exonéré de cotisations sociales mais soumis à la CSG-RDS (9,7%). Il est imposé sur le revenu du bénéficiaire en cas de perception directe mais exonéré d'impôt s'il est placé sur un plan d'épargne salariale (PEE/PER).",
                                    "Le simulateur applique une répartition uniforme (même montant pour tous les bénéficiaires). D'autres modes de répartition sont possibles (durée de présence, proportionnalité au salaire, panachage des critères).",
                                ],
                                value: '',
                                description: 'Informations',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default Interessement;
