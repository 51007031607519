import { useForm } from 'react-hook-form';

import { useMeetUsContext } from '../../../contexts/MeetUsContextProvider';
import { identifyHubspot } from '../../../tracking/identifyHubspot';
import { trackCustomBehavioralEventHubspot } from '../../../tracking/trackCustomBehavioralEventHubspot';
import { IneligibleReasons, payloadType, Steps } from '../types/types';

const useMeetUsForm = () => {
    const defaultValues = {
        email: '',
        phone: '',
        lastName: '',
        firstName: '',
        activity: '',
        location: '',
        serviceOfInterest: '',
        activityFreeInput: '',
        client: false,
        fetch: false,
    };

    const {
        control,
        setError,
        formState: { errors, isSubmitting },
        handleSubmit,
        watch,
    } = useForm({ defaultValues });

    const moreDetail = watch('activity');

    const { setCalendlyLink, setStep, getCalendlyLink, setPayload } = useMeetUsContext();

    async function onSubmit(data: payloadType) {
        identifyHubspot(data.email);
        trackCustomBehavioralEventHubspot('pe9258772_marketing_website_contact_form_filled', {});

        setPayload(data);
        if (data.client) {
            return setStep(Steps.OldClient);
        }
        try {
            const response = await getCalendlyLink(data);

            if (typeof response === 'string') {
                setCalendlyLink(response);
                setStep(Steps.Calendly);
                return;
            }

            if (response[1]) {
                // we have two ineligible reasons
                setStep(Steps.Both);
                return;
            }

            if (response[0] === IneligibleReasons.Location) {
                // we have an ineligible location
                setStep(Steps.NotFrance);
            } else {
                // we have an ineligible activity
                setStep(Steps.BadActivity);
            }
        } catch (err) {
            console.error(err);
            setError('fetch', {
                type: 'server',
                message: 'Une erreur est servenue, veuillez réessayer',
            });
        }
    }

    return { control, moreDetail, errors, isSubmitting, onSubmit, handleSubmit };
};

export default useMeetUsForm;
