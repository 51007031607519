import React from 'react';

import RotateIcon from './../../../../images/icons/arrows-rotate.svg';
import ArrowIcon from './../../../../images/icons/fa-arrow-right.svg';
import {
    DefaultButton,
    getColors,
    getSizes,
    LinkButton,
    OutlinedButton,
    StyledAnchor,
    StyledDiv,
    StyledLink,
} from './styles';
import { ACTION_TYPE, ARROW_BUTTON_COLOR, FULL_WIDTH, ICON_TYPE, SIZE, VARIANT } from './types';

type Props = {
    actionType: keyof typeof ACTION_TYPE;
    variant?: keyof typeof VARIANT;
    color?: keyof typeof ARROW_BUTTON_COLOR;
    size?: keyof typeof SIZE;
    fullWidth?: keyof typeof FULL_WIDTH;
    children: React.ReactNode;
    trackingId?: string;
    onClick?: () => void;
    linkOptions?: {
        href?: string;
        rel?: string;
        target?: string;
    };
    id?: string;
    isWrap?: boolean;
    disabled?: boolean;
    icon?: keyof typeof ICON_TYPE;
};

const ArrowButton = (props: Props) => {
    if (props.actionType === ACTION_TYPE.EXTERNAL_LINK) {
        return (
            <StyledAnchor
                href={props.linkOptions.href}
                data-ga-send={`event,button,click,${props.trackingId}`}
                rel={props.linkOptions.rel}
                target={props.linkOptions.target}
                fullWidth={props.fullWidth}
                variant={props.variant}
                id={props.id}
            >
                <Button {...props} />
            </StyledAnchor>
        );
    }

    if (props.actionType === ACTION_TYPE.INTERNAL_LINK) {
        return (
            <StyledLink
                to={props.linkOptions.href}
                data-ga-send={`event,button,click,${props.trackingId}`}
                rel={props.linkOptions.rel}
                target={props.linkOptions.target}
                fullWidth={props.fullWidth}
                variant={props.variant}
                id={props.id}
            >
                <Button {...props} />
            </StyledLink>
        );
    }

    return (
        <StyledDiv
            onClick={props.onClick}
            data-ga-send={`event,button,click,${props.trackingId}`}
            fullWidth={props.fullWidth}
            variant={props.variant}
            id={props.id}
            disabled={props.disabled}
        >
            <Button {...props} />
        </StyledDiv>
    );
};

const Button = ({
    variant = VARIANT.DEFAULT,
    size = SIZE.NORMAL,
    color = ARROW_BUTTON_COLOR.PRIMARY,
    fullWidth,
    children,
    isWrap,
    icon = ICON_TYPE.ARROW,
}: Props) => {
    const colors = getColors(color);
    const sizes = getSizes(size);

    const Icon = icon === ICON_TYPE.ROTATE ? RotateIcon : ArrowIcon;

    if (variant === VARIANT.LINK) {
        return (
            <LinkButton colors={colors}>
                <span>{children}</span>
                <Icon fill={colors.primary} width={16} />
            </LinkButton>
        );
    }

    if (variant === VARIANT.OUTLINE) {
        return (
            <OutlinedButton sizes={sizes} colors={colors} fullWidth={fullWidth}>
                <div>
                    <span>{children}</span> <Icon fill={colors.primary} width={sizes.arrowWidth} />
                </div>
            </OutlinedButton>
        );
    }
    return (
        <DefaultButton
            sizes={sizes}
            colors={colors}
            fullWidth={fullWidth}
            isWrap={isWrap}
            haveBorder={color === 'LIGHT_ORANGE'}
        >
            <div>
                <span>{children}</span>
                <Icon fill={colors.secondary} width={sizes.arrowWidth} />
            </div>
        </DefaultButton>
    );
};

export default ArrowButton;
