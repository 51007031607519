import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import useModal from '../../../hooks/useModal';
import { breakpoint } from '../../../styles/theme';
import { ImageData } from '../../../types/Image';
import HubspotForm from '../../hubspot/HubspotForm';
import Modal from '../../modals/Modal';
import { SeoTitle } from '../../seo/seoTitle';
import { Button } from '../../UI/Button/legacy/button/Button';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${breakpoint['$large-max']} {
        width: 100%;
    }

    width: 50%;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 0 120px 80px;

    ${breakpoint['$large-max']} {
        padding: 0 40px 80px;
    }

    justify-content: space-between;

    :last-child {
        padding-bottom: 0;
    }
`;

const TypeTitle = styled.div`
    font-weight: 400;
    font-size: 16px;

    color: #11abec;
`;

const Body = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #013a51;
`;

const ImageCentered = ({ image }: { image: ImageData }) => (
    <div className="u-textCenter u-hideMedium">
        <GatsbyImage alt={image.alt} image={image.gatsbyImageData} />
    </div>
);

const RowImage = ({
    item,
    left,
    index,
    position,
}: {
    item: Queries.PrismicTagDataBodyMiseEnAvantItem;
    left: boolean;
    index: number;
    position: number;
}) => {
    const { isModalOpen, openModal, closeModal } = useModal();
    let modal = null;
    let button = (
        <a data-testid="row-image" href={item.cta_link1} target="_blank" rel="noopener nofollow">
            <Button
                data-ga-send={`event,button,click,cta-simulateur${index}-supertag`}
                data-ga4={`container-level${position}-cta-simulateur${index}-click-link`}
            >
                {item.cta_text1}
            </Button>
        </a>
    );
    if (item.internal_content_link?.document) {
        const document = item.internal_content_link.document.data as Queries.PrismicChecklistData;
        modal = (
            <Modal isOpen={isModalOpen} close={closeModal} size="small">
                <div className="h2">{document.cta_text + ' gratuite.'}</div>
                <HubspotForm trackingId={document.keyword} formId={document.form_hubspot_id}></HubspotForm>
            </Modal>
        );
        button = (
            <div onClick={openModal}>
                <Button
                    data-ga-send={`event,button,click,cta-checklist${index}-supertag`}
                    data-ga4={`container-level${position}-cta-checklist${index}-click-link`}
                >
                    {item.cta_text1}
                </Button>
            </div>
        );
    }

    return (
        <>
            {modal}
            <Row>
                {!left && <ImageCentered image={item.image as unknown as ImageData} />}
                <Wrapper>
                    <TypeTitle className="mb-1">{item.type}</TypeTitle>
                    <SeoTitle className="mb-1" as="div" seoStyle="h2">
                        {item.title}
                    </SeoTitle>
                    <Body className="mb-2">{item.text}</Body>
                    {button}
                </Wrapper>
                {left && <ImageCentered image={item as unknown as ImageData} />}
            </Row>
        </>
    );
};

export const Highlight = ({
    slice,
    position,
}: {
    slice: {
        items: Queries.PrismicTagDataBodyMiseEnAvantItem[];
    };
    position: number;
}) => {
    return (
        <div className="section section--light section--large mb-10">
            <div>
                {slice.items.map((item, index) => (
                    <RowImage position={position} index={index} key={index} item={item} left={index % 2 === 0} />
                ))}
            </div>
        </div>
    );
};
