import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { AddBlockContextProvider, MUIThemeProvider, PageContextProvider } from '.';

const RootContextProvider = ({ children }: { children: React.ReactNode }) => {
    const data = useStaticQuery(graphql`
        query {
            allPrismicWebsiteconfig {
                nodes {
                    data {
                        google_score
                        trustpilot_score
                        customer_number
                        google_number
                        trustpilot_number
                    }
                }
            }
        }
    `);

    const { google_score, trustpilot_score, customer_number, google_number, trustpilot_number } = data
        .allPrismicWebsiteconfig.nodes[0].data as Queries.PrismicWebsiteconfigData;

    return (
        <MUIThemeProvider>
            <AddBlockContextProvider>
                <PageContextProvider
                    context={{
                        google_score,
                        trustpilot_score,
                        customer_number,
                        google_number,
                        trustpilot_number,
                    }}
                >
                    {children}
                </PageContextProvider>
            </AddBlockContextProvider>
        </MUIThemeProvider>
    );
};

export default RootContextProvider;
