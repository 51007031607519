import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface LicenciementData {
    salaireBrut: string;
    anciennete: string;
    age: string;
}

interface LicenciementResults {
    indemnites: {
        legale: number;
        conventionnelle: number | null;
        preavis: number;
        conges: number;
        transaction: number | null;
    };
    charges: {
        patronales: number;
        salariales: number;
    };
    coutTotal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CoutLicenciement: React.FC = () => {
    const [formData, setFormData] = useState<LicenciementData>({
        salaireBrut: '',
        anciennete: '',
        age: '',
    });

    const [results, setResults] = useState<LicenciementResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateLicenciement = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const anciennete = parseFloat(formData.anciennete) || 0;
        const age = parseInt(formData.age) || 0;

        // Calcul de l'indemnité légale
        let indemniteLegale = 0;
        if (anciennete >= 8 / 12) {
            // 8 mois minimum
            if (anciennete < 10) {
                indemniteLegale = salaireBrut * 0.25 * anciennete;
            } else {
                indemniteLegale = salaireBrut * 0.25 * 10 + salaireBrut * 0.33 * (anciennete - 10);
            }
        }

        // Estimation de l'indemnité conventionnelle (si applicable)
        const indemniteConventionnelle = null;

        // Calcul du préavis (fixé à 3 mois)
        const montantPreavis = salaireBrut * 3;

        // Calcul des congés payés
        const montantConges = 0;

        // Estimation d'une éventuelle transaction
        let montantTransaction = null;
        if (age > 50 || anciennete > 10) {
            montantTransaction = indemniteLegale * 0.5;
        }

        // Calcul des charges sociales
        const chargesPatronales = (montantPreavis + montantConges) * 0.45;
        const chargesSalariales = (montantPreavis + montantConges) * 0.22;

        // Calcul du coût total
        const coutTotal =
            (indemniteConventionnelle || indemniteLegale) +
            montantPreavis +
            montantConges +
            (montantTransaction || 0) +
            chargesPatronales;

        // Détails des calculs
        const details = [
            {
                label: 'Indemnité légale minimale',
                montant: indemniteLegale,
                description: `Calculée sur ${anciennete} années d'ancienneté`,
            },
        ];

        // Recommandations
        const recommandations = [];

        if (age > 55) {
            recommandations.push('Attention aux obligations spécifiques pour les seniors');
        }

        if (anciennete > 15) {
            recommandations.push(
                "Dans le cas d'un licenciement économique, vérifiez les obligations de reclassement renforcées",
            );
        }

        setResults({
            indemnites: {
                legale: indemniteLegale,
                conventionnelle: indemniteConventionnelle,
                preavis: montantPreavis,
                conges: montantConges,
                transaction: montantTransaction,
            },
            charges: {
                patronales: chargesPatronales,
                salariales: chargesSalariales,
            },
            coutTotal,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateLicenciement();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de coût de licenciement" />
                <SimulatorAlert text="Ce simulateur calcule l'indemnité légale minimale. L'indemnité conventionnelle, qui dépend de votre convention collective, peut être plus avantageuse et sera alors celle qui s'appliquera." />

                <InputNumber
                    label="Salaire mensuel brut"
                    value={Number(formData.salaireBrut) || null}
                    setValue={value => setFormData(prev => ({ ...prev, salaireBrut: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 2500"
                />

                <InputNumber
                    label="Ancienneté (années)"
                    value={Number(formData.anciennete) || null}
                    setValue={value => setFormData(prev => ({ ...prev, anciennete: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 5"
                />

                <InputNumber
                    label="Âge du salarié"
                    value={Number(formData.age) || null}
                    setValue={value => setFormData(prev => ({ ...prev, age: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 45"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.salaireBrut || !formData.anciennete}
                >
                    Calculer le coût
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Coût total',
                                value: `${Math.round(results.coutTotal).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Indemnité légale minimale',
                                value: `${Math.round(results.indemnites.legale).toLocaleString('fr-FR')} €`,
                                highlight: true,
                                description: `Calculée sur ${formData.anciennete} années d'ancienneté`,
                            },
                            {
                                label: 'Préavis',
                                value: `${Math.round(results.indemnites.preavis).toLocaleString('fr-FR')} €`,
                            },
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CoutLicenciement;
