import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { css } from 'styled-components';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import { breakpoint } from '../../../../styles/theme';
import { HEADER } from '../constants/header.const';

type DefaultTopPaddingType = {
    desktop?: number;
    tablet?: number;
    mobile?: number;
};

const DEFAULT_TOP_PADDING = {
    desktop: 60,
    tablet: 24,
    mobile: 12,
} as const;

const Padding = styled.div<{ headerHeight: number; padding: DefaultTopPaddingType; isBannerFixed: boolean }>`
    padding-top: ${props =>
        props.headerHeight +
        props.padding.mobile +
        (props.isBannerFixed ? HEADER.HEIGHT.BANNER.PROMO_MOBILE : 0)}px !important;

    ${breakpoint.tablet} {
        padding-top: ${props =>
            props.headerHeight +
            props.padding.tablet +
            (props.isBannerFixed ? HEADER.HEIGHT.BANNER.PROMO_DESKTOP : 0)}px !important;
    }

    ${breakpoint.desktop} {
        padding-top: ${props =>
            props.headerHeight +
            props.padding.desktop +
            (props.isBannerFixed ? HEADER.HEIGHT.BANNER.PROMO_DESKTOP : 0)}px !important;
    }
`;

const TopPaddingBasedOnHeader = ({
    padding = DEFAULT_TOP_PADDING,
    children,
    as,
    ...args
}: {
    padding?: DefaultTopPaddingType;
    children?: ReactNode;
    as?: 'div' | 'section';
    [key: string]: unknown;
}) => {
    const { stickyHeader, bannerOptions } = useHeaderContext();

    const paddingWithProps = {
        ...DEFAULT_TOP_PADDING,
        ...padding,
    };

    return (
        <Padding
            headerHeight={stickyHeader?.cssHeight ?? 0}
            isBannerFixed={bannerOptions?.isVisible ?? false}
            padding={paddingWithProps}
            as={as}
            {...args}
        >
            {children}
        </Padding>
    );
};

export default TopPaddingBasedOnHeader;
