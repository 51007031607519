import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import * as React from 'react';

import useRedirect from '../../../hooks/useRedirect';
import { breakpoint, colors, fonts } from '../../../styles/theme';
import { slugify, transformTextImageToGatsbyImage, unbreackableSpace } from '../utils';
import { addUtm } from './Card';
import ZoomableImage from './ZoomableImage';

interface TextProps {
    slice: {
        primary: {
            content_text: {
                richText: RichTextBlock[];
            };
        };
    };
}

const StyledH2 = styled.h2`
    color: ${colors.$primary700};

    font-family: ${fonts.Eina_SemiBold};
    font-size: 22px;
    padding-left: 0 !important;
    line-height: 125%;

    ::before {
        display: none;
    }
    ${breakpoint.desktop} {
        font-size: 32px;
    }
`;

const StyledH3 = styled.h3`
    color: ${colors.$primary700};

    font-family: ${fonts.Eina_SemiBold};
    font-size: 18px;
    padding-left: 0 !important;
    line-height: 125%;

    ${breakpoint.desktop} {
        font-size: 24px;
    }
`;

const StyledH4 = styled.h4`
    color: ${colors.$primary700};

    font-family: ${fonts.Eina_SemiBold};
    font-size: 18px;
    padding-left: 0 !important;
    line-height: 125%;

    ${breakpoint.desktop} {
        font-size: 24px;
    }
`;

const StyledText = styled.p`
    font-size: 16px;
    line-height: 170%;
    color: ${colors.$primary700};

    ${breakpoint.laptop} {
        font-size: 18px;
    }
`;

const StyledVideo = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* Ratio 16:9 pour les vidéos YouTube */
    height: 0;
    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const ImageContainer = styled.div`
    margin-top: 48px;
    margin-bottom: 48px;
`;

const htmlSerializer = (type: string, element, content, children) => {
    if (type === 'paragraph') {
        const unbreackableChildren = children => {
            return children.map(element => {
                if (Array.isArray(element) && typeof element[0] === 'string') {
                    return [unbreackableSpace(element[0])];
                }
                return element;
            });
        };

        return <StyledText>{unbreackableChildren(children)}</StyledText>;
    }
    if (type === 'heading2') {
        const id = slugify(element.text);
        return (
            <StyledH2 key={id} className="u-scroll-margin-top" id={id}>
                {unbreackableSpace(element.text)}
            </StyledH2>
        );
    }
    if (type === 'heading3') {
        return <StyledH3 key={element.type}>{unbreackableSpace(element.text)}</StyledH3>;
    }
    if (type === 'heading4') {
        return <StyledH4 key={element.type}>{unbreackableSpace(element.text)}</StyledH4>;
    }

    if (type === 'list-item') {
        return <li key={element.type}>{unbreackableSpace(element.text)}</li>;
    }

    if (type === 'embed') {
        return (
            <StyledVideo>
                <div
                    key={element.oembed.type}
                    data-oembed={`${element.oembed.embed_url}`}
                    data-oembed-type={`${element.oembed.type}`}
                    data-oembed-provider={`${element.oembed.provider_name}`}
                    data-ga-send="event,button,click,blog-video"
                    itemProp="video"
                    itemScope
                    itemType="https://schema.org/VideoObject"
                >
                    <meta itemProp="embedURL" content={element.oembed.embed_url} />
                    <meta itemProp="thumbnailUrl" content={element.oembed.thumbnail_url} />
                    <meta itemProp="uploadDate" content={'26/04/2021'} />
                    <meta itemProp="name" content={element.oembed.title} />
                    <meta itemProp="description" content={element.oembed.title} />
                    <div dangerouslySetInnerHTML={{ __html: element.oembed.html }} />
                </div>
            </StyledVideo>
        );
    }
    if (type === 'hyperlink') {
        const { getUrl } = useRedirect(element.data.url);
        const url = addUtm(getUrl());
        return (
            <a
                key={element.data.url}
                target="_blank"
                rel="noopener"
                data-ga-send="event,button,click,blog-body-link"
                data-ga4="container-body-cta-product-click-link"
                href={url}
            >
                {children}
            </a>
        );
    }
    if (type === 'image') {
        return (
            <ImageContainer>
                <ZoomableImage image={transformTextImageToGatsbyImage(element)} alt={element.alt} />
            </ImageContainer>
        );
    }
};

export const Text = ({ slice }: TextProps) => {
    return <RichText render={slice.primary.content_text.richText} htmlSerializer={htmlSerializer} />;
};

export const query = graphql`
    fragment VideoDataText on PrismicVideoDataBodyNormalText {
        primary {
            content_text {
                richText
                text
            }
        }
        slice_type
    }
`;
