import styled from '@emotion/styled';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';

import { colors, fonts } from '../../../styles/theme';
import SimulatorInputLabel from './SimulatorInputLabel';

interface RadioOption {
    value: string;
    label: string;
}

interface RadioButtonGroupProps {
    options: RadioOption[];
    value: string | boolean;
    onChange: (value: string) => void;
    label?: string;
    direction?: 'row' | 'column';
    tooltipText?: string;
}

const RadioGroupContainer = styled.div`
    border: 1px solid ${colors.$gray300};
    border-radius: 4px;
    padding: 0 16px;
`;

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    options,
    value,
    onChange,
    label,
    direction = 'row',
    tooltipText,
}) => {
    return (
        <div>
            {!!label && <SimulatorInputLabel text={label} tooltipText={tooltipText} />}
            <RadioGroupContainer>
                <RadioGroup row={direction === 'row'} value={value} onChange={e => onChange(e.target.value)}>
                    {options.map(option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            sx={{
                                flex: direction === 'row' ? 1 : 'initial',
                                '& .MuiFormControlLabel-label': {
                                    fontSize: '14px',
                                    color: String(value) === option.value ? colors.$primary700 : colors.$gray350,
                                    fontFamily:
                                        String(value) === option.value
                                            ? fonts.OpenSans_SemiBold
                                            : fonts.OpenSans_Regular,
                                },
                            }}
                        />
                    ))}
                </RadioGroup>
            </RadioGroupContainer>
        </div>
    );
};

export default RadioButtonGroup;
