import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputDate from '../components/InputDate';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import Select from '../components/Select';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const TaxeVehiculesSociete: React.FC = () => {
    const [dateImmatriculation, setDateImmatriculation] = useState<string>('');
    const [emissionsCO2, setEmissionsCO2] = useState<number>(0);
    const [puissanceFiscale, setPuissanceFiscale] = useState<number>(0);
    const [critAir, setCritAir] = useState<string>('');
    const [isHybride, setIsHybride] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);

    const { handleOpen, logged } = useSimulatorContext();

    useEffect(() => {
        setShowResults(false);
    }, [dateImmatriculation, emissionsCO2, puissanceFiscale, critAir, isHybride]);

    const calculTaxeCO2 = () => {
        const dateImmat = new Date(dateImmatriculation);
        const date2020 = new Date('2020-03-01');
        const date2004 = new Date('2004-06-01');

        // Véhicules WLTP (immatriculés après mars 2020)
        if (dateImmat >= date2020) {
            let taxe = 0;

            // Tranche ≤ 14g : 0€
            // Tranche 15-55g : 1€/g
            if (emissionsCO2 > 14) {
                taxe += Math.min(emissionsCO2 - 14, 41) * 1;
            }
            // Tranche 56-63g : 2€/g
            if (emissionsCO2 > 55) {
                taxe += Math.min(emissionsCO2 - 55, 8) * 2;
            }
            // Tranche 64-95g : 3€/g
            if (emissionsCO2 > 63) {
                taxe += Math.min(emissionsCO2 - 63, 32) * 3;
            }
            // Tranche 96-115g : 4€/g
            if (emissionsCO2 > 95) {
                taxe += Math.min(emissionsCO2 - 95, 20) * 4;
            }
            // Tranche 116-135g : 10€/g
            if (emissionsCO2 > 115) {
                taxe += Math.min(emissionsCO2 - 115, 20) * 10;
            }
            // Tranche 136-155g : 50€/g
            if (emissionsCO2 > 135) {
                taxe += Math.min(emissionsCO2 - 135, 20) * 50;
            }
            // Tranche 156-175g : 60€/g
            if (emissionsCO2 > 155) {
                taxe += Math.min(emissionsCO2 - 155, 20) * 60;
            }
            // Tranche > 175g : 65€/g
            if (emissionsCO2 > 175) {
                taxe += (emissionsCO2 - 175) * 65;
            }

            return taxe;
        }
        // Véhicules entre juin 2004 et février 2020
        else if (dateImmat >= date2004) {
            let taxe = 0;

            // Tranche ≤ 12g : 0€
            // Tranche 13-45g : 1€/g
            if (emissionsCO2 > 12) {
                taxe += Math.min(emissionsCO2 - 12, 33) * 1;
            }
            // Tranche 46-52g : 2€/g
            if (emissionsCO2 > 45) {
                taxe += Math.min(emissionsCO2 - 45, 7) * 2;
            }
            // Tranche 53-79g : 3€/g
            if (emissionsCO2 > 52) {
                taxe += Math.min(emissionsCO2 - 52, 27) * 3;
            }
            // Tranche 80-95g : 4€/g
            if (emissionsCO2 > 79) {
                taxe += Math.min(emissionsCO2 - 79, 16) * 4;
            }
            // Tranche 96-112g : 10€/g
            if (emissionsCO2 > 95) {
                taxe += Math.min(emissionsCO2 - 95, 17) * 10;
            }
            // Tranche 113-128g : 50€/g
            if (emissionsCO2 > 112) {
                taxe += Math.min(emissionsCO2 - 112, 16) * 50;
            }
            // Tranche 129-145g : 60€/g
            if (emissionsCO2 > 128) {
                taxe += Math.min(emissionsCO2 - 128, 17) * 60;
            }
            // Tranche > 145g : 65€/g
            if (emissionsCO2 > 145) {
                taxe += (emissionsCO2 - 145) * 65;
            }

            return taxe;
        }
        // Véhicules avant juin 2004 (puissance fiscale)
        else {
            const bareme = [1500, 2250, 3750, 4750, 6000];
            let taxe = 0;
            if (puissanceFiscale <= 3) taxe = bareme[0];
            else if (puissanceFiscale <= 6) taxe = bareme[0] + bareme[1];
            else if (puissanceFiscale <= 10) taxe = bareme[0] + bareme[1] + bareme[2];
            else if (puissanceFiscale <= 15) taxe = bareme[0] + bareme[1] + bareme[2] + bareme[3];
            else taxe = bareme[0] + bareme[1] + bareme[2] + bareme[3] + bareme[4];
            return taxe;
        }
    };

    const calculTaxePolluants = () => {
        switch (critAir) {
            case '0':
                return 0;
            case '1':
                return 100;
            default:
                return 500;
        }
    };

    const handleCalculer = () => {
        setShowResults(true);
        if (!logged) handleOpen();
    };

    const showPuissanceFiscale = () => {
        if (!dateImmatriculation) return false;
        return new Date(dateImmatriculation) < new Date('2004-06-01');
    };

    const showEmissionsCO2 = () => {
        if (!dateImmatriculation) return false;
        return new Date(dateImmatriculation) >= new Date('2004-06-01');
    };

    const taxeCO2 = isHybride && new Date().getFullYear() <= 2024 ? 0 : calculTaxeCO2();
    const taxePolluants = calculTaxePolluants();
    const totalTaxes = taxeCO2 + taxePolluants;

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Taxe sur les Véhicules de Société" />

                <InputDate
                    label="Première immatriculation"
                    value={dateImmatriculation}
                    setValue={setDateImmatriculation}
                />

                {showEmissionsCO2() && (
                    <InputNumber
                        label="Émissions de CO₂ (g/km)"
                        value={emissionsCO2}
                        setValue={(value: string | number) => setEmissionsCO2(Number(value))}
                        placeholder="Ex: 95"
                    />
                )}

                {showPuissanceFiscale() && (
                    <InputNumber
                        label="Puissance fiscale (CV)"
                        value={puissanceFiscale}
                        setValue={(value: string | number) => setPuissanceFiscale(Number(value))}
                        placeholder="Ex: 6"
                    />
                )}

                <Select
                    label="Vignette Crit'Air"
                    value={critAir}
                    setValue={setCritAir}
                    options={[
                        { value: '0', label: "Crit'Air 0 (Véhicule électrique)" },
                        { value: '1', label: "Crit'Air 1" },
                        { value: '2', label: "Crit'Air 2" },
                        { value: '3', label: "Crit'Air 3" },
                        { value: '4', label: "Crit'Air 4" },
                        { value: '5', label: "Crit'Air 5" },
                    ]}
                    placeholder="Sélectionnez votre vignette Crit'Air"
                />

                <RadioButtonGroup
                    label="Véhicule hybride"
                    options={[
                        { value: 'oui', label: 'Oui' },
                        { value: 'non', label: 'Non' },
                    ]}
                    value={isHybride ? 'oui' : 'non'}
                    onChange={value => setIsHybride(value === 'oui')}
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleCalculer}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!dateImmatriculation || !critAir}
                >
                    Calculer les taxes
                </ArrowButton>
            </SimulatorCard>

            {logged && showResults && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Total des taxes',
                                value: `${totalTaxes.toFixed(2)} €`,
                                highlight: true,
                            },
                            {
                                label: 'Taxe sur les émissions de CO₂',
                                value:
                                    isHybride && new Date().getFullYear() <= 2024
                                        ? "Exonération jusqu'à fin 2024"
                                        : `${taxeCO2.toFixed(2)} €`,
                            },
                            {
                                label: 'Taxe sur les émissions de polluants atmosphériques',
                                value: `${taxePolluants.toFixed(2)} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default TaxeVehiculesSociete;
