// extracted by mini-css-extract-plugin
export var h1 = "media-slogan-block-module--h1--69f04";
export var h2 = "media-slogan-block-module--h2--be953";
export var h3 = "media-slogan-block-module--h3--2e9bc";
export var h4 = "media-slogan-block-module--h4--56d9f";
export var h5 = "media-slogan-block-module--h5--34046";
export var h6 = "media-slogan-block-module--h6--c4073";
export var italic = "media-slogan-block-module--italic--4be9e";
export var mBig = "media-slogan-block-module--m-big--86170";
export var mSmall = "media-slogan-block-module--m-small--14291";
export var p = "media-slogan-block-module--p--02437";
export var sloganBlock = "media-slogan-block-module--slogan-block--15cb7";
export var sloganBlockLogo = "media-slogan-block-module--slogan-block-logo--49539";
export var sloganBlockText = "media-slogan-block-module--slogan-block-text--3e51b";
export var textBordered = "media-slogan-block-module--text-bordered--19a4f";
export var textBordered__wrapper = "media-slogan-block-module--text-bordered__wrapper--3d646";
export var textHalfcircle = "media-slogan-block-module--text-halfcircle--bfa64";
export var textMini = "media-slogan-block-module--text-mini--60c38";
export var titlePattern = "media-slogan-block-module--title-pattern--1cac6";