import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import ArrowButton from '../../UI/Button/ArrowButton';
import RessourceModalProvider, { useModalRessourceContext } from '../context/RessourceModalProvider';
import { ButtonWrapper, Card, Text } from './styles';

const RessourceSlice = ({
    slice,
}: {
    slice: {
        primary: {
            ressource: string;
            cta_text: string;
        };
    };
}) => {
    const data = useStaticQuery(graphql`
        query QuerySimulators {
            prismicSimulators {
                data {
                    simulator {
                        bullet_point1
                        bullet_point3
                        bullet_point2
                        hubspot_form_id
                        keyword
                        show_on_the_resources_page
                        title1 {
                            text
                        }
                    }
                }
            }
        }
    `);

    const ressource = data.prismicSimulators.data.simulator.find(sim => sim.keyword === slice.primary.ressource);

    if (!ressource) return;

    return (
        <RessourceModalProvider>
            <RessourceCard {...ressource} cta_text={slice.primary.cta_text} />
        </RessourceModalProvider>
    );
};

const RessourceCard = ({
    title1,
    bullet_point1,
    bullet_point2,
    bullet_point3,
    hubspot_form_id,
    cta_text,
}: {
    title1: {
        text: string;
    };
    bullet_point1: string;
    bullet_point2: string;
    bullet_point3: string;
    hubspot_form_id: string;
    cta_text: string;
}) => {
    const { handleOpen } = useModalRessourceContext();

    return (
        <RessourceModalProvider>
            <Card>
                <Text>
                    <h3>{title1.text}</h3>
                    <ul>
                        <li>{bullet_point1}</li>
                        <li>{bullet_point2}</li>
                        <li>{bullet_point3}</li>
                    </ul>
                </Text>
                <ButtonWrapper>
                    <ArrowButton
                        actionType="MODAL"
                        trackingId="keyword"
                        color="WHITE"
                        onClick={() => handleOpen({ title: title1.text, form_hubspot_id: hubspot_form_id })}
                    >
                        {cta_text}
                    </ArrowButton>
                </ButtonWrapper>
            </Card>
        </RessourceModalProvider>
    );
};

export default RessourceSlice;
