import styled from '@emotion/styled';
import React from 'react';

import { breakpoint, fonts } from '../../../styles/theme';

interface InputDateProps {
    label: string;
    value: string;
    setValue: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
}

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    ${breakpoint.laptop} {
        max-width: 200px;
    }
`;

const Label = styled.label`
    color: #1e1e1e;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    display: block;
`;

const StyledInput = styled.input`
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8.5px 14px;
    font-family: ${fonts.OpenSans_Bold};
    font-size: 14px;
    width: 100%;
    margin-bottom: 0px;
    background-color: white;
    color: #013a51;
    font-weight: 600;
    height: 37px;

    &::placeholder {
        font-family: ${fonts.OpenSans_Regular};
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
    }

    &:not([value]),
    &[value=''] {
        font-family: ${fonts.OpenSans_Regular};
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
    }

    &::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
`;

const InputDate: React.FC<InputDateProps> = ({ label, value, setValue, placeholder, disabled }) => {
    return (
        <InputContainer>
            <Label>{label}</Label>
            <StyledInput
                type="date"
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
            />
        </InputContainer>
    );
};

export default InputDate;
