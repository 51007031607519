import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface RuptureData {
    salaireBrut: string;
    anciennete: string;
}

interface RuptureResults {
    indemnites: {
        label: string;
        montant: number;
        description: string;
    }[];
    totalBrut: number;
    totalNet: number;
    coutEmployeur: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const RuptureConventionnelle: React.FC = () => {
    const [formData, setFormData] = useState<RuptureData>({
        salaireBrut: '',
        anciennete: '',
    });

    const [results, setResults] = useState<RuptureResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateRupture = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const anciennete = parseFloat(formData.anciennete) || 0;

        // Base de calcul mensuelle
        const baseMensuelle = salaireBrut;

        // Indemnité légale de licenciement
        let indemniteLegale = 0;
        if (anciennete < 10) {
            indemniteLegale = baseMensuelle * 0.25 * anciennete;
        } else {
            indemniteLegale = baseMensuelle * 0.25 * 10 + baseMensuelle * 0.33 * (anciennete - 10);
        }

        // Forfait social
        const forfaitSocial = indemniteLegale * 0.3;

        const indemnites = [
            {
                label: 'Indemnité légale de rupture',
                montant: indemniteLegale,
                description: `${anciennete < 10 ? '1/4' : '1/4 puis 1/3'} de mois par année d'ancienneté`,
            },
            {
                label: 'Forfait social',
                montant: forfaitSocial,
                description: "30% sur l'indemnité légale",
            },
        ];

        const totalBrut = indemniteLegale;
        const totalNet = totalBrut;
        const coutEmployeur = totalBrut + forfaitSocial;

        setResults({
            indemnites,
            totalBrut,
            totalNet,
            coutEmployeur,
        });
    };

    const handleSubmit = () => {
        calculateRupture();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de rupture conventionnelle" />
                <SimulatorAlert text="Ce simulateur ne calcule que l'indemnité légale minimale de rupture conventionnelle : si la convention collective est plus favorable au salarié, il faut l'appliquer à la place." />

                <InputNumber
                    label="Salaire brut mensuel"
                    value={Number(formData.salaireBrut) || null}
                    setValue={value => setFormData(prev => ({ ...prev, salaireBrut: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 2500"
                    tooltipText="Moyenne des 12 derniers mois de salaire avant signature de la rupture, ou moyenne des 3 derniers mois si elle est plus élevée."
                />

                <InputNumber
                    label="Ancienneté"
                    value={Number(formData.anciennete) || null}
                    setValue={value => setFormData(prev => ({ ...prev, anciennete: String(value) }))}
                    placeholder="Ex : 5"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.salaireBrut || !formData.anciennete}
                >
                    Calculer les indemnités
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Net à verser au salarié',
                                value: `${Math.round(results.totalNet).toLocaleString('fr-FR')}\u00A0€`,
                                highlight: true,
                            },
                            {
                                label: 'Coût total employeur',
                                value: `${Math.round(results.coutEmployeur).toLocaleString('fr-FR')}\u00A0€`,
                                highlight: true,
                            },
                            ...results.indemnites.map(indemnite => ({
                                label: indemnite.label,
                                value: `${Math.round(indemnite.montant).toLocaleString('fr-FR')}\u00A0€`,
                                description: indemnite.description,
                            })),
                            {
                                description: 'Recommandations',
                                label: `L'indemnité calculée ne compense que la rupture et n'englobe pas les autres droits du salarié : l'indemnité de congés payés éventuellement acquis doit par exemple être payée en plus et à part.`,
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default RuptureConventionnelle;
