import styled from '@emotion/styled';
import * as React from 'react';

import { breakpoint, colors, fonts } from '../styles/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    padding: 30px;
    background-color: ${colors.$primary200};
    color: ${colors.$primary800};
    border-radius: 16px;
    gap: 20px;

    ${breakpoint.laptop} {
        padding: 40px;
    }

    p {
        margin-bottom: 0;
        font-size: 18px;
    }
`;

const Title = styled.h3`
    font-family: ${fonts.Eina_SemiBold};
    font-size: 18px;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 0;

    ${breakpoint.laptop} {
        font-size: 20px;
    }
`;

const LegalTerm = (props: { legalTerm: Queries.PrismicLegalTermDataBodyLegalTermPrimary }) => {
    const { old_term, new_term, info_text, link, text_link, tag_link, text_tag_link, title } = props.legalTerm;
    const title_content = title || `💡 ${old_term} devient ${new_term}`;
    const hadLink = !!link || !!tag_link;

    return (
        <Container>
            <Title>{title_content}</Title>
            <div dangerouslySetInnerHTML={{ __html: info_text.html }} />
            {hadLink && (
                <div className="u-spaceBetween post__small">
                    {link ? <a href={link} data-ga4="container-legal-text-word1-click-link">{`${text_link}`}</a> : null}
                    {tag_link ? (
                        <a href={tag_link} data-ga4="container-legal-text-word2-click-link">{`${text_tag_link}`}</a>
                    ) : null}
                </div>
            )}
        </Container>
    );
};

export default LegalTerm;
