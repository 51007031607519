import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface Investment {
    description: string;
    montant: string;
    duree: string;
    type: 'materiel' | 'immobilier' | 'incorporel';
    financement: 'autofinancement' | 'emprunt' | 'mixte';
    partEmprunt: string;
    tauxEmprunt: string;
    rentabiliteAttendue: string;
}

interface InvestmentResults {
    totalInvestissements: number;
    amortissementsAnnuels: number;
    chargesFinancieres: number;
    rentabiliteGlobale: number;
    fluxTresorerie: {
        annee: number;
        investissement: number;
        amortissement: number;
        chargesFinancieres: number;
        rentabilite: number;
        fluxNet: number;
    }[];
    ratios: {
        label: string;
        valeur: number;
        interpretation: string;
    }[];
    recommandations: string[];
}

const PlanInvestissement: React.FC = () => {
    const [investments, setInvestments] = useState<Investment[]>([
        {
            description: '',
            montant: '',
            duree: '',
            type: 'materiel',
            financement: 'autofinancement',
            partEmprunt: '0',
            tauxEmprunt: '0',
            rentabiliteAttendue: '',
        },
    ]);

    const [results, setResults] = useState<InvestmentResults | null>(null);

    const addInvestment = () => {
        setInvestments([
            ...investments,
            {
                description: '',
                montant: '',
                duree: '',
                type: 'materiel',
                financement: 'autofinancement',
                partEmprunt: '0',
                tauxEmprunt: '0',
                rentabiliteAttendue: '',
            },
        ]);
    };

    const updateInvestment = (index: number, field: keyof Investment, value: string) => {
        const newInvestments = [...investments];
        newInvestments[index] = { ...newInvestments[index], [field]: value };
        setInvestments(newInvestments);
    };

    const calculatePlan = () => {
        const totalInvestissements = investments.reduce((sum, inv) => sum + (parseFloat(inv.montant) || 0), 0);
        const amortissementsAnnuels = 0;
        const chargesFinancieres = 0;
        const rentabiliteGlobale = 0;

        // Calcul des flux sur 5 ans
        const fluxTresorerie = Array.from({ length: 5 }, (_, annee) => {
            const investissement = annee === 0 ? totalInvestissements : 0;
            const amortissement = investments.reduce((sum, inv) => {
                const duree = parseFloat(inv.duree) || 1;
                return sum + (parseFloat(inv.montant) || 0) / duree;
            }, 0);
            const chargesFinancieres = investments.reduce((sum, inv) => {
                if (inv.financement !== 'autofinancement') {
                    const montantEmprunte = parseFloat(inv.montant) * (parseFloat(inv.partEmprunt) / 100) || 0;
                    const tauxEmprunt = parseFloat(inv.tauxEmprunt) / 100 || 0;
                    return sum + montantEmprunte * tauxEmprunt * Math.max(0, 1 - annee / 5);
                }
                return sum;
            }, 0);
            const rentabilite = investments.reduce((sum, inv) => {
                const rentabiliteAnnuelle = parseFloat(inv.montant) * (parseFloat(inv.rentabiliteAttendue) / 100) || 0;
                return sum + rentabiliteAnnuelle;
            }, 0);
            const fluxNet = rentabilite - chargesFinancieres - (annee === 0 ? investissement : 0);

            return {
                annee: annee + 1,
                investissement,
                amortissement,
                chargesFinancieres,
                rentabilite,
                fluxNet,
            };
        });

        // Calcul des ratios
        const ratios = [
            {
                label: 'Taux de rentabilité interne',
                valeur: calculateIRR(fluxTresorerie.map(f => f.fluxNet)),
                interpretation: 'Mesure la rentabilité intrinsèque du projet',
            },
            {
                label: 'Délai de récupération',
                valeur: calculatePaybackPeriod(fluxTresorerie),
                interpretation: "Nombre d'années pour récupérer l'investissement",
            },
            {
                label: "Taux d'endettement",
                valeur: calculateDebtRatio(investments),
                interpretation: "Part de l'investissement financée par emprunt",
            },
        ];

        // Génération des recommandations
        const recommandations = generateRecommendations(investments, ratios);

        setResults({
            totalInvestissements,
            amortissementsAnnuels,
            chargesFinancieres,
            rentabiliteGlobale,
            fluxTresorerie,
            ratios,
            recommandations,
        });
    };

    // Fonction de calcul du TRI (simplifiée)
    const calculateIRR = (cashFlows: number[]): number => {
        let irr = 0;
        // Implémentation simplifiée du calcul du TRI
        const sumPositiveFlows = cashFlows.reduce((sum, flow) => (flow > 0 ? sum + flow : sum), 0);
        const initialInvestment = Math.abs(cashFlows[0]);
        irr = ((sumPositiveFlows - initialInvestment) / initialInvestment) * 100;
        return Math.round(irr * 10) / 10;
    };

    // Fonction de calcul du délai de récupération
    const calculatePaybackPeriod = (
        fluxTresorerie: {
            annee: number;
            investissement: number;
            amortissement: number;
            chargesFinancieres: number;
            rentabilite: number;
            fluxNet: number;
        }[],
    ): number => {
        let cumulativeFlow = 0;
        for (let i = 0; i < fluxTresorerie.length; i++) {
            cumulativeFlow += fluxTresorerie[i].fluxNet;
            if (cumulativeFlow >= 0) return i + 1;
        }
        return fluxTresorerie.length;
    };

    // Fonction de calcul du taux d'endettement
    const calculateDebtRatio = (investments: Investment[]): number => {
        const totalInvestment = investments.reduce((sum, inv) => sum + (parseFloat(inv.montant) || 0), 0);
        const totalDebt = investments.reduce((sum, inv) => {
            if (inv.financement !== 'autofinancement') {
                return sum + (parseFloat(inv.montant) * (parseFloat(inv.partEmprunt) / 100) || 0);
            }
            return sum;
        }, 0);
        return Math.round((totalDebt / totalInvestment) * 1000) / 10;
    };

    // Fonction de génération des recommandations
    const generateRecommendations = (
        investments: Investment[],
        ratios: {
            label: string;
            valeur: number;
            interpretation: string;
        }[],
    ): string[] => {
        const recommandations = [];
        const irr = ratios[0].valeur;
        const payback = ratios[1].valeur;
        const debtRatio = ratios[2].valeur;

        if (irr < 10) {
            recommandations.push(
                "La rentabilité du projet est faible, envisagez d'optimiser les coûts ou d'augmenter les revenus attendus",
            );
        }
        if (payback > 3) {
            recommandations.push(
                "Le délai de récupération est long, étudiez la possibilité de réduire l'investissement initial",
            );
        }
        if (debtRatio > 70) {
            recommandations.push("Le taux d'endettement est élevé, privilégiez l'autofinancement si possible");
        }
        if (investments.some(inv => inv.type === 'materiel' && parseFloat(inv.duree) > 5)) {
            recommandations.push(
                "Attention à la durée d'amortissement du matériel, privilégiez des durées plus courtes",
            );
        }

        return recommandations;
    };

    return (
        <div className="simulator-content">
            {investments.map((investment, index) => (
                <Paper key={index} elevation={0} sx={{ p: 3, mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Investissement {index + 1}
                    </Typography>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Description"
                            value={investment.description}
                            onChange={e => updateInvestment(index, 'description', e.target.value)}
                            placeholder="Ex: Machine de production"
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Montant (€)"
                            type="number"
                            value={investment.montant}
                            onChange={e => updateInvestment(index, 'montant', e.target.value)}
                            placeholder="Ex: 50000"
                        />
                    </FormControl>

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Durée d'amortissement (années)"
                            type="number"
                            value={investment.duree}
                            onChange={e => updateInvestment(index, 'duree', e.target.value)}
                            placeholder="Ex: 5"
                        />
                    </FormControl>

                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <RadioGroup
                            row
                            value={investment.type}
                            onChange={e => updateInvestment(index, 'type', e.target.value)}
                        >
                            <FormControlLabel
                                value="materiel"
                                control={<Radio />}
                                label="Matériel"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="immobilier"
                                control={<Radio />}
                                label="Immobilier"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="incorporel"
                                control={<Radio />}
                                label="Incorporel"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                        </RadioGroup>
                    </FormControl>

                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <RadioGroup
                            row
                            value={investment.financement}
                            onChange={e => updateInvestment(index, 'financement', e.target.value)}
                        >
                            <FormControlLabel
                                value="autofinancement"
                                control={<Radio />}
                                label="Autofinancement"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="emprunt"
                                control={<Radio />}
                                label="Emprunt"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="mixte"
                                control={<Radio />}
                                label="Mixte"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                        </RadioGroup>
                    </FormControl>

                    {investment.financement !== 'autofinancement' && (
                        <>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label="Part empruntée (%)"
                                    type="number"
                                    value={investment.partEmprunt}
                                    onChange={e => updateInvestment(index, 'partEmprunt', e.target.value)}
                                    placeholder="Ex: 70"
                                />
                            </FormControl>

                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <TextField
                                    fullWidth
                                    label="Taux d'emprunt (%)"
                                    type="number"
                                    value={investment.tauxEmprunt}
                                    onChange={e => updateInvestment(index, 'tauxEmprunt', e.target.value)}
                                    placeholder="Ex: 3"
                                />
                            </FormControl>
                        </>
                    )}

                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <TextField
                            fullWidth
                            label="Rentabilité attendue (%)"
                            type="number"
                            value={investment.rentabiliteAttendue}
                            onChange={e => updateInvestment(index, 'rentabiliteAttendue', e.target.value)}
                            placeholder="Ex: 15"
                        />
                    </FormControl>
                </Paper>
            ))}

            <Button variant="outlined" color="primary" onClick={addInvestment} fullWidth sx={{ mb: 3 }}>
                Ajouter un investissement
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={calculatePlan}
                disabled={!investments.some(inv => inv.montant && inv.duree)}
                fullWidth
                sx={{ mb: 3 }}
            >
                Analyser le plan d'investissement
            </Button>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Investissement total</span>
                        <span className="value">
                            {Math.round(results.totalInvestissements).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Flux de trésorerie prévisionnels</span>
                        <span className="value"></span>
                    </div>

                    {results.fluxTresorerie.map((flux, index) => (
                        <div key={index} className="result-row">
                            <span className="label">Année {flux.annee}</span>
                            <span className="value" style={{ color: flux.fluxNet >= 0 ? '#10B981' : '#EF4444' }}>
                                {Math.round(flux.fluxNet).toLocaleString('fr-FR')} €
                            </span>
                        </div>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ratios d'analyse</span>
                        <span className="value"></span>
                    </div>

                    {results.ratios.map((ratio, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ratio.label}</span>
                                <span className="value">
                                    {ratio.label.includes('Délai') ? `${ratio.valeur} ans` : `${ratio.valeur}%`}
                                </span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {ratio.interpretation}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default PlanInvestissement;
