import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface RatiosData {
    chiffreAffaires: string;
    resultatNet: string;
    capitauxPropres: string;
    totalBilan: string;
    dettes: string;
    actifCirculant: string;
    passifCirculant: string;
    tresorerie: string;
    stocksMoyens: string;
    creancesClients: string;
    dettesFournisseurs: string;
}

interface RatiosResults {
    rentabilite: {
        commerciale: number;
        financiere: number;
        economique: number;
    };
    structure: {
        autonomie: number;
        endettement: number;
        liquidite: number;
    };
    gestion: {
        rotationStocks: number;
        delaiClients: number;
        delaiFournisseurs: number;
        bfr: number;
    };
    appreciation: {
        label: string;
        commentaire: string;
        recommandations: string[];
    };
}

const RatiosFinanciers: React.FC = () => {
    const [formData, setFormData] = useState<RatiosData>({
        chiffreAffaires: '',
        resultatNet: '',
        capitauxPropres: '',
        totalBilan: '',
        dettes: '',
        actifCirculant: '',
        passifCirculant: '',
        tresorerie: '',
        stocksMoyens: '',
        creancesClients: '',
        dettesFournisseurs: '',
    });

    const [results, setResults] = useState<RatiosResults | null>(null);

    const calculateRatios = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rn = parseFloat(formData.resultatNet) || 0;
        const cp = parseFloat(formData.capitauxPropres) || 0;
        const tb = parseFloat(formData.totalBilan) || 0;
        const dettes = parseFloat(formData.dettes) || 0;
        const ac = parseFloat(formData.actifCirculant) || 0;
        const pc = parseFloat(formData.passifCirculant) || 0;
        const stocks = parseFloat(formData.stocksMoyens) || 0;
        const clients = parseFloat(formData.creancesClients) || 0;
        const fournisseurs = parseFloat(formData.dettesFournisseurs) || 0;

        // Calcul des ratios de rentabilité
        const rentabiliteCommerciale = (rn / ca) * 100;
        const rentabiliteFinanciere = (rn / cp) * 100;
        const rentabiliteEconomique = (rn / tb) * 100;

        // Calcul des ratios de structure
        const autonomieFinanciere = (cp / tb) * 100;
        const tauxEndettement = (dettes / cp) * 100;
        const ratioLiquidite = ac / pc;

        // Calcul des ratios de gestion
        const rotationStocks = (stocks * 360) / ca;
        const delaiClients = (clients * 360) / ca;
        const delaiFournisseurs = (fournisseurs * 360) / ca;
        const bfr = ac - pc;

        // Évaluation globale
        let appreciation: { label: string; commentaire: string; recommandations: string[] };

        if (rentabiliteFinanciere > 15 && autonomieFinanciere > 40 && ratioLiquidite > 1.5) {
            appreciation = {
                label: 'Excellente situation financière',
                commentaire: 'Votre entreprise présente une structure financière solide avec une bonne rentabilité.',
                recommandations: [
                    'Envisagez des investissements pour poursuivre la croissance',
                    'Optimisez la trésorerie excédentaire',
                    'Maintenez le niveau de performance actuel',
                ],
            };
        } else if (rentabiliteFinanciere > 8 && autonomieFinanciere > 30 && ratioLiquidite > 1.2) {
            appreciation = {
                label: 'Situation financière satisfaisante',
                commentaire: "La structure financière est équilibrée mais présente des axes d'amélioration.",
                recommandations: [
                    'Renforcez les fonds propres progressivement',
                    'Optimisez le BFR pour améliorer la trésorerie',
                    "Travaillez sur l'amélioration des marges",
                ],
            };
        } else {
            appreciation = {
                label: 'Points de vigilance',
                commentaire: 'Certains indicateurs nécessitent une attention particulière.',
                recommandations: [
                    'Renforcez rapidement les fonds propres',
                    "Réduisez le niveau d'endettement",
                    'Améliorez la gestion du BFR',
                    'Travaillez sur la rentabilité opérationnelle',
                ],
            };
        }

        setResults({
            rentabilite: {
                commerciale: rentabiliteCommerciale,
                financiere: rentabiliteFinanciere,
                economique: rentabiliteEconomique,
            },
            structure: {
                autonomie: autonomieFinanciere,
                endettement: tauxEndettement,
                liquidite: ratioLiquidite,
            },
            gestion: {
                rotationStocks,
                delaiClients,
                delaiFournisseurs,
                bfr,
            },
            appreciation,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Analyse des ratios financiers
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Total bilan (€)"
                        type="number"
                        value={formData.totalBilan}
                        onChange={e => setFormData(prev => ({ ...prev, totalBilan: e.target.value }))}
                        placeholder="Ex: 1500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes financières (€)"
                        type="number"
                        value={formData.dettes}
                        onChange={e => setFormData(prev => ({ ...prev, dettes: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Actif circulant (€)"
                        type="number"
                        value={formData.actifCirculant}
                        onChange={e => setFormData(prev => ({ ...prev, actifCirculant: e.target.value }))}
                        placeholder="Ex: 800000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Passif circulant (€)"
                        type="number"
                        value={formData.passifCirculant}
                        onChange={e => setFormData(prev => ({ ...prev, passifCirculant: e.target.value }))}
                        placeholder="Ex: 600000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Trésorerie (€)"
                        type="number"
                        value={formData.tresorerie}
                        onChange={e => setFormData(prev => ({ ...prev, tresorerie: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stocks moyens (€)"
                        type="number"
                        value={formData.stocksMoyens}
                        onChange={e => setFormData(prev => ({ ...prev, stocksMoyens: e.target.value }))}
                        placeholder="Ex: 400000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Créances clients (€)"
                        type="number"
                        value={formData.creancesClients}
                        onChange={e => setFormData(prev => ({ ...prev, creancesClients: e.target.value }))}
                        placeholder="Ex: 250000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes fournisseurs (€)"
                        type="number"
                        value={formData.dettesFournisseurs}
                        onChange={e => setFormData(prev => ({ ...prev, dettesFournisseurs: e.target.value }))}
                        placeholder="Ex: 150000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateRatios}
                    disabled={!formData.chiffreAffaires || !formData.totalBilan}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser les ratios
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ratios de rentabilité</span>
                        <span className="value"></span>
                    </div>
                    <div className="result-row">
                        <span className="label">Rentabilité commerciale</span>
                        <span className="value">{results.rentabilite.commerciale.toFixed(1)}%</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Rentabilité financière</span>
                        <span className="value">{results.rentabilite.financiere.toFixed(1)}%</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Rentabilité économique</span>
                        <span className="value">{results.rentabilite.economique.toFixed(1)}%</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ratios de structure</span>
                        <span className="value"></span>
                    </div>
                    <div className="result-row">
                        <span className="label">Autonomie financière</span>
                        <span className="value">{results.structure.autonomie.toFixed(1)}%</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Taux d'endettement</span>
                        <span className="value">{results.structure.endettement.toFixed(1)}%</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Ratio de liquidité</span>
                        <span className="value">{results.structure.liquidite.toFixed(2)}</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ratios de gestion</span>
                        <span className="value"></span>
                    </div>
                    <div className="result-row">
                        <span className="label">Rotation des stocks</span>
                        <span className="value">{Math.round(results.gestion.rotationStocks)} jours</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Délai clients</span>
                        <span className="value">{Math.round(results.gestion.delaiClients)} jours</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Délai fournisseurs</span>
                        <span className="value">{Math.round(results.gestion.delaiFournisseurs)} jours</span>
                    </div>
                    <div className="result-row">
                        <span className="label">BFR</span>
                        <span className="value">{Math.round(results.gestion.bfr).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Appréciation globale</span>
                        <span className="value">{results.appreciation.label}</span>
                    </div>
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">{results.appreciation.commentaire}</span>
                    </div>
                    <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                        <span className="label">Recommandations</span>
                        {results.appreciation.recommandations.map((recommandation, index) => (
                            <p key={index} style={{ margin: 0, color: '#666' }}>
                                • {recommandation}
                            </p>
                        ))}
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default RatiosFinanciers;
