import styled from '@emotion/styled';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import React from 'react';

import { breakpoint, colors, fonts } from '../../styles/theme';

const Title = styled.div`
    color: ${colors.$primary};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 12px;
    padding-bottom: 8px;

    ${breakpoint.desktop} {
        font-size: 13px;
    }
`;

const Text = styled.div`
    padding-left: 24px;
    border-left: 2px solid ${colors.$primary};
`;

const IntroText = ({ render }: { render: RichTextBlock[] }) => {
    return (
        <div>
            <Title>DANS CET ARTICLE</Title>
            <Text>
                <RichText render={render} />
            </Text>
        </div>
    );
};

export default IntroText;
