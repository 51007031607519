import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

import { useMeetUsContext } from '../../../contexts/MeetUsContextProvider';
import { mixpanelEvent } from '../../layout/scripts';
import { Steps } from '../types/types';
import { wording } from './../constants/constants';

const Wrapper = styled.div`
    text-align: center;
    img {
        width: 142px;
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1024px) {
        padding: 48px;
        img {
            width: 90px;
        }
    }
`;

const Title = styled.div`
    font-size: 40px;
    font-family: Eina-SemiBold, serif;
    @media screen and (max-width: 1024px) {
        font-size: 32px;
    }

    color: white;
`;

const Paragraph = styled.div`
    color: white;
    max-width: 960px;
`;

type Props = {
    reason: Steps.NotFrance | Steps.BadActivity | Steps.Both;
};

const StepNotEligibleModal = ({ reason }: Props) => {
    const { payload } = useMeetUsContext();
    useEffect(() => {
        mixpanelEvent({ params: 'send,event,modal,click,meet-us-not-eligible'.split(','), options: payload });
    }, [payload]);

    return (
        <Wrapper>
            <img src="/images/blog/illu-25.svg" />
            <Title data-testid="notElegibleTitle" dangerouslySetInnerHTML={{ __html: wording[reason].modal.title }} />
            <Paragraph className="mb-4 mt-2">
                <div dangerouslySetInnerHTML={{ __html: wording[reason].modal.firstParagraph }} />
            </Paragraph>
        </Wrapper>
    );
};

export default StepNotEligibleModal;
