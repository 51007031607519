import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface HoldingData {
    activiteRisque: string;
    societeTransmissible: string;
    croissanceRapide: string;
}

interface HoldingResults {
    recommandation: string;
    explications: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const SimulateurHolding: React.FC = () => {
    const [formData, setFormData] = useState<HoldingData>({
        activiteRisque: '',
        societeTransmissible: '',
        croissanceRapide: '',
    });

    const [results, setResults] = useState<HoldingResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const determinerRecommandation = () => {
        if (
            formData.activiteRisque === 'oui' ||
            formData.societeTransmissible === 'oui' ||
            formData.croissanceRapide === 'oui'
        ) {
            const explications = [];
            if (formData.activiteRisque === 'oui') {
                explications.push('Protéger votre patrimoine');
            }
            if (formData.societeTransmissible === 'oui') {
                explications.push("Faciliter la transmission de l'entreprise");
            }
            if (formData.croissanceRapide === 'oui') {
                explications.push('Optimiser le développement du groupe');
            }

            return {
                recommandation: "La création d'une holding est recommandée",
                explications: explications,
            };
        }

        return {
            recommandation: "La création d'une holding ne semble pas pertinente",
            explications: [],
        };
    };

    const handleSubmit = () => {
        const resultats = determinerRecommandation();
        setResults(resultats);
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'oui', label: 'Oui' },
        { value: 'non', label: 'Non' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Création de holding" />

                <RadioButtonGroup
                    label="L'activité est elle à risque ?"
                    options={radioOptions}
                    value={formData.activiteRisque}
                    onChange={value => setFormData(prev => ({ ...prev, activiteRisque: value }))}
                    tooltipText="Une activité qui peut générer des litiges ou un endettement très fort"
                />

                <RadioButtonGroup
                    label="La société est-elle transmissible ?"
                    options={radioOptions}
                    value={formData.societeTransmissible}
                    onChange={value => setFormData(prev => ({ ...prev, societeTransmissible: value }))}
                    tooltipText="Une activité qu'il est possible de vendre à un repreneur, c'est à dire une activité où le chiffre d'affaires n'est pas strictement dépendant du dirigeant"
                />

                <RadioButtonGroup
                    label="Allez vous avoir plusieurs sociétés filles rapidement ?"
                    options={radioOptions}
                    value={formData.croissanceRapide}
                    onChange={value => setFormData(prev => ({ ...prev, croissanceRapide: value }))}
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.activiteRisque || !formData.societeTransmissible || !formData.croissanceRapide}
                >
                    Analyser la situation
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: results.recommandation,
                                value: '',
                                highlight: true,
                            },
                            ...(results.explications.length > 0
                                ? [
                                      {
                                          label: results.explications,
                                          description: 'Explications',
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default SimulateurHolding;
