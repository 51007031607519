import styled from '@emotion/styled';
import React from 'react';

import InfoIcon from '../../../images/icons/fa-circle-info.svg';
import { breakpoint, colors, fonts } from '../../../styles/theme';

const AlertContainer = styled.div`
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    background: #f3f7fc;

    color: ${colors.$primary700};

    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    line-height: 150%;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
    min-width: 20px;
    min-height: 20px;
    fill: ${colors.$primary700};
`;

interface SimulatorAlertProps {
    text: string;
}

const SimulatorAlert: React.FC<SimulatorAlertProps> = ({ text }) => {
    return (
        <AlertContainer>
            <StyledInfoIcon />
            {text}
        </AlertContainer>
    );
};

export default SimulatorAlert;
