import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface CongesData {
    salaireBrut: string;
    primes: string;
    heuresSupp: string;
    joursAcquis: string;
    joursPris: string;
    methodeCalcul: 'maintien' | 'dixieme';
    primeVacances: boolean;
}

interface CongesResults {
    maintienSalaire: number;
    dixiemeSalaire: number;
    methodeRetenue: 'maintien' | 'dixieme';
    primeVacances: number;
    montantTotal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const IndemnitesConges: React.FC = () => {
    const [formData, setFormData] = useState<CongesData>({
        salaireBrut: '',
        primes: '0',
        heuresSupp: '0',
        joursAcquis: '',
        joursPris: '',
        methodeCalcul: 'maintien',
        primeVacances: false,
    });

    const [results, setResults] = useState<CongesResults | null>(null);

    const calculateIndemnites = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;
        const primes = parseFloat(formData.primes) || 0;
        const heuresSupp = parseFloat(formData.heuresSupp) || 0;
        const joursAcquis = parseFloat(formData.joursAcquis) || 0;
        const joursPris = parseFloat(formData.joursPris) || 0;

        // Base de calcul
        const salaireTotal = salaireBrut + primes + heuresSupp;
        const salaireJournalier = salaireTotal / 30;

        // Calcul selon la méthode du maintien de salaire
        const maintienSalaire = salaireJournalier * joursPris;

        // Calcul selon la méthode du dixième
        const dixiemeSalaire = salaireTotal * 12 * 0.1 * (joursPris / joursAcquis);

        // Détermination de la méthode la plus avantageuse
        const methodeRetenue = dixiemeSalaire > maintienSalaire ? 'dixieme' : 'maintien';
        const montantBase = Math.max(maintienSalaire, dixiemeSalaire);

        // Prime de vacances (si applicable)
        const primeVacances = formData.primeVacances ? montantBase * 0.1 : 0;

        // Montant total
        const montantTotal = montantBase + primeVacances;

        const details = [
            {
                label: 'Maintien de salaire',
                montant: maintienSalaire,
                description: `${salaireJournalier.toFixed(2)}€ × ${joursPris} jours`,
            },
            {
                label: 'Dixième des salaires',
                montant: dixiemeSalaire,
                description: `10% de la rémunération annuelle × (${joursPris}/${joursAcquis})`,
            },
            {
                label: 'Prime de vacances',
                montant: primeVacances,
                description: formData.primeVacances ? '10% des indemnités de congés' : 'Non applicable',
            },
        ];

        setResults({
            maintienSalaire,
            dixiemeSalaire,
            methodeRetenue,
            primeVacances,
            montantTotal,
            details,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'indemnités de congés payés
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire mensuel brut (€)"
                        type="number"
                        value={formData.salaireBrut}
                        onChange={e => setFormData(prev => ({ ...prev, salaireBrut: e.target.value }))}
                        placeholder="Ex: 2500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Primes mensuelles (€)"
                        type="number"
                        value={formData.primes}
                        onChange={e => setFormData(prev => ({ ...prev, primes: e.target.value }))}
                        placeholder="Ex: 200"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Heures supplémentaires (€)"
                        type="number"
                        value={formData.heuresSupp}
                        onChange={e => setFormData(prev => ({ ...prev, heuresSupp: e.target.value }))}
                        placeholder="Ex: 300"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Jours de congés acquis"
                        type="number"
                        value={formData.joursAcquis}
                        onChange={e => setFormData(prev => ({ ...prev, joursAcquis: e.target.value }))}
                        placeholder="Ex: 25"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Jours de congés pris"
                        type="number"
                        value={formData.joursPris}
                        onChange={e => setFormData(prev => ({ ...prev, joursPris: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.methodeCalcul}
                        onChange={e =>
                            setFormData(prev => ({ ...prev, methodeCalcul: e.target.value as 'maintien' | 'dixieme' }))
                        }
                    >
                        <FormControlLabel
                            value="maintien"
                            control={<Radio />}
                            label="Maintien de salaire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="dixieme"
                            control={<Radio />}
                            label="Dixième des salaires"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.primeVacances}
                                onChange={e => setFormData(prev => ({ ...prev, primeVacances: e.target.checked }))}
                            />
                        }
                        label="Prime de vacances"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateIndemnites}
                    disabled={!formData.salaireBrut || !formData.joursAcquis || !formData.joursPris}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les indemnités
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#E8F5E9' }}>
                        <span className="label">Méthode la plus avantageuse</span>
                        <span className="value" style={{ color: '#2E7D32' }}>
                            {results.methodeRetenue === 'maintien' ? 'Maintien de salaire' : 'Dixième des salaires'}
                        </span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Montant total à verser</span>
                        <span className="value">{Math.round(results.montantTotal).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default IndemnitesConges;
