import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface CessionData {
    prixCession: string;
    valeurComptable: string;
    dureeDetention: string;
    titresParticipation: boolean;
    tauxReduit: boolean;
}

interface CessionResults {
    plusValue: number;
    abattement: number;
    plusValueImposable: number;
    montantImpot: number;
    montantNet: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const PlusValueCession: React.FC = () => {
    const [formData, setFormData] = useState<CessionData>({
        prixCession: '',
        valeurComptable: '',
        dureeDetention: '',
        titresParticipation: false,
        tauxReduit: false,
    });

    const [results, setResults] = useState<CessionResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculatePlusValue = () => {
        const prixCession = parseFloat(formData.prixCession) || 0;
        const valeurComptable = parseFloat(formData.valeurComptable) || 0;
        const dureeDetention = parseFloat(formData.dureeDetention) || 0;
        const plusValue = prixCession - valeurComptable;

        let plusValueImposable = plusValue;
        let abattement = 0;

        if (formData.titresParticipation && dureeDetention >= 2) {
            abattement = plusValue * 0.88;
            plusValueImposable = plusValue * 0.12;
        }

        // Calcul de l'impôt avec double taux si nécessaire
        let montantImpot = 0;
        if (formData.tauxReduit) {
            if (plusValueImposable <= 42500) {
                montantImpot = plusValueImposable * 0.15;
            } else {
                montantImpot = 42500 * 0.15 + (plusValueImposable - 42500) * 0.25;
            }
        } else {
            montantImpot = plusValueImposable * 0.25;
        }

        const montantNet = prixCession - montantImpot;

        setResults({
            plusValue,
            abattement,
            plusValueImposable,
            montantImpot,
            montantNet,
        });
    };

    const handleSubmit = () => {
        calculatePlusValue();
        if (!logged) handleOpen();
    };

    const radioOptionsTitres = [
        { value: 'true', label: 'Titres de participation (≥5% du capital)' },
        { value: 'false', label: 'Autres titres' },
    ];

    const radioOptionsTaux = [
        { value: 'true', label: 'Oui' },
        { value: 'false', label: 'Non' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de plus-value de cession" />
                <SimulatorAlert text="Ce simulateur est conçu pour calculer la plus-value de cession de titres pour les sociétés soumises à l'impôt sur les sociétés (IS)" />

                <RadioButtonGroup
                    label="Type de titres"
                    options={radioOptionsTitres}
                    value={formData.titresParticipation}
                    onChange={value => setFormData(prev => ({ ...prev, titresParticipation: value === 'true' }))}
                    direction="column"
                    tooltipText="Un titre de participation est un titre détenu avec l’intention d’exercer une influence ou un contrôle durable sur la société émettrice, dans un cadre stratégique. Il est inscrit en comptabilité comme tel et correspond à une détention utile à l’activité de l’entreprise, généralement sur une durée supérieure à 2 ans."
                />

                <RadioButtonGroup
                    label="Le taux réduit d'IS est-il applicable ?"
                    options={radioOptionsTaux}
                    value={formData.tauxReduit}
                    onChange={value => setFormData(prev => ({ ...prev, tauxReduit: value === 'true' }))}
                    direction="column"
                />

                <InputNumber
                    label="Prix de cession"
                    value={Number(formData.prixCession) || null}
                    setValue={value => setFormData(prev => ({ ...prev, prixCession: value.toString() }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 500000"
                />

                <InputNumber
                    label="Coût d'acquisition"
                    value={Number(formData.valeurComptable) || null}
                    setValue={value => setFormData(prev => ({ ...prev, valeurComptable: value.toString() }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 100000"
                />

                <InputNumber
                    label="Durée de détention"
                    value={Number(formData.dureeDetention) || 1}
                    setValue={value => setFormData(prev => ({ ...prev, dureeDetention: value.toString() }))}
                    placeholder="Ex : 5"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.prixCession || !formData.valeurComptable}
                >
                    Calculer la plus-value
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Montant net après impôt',
                                value: `${Math.round(results.montantNet).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Prix de cession',
                                value: `${parseFloat(formData.prixCession).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Plus-value brute',
                                value: `${Math.round(results.plusValue).toLocaleString('fr-FR')} €`,
                            },
                            ...(results.abattement > 0
                                ? [
                                      {
                                          label: 'Abattement (88%)',
                                          value: `-${Math.round(results.abattement).toLocaleString('fr-FR')} €`,
                                          //   comment:
                                          //       "Les plus-values sur titres de participation détenus depuis plus de 2 ans bénéficient d'une exonération de 88% avec une quote-part de frais et charges de 12%",
                                      },
                                  ]
                                : []),
                            {
                                label: 'Plus-value imposable',
                                value: `${Math.round(results.plusValueImposable).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Impôt estimé',
                                value: `${Math.round(results.montantImpot).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PlusValueCession;
