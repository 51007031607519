// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackCustomBehavioralEventHubspot = (eventName: string, properties: any) => {
    if (typeof window === 'undefined') return;
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push([
        'trackCustomBehavioralEvent',
        {
            name: eventName,
            properties: properties,
        },
    ]);
};
