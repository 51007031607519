import { Link } from 'gatsby';
import * as React from 'react';

import { BreadcrumbItem } from '../../utils/BreadcrumbParser';
import * as styles from './breadcrumb.module.scss';

const Breadcrumb = ({ items }: { items: BreadcrumbItem[] }) => (
    <ul itemScope itemType="https://schema.org/BreadcrumbList" className={styles.breadcrumb}>
        {items.map((breadcrumb, index) => {
            const LinkOrText = !breadcrumb.uri ? 'div' : Link;

            return (
                <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={index}>
                    <LinkOrText
                        itemProp="item"
                        data-ga4={`topbar-breadcrumb1-text-title${index + 1}-click-link`}
                        to={!!breadcrumb.uri ? breadcrumb.uri : undefined}
                    >
                        <span itemProp="name">{breadcrumb.name}</span>
                        <meta itemProp="position" content={`${index}`} />
                    </LinkOrText>
                </li>
            );
        })}
    </ul>
);

export default Breadcrumb;
