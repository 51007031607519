import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { useHeaderContext } from '../../../../contexts/headerContextProvider';
import { breakpoint, colors, fonts } from '../../../../styles/theme';
import { NavButton } from '../../../UI/Button/NavButton';
import { useMobileHeaderContext } from './context/mobileHeaderProvider';
import MobileMenu from './mobile/MobileMenu';
import { Header, StyledLink } from './styles/styles';

const StyledHeader = styled(Header)`
    justify-content: space-between;
    height: 72px;
    padding: 0 16px;
`;

const StyledLogo = styled.img`
    width: 100px;
    height: 26px;
`;

const Telephone = styled.a`
    font-family: ${fonts.OpenSans_Bold};
    font-size: 13px;
    color: ${colors.$primary700};

    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 16px;
        align-self: center;
    }

    padding: 8px;
    border-radius: 50%;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }
`;
const HeaderWrapper = styled.div`
    ${breakpoint.custom.min('1162')} {
        display: none;
    }
`;

const Menu = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    cursor: pointer;

    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }

    img {
        width: 20px;
        align-self: center;
    }
`;

const RightSection = styled.div`
    display: flex;
    gap: 8px;
`;

const Buttons = styled.div`
    display: none;
    gap: 8px;

    @media only screen and (min-width: 560px) and (max-width: 1161px) {
        display: flex;
    }
`;

const HeaderMobile = () => {
    const { isMenuOpen, setIsMenuOpen } = useMobileHeaderContext();
    const { stickyHeader, hasHeaderMedia, forceWhiteBackground } = useHeaderContext();

    return (
        <HeaderWrapper>
            <StyledHeader
                isScrolled={stickyHeader.hasReachedScrollBreakpoint}
                hasHeaderMedia={hasHeaderMedia}
                forceWhiteBackground={forceWhiteBackground}
            >
                <StyledLink
                    to={'/'}
                    className="logo"
                    title="Expert comptable en ligne"
                    data-ga-send="event,link,click,topbar-logo"
                    data-ga4="topbar-bar1-img-logodougs-click-link"
                >
                    <StyledLogo src="/images/header/logo-dougs.svg" alt="Dougs expert comptable en ligne" />
                </StyledLink>
                <RightSection>
                    <Telephone href="tel:0428296262" data-ga-send="event,link,click,topbar-phone">
                        <img src="/images/header/icn-tel.svg" alt="Telephone" width="11" height="11" />
                    </Telephone>
                    <Buttons>
                        <a
                            href="https://app.dougs.fr"
                            data-ga-send="event,button,click,topbar-login"
                            data-ga4="topbar-bar1-cta-text-click-link"
                            rel="nofollow"
                        >
                            <NavButton variant="secondary">Se connecter</NavButton>
                        </a>
                    </Buttons>

                    <Link
                        to="/presignup"
                        target="_blank"
                        rel="noopener nofollow"
                        data-ga-send="event,button,click,topbar-signup-mobile"
                        data-ga4="topbar-bar1-cta-button1-click-link"
                    >
                        <NavButton variant="primary">S'inscrire</NavButton>
                    </Link>

                    <Menu onClick={() => setIsMenuOpen(true)}>
                        <img src="/images/header/icon-burger.svg" alt="Menu" width="20" height="20" />
                    </Menu>
                </RightSection>
            </StyledHeader>

            {isMenuOpen && <MobileMenu setIsOpen={setIsMenuOpen} />}
        </HeaderWrapper>
    );
};

export default HeaderMobile;
