import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface ParticipationData {
    benefice: string;
    capitauxPropres: string;
    masseSalariale: string;
    valeurAjoutee: string;
    effectif: string;
}

interface ParticipationResults {
    rsp: number;
    montantParSalarie: number;
    montantPlafonne: number;
}

const PASS = 47100; // PASS 2025
const PLAFOND_INDIVIDUEL = PASS * 0.75;

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const ParticipationBenefices: React.FC = () => {
    const [formData, setFormData] = useState<ParticipationData>({
        benefice: '',
        capitauxPropres: '',
        masseSalariale: '',
        valeurAjoutee: '',
        effectif: '',
    });

    const [results, setResults] = useState<ParticipationResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateParticipation = () => {
        const benefice = parseFloat(formData.benefice) || 0;
        const capitauxPropres = parseFloat(formData.capitauxPropres) || 0;
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const valeurAjoutee = parseFloat(formData.valeurAjoutee) || 0;
        const effectif = parseInt(formData.effectif) || 0;

        // Calcul de la RSP selon la formule légale
        const tauxRendement = 0.05; // 5% des capitaux propres
        const rendementCapitaux = capitauxPropres * tauxRendement;
        const beneficeNet = benefice - rendementCapitaux;

        // Formule légale : RSP = 1/2 × (B - 5%C) × (S/VA)
        const rspFinale = 0.5 * beneficeNet * (masseSalariale / valeurAjoutee);

        // Calcul du montant par salarié
        const montantParSalarie = effectif > 0 ? rspFinale / effectif : 0;

        // Application du plafond individuel
        const montantPlafonne = Math.min(montantParSalarie, PLAFOND_INDIVIDUEL);

        setResults({
            rsp: rspFinale,
            montantParSalarie,
            montantPlafonne,
        });
    };

    const handleSubmit = () => {
        calculateParticipation();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de participation aux bénéfices" />
                <SimulatorAlert text="Ce simulateur calcule la participation selon la formule légale ; il ne propose pas le calcul des formules dérogatoires libres." />

                <InputNumber
                    label="Bénéfice net fiscal"
                    value={Number(formData.benefice) || null}
                    setValue={value => setFormData(prev => ({ ...prev, benefice: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 100000"
                />

                <InputNumber
                    label="Capitaux propres"
                    value={Number(formData.capitauxPropres) || null}
                    setValue={value => setFormData(prev => ({ ...prev, capitauxPropres: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 500000"
                />

                <InputNumber
                    label="Masse salariale"
                    value={Number(formData.masseSalariale) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalariale: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 300000"
                />

                <InputNumber
                    label="Valeur ajoutée"
                    value={Number(formData.valeurAjoutee) || null}
                    setValue={value => setFormData(prev => ({ ...prev, valeurAjoutee: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 800000"
                />

                <InputNumber
                    label="Nombre de bénéficiaires"
                    value={Number(formData.effectif) || null}
                    setValue={value => setFormData(prev => ({ ...prev, effectif: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 25"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.benefice || !formData.masseSalariale || !formData.valeurAjoutee}
                >
                    Calculer la participation
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Participation légale',
                                value: `${Math.round(results.rsp).toLocaleString('fr-FR')}\u00A0€`,
                                highlight: true,
                            },
                            {
                                label: 'Montant par bénéficiaire',
                                value: `${Math.round(results.montantParSalarie).toLocaleString('fr-FR')}\u00A0€`,
                                highlight: true,
                            },
                            ...(results.montantParSalarie > PLAFOND_INDIVIDUEL
                                ? [
                                      {
                                          label: 'Montant plafonné par bénéficiaire',
                                          value: `${Math.round(results.montantPlafonne).toLocaleString('fr-FR')}\u00A0€`,
                                          highlight: true,
                                      },
                                      {
                                          label: `Le montant par bénéficiaire a été plafonné à 75% du PASS (${Math.round(
                                              PLAFOND_INDIVIDUEL,
                                          ).toLocaleString('fr-FR')} €)`,
                                          value: '',
                                      },
                                  ]
                                : []),
                            {
                                description: 'Informations complémentaires',
                                label: [
                                    "La participation est exonérée de cotisations sociales mais soumise à la CSG-RDS (9,7%). Elle est imposée sur le revenu du bénéficiaire en cas de perception directe mais exonérée d'impôt si elle est placée sur un plan d'épargne (PEE/PER).",
                                    "Le simulateur applique une répartition uniforme (même montant pour tous les bénéficiaires). D'autres modes de répartition sont possibles (durée de présence, proportionnalité au salaire, panachage des critères)",
                                ],
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default ParticipationBenefices;
