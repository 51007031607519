import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';

interface TaxeData {
    masseSalariale: string;
    nbApprentis: string;
    departement: string;
    nbEffectifs: string;
    masseSalarialeApprentis: string;
}

interface TaxeResults {
    taxeBrute: number;
    quoteSolde: number;
    quoteCfa: number;
    reductionApprentis: number;
    reductionConvention: number;
    montantDu: number;
    repartition: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const TaxeApprentissage: React.FC = () => {
    const [formData, setFormData] = useState<TaxeData>({
        masseSalariale: '',
        nbApprentis: '0',
        departement: '',
        nbEffectifs: '',
        masseSalarialeApprentis: '',
    });

    const [results, setResults] = useState<TaxeResults | null>(null);

    const calculateTaxe = () => {
        const masseSalariale = parseFloat(formData.masseSalariale) || 0;
        const effectifs = parseInt(formData.nbEffectifs) || 0;
        const masseSalarialeApprentis = parseFloat(formData.masseSalarialeApprentis) || 0;
        const isAlsaceMoselle = ['57', '67', '68'].includes(formData.departement);

        // La taxe ne s'applique qu'aux entreprises de 11 salariés et plus
        if (effectifs < 11) {
            const masseSalarialeHorsApprentis = masseSalariale - masseSalarialeApprentis;
            const taxeBrute = masseSalarialeHorsApprentis * (isAlsaceMoselle ? 0.0044 : 0.0068);

            setResults({
                taxeBrute,
                quoteSolde: isAlsaceMoselle ? 0 : taxeBrute * 0.13,
                quoteCfa: isAlsaceMoselle ? taxeBrute : taxeBrute * 0.87,
                reductionApprentis: 0,
                reductionConvention: 0,
                montantDu: taxeBrute,
                repartition: [
                    {
                        label: isAlsaceMoselle ? 'Part unique (100%)' : 'Part principale (87%)',
                        montant: isAlsaceMoselle ? taxeBrute : taxeBrute * 0.87,
                        description: "Financement des CFA et de l'apprentissage",
                    },
                    ...(!isAlsaceMoselle
                        ? [
                              {
                                  label: 'Solde (13%)',
                                  montant: taxeBrute * 0.13,
                                  description: 'Financement des formations technologiques et professionnelles',
                              },
                          ]
                        : []),
                ],
            });
            return;
        }

        const taxeBrute = masseSalariale * (isAlsaceMoselle ? 0.0044 : 0.0068);
        const quoteSolde = isAlsaceMoselle ? 0 : taxeBrute * 0.13;
        const quoteCfa = isAlsaceMoselle ? taxeBrute : taxeBrute * 0.87;
        const montantDu = taxeBrute;

        const repartition = [
            {
                label: isAlsaceMoselle ? 'Part unique (100%)' : 'Part principale (87%)',
                montant: quoteCfa,
                description: "Financement des CFA et de l'apprentissage",
            },
            ...(!isAlsaceMoselle
                ? [
                      {
                          label: 'Solde (13%)',
                          montant: quoteSolde,
                          description: 'Financement des formations technologiques et professionnelles',
                      },
                  ]
                : []),
        ];

        setResults({
            taxeBrute,
            quoteSolde,
            quoteCfa,
            reductionApprentis: 0,
            reductionConvention: 0,
            montantDu,
            repartition,
        });
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de taxe d'apprentissage" />

                <InputNumber
                    label="Masse salariale brute totale (apprentis inclus)"
                    value={Number(formData.masseSalariale) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalariale: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 500000"
                />

                <InputNumber
                    label="Masse salariale brute des apprentis"
                    value={Number(formData.masseSalarialeApprentis) || null}
                    setValue={value => setFormData(prev => ({ ...prev, masseSalarialeApprentis: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 50000"
                />

                <InputNumber
                    label="Nombre d'apprentis"
                    value={Number(formData.nbApprentis) || null}
                    setValue={value => setFormData(prev => ({ ...prev, nbApprentis: String(value) }))}
                    placeholder="Ex: 2"
                />

                <InputNumber
                    label="Nombre d'effectifs"
                    value={Number(formData.nbEffectifs) || null}
                    setValue={value => setFormData(prev => ({ ...prev, nbEffectifs: String(value) }))}
                    placeholder="Ex: 11"
                />

                <InputNumber
                    label="Département"
                    value={formData.departement ? Number(formData.departement) : null}
                    setValue={value => setFormData(prev => ({ ...prev, departement: value ? String(value) : '' }))}
                    placeholder="Ex: 75"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={calculateTaxe}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.masseSalariale || !formData.masseSalarialeApprentis}
                >
                    Calculer la taxe
                </ArrowButton>
            </SimulatorCard>

            {results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Montant total à verser',
                                value: `${Math.round(results.montantDu).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Masse salariale totale',
                                value: `${parseFloat(formData.masseSalariale).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Masse salariale apprentis',
                                value: `${parseFloat(formData.masseSalarialeApprentis).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Base de calcul (hors apprentis)',
                                value: `${(parseFloat(formData.masseSalariale) - parseFloat(formData.masseSalarialeApprentis)).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: `Taxe brute (${['57', '67', '68'].includes(formData.departement) ? '0,44' : '0,68'}%)`,
                                value: `${Math.round(results.taxeBrute).toLocaleString('fr-FR')} €`,
                            },
                            ...results.repartition.map(part => ({
                                label: part.label,
                                value: `${Math.round(part.montant).toLocaleString('fr-FR')} €`,
                                description: part.description,
                            })),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default TaxeApprentissage;
