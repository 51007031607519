import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface PrecariteData {
    salaireTotal: string;
    indemnitesCP: string;
}

interface PrecariteResults {
    basePrime: number;
    montantPrime: number;
    cotisationsSociales: number;
    montantNet: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const PrimePrecarite: React.FC = () => {
    const [formData, setFormData] = useState<PrecariteData>({
        salaireTotal: '',
        indemnitesCP: '0',
    });

    const [results, setResults] = useState<PrecariteResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculatePrime = () => {
        const salaireTotal = parseFloat(formData.salaireTotal) || 0;
        const indemnitesCP = parseFloat(formData.indemnitesCP) || 0;

        // Base de calcul
        const basePrime = salaireTotal + indemnitesCP;

        // Calcul de la prime (10% de la rémunération totale brute)
        const montantPrime = basePrime * 0.1;

        setResults({
            basePrime,
            montantPrime,
            cotisationsSociales: 0,
            montantNet: 0,
            details: [],
        });
    };

    const handleSubmit = () => {
        calculatePrime();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de prime de précarité" />
                <SimulatorAlert text="En cas de rupture anticipée, proposition de CDI, faute grave, contrat saisonnier, contrat étudiant pendant les vacances, il n'y a pas de prime de précarité." />

                <InputNumber
                    label="Salaire brut total sur la durée du contrat (renouvellements inclus)"
                    value={Number(formData.salaireTotal) || null}
                    setValue={value => setFormData(prev => ({ ...prev, salaireTotal: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 12000"
                />

                <InputNumber
                    label="Montant des congés payés versés à la fin du contrat"
                    value={Number(formData.indemnitesCP) || null}
                    setValue={value => setFormData(prev => ({ ...prev, indemnitesCP: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.salaireTotal}
                >
                    Calculer la prime
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Prime de précarité brute',
                                value: `${Math.round(results.montantPrime).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PrimePrecarite;
