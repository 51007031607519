import styled from '@emotion/styled';
import React from 'react';

import { colors, fonts } from '../../styles/theme';
import Tooltip from '../section/SectionTarif/PackCards/UI/Tooltip';

interface SimulatorInputLabelProps {
    text: string;
    tooltipText?: string;
    disabled?: boolean;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    position: 'relative';
    z-index: 10;
`;

const InputLabel = styled.div<{ disabled?: boolean }>`
    color: ${props => (props.disabled ? '#A8BFD7' : colors.$primary700)};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    line-height: 150%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const SimulatorInputLabel = ({ text, tooltipText, disabled }: SimulatorInputLabelProps) => {
    return (
        <Container>
            <InputLabel disabled={disabled}>{text}</InputLabel>
            {!!tooltipText && <Tooltip text={tooltipText} disabled={disabled} />}
        </Container>
    );
};

export default SimulatorInputLabel;
