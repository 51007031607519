import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface RetraiteData {
    revenuAnnuel: string;
    age: string;
    ageRetraite: string;
    anneesCotisees: string;
    statut: 'artisan' | 'commercant' | 'liberal';
    regimeComplementaire: string;
}

interface RetraiteResults {
    droitsBase: {
        trimestres: number;
        pension: number;
    };
    droitsComplementaires: {
        points: number;
        pension: number;
    };
    total: {
        pensionMensuelle: number;
        tauxRemplacement: number;
    };
    optimisation: {
        label: string;
        gain: number;
        cout: number;
    }[];
    recommandations: string[];
}

const RetraiteTns: React.FC = () => {
    const [formData, setFormData] = useState<RetraiteData>({
        revenuAnnuel: '',
        age: '',
        ageRetraite: '',
        anneesCotisees: '',
        statut: 'artisan',
        regimeComplementaire: '',
    });

    const [results, setResults] = useState<RetraiteResults | null>(null);

    const calculateRetraite = () => {
        const revenu = parseFloat(formData.revenuAnnuel) || 0;
        const age = parseInt(formData.age) || 0;
        const ageRetraite = parseInt(formData.ageRetraite) || 0;
        const anneesCotisees = parseInt(formData.anneesCotisees) || 0;
        const regimeComplementaire = parseFloat(formData.regimeComplementaire) || 0;

        // Calcul des droits au régime de base
        const plafondSS = 43992; // PASS 2024
        const tauxBase = 0.5; // Taux plein
        const revenuPlafonneBase = Math.min(revenu, plafondSS);
        const pensionBase = (revenuPlafonneBase * tauxBase * anneesCotisees) / 43;

        // Calcul des trimestres
        const trimestresAcquis = anneesCotisees * 4;
        const trimestresRequis = 172; // Génération 1973 et suivantes

        // Calcul des droits complémentaires
        const pointsAnnuels = (revenu * 0.07) / 17.0571; // Valeur du point 2024
        const pointsTotaux = pointsAnnuels * anneesCotisees;
        const pensionComplementaire = pointsTotaux * 0.4648; // Valeur de service du point

        // Calcul de la pension totale
        const pensionMensuelle = (pensionBase + pensionComplementaire) / 12;
        const tauxRemplacement = ((pensionMensuelle * 12) / revenu) * 100;

        // Calcul des optimisations possibles
        const optimisations = [];

        // Rachat de trimestres
        if (trimestresAcquis < trimestresRequis) {
            const trimestresManquants = trimestresRequis - trimestresAcquis;
            const coutRachat = trimestresManquants * 2500;
            const gainRachat = pensionMensuelle * 0.15 * 12;
            optimisations.push({
                label: 'Rachat de trimestres',
                gain: gainRachat,
                cout: coutRachat,
            });
        }

        // Surcotisation régime complémentaire
        const gainSurcotisation = pensionMensuelle * 0.2 * 12;
        const coutSurcotisation = revenu * 0.03;
        optimisations.push({
            label: 'Surcotisation complémentaire',
            gain: gainSurcotisation,
            cout: coutSurcotisation,
        });

        // Recommandations
        const recommandations = [];

        if (age > 55 && trimestresAcquis < trimestresRequis) {
            recommandations.push('Envisagez rapidement un rachat de trimestres');
        }

        if (tauxRemplacement < 50) {
            recommandations.push('Votre taux de remplacement est faible, renforcez votre retraite complémentaire');
        }

        if (ageRetraite < 65 && trimestresAcquis < trimestresRequis) {
            recommandations.push('Un départ avant 65 ans impactera négativement votre pension');
        }

        if (revenu > plafondSS && regimeComplementaire < revenu * 0.1) {
            recommandations.push('Optimisez votre retraite avec un régime complémentaire facultatif');
        }

        setResults({
            droitsBase: {
                trimestres: trimestresAcquis,
                pension: pensionBase,
            },
            droitsComplementaires: {
                points: pointsTotaux,
                pension: pensionComplementaire,
            },
            total: {
                pensionMensuelle,
                tauxRemplacement,
            },
            optimisation: optimisations,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur retraite TNS
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.statut}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                statut: e.target.value as 'artisan' | 'commercant' | 'liberal',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="artisan"
                            control={<Radio />}
                            label="Artisan"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="commercant"
                            control={<Radio />}
                            label="Commerçant"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="liberal"
                            control={<Radio />}
                            label="Profession libérale"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Revenu annuel (€)"
                        type="number"
                        value={formData.revenuAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, revenuAnnuel: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Âge actuel"
                        type="number"
                        value={formData.age}
                        onChange={e => setFormData(prev => ({ ...prev, age: e.target.value }))}
                        placeholder="Ex: 45"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Âge souhaité de départ"
                        type="number"
                        value={formData.ageRetraite}
                        onChange={e => setFormData(prev => ({ ...prev, ageRetraite: e.target.value }))}
                        placeholder="Ex: 65"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Années déjà cotisées"
                        type="number"
                        value={formData.anneesCotisees}
                        onChange={e => setFormData(prev => ({ ...prev, anneesCotisees: e.target.value }))}
                        placeholder="Ex: 20"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cotisations régime complémentaire (€/an)"
                        type="number"
                        value={formData.regimeComplementaire}
                        onChange={e => setFormData(prev => ({ ...prev, regimeComplementaire: e.target.value }))}
                        placeholder="Ex: 5000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateRetraite}
                    disabled={!formData.revenuAnnuel || !formData.age}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer la retraite
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Régime de base</span>
                        <span className="value"></span>
                    </div>

                    <div className="result-row">
                        <span className="label">Trimestres acquis</span>
                        <span className="value">{results.droitsBase.trimestres} / 172</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Pension annuelle de base</span>
                        <span className="value">
                            {Math.round(results.droitsBase.pension).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Régime complémentaire</span>
                        <span className="value"></span>
                    </div>

                    <div className="result-row">
                        <span className="label">Points acquis</span>
                        <span className="value">{Math.round(results.droitsComplementaires.points)}</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Pension annuelle complémentaire</span>
                        <span className="value">
                            {Math.round(results.droitsComplementaires.pension).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Pension mensuelle totale estimée</span>
                        <span className="value">
                            {Math.round(results.total.pensionMensuelle).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row">
                        <span className="label">Taux de remplacement</span>
                        <span className="value">{Math.round(results.total.tauxRemplacement)}%</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Optimisations possibles</span>
                        <span className="value"></span>
                    </div>

                    {results.optimisation.map((opt, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{opt.label}</span>
                                <span className="value">+{Math.round(opt.gain).toLocaleString('fr-FR')} €/an</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">Coût estimé</span>
                                <span className="value">{Math.round(opt.cout).toLocaleString('fr-FR')} €</span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default RetraiteTns;
