import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

type StatusType = 'conforme' | 'alerte' | 'depassement';

interface MicroData {
    chiffreAffaires: string;
    activite: 'commerce' | 'service' | 'liberal';
}

interface MicroResults {
    plafondApplicable: number;
    depassementAnnuel: number;
    statut: StatusType;
    recommendations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const PlafondsMicro: React.FC = () => {
    const [formData, setFormData] = useState<MicroData>({
        chiffreAffaires: '',
        activite: 'commerce',
    });

    const [results, setResults] = useState<MicroResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculatePlafonds = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;

        // Plafonds 2024
        const plafonds = {
            commerce: 188700,
            service: 77700,
            liberal: 77700,
        };

        const plafondApplicable = plafonds[formData.activite];
        const depassementAnnuel = Math.max(0, ca - plafondApplicable);

        // Détermination du statut
        let statut: StatusType = 'conforme';
        if (ca > plafondApplicable * 1.1) {
            statut = 'depassement';
        } else if (ca > plafondApplicable * 0.7) {
            statut = 'alerte';
        }

        // Recommandations personnalisées
        const recommendations = [];

        if (statut === 'depassement') {
            recommendations.push('Vous devez sortir du régime micro-entreprise.');
            recommendations.push('Contactez votre expert-comptable pour préparer le passage au régime réel.');
        } else if (statut === 'alerte') {
            recommendations.push('Vous approchez du plafond, surveillez votre CA.');
            recommendations.push('Anticipez un possible changement au régime réel');
        } else {
            recommendations.push('Vous respectez les plafonds du régime micro.');
        }

        recommendations.push(
            'Attention, si vous dépassez pendant 2 ans les seuils de CA, vous sortez du régime de la micro-entreprise.',
        );

        setResults({
            plafondApplicable,
            depassementAnnuel,
            statut,
            recommendations,
        });
    };

    const getStatusText = (status: StatusType) => {
        const texts = {
            conforme: 'Conforme',
            alerte: 'Vigilance',
            depassement: 'Dépassement',
        };
        return texts[status];
    };

    const handleSubmit = () => {
        calculatePlafonds();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'commerce', label: 'Commerce' },
        { value: 'service', label: 'Service' },
        { value: 'liberal', label: 'Libéral' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Vérification des plafonds micro-entreprise" />

                <RadioButtonGroup
                    label="Type d'activité"
                    options={radioOptions}
                    value={formData.activite}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, activite: value as 'commerce' | 'service' | 'liberal' }))
                    }
                    direction="column"
                />

                <InputNumber
                    label="Chiffre d'affaires annuel"
                    value={Number(formData.chiffreAffaires) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chiffreAffaires: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 50000"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.chiffreAffaires}
                >
                    Vérifier les plafonds
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Statut',
                                value: getStatusText(results.statut),
                                highlight: true,
                            },
                            {
                                label: 'Plafond applicable',
                                value: `${results.plafondApplicable.toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'CA actuel',
                                value: `${parseFloat(formData.chiffreAffaires).toLocaleString('fr-FR')} €`,
                            },
                            ...(results.depassementAnnuel > 0
                                ? [
                                      {
                                          label: 'Dépassement',
                                          value: `+${Math.round(results.depassementAnnuel).toLocaleString('fr-FR')} €`,
                                      },
                                  ]
                                : []),
                            {
                                description: 'Recommandations',
                                label: results.recommendations,
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PlafondsMicro;
