import { OutlinedInput } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';

import SimulatorInputLabel from '../../../../../UI/SimulatorInputLabel';

interface InputNumberProps {
    label?: string;
    value: number;
    valueLabel?: string;
    setValue: (updates: Partial<Record<string, string | number>>) => void;
    tooltipText?: string;
    inputProps?: { min?: number; max?: number };
    endAdornment?: ReactElement;
    variant?: 'DEFAULT' | 'ADORNMENT';
}

const defaultStyle = {
    backgroundColor: 'white',
    '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#013A51',
        fontWeight: '600',
    },
};

const STYLES = {
    DEFAULT: {
        ...defaultStyle,
        // permanently display arrows
        '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
            opacity: 1,
        },
    },
    ADORNMENT: {
        ...defaultStyle,
        // hidden arrows
        '& input[type=number]': {
            '&::-webkit-outer-spin-button': { display: 'none' },
            '&::-webkit-inner-spin-button': { display: 'none' },
        },
    },
};

const InputNumber = ({
    label,
    value,
    valueLabel,
    setValue,
    tooltipText,
    endAdornment,
    inputProps = { min: 0 },
    variant = 'DEFAULT',
}: InputNumberProps) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleInputChange = e => {
        let newValue = e.target.value;
        if (/^0+\d/.test(newValue)) {
            newValue = newValue.replace(/^0+/, '');
        }
        setInputValue(newValue);
        setValue({ [valueLabel]: Number(newValue) });
    };

    return (
        <>
            {!!label && <SimulatorInputLabel text={label} tooltipText={tooltipText} />}
            <OutlinedInput
                fullWidth
                value={inputValue}
                onChange={handleInputChange}
                endAdornment={endAdornment}
                size="small"
                inputProps={{
                    ...(inputProps ?? {}),
                    type: 'number',
                }}
                sx={STYLES[variant]}
            />
        </>
    );
};

export default InputNumber;
