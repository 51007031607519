import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface KilometriqueData {
    kilometres: string;
    puissance: string;
    typeVehicule: 'voiture' | 'moto';
    carburant: 'essence' | 'diesel' | 'electrique';
}

interface KilometriqueResults {
    bareme: number;
    indemnites: number;
    plafond: number | null;
    depassement: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const FraisKilometriques: React.FC = () => {
    const [formData, setFormData] = useState<KilometriqueData>({
        kilometres: '',
        puissance: '',
        typeVehicule: 'voiture',
        carburant: 'essence',
    });

    const [results, setResults] = useState<KilometriqueResults | null>(null);

    // Barèmes 2024 pour les voitures
    const baremesVoiture = {
        '3cv': { jusqu5000: 0.529, de5001a20000: 0.316, plus20000: 0.37 },
        '4cv': { jusqu5000: 0.606, de5001a20000: 0.34, plus20000: 0.407 },
        '5cv': { jusqu5000: 0.636, de5001a20000: 0.357, plus20000: 0.427 },
        '6cv': { jusqu5000: 0.665, de5001a20000: 0.374, plus20000: 0.447 },
        '7cv+': { jusqu5000: 0.697, de5001a20000: 0.394, plus20000: 0.47 },
    };

    // Barèmes 2024 pour les motos (cylindrée > 50 cm³)
    const baremesMoto = {
        '1ou2cv': { jusqu3000: 0.395, de3001a6000: 0.099, plus6000: 0.248 },
        '3a5cv': { jusqu3000: 0.468, de3001a6000: 0.082, plus6000: 0.275 },
        plus5cv: { jusqu3000: 0.606, de3001a6000: 0.079, plus6000: 0.343 },
    };

    const getBonusKilometrique = (tranchePuissance: string) => {
        const bonus = {
            '3cv': 1065,
            '4cv': 1330,
            '5cv': 1395,
            '6cv': 1457,
            '7cv+': 1515,
        };
        return bonus[tranchePuissance as keyof typeof bonus];
    };

    const calculateFrais = () => {
        const kilometres = parseFloat(formData.kilometres) || 0;
        const puissance = parseInt(formData.puissance) || 0;
        let indemnites = 0;
        let bareme = 0;

        if (formData.typeVehicule === 'voiture') {
            // Détermination de la tranche de puissance
            let tranchePuissance = '7cv+';
            if (puissance <= 3) tranchePuissance = '3cv';
            else if (puissance === 4) tranchePuissance = '4cv';
            else if (puissance === 5) tranchePuissance = '5cv';
            else if (puissance === 6) tranchePuissance = '6cv';

            const baremes = baremesVoiture[tranchePuissance as keyof typeof baremesVoiture];

            if (kilometres <= 5000) {
                indemnites = kilometres * baremes.jusqu5000;
                bareme = baremes.jusqu5000;
            } else if (kilometres <= 20000) {
                indemnites = kilometres * baremes.de5001a20000 + getBonusKilometrique(tranchePuissance);
                bareme = indemnites / kilometres;
            } else {
                indemnites = kilometres * baremes.plus20000;
                bareme = baremes.plus20000;
            }

            // Majoration véhicule électrique
            if (formData.carburant === 'electrique') {
                indemnites *= 1.2;
                bareme *= 1.2;
            }
        } else {
            let tranchePuissance = 'plus5cv';
            if (puissance <= 2) tranchePuissance = '1ou2cv';
            else if (puissance <= 5) tranchePuissance = '3a5cv';

            const baremes = baremesMoto[tranchePuissance as keyof typeof baremesMoto];

            if (kilometres <= 3000) {
                indemnites = kilometres * baremes.jusqu3000;
                bareme = baremes.jusqu3000;
            } else if (kilometres <= 6000) {
                const premiereTrancheIndemnites = 3000 * baremes.jusqu3000;
                const deuxiemeTrancheIndemnites = (kilometres - 3000) * baremes.de3001a6000;
                indemnites = premiereTrancheIndemnites + deuxiemeTrancheIndemnites;
                bareme = indemnites / kilometres;
            } else {
                const premiereTrancheIndemnites = 3000 * baremes.jusqu3000;
                const deuxiemeTrancheIndemnites = 3000 * baremes.de3001a6000;
                const troisiemeTrancheIndemnites = (kilometres - 6000) * baremes.plus6000;
                indemnites = premiereTrancheIndemnites + deuxiemeTrancheIndemnites + troisiemeTrancheIndemnites;
                bareme = indemnites / kilometres;
            }

            // Majoration véhicule électrique
            if (formData.carburant === 'electrique') {
                indemnites *= 1.2;
                bareme *= 1.2;
            }
        }

        const plafond = 2000; // Plafond pour les motos et cyclomoteurs
        const depassement = plafond ? Math.max(0, indemnites - plafond) : 0;

        setResults({
            bareme,
            indemnites,
            plafond,
            depassement,
        });
    };

    const { handleOpen, logged } = useSimulatorContext();

    const handleSubmit = () => {
        calculateFrais();
        if (!logged) handleOpen();
    };

    const vehiculeOptions = [
        { value: 'voiture', label: 'Voiture' },
        { value: 'moto', label: 'Moto' },
    ];

    const carburantOptions = [
        { value: 'essence', label: 'Essence' },
        { value: 'diesel', label: 'Diesel' },
        { value: 'electrique', label: 'Électrique' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de frais kilométriques" />

                <RadioButtonGroup
                    label="Type de véhicule"
                    options={vehiculeOptions}
                    value={formData.typeVehicule}
                    onChange={value => setFormData(prev => ({ ...prev, typeVehicule: value as 'voiture' | 'moto' }))}
                    direction="column"
                />

                {formData.typeVehicule === 'voiture' && (
                    <RadioButtonGroup
                        label="Type de carburant"
                        options={carburantOptions}
                        value={formData.carburant}
                        onChange={value =>
                            setFormData(prev => ({ ...prev, carburant: value as 'essence' | 'diesel' | 'electrique' }))
                        }
                        direction="column"
                    />
                )}

                <InputNumber
                    label={formData.typeVehicule === 'voiture' ? 'Puissance fiscale (CV)' : 'Puissance (kW)'}
                    value={Number(formData.puissance) || null}
                    setValue={value => setFormData(prev => ({ ...prev, puissance: String(value) }))}
                    placeholder={formData.typeVehicule === 'voiture' ? 'Ex : 5' : 'Ex : 3'}
                />

                <InputNumber
                    label="Kilométrage annuel"
                    value={Number(formData.kilometres) || null}
                    setValue={value => setFormData(prev => ({ ...prev, kilometres: String(value) }))}
                    placeholder="Ex : 10000"
                    endAdornment={<>km</>}
                    variant="ADORNMENT"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.kilometres || !formData.puissance}
                >
                    Calculer les frais kilométriques
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Indemnités kilométriques',
                                value: `${Math.round(results.indemnites).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Kilométrage annuel',
                                value: `${parseFloat(formData.kilometres).toLocaleString('fr-FR')} km`,
                            },
                            {
                                label: 'Barème kilométrique',
                                value: `${results.bareme.toFixed(3)} €/km`,
                            },
                            ...(results.plafond
                                ? [
                                      {
                                          label: 'Plafond déductible',
                                          value: `${results.plafond.toLocaleString('fr-FR')} €`,
                                      },
                                  ]
                                : []),
                            ...(results.depassement > 0
                                ? [
                                      {
                                          label: 'Dépassement du plafond',
                                          value: `${Math.round(results.depassement).toLocaleString('fr-FR')} €`,
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default FraisKilometriques;
