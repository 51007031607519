import { Button, Checkbox, FormControl, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useSimulatorContext } from '../context/SimulatorProvider';

interface ComparatifData {
    chiffreAffaires: string;
    benefice: string;
    investissements: string;
    nombreAssocies: string;
    criteres: {
        responsabilite: boolean;
        fiscalite: boolean;
        protection: boolean;
        credibilite: boolean;
        transmission: boolean;
    };
}

interface StatutScore {
    statut: string;
    description: string;
    score: number;
    avantages: string[];
    inconvenients: string[];
    details: {
        responsabilite: number;
        fiscalite: number;
        protection: number;
        credibilite: number;
        transmission: number;
    };
}

const ComparatifStatuts: React.FC = () => {
    const [formData, setFormData] = useState<ComparatifData>({
        chiffreAffaires: '',
        benefice: '',
        investissements: '',
        nombreAssocies: '1',
        criteres: {
            responsabilite: true,
            fiscalite: true,
            protection: true,
            credibilite: true,
            transmission: true,
        },
    });

    const [results, setResults] = useState<StatutScore[]>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateComparatif = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const investissements = parseFloat(formData.investissements) || 0;
        const associes = parseInt(formData.nombreAssocies) || 1;

        // Définition des statuts et leurs caractéristiques
        const statuts: StatutScore[] = [
            {
                statut: 'Entreprise individuelle',
                description: 'Structure la plus simple, adaptée aux petites activités',
                score: 0,
                avantages: [
                    'Création simple et rapide',
                    'Gestion simplifiée',
                    'Pas de capital minimum',
                    'Régime micro-entreprise possible',
                ],
                inconvenients: [
                    'Responsabilité illimitée',
                    'Crédibilité limitée',
                    'Transmission difficile',
                    'Protection sociale minimale',
                ],
                details: {
                    responsabilite: 1,
                    fiscalite: ca < 72600 ? 4 : 2,
                    protection: 2,
                    credibilite: 1,
                    transmission: 1,
                },
            },
            {
                statut: 'EURL',
                description: 'SARL unipersonnelle, bonne protection du patrimoine',
                score: 0,
                avantages: [
                    'Responsabilité limitée',
                    'Patrimoine protégé',
                    'Régime fiscal flexible',
                    'Structure évolutive',
                ],
                inconvenients: [
                    'Formalisme juridique',
                    'Coûts de constitution',
                    'Charges sociales élevées',
                    'Comptabilité obligatoire',
                ],
                details: {
                    responsabilite: 4,
                    fiscalite: 3,
                    protection: 4,
                    credibilite: 3,
                    transmission: 4,
                },
            },
            {
                statut: 'SASU',
                description: 'SAS unipersonnelle, grande flexibilité statutaire',
                score: 0,
                avantages: [
                    'Grande flexibilité',
                    'Statut assimilé salarié',
                    'Image professionnelle',
                    'Transmission facilitée',
                ],
                inconvenients: [
                    'Coûts de constitution',
                    'Charges sociales élevées',
                    'Formalisme important',
                    'Commissaire aux comptes possible',
                ],
                details: {
                    responsabilite: 4,
                    fiscalite: 3,
                    protection: 5,
                    credibilite: 5,
                    transmission: 5,
                },
            },
            {
                statut: 'SARL',
                description: 'Société adaptée aux petites et moyennes structures',
                score: 0,
                avantages: [
                    'Structure éprouvée',
                    'Responsabilité limitée',
                    'Fonctionnement encadré',
                    'Crédibilité établie',
                ],
                inconvenients: [
                    'Formalisme important',
                    'Gérant majoritaire TNS',
                    'Moins flexible que la SAS',
                    'Charges sociales variables',
                ],
                details: {
                    responsabilite: 4,
                    fiscalite: 3,
                    protection: 4,
                    credibilite: 4,
                    transmission: 4,
                },
            },
            {
                statut: 'SAS',
                description: 'Société très flexible, adaptée à la croissance',
                score: 0,
                avantages: [
                    'Grande flexibilité statutaire',
                    'Gouvernance sur mesure',
                    'Levée de fonds facilitée',
                    'Image professionnelle forte',
                ],
                inconvenients: [
                    'Coût de constitution élevé',
                    'Formalisme important',
                    'Commissaire aux comptes possible',
                    'Structure complexe',
                ],
                details: {
                    responsabilite: 5,
                    fiscalite: 3,
                    protection: 5,
                    credibilite: 5,
                    transmission: 5,
                },
            },
        ];

        // Calcul des scores selon les critères sélectionnés
        const statutsScores = statuts.map(statut => {
            let score = 0;
            let maxScore = 0;

            if (formData.criteres.responsabilite) {
                score += statut.details.responsabilite;
                maxScore += 5;
            }
            if (formData.criteres.fiscalite) {
                score += statut.details.fiscalite;
                maxScore += 5;
            }
            if (formData.criteres.protection) {
                score += statut.details.protection;
                maxScore += 5;
            }
            if (formData.criteres.credibilite) {
                score += statut.details.credibilite;
                maxScore += 5;
            }
            if (formData.criteres.transmission) {
                score += statut.details.transmission;
                maxScore += 5;
            }

            // Ajustements selon les paramètres
            if (
                associes > 1 &&
                (statut.statut === 'Entreprise individuelle' || statut.statut === 'EURL' || statut.statut === 'SASU')
            ) {
                score = 0;
            }
            if (ca > 500000 && statut.statut === 'Entreprise individuelle') {
                score *= 0.5;
            }
            if (investissements > 50000 && statut.statut === 'Entreprise individuelle') {
                score *= 0.7;
            }

            return {
                ...statut,
                score: Math.round((score / maxScore) * 100),
            };
        });

        // Tri par score décroissant
        setResults(statutsScores.sort((a, b) => b.score - a.score));
    };

    const handleSubmit = () => {
        calculateComparatif();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Comparatif des statuts juridiques
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires prévisionnel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Bénéfice prévisionnel (€)"
                        type="number"
                        value={formData.benefice}
                        onChange={e => setFormData(prev => ({ ...prev, benefice: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Investissements prévus (€)"
                        type="number"
                        value={formData.investissements}
                        onChange={e => setFormData(prev => ({ ...prev, investissements: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'associés"
                        type="number"
                        value={formData.nombreAssocies}
                        onChange={e => setFormData(prev => ({ ...prev, nombreAssocies: e.target.value }))}
                        placeholder="Ex: 1"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Critères importants pour vous :
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.criteres.responsabilite}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        criteres: { ...prev.criteres, responsabilite: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Protection du patrimoine personnel"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.criteres.fiscalite}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        criteres: { ...prev.criteres, fiscalite: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Optimisation fiscale"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.criteres.protection}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        criteres: { ...prev.criteres, protection: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Protection sociale"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.criteres.credibilite}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        criteres: { ...prev.criteres, credibilite: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Crédibilité commerciale"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.criteres.transmission}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        criteres: { ...prev.criteres, transmission: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Facilité de transmission"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!formData.chiffreAffaires || !formData.benefice}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Comparer les statuts
                </Button>
            </Paper>

            {logged && results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.length > 0 &&
                        results.map((statut, index) => (
                            <div key={index} className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">{statut.statut}</span>
                                <span
                                    className="value"
                                    style={{
                                        color: index === 0 ? '#10B981' : undefined,
                                        fontWeight: index === 0 ? 'bold' : undefined,
                                    }}
                                >
                                    Score : {statut.score}%
                                </span>
                            </div>
                        ))}
                </Paper>
            )}
        </div>
    );
};

export default ComparatifStatuts;
