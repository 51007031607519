import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface DiagnosticData {
    chiffreAffaires: string;
    resultatNet: string;
    capitauxPropres: string;
    dettes: string;
    tresorerie: string;
    actifCirculant: string;
    passifCirculant: string;
    valeurAjoutee: string;
    secteurActivite: 'commerce' | 'service' | 'industrie';
}

interface DiagnosticResults {
    ratios: {
        categorie: string;
        items: {
            label: string;
            valeur: number;
            reference: number;
            interpretation: string;
            alerte: AlertType;
        }[];
    }[];
    score: {
        total: number;
        appreciation: string;
    };
    recommandations: string[];
}

type AlertType = 'success' | 'warning' | 'danger';

const DiagnosticFinancier: React.FC = () => {
    const [formData, setFormData] = useState<DiagnosticData>({
        chiffreAffaires: '',
        resultatNet: '',
        capitauxPropres: '',
        dettes: '',
        tresorerie: '',
        actifCirculant: '',
        passifCirculant: '',
        valeurAjoutee: '',
        secteurActivite: 'commerce',
    });

    const [results, setResults] = useState<DiagnosticResults | null>(null);

    const calculateDiagnostic = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rn = parseFloat(formData.resultatNet) || 0;
        const cp = parseFloat(formData.capitauxPropres) || 0;
        const dettes = parseFloat(formData.dettes) || 0;
        const treso = parseFloat(formData.tresorerie) || 0;
        const ac = parseFloat(formData.actifCirculant) || 0;
        const pc = parseFloat(formData.passifCirculant) || 0;
        const va = parseFloat(formData.valeurAjoutee) || 0;

        // Calcul des ratios par catégorie
        const ratios = [
            {
                categorie: 'Rentabilité',
                items: [
                    {
                        label: 'Rentabilité commerciale',
                        valeur: (rn / ca) * 100,
                        reference:
                            formData.secteurActivite === 'commerce'
                                ? 3
                                : formData.secteurActivite === 'service'
                                  ? 8
                                  : 5,
                        interpretation: "Mesure la profitabilité de l'activité",
                        alerte: getAlertType((rn / ca) * 100, { danger: 2, warning: 4 }),
                    },
                    {
                        label: 'Rentabilité financière',
                        valeur: (rn / cp) * 100,
                        reference: 15,
                        interpretation: 'Rendement des capitaux propres',
                        alerte: getAlertType((rn / cp) * 100, { danger: 10, warning: 15 }),
                    },
                ],
            },
            {
                categorie: 'Structure financière',
                items: [
                    {
                        label: 'Autonomie financière',
                        valeur: (cp / (cp + dettes)) * 100,
                        reference: 40,
                        interpretation: 'Indépendance financière',
                        alerte: getAlertType((cp / (cp + dettes)) * 100, { danger: 30, warning: 40 }),
                    },
                    {
                        label: 'Liquidité générale',
                        valeur: ac / pc,
                        reference: 1.5,
                        interpretation: 'Capacité à honorer ses dettes court terme',
                        alerte: getAlertType(ac / pc, { danger: 1.2, warning: 1.5 }),
                    },
                ],
            },
            {
                categorie: 'Exploitation',
                items: [
                    {
                        label: 'Taux de valeur ajoutée',
                        valeur: (va / ca) * 100,
                        reference: formData.secteurActivite === 'industrie' ? 35 : 25,
                        interpretation: 'Création de valeur',
                        alerte: getAlertType((va / ca) * 100, { danger: 20, warning: 30 }),
                    },
                    {
                        label: 'Trésorerie / CA',
                        valeur: (treso / ca) * 100,
                        reference: 10,
                        interpretation: 'Niveau de trésorerie',
                        alerte: getAlertType((treso / ca) * 100, { danger: 5, warning: 10 }),
                    },
                ],
            },
        ];

        // Calcul du score global
        let scoreTotal = 0;
        let maxScore = 0;
        ratios.forEach(categorie => {
            categorie.items.forEach(item => {
                maxScore += 100;
                if (item.alerte === 'success') scoreTotal += 100;
                else if (item.alerte === 'warning') scoreTotal += 50;
            });
        });

        const scorePercentage = (scoreTotal / maxScore) * 100;
        let appreciation = '';
        if (scorePercentage >= 80) appreciation = 'Excellente santé financière';
        else if (scorePercentage >= 60) appreciation = 'Situation financière satisfaisante';
        else if (scorePercentage >= 40) appreciation = 'Situation financière à surveiller';
        else appreciation = 'Situation financière préoccupante';

        // Génération des recommandations
        const recommandations = [];

        ratios.forEach(categorie => {
            categorie.items.forEach(item => {
                if (item.alerte === 'danger') {
                    switch (item.label) {
                        case 'Rentabilité commerciale':
                            recommandations.push('Optimisez vos marges et réduisez vos coûts');
                            break;
                        case 'Rentabilité financière':
                            recommandations.push("Améliorez l'utilisation de vos capitaux propres");
                            break;
                        case 'Autonomie financière':
                            recommandations.push('Renforcez vos fonds propres');
                            break;
                        case 'Liquidité générale':
                            recommandations.push('Améliorez votre BFR et votre trésorerie');
                            break;
                        case 'Taux de valeur ajoutée':
                            recommandations.push("Revoyez votre politique d'achat et de prix");
                            break;
                        case 'Trésorerie / CA':
                            recommandations.push('Renforcez votre trésorerie');
                            break;
                    }
                }
            });
        });

        setResults({
            ratios,
            score: {
                total: scorePercentage,
                appreciation,
            },
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Diagnostic financier
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.secteurActivite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                secteurActivite: e.target.value as 'commerce' | 'service' | 'industrie',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="industrie"
                            control={<Radio />}
                            label="Industrie"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes financières (€)"
                        type="number"
                        value={formData.dettes}
                        onChange={e => setFormData(prev => ({ ...prev, dettes: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Trésorerie (€)"
                        type="number"
                        value={formData.tresorerie}
                        onChange={e => setFormData(prev => ({ ...prev, tresorerie: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Actif circulant (€)"
                        type="number"
                        value={formData.actifCirculant}
                        onChange={e => setFormData(prev => ({ ...prev, actifCirculant: e.target.value }))}
                        placeholder="Ex: 400000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Passif circulant (€)"
                        type="number"
                        value={formData.passifCirculant}
                        onChange={e => setFormData(prev => ({ ...prev, passifCirculant: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur ajoutée (€)"
                        type="number"
                        value={formData.valeurAjoutee}
                        onChange={e => setFormData(prev => ({ ...prev, valeurAjoutee: e.target.value }))}
                        placeholder="Ex: 400000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateDiagnostic}
                    disabled={!formData.chiffreAffaires || !formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Analyser la situation
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Score global</span>
                        <span className="value">{Math.round(results.score.total)}%</span>
                    </div>

                    <div
                        className="result-row"
                        style={{
                            backgroundColor:
                                results.score.total >= 60
                                    ? '#E8F5E9'
                                    : results.score.total >= 40
                                      ? '#FFF3E0'
                                      : '#FFEBEE',
                        }}
                    >
                        <span className="label">Appréciation</span>
                        <span className="value">{results.score.appreciation}</span>
                    </div>

                    {results.ratios.map((categorie, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                                <span className="label">{categorie.categorie}</span>
                                <span className="value"></span>
                            </div>

                            {categorie.items.map((item, itemIndex) => (
                                <React.Fragment key={itemIndex}>
                                    <div className="result-row">
                                        <span className="label">{item.label}</span>
                                        <span
                                            className="value"
                                            style={{
                                                color:
                                                    item.alerte === 'success'
                                                        ? '#2E7D32'
                                                        : item.alerte === 'warning'
                                                          ? '#ED6C02'
                                                          : '#C62828',
                                            }}
                                        >
                                            {item.valeur.toFixed(1)}%
                                        </span>
                                    </div>
                                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                        <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                            {item.interpretation} (référence : {item.reference}%)
                                        </span>
                                    </div>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default DiagnosticFinancier;
