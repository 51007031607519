import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import ArrayResults from '../components/ArrayResults';
import InputDate from '../components/InputDate';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface AmortissementData {
    montantInitial: string;
    duree: string;
    modeCalcul: 'lineaire' | 'degressif';
    tauxDegressif: string;
    dateAchat: string;
}

interface AnnuiteAmortissement {
    annee: number;
    baseAmortissable: number;
    dotation: number;
    cumul: number;
    vnc: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const PlanAmortissement: React.FC = () => {
    const [formData, setFormData] = useState<AmortissementData>({
        montantInitial: '',
        duree: '',
        modeCalcul: 'lineaire',
        tauxDegressif: '1.75', // valeur par défaut qui sera mise à jour automatiquement
        dateAchat: new Date().toISOString().split('T')[0],
    });

    const [results, setResults] = useState<AnnuiteAmortissement[]>([]);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateAmortissement = () => {
        const montant = parseFloat(formData.montantInitial) || 0;
        const duree = parseInt(formData.duree) || 0;
        const tauxDegressif = parseFloat(formData.tauxDegressif);
        const dateAchat = new Date(formData.dateAchat);
        const anneeAchat = dateAchat.getFullYear();

        const annuites: AnnuiteAmortissement[] = [];

        if (formData.modeCalcul === 'lineaire') {
            // Amortissement linéaire
            const dotationAnnuelle = montant / duree;
            let cumul = 0;

            for (let i = 1; i <= duree; i++) {
                cumul += dotationAnnuelle;
                annuites.push({
                    annee: anneeAchat + i - 1,
                    baseAmortissable: montant,
                    dotation: dotationAnnuelle,
                    cumul: cumul,
                    vnc: montant - cumul,
                });
            }
        } else {
            // Amortissement dégressif
            const tauxLineaire = 1 / duree;
            const tauxDegressifAnnuel = tauxLineaire * tauxDegressif;
            let baseAmortissable = montant;
            let cumul = 0;

            for (let i = 1; i <= duree; i++) {
                // On passe en linéaire quand c'est plus avantageux
                const dotationDegressif = baseAmortissable * tauxDegressifAnnuel;
                const dotationLineaire = (montant - cumul) / (duree - i + 1);

                const dotation = Math.max(dotationDegressif, dotationLineaire);
                cumul += dotation;

                annuites.push({
                    annee: anneeAchat + i - 1,
                    baseAmortissable: baseAmortissable,
                    dotation: dotation,
                    cumul: cumul,
                    vnc: montant - cumul,
                });

                baseAmortissable = montant - cumul;
            }
        }

        setResults(annuites);
    };

    const handleSubmit = () => {
        calculateAmortissement();
        if (!logged) handleOpen();
    };

    // Fonction pour déterminer le coefficient dégressif en fonction de la durée
    const updateTauxDegressif = (duree: string) => {
        const dureeNum = parseInt(duree);
        let nouveauTaux = '1.75'; // valeur par défaut

        if (dureeNum <= 4) {
            nouveauTaux = '1.25';
        } else if (dureeNum <= 6) {
            nouveauTaux = '1.75';
        } else {
            nouveauTaux = '2.25';
        }

        setFormData(prev => ({ ...prev, tauxDegressif: nouveauTaux }));
    };

    return (
        <>
            <SimulatorCard>
                <SimulatorHeader title="Plan d'amortissement" />

                <RadioButtonGroup
                    label="Mode d'amortissement"
                    options={[
                        { value: 'lineaire', label: 'Linéaire' },
                        { value: 'degressif', label: 'Dégressif' },
                    ]}
                    value={formData.modeCalcul}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, modeCalcul: value as 'lineaire' | 'degressif' }))
                    }
                    direction="column"
                />

                <InputNumber
                    label="Montant de l'immobilisation"
                    value={Number(formData.montantInitial) || null}
                    setValue={value => setFormData(prev => ({ ...prev, montantInitial: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 50000"
                />

                <InputNumber
                    label="Durée d'amortissement (années)"
                    value={Number(formData.duree) || null}
                    setValue={value => {
                        const dureeStr = String(value);
                        setFormData(prev => ({ ...prev, duree: dureeStr }));
                        updateTauxDegressif(dureeStr);
                    }}
                    placeholder="Ex : 5"
                />

                <InputDate
                    label="Date d'acquisition"
                    value={formData.dateAchat}
                    setValue={value => setFormData(prev => ({ ...prev, dateAchat: value }))}
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.montantInitial || !formData.duree}
                >
                    Calculer l'amortissement
                </ArrowButton>
            </SimulatorCard>

            {logged && results.length > 0 && (
                <ArrayResults
                    columns={[
                        { id: 'annee', label: 'Année' },
                        { id: 'baseAmortissable', label: 'Base amortissable', align: 'right', showEuroSymbol: true },
                        { id: 'dotation', label: 'Dotation', align: 'right', showEuroSymbol: true },
                        { id: 'cumul', label: 'Cumul des amortissements', align: 'right', showEuroSymbol: true },
                        { id: 'vnc', label: 'Valeur nette comptable', align: 'right', showEuroSymbol: true },
                    ]}
                    rows={results.map(annuite => ({
                        ...annuite,
                        baseAmortissable: Math.round(annuite.baseAmortissable),
                        dotation: Math.round(annuite.dotation),
                        cumul: Math.round(annuite.cumul),
                        vnc: Math.round(annuite.vnc),
                    }))}
                />
            )}
        </>
    );
};

export default PlanAmortissement;
