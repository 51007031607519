import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';

import { colors, fonts } from '../../../../../styles/theme';
import { NavButton } from '../../../../UI/Button/NavButton';
import { useMobileHeaderContext } from '../context/mobileHeaderProvider';
import { StyledAnchor, StyledLink } from '../styles/styles';
import Navbar from './Navbar/Navbar';

const MenuWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: white;
    position: fixed;
    z-index: 999999;
    padding: 0 16px;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    z-index: 9;
    width: 100%;
`;

const StyledLogo = styled.img`
    width: 100px;
    height: 26px;
`;

const CloseButton = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    cursor: pointer;

    padding: 8px;
    border-radius: 8px;
    background-color: transparent;
    height: 40px;

    &:hover {
        background-color: rgba(1, 58, 81, 0.05);
    }

    img {
        width: 20px;
        align-self: center;
    }
`;

const Retour = styled.div`
    font-family: ${fonts.OpenSans_Bold};
    font-size: 14px;
    display: flex;
    gap: 8px;
    padding: 0 8px;

    cursor: pointer;

    align-items: baseline;

    color: ${colors.$primary700};

    img {
        width: 10px;
        display: flex;
        transform: rotate(90deg);
    }
`;

const Telephone = styled(StyledAnchor)`
    font-family: ${fonts.OpenSans_Bold};
    font-size: 13px;
    color: ${colors.$primary700};

    display: flex;
    align-items: center;

    img {
        width: 12px;
        margin-right: 6px;
        align-self: center;
    }
`;

const BottomSection = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    gap: 8px;
`;

const MobileMenu = ({ setIsOpen }: { setIsOpen: (prevState: boolean) => void }) => {
    const { isSubmenuActive, setIsSubmenuActive } = useMobileHeaderContext();

    const handleClick = () => {
        setIsOpen(false);
        setIsSubmenuActive(false);
    };

    return (
        <MenuWrapper>
            <div>
                <Header>
                    {isSubmenuActive ? (
                        <Retour onClick={() => setIsSubmenuActive(false)}>
                            <img src="/images/header/icon-arrow-down-menu.svg" /> Retour
                        </Retour>
                    ) : (
                        <StyledLink
                            to={'/'}
                            className="logo"
                            title="Expert comptable en ligne"
                            data-ga-send="event,link,click,topbar-logo"
                            data-ga4="topbar-bar1-img-logodougs-click-link"
                        >
                            <StyledLogo src="/images/header/logo-dougs.svg" alt="Dougs expert comptable en ligne" />
                        </StyledLink>
                    )}

                    <CloseButton onClick={handleClick}>
                        <img src="/images/header/icon-close.svg" alt="Menu" />
                    </CloseButton>
                </Header>

                <Navbar />
            </div>

            <BottomSection>
                <a
                    href="https://app.dougs.fr"
                    data-ga-send="event,button,click,topbar-login"
                    data-ga4="topbar-bar1-cta-text-click-link"
                    rel="nofollow"
                    style={{ width: '100%' }}
                >
                    <NavButton variant="secondary">Se connecter</NavButton>
                </a>

                <Link
                    to="/presignup"
                    target="_blank"
                    rel="noopener nofollow"
                    data-ga-send="event,button,click,topbar-signup"
                    data-ga4="topbar-bar1-cta-button1-click-link"
                    style={{ width: '100%' }}
                >
                    <NavButton variant="primary">S'inscrire</NavButton>
                </Link>

                <Telephone href="tel:0428296262" data-ga-send="event,link,click,topbar-phone">
                    <img src="/images/header/icn-tel.svg" alt="Telephone" width="11" height="11" />
                    <span>04 28 29 62 62</span>
                </Telephone>
            </BottomSection>
        </MenuWrapper>
    );
};

export default MobileMenu;
