import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { useMeetUsContext } from '../../../contexts/MeetUsContextProvider';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { colors } from '../../../styles/theme';
import * as styles from '../../modals/modal.module.scss';
import { Steps } from '../types/types';
import { getStep } from '../utils/getStep';
import CloseIcon from './../../../images/icons/closeWhite.svg';

const StyledModal = styled.div`
    background-color: ${colors.$primary700} !important;
    width: auto !important;
    padding-top: 64px !important;
    padding-bottom: 64px !important;
    border-radius: 24px !important;
    overflow: auto;
    @media screen and (max-width: 1024px) {
        border-radius: 24px 24px 0px 0px !important;
        padding-top: 0px !important;
        > div {
            align-items: center !important;
        }
    }
`;

export const StyledCross = styled.button`
    width: 32px !important;
    height: 32px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    border-radius: 50% !important;

    svg {
        transform: scale(0.5);
    }

    @media screen and (max-width: 1024px) {
        position: sticky !important;
        left: calc(100% - 60px) !important;
    }
`;

const MeetUsModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    const useCloseModal = (ref, close) =>
        useEffect(() => {
            const handler = e => {
                if (e.which === 27) close();
            };
            document.addEventListener('keyup', handler);
            return () => document.removeEventListener('keyup', handler);
        }, [ref, close]);

    const { step } = useMeetUsContext();
    const isSmall = useBreakpoints(['small', 'medium']);
    const size = isSmall ? 'fullscreen' : '';
    const wrapperRef = useRef(null);
    useCloseModal(wrapperRef, close);
    return (
        <div className={`${styles.modal} ${size || ''} ${isOpen ? 'open' : 'closed'}`}>
            <StyledModal
                ref={wrapperRef}
                className={`${styles.modalContent} ${size || ''} ${isOpen ? 'open' : 'closed'}`}
            >
                <StyledCross className={styles.modalClose} onClick={close}>
                    <CloseIcon />
                </StyledCross>
                {getStep(step as Steps, true)}
            </StyledModal>
        </div>
    );
};

export default MeetUsModal;
