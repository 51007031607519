import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import useBreakpoints from '../../hooks/useBreakpoints';
import { breakpoint, colors, fonts } from '../../styles/theme';
import Divider from '../UI/Divider';

interface RelatedPosts {
    posts: {
        title: string;
        img: IGatsbyImageData;
        uri: string;
    }[];
}

const Container = styled.div`
    margin-bottom: 64px;

    ${breakpoint.laptop} {
        margin-bottom: 120px;
    }
`;

const Label = styled.div`
    font-family: ${fonts.Eina_SemiBold};
    color: ${colors.$primary700};
    line-height: 125%;
    font-size: 22px;
    margin-bottom: 24px;

    ${breakpoint.laptop} {
        font-size: 32px;
    }
`;

const RelatedPostsList = styled.div`
    display: flex;
    gap: 24px;
    flex-direction: column;

    ${breakpoint.tablet} {
        flex-direction: row;
    }
`;

const RelatedPost = styled.div`
    max-width: 100%;

    ${breakpoint.laptop} {
        max-width: 252px;
    }
`;

const FullWidthImage = styled(GatsbyImage)`
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: 140px;
    margin-bottom: 12px;
    transition: transform 0.3s;

    box-shadow:
        0px 8px 40px 0px rgba(0, 0, 0, 0.1),
        0px 0px 8px 0px rgba(0, 0, 0, 0.05);

    ${RelatedPost}:hover & {
        transform: scale(1.03);
    }
`;

const Title = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 14px;

    ${breakpoint.laptop} {
        font-size: 16px;
    }
`;

const UnderLine = styled.div`
    margin-top: 4px;
    background: ${colors.$primary700};
    opacity: 0;
    height: 2px;
    width: 0;
    transition:
        width 0.4s,
        opacity 0.4s;

    ${RelatedPost}:hover & {
        opacity: 1;
        width: 40px;
    }
`;

const RelatedPosts = ({ posts }: RelatedPosts) => {
    const isDesktop = useBreakpoints(['large', 'xlarge', 'xxlarge']);

    if (!posts.filter(e => e).length) return null;
    return (
        <Container>
            <Divider marginY={isDesktop ? 80 : 48} />
            <Label>Ces articles peuvent vous intéresser</Label>
            <RelatedPostsList>
                {posts.map((post, index) => (
                    <RelatedPost key="related-post">
                        <Link
                            to={post.uri}
                            data-ga4={`container-miniature${index}-img-topimage-click-link`}
                            data-ga-send={`event,button,click,blog-related-article${index}`}
                        >
                            <FullWidthImage image={post.img} alt={post.title || ''} />
                            <Title>{post.title}</Title>
                        </Link>
                        <UnderLine />
                    </RelatedPost>
                ))}
            </RelatedPostsList>
        </Container>
    );
};

export default RelatedPosts;
