import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface LevierData {
    investissement: string;
    fondsPropresPct: string;
    tauxInteret: string;
    rentabiliteEconomique: string;
    impotSocietes: string;
}

interface LevierResults {
    rentabiliteFinanciere: number;
    effetLevier: number;
    details: {
        label: string | string[];
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const EffetLevier: React.FC = () => {
    const [formData, setFormData] = useState<LevierData>({
        investissement: '',
        fondsPropresPct: '',
        tauxInteret: '',
        rentabiliteEconomique: '',
        impotSocietes: '25',
    });

    const [results, setResults] = useState<LevierResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateLevier = () => {
        const investissement = parseFloat(formData.investissement) || 0;
        const fondsPropresPct = parseFloat(formData.fondsPropresPct) || 0;
        const tauxInteret = parseFloat(formData.tauxInteret) || 0;
        const rentabiliteEconomique = parseFloat(formData.rentabiliteEconomique) || 0;
        const tauxIS = parseFloat(formData.impotSocietes) / 100;

        // Calcul des montants
        const fondsPropresMontant = investissement * (fondsPropresPct / 100);
        const detteMontant = investissement - fondsPropresMontant;
        const levier = detteMontant / fondsPropresMontant;

        // Calcul des résultats
        const resultatEconomique = investissement * (rentabiliteEconomique / 100);
        const fraisFinanciers = detteMontant * (tauxInteret / 100);
        const resultatAvantImpot = resultatEconomique - fraisFinanciers;
        const impot = resultatAvantImpot * tauxIS;
        const resultatNet = resultatAvantImpot - impot;

        // Calcul des rentabilités
        const rentabiliteFinanciere = (resultatNet / fondsPropresMontant) * 100;
        const effetLevier = rentabiliteFinanciere - rentabiliteEconomique;

        // Détails des calculs
        const details = [
            {
                label: "Montant de l'investissement",
                description: 'Investissement total nécessaire',
                montant: investissement,
            },
            {
                label: `Fonds propres`,
                description: `${fondsPropresPct}% de l'investissement`,
                montant: fondsPropresMontant,
            },
            {
                label: `Dette`,
                description: `${(100 - fondsPropresPct).toFixed(1)}% de l'investissement`,
                montant: detteMontant,
            },
            {
                label: 'Levier financier',
                description: 'Ratio Dette/Fonds propres',
                montant: levier,
            },
            {
                label: `Résultat économique`,
                description: `${rentabiliteEconomique}% de l'investissement`,
                montant: resultatEconomique,
            },
            {
                label: `Frais financiers`,
                description: `${tauxInteret}% de la dette`,
                montant: fraisFinanciers,
            },
            {
                label: `Résultat net`,
                description: 'Après frais financiers et IS',
                montant: resultatNet,
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (rentabiliteEconomique < tauxInteret) {
            recommandations.push(
                "Attention : la rentabilité économique est inférieure au taux d'intérêt (effet de levier négatif)",
            );
        }

        if (levier > 2) {
            recommandations.push("Le niveau d'endettement est élevé, ce qui augmente le risque financier");
        }

        if (rentabiliteFinanciere < 8) {
            recommandations.push("La rentabilité financière est faible, envisagez d'optimiser la structure financière");
        }

        if (effetLevier > 10) {
            recommandations.push(
                "L'effet de levier est très important, vérifiez la capacité à supporter les frais financiers",
            );
        }

        setResults({
            rentabiliteFinanciere,
            effetLevier,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateLevier();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur d'effet de levier" />

                <InputNumber
                    label="Montant de l'investissement"
                    value={Number(formData.investissement) || null}
                    setValue={value => setFormData(prev => ({ ...prev, investissement: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000000"
                />

                <InputNumber
                    label="Part des fonds propres"
                    value={Number(formData.fondsPropresPct) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fondsPropresPct: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 30"
                />

                <InputNumber
                    label="Taux d'intérêt"
                    value={Number(formData.tauxInteret) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxInteret: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 4"
                />

                <InputNumber
                    label="Rentabilité économique"
                    value={Number(formData.rentabiliteEconomique) || null}
                    setValue={value => setFormData(prev => ({ ...prev, rentabiliteEconomique: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 8"
                />

                <InputNumber
                    label="Taux IS"
                    value={Number(formData.impotSocietes) || null}
                    setValue={value => setFormData(prev => ({ ...prev, impotSocietes: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 25"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.investissement || !formData.fondsPropresPct}
                >
                    Calculer l'effet de levier
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Rentabilité financière',
                                value: `${results.rentabiliteFinanciere.toFixed(1)}%`,
                                highlight: true,
                            },
                            {
                                label: 'Effet de levier',
                                value: `${results.effetLevier.toFixed(1)}%`,
                                highlight: true,
                            },
                            ...results.details.map(detail => ({
                                label: detail.label,
                                description: detail.description,
                                value: detail.label.includes('Levier')
                                    ? detail.montant.toFixed(2)
                                    : `${Math.round(detail.montant).toLocaleString('fr-FR')}\u00A0€`,
                            })),
                            {
                                label: results.recommandations,
                                description: 'Recommandations',
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default EffetLevier;
