import React from 'react';

import { useMeetUsContext } from '../../../../../../contexts/MeetUsContextProvider';
import ArrowButton from '../../../../../UI/Button/ArrowButton';
import { Buttons, Details, RightContainerCustomMade, TextResponse, TitleResponse } from '../../styles';

const SuccessContent = ({ pack, peoples }: { pack: string[]; peoples: number }) => {
    const { openModal } = useMeetUsContext();
    return (
        <RightContainerCustomMade>
            <TitleResponse>Comment Dougs vous accompagne ?</TitleResponse>
            <TextResponse>
                {pack.map(line => (
                    <li key="line">{line}</li>
                ))}
                {peoples > 0 && <li>Pack social (fiches de paie de vos salariés)</li>}
            </TextResponse>
            <Buttons>
                <ArrowButton
                    actionType="INTERNAL_LINK"
                    color="ORANGE"
                    linkOptions={{
                        href: 'https://app.dougs.fr/signup',
                        rel: 'noopener nofollow',
                        target: '_blank',
                    }}
                    trackingId="calculateur-essayer-dougs"
                >
                    Essayer gratuitement
                </ArrowButton>
                <ArrowButton
                    actionType="MODAL"
                    color="LIGHT_ORANGE"
                    onClick={openModal}
                    trackingId="calculateur-prendre-rendez-vous"
                >
                    Prendre rendez-vous
                </ArrowButton>
            </Buttons>

            <Details>
                * L’économie annuelle estimée repose sur notre tarification et sur les données que vous avez
                renseignées.
            </Details>
        </RightContainerCustomMade>
    );
};

export default SuccessContent;
