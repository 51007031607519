import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface CfeData {
    valeurLocative: string;
    commune: string;
    tauxCommune: string;
    premierExercice: boolean;
}

interface CfeResults {
    baseCotisation: number;
    cotisationFinale: number;
    exoneration: boolean;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CotisationFonciere: React.FC = () => {
    const [formData, setFormData] = useState<CfeData>({
        valeurLocative: '',
        commune: '',
        tauxCommune: '',
        premierExercice: true,
    });

    const [results, setResults] = useState<CfeResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCFE = () => {
        const valeurLocative = parseFloat(formData.valeurLocative) || 0;
        const tauxCommune = parseFloat(formData.tauxCommune) || 0;

        // Calcul de la base d'imposition
        const baseCotisation = valeurLocative;
        const cotisationFinale = baseCotisation * (tauxCommune / 100);

        // Vérification des cas d'exonération (uniquement première année)
        const exoneration = formData.premierExercice;

        setResults({
            baseCotisation,
            cotisationFinale: exoneration ? 0 : cotisationFinale,
            exoneration,
        });
    };

    const handleSubmit = () => {
        calculateCFE();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'true', label: "Première année d'activité" },
        { value: 'false', label: 'Année courante' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de CFE" />
                <SimulatorAlert text="Ce simulateur ne traite pas le calcul de la cotisation minimum" />

                <RadioButtonGroup
                    label="Votre situation"
                    options={radioOptions}
                    value={formData.premierExercice}
                    onChange={value => setFormData(prev => ({ ...prev, premierExercice: value === 'true' }))}
                    direction="column"
                />

                <InputNumber
                    label="Valeur locative cadastrale"
                    value={Number(formData.valeurLocative) || null}
                    setValue={value => setFormData(prev => ({ ...prev, valeurLocative: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5000"
                />

                <InputNumber
                    label="Taux communal"
                    value={Number(formData.tauxCommune) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxCommune: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 25,59"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.valeurLocative || !formData.tauxCommune}
                >
                    Calculer la CFE
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={
                            results.exoneration
                                ? [
                                      {
                                          label: 'Statut',
                                          value: 'Exonéré de CFE',
                                          highlight: true,
                                      },
                                  ]
                                : [
                                      {
                                          label: 'CFE à payer',
                                          value: `${Math.round(results.cotisationFinale).toLocaleString('fr-FR')} €`,
                                          highlight: true,
                                      },
                                      {
                                          label: "Base d'imposition",
                                          value: `${Math.round(results.baseCotisation).toLocaleString('fr-FR')} €`,
                                      },
                                  ]
                        }
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CotisationFonciere;
