import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface RentabiliteData {
    prixAchat: string;
    fraisNotaire: string;
    fraisAgence: string;
    montantTravaux: string;
    loyerMensuel: string;
    chargesAnnuelles: string;
    tauxVacance: string;
}

interface RentabiliteResults {
    rentabiliteBrute: number;
    rentabiliteNette: number;
    cashFlowMensuel: number;
    coutTotalProjet: number;
    revenuAnnuel: number;
    chargesAnnuelles: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const RentabiliteLocative: React.FC = () => {
    const [formData, setFormData] = useState<RentabiliteData>({
        prixAchat: '',
        fraisNotaire: '',
        fraisAgence: '',
        montantTravaux: '',
        loyerMensuel: '',
        chargesAnnuelles: '',
        tauxVacance: '',
    });

    const [results, setResults] = useState<RentabiliteResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateRentabilite = () => {
        // Conversion des valeurs en nombres
        const prixAchat = formData.prixAchat === '' ? 0 : parseFloat(formData.prixAchat);
        const fraisNotaire = formData.fraisNotaire === '' ? prixAchat * 0.08 : parseFloat(formData.fraisNotaire);
        const fraisAgence = formData.fraisAgence === '' ? 0 : parseFloat(formData.fraisAgence);
        const montantTravaux = formData.montantTravaux === '' ? 0 : parseFloat(formData.montantTravaux);
        const loyerMensuel = formData.loyerMensuel === '' ? 0 : parseFloat(formData.loyerMensuel);
        const chargesAnnuelles = formData.chargesAnnuelles === '' ? 0 : parseFloat(formData.chargesAnnuelles);
        const tauxVacance = formData.tauxVacance === '' ? 0 : parseFloat(formData.tauxVacance);

        // Calculs
        const coutTotalProjet = prixAchat + fraisNotaire + fraisAgence + montantTravaux;
        const revenuAnnuel = loyerMensuel * 12 * (1 - tauxVacance / 100);

        const rentabiliteBrute = coutTotalProjet ? (revenuAnnuel / coutTotalProjet) * 100 : 0;
        const rentabiliteNette = coutTotalProjet ? (revenuAnnuel / (coutTotalProjet + chargesAnnuelles)) * 100 : 0;
        const cashFlowMensuel = (revenuAnnuel - chargesAnnuelles) / 12;

        setResults({
            rentabiliteBrute,
            rentabiliteNette,
            cashFlowMensuel,
            coutTotalProjet,
            revenuAnnuel,
            chargesAnnuelles,
        });
    };

    const handleSubmit = () => {
        calculateRentabilite();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de rentabilité locative" />

                <InputNumber
                    label="Prix d'achat"
                    value={Number(formData.prixAchat) || null}
                    setValue={value => setFormData(prev => ({ ...prev, prixAchat: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 200000"
                />
                <InputNumber
                    label="Frais de notaire"
                    value={Number(formData.fraisNotaire) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisNotaire: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 16000"
                />
                <InputNumber
                    label="Frais d'agence"
                    value={Number(formData.fraisAgence) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisAgence: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 10000"
                />

                <InputNumber
                    label="Montant des travaux"
                    value={Number(formData.montantTravaux) || null}
                    setValue={value => setFormData(prev => ({ ...prev, montantTravaux: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 20000"
                />
                <InputNumber
                    label="Charges annuelles"
                    value={Number(formData.chargesAnnuelles) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chargesAnnuelles: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1200"
                />

                <InputNumber
                    label="Loyer mensuel (charges incluses)"
                    value={Number(formData.loyerMensuel) || null}
                    setValue={value => setFormData(prev => ({ ...prev, loyerMensuel: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />
                <InputNumber
                    label="Taux de vacance locative"
                    value={Number(formData.tauxVacance) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxVacance: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.prixAchat || !formData.loyerMensuel}
                >
                    Calculer la rentabilité
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Rentabilité brute',
                                value: `${results.rentabiliteBrute.toFixed(2)}%`,
                                highlight: true,
                            },
                            {
                                label: 'Rentabilité nette',
                                value: `${results.rentabiliteNette.toFixed(2)}%`,
                                highlight: true,
                            },
                            {
                                label: 'Cash-flow mensuel',
                                value: `${Math.round(results.cashFlowMensuel).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Coût total du projet',
                                value: `${Math.round(results.coutTotalProjet).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Revenu locatif annuel',
                                value: `${Math.round(results.revenuAnnuel).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Charges annuelles',
                                value: `${Math.round(results.chargesAnnuelles).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default RentabiliteLocative;
