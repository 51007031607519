import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

import { breakpoint, colors, fonts } from '../../../styles/theme';

// Définition d'un type générique pour les données de ligne
type RowData = Record<string, string | number | boolean>;

interface Column {
    id: string;
    label: string;
    align?: 'right' | 'left' | 'center';
    format?: (value: string | number) => string;
    showEuroSymbol?: boolean;
}

interface ArrayResultsProps {
    columns: Column[];
    rows: RowData[];
}

const StyledTableCell = styled(TableCell)({
    padding: '8px 16px !important',
    '@media (max-width: 600px)': {
        padding: '6px 8px !important',
        fontSize: '0.8rem !important',
    },
});

const StyledLabel = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 8px;
    margin-top: 16px;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const StyledTableRow = styled(TableRow)<{ highlighted?: boolean }>(({ highlighted }) => ({
    backgroundColor: highlighted ? '#f5f5f5' : 'inherit',
    '&:hover': {
        backgroundColor: highlighted ? '#f0f0f0' : '#fafafa',
    },
}));

const ArrayResults: React.FC<ArrayResultsProps> = ({ columns, rows }) => {
    const formatValue = (column: Column, value: string | number | boolean) => {
        if (column.format && typeof value !== 'boolean') {
            return column.format(value);
        }

        if (typeof value === 'number') {
            return value.toLocaleString('fr-FR');
        }

        if (typeof value === 'boolean') {
            return value ? 'Oui' : 'Non';
        }

        return value;
    };

    return (
        <>
            <StyledLabel>Résultat</StyledLabel>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <StyledTableCell key={column.id} align={column.align || 'left'}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <StyledTableRow key={`${row.annee}-${index}`} highlighted={row.highlightedRow as boolean}>
                                {columns.map(column => (
                                    <StyledTableCell key={column.id} align={column.align || 'left'}>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {formatValue(column, row[column.id])}
                                            {column.showEuroSymbol && typeof row[column.id] === 'number' ? ' €' : ''}
                                        </span>
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ArrayResults;
