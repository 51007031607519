import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface PlusValueData {
    valeurImmeuble: string;
    prixAcquisition: string;
    dureeDetention: string;
    travaux: string;
    isNeuf: boolean;
}

interface PlusValueResults {
    plusValueBrute: number;
    abattementIR: number;
    abattementPS: number;
    plusValueNetteIR: number;
    plusValueNettePS: number;
    impositionIR: number;
    impositionPS: number;
    impositionTotale: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const PlusValueImmobiliere: React.FC = () => {
    const [formData, setFormData] = useState<PlusValueData>({
        valeurImmeuble: '',
        prixAcquisition: '',
        dureeDetention: '',
        travaux: '',
        isNeuf: false,
    });

    const [results, setResults] = useState<PlusValueResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const getAbattementIR = (duree: number): number => {
        if (duree <= 5) return 0;
        if (duree <= 21) {
            const abattements = {
                6: 6,
                7: 12,
                8: 18,
                9: 24,
                10: 30,
                11: 36,
                12: 42,
                13: 48,
                14: 54,
                15: 60,
                16: 66,
                17: 72,
                18: 78,
                19: 84,
                20: 90,
                21: 96,
            };
            return abattements[duree as keyof typeof abattements] || 0;
        }
        return 100;
    };

    const getAbattementPS = (duree: number): number => {
        if (duree <= 5) return 0;
        if (duree <= 30) {
            const abattements = {
                6: 1.65,
                7: 3.3,
                8: 4.95,
                9: 6.6,
                10: 8.25,
                11: 9.9,
                12: 11.55,
                13: 13.2,
                14: 14.85,
                15: 16.5,
                16: 18.15,
                17: 19.8,
                18: 21.45,
                19: 23.1,
                20: 24.75,
                21: 26.4,
                22: 28.0,
                23: 37.0,
                24: 46.0,
                25: 55.0,
                26: 64.0,
                27: 73.0,
                28: 82.0,
                29: 91.0,
                30: 100.0,
            };
            return abattements[duree as keyof typeof abattements] || 0;
        }
        return 100;
    };

    const calculatePlusValue = () => {
        const valeurImmeuble = parseFloat(formData.valeurImmeuble) || 0;
        const prixAcquisition = parseFloat(formData.prixAcquisition) || 0;
        const dureeDetention = parseFloat(formData.dureeDetention) || 0;
        const travaux = formData.isNeuf ? 0 : parseFloat(formData.travaux) || 0;

        // Calcul automatique des frais d'acquisition (7,5% ou 2,5% si neuf)
        const tauxFrais = formData.isNeuf ? 0.025 : 0.075;
        const fraisAcquisition = prixAcquisition * tauxFrais;

        // Calcul de la plus-value brute
        const plusValueBrute = valeurImmeuble - (prixAcquisition + fraisAcquisition + travaux);

        // Calcul des abattements
        const abattementIR = getAbattementIR(dureeDetention);
        const abattementPS = getAbattementPS(dureeDetention);

        // Calcul des plus-values nettes
        const plusValueNetteIR = plusValueBrute * (1 - abattementIR / 100);
        const plusValueNettePS = plusValueBrute * (1 - abattementPS / 100);

        // Calcul des impositions
        const tauxIR = 19;
        const tauxPS = 17.2;
        const impositionIR = plusValueNetteIR * (tauxIR / 100);
        const impositionPS = plusValueNettePS * (tauxPS / 100);

        setResults({
            plusValueBrute,
            abattementIR,
            abattementPS,
            plusValueNetteIR,
            plusValueNettePS,
            impositionIR,
            impositionPS,
            impositionTotale: impositionIR + impositionPS,
        });
    };

    const handleSubmit = () => {
        calculatePlusValue();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur d'impôt sur la plus-value immobilière" />

                <InputNumber
                    label="Prix de vente"
                    value={Number(formData.valeurImmeuble) || null}
                    setValue={value => setFormData(prev => ({ ...prev, valeurImmeuble: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 200000"
                />

                <InputNumber
                    label="Prix d'acquisition"
                    value={Number(formData.prixAcquisition) || null}
                    setValue={value => setFormData(prev => ({ ...prev, prixAcquisition: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 120000"
                />

                <RadioButtonGroup
                    label="Logement neuf"
                    options={[
                        { value: 'true', label: 'Oui' },
                        { value: 'false', label: 'Non' },
                    ]}
                    value={formData.isNeuf}
                    onChange={value =>
                        setFormData(prev => ({
                            ...prev,
                            isNeuf: value === 'true',
                        }))
                    }
                    direction="row"
                />

                <InputNumber
                    label="Durée de détention (années)"
                    value={Number(formData.dureeDetention) || null}
                    setValue={value => setFormData(prev => ({ ...prev, dureeDetention: String(value) }))}
                    placeholder="Ex : 18"
                />

                {!formData.isNeuf && (
                    <InputNumber
                        label="Coûts des travaux"
                        value={Number(formData.travaux) || null}
                        setValue={value => setFormData(prev => ({ ...prev, travaux: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 0"
                    />
                )}

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.valeurImmeuble || !formData.prixAcquisition || !formData.dureeDetention}
                >
                    Calculer l'impôt
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Imposition totale',
                                value: `${Math.round(results.impositionTotale).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Plus-value brute',
                                value: `${Math.round(results.plusValueBrute).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Abattement IR',
                                value: `${results.abattementIR.toLocaleString('fr-FR')} %`,
                            },
                            {
                                label: 'Abattement PS',
                                value: `${results.abattementPS.toLocaleString('fr-FR')} %`,
                            },
                            {
                                label: 'Plus-value nette IR',
                                value: `${Math.round(results.plusValueNetteIR).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Plus-value nette PS',
                                value: `${Math.round(results.plusValueNettePS).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Imposition IR (19%)',
                                value: `${Math.round(results.impositionIR).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Imposition PS (17,2%)',
                                value: `${Math.round(results.impositionPS).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PlusValueImmobiliere;
