import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputDate from '../components/InputDate';
import InputNumber from '../components/InputNumber';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface ArceData {
    allocationJournaliere: string;
    nombreJoursRestants: string;
    dateOuvertureDroits: string;
}

interface ArceResults {
    montantARCE: number;
    montantNet: number;
    deductionRetraite: number;
    tauxApplique: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const AideRepriseCreationEntreprise: React.FC = () => {
    const [formData, setFormData] = useState<ArceData>({
        allocationJournaliere: '',
        nombreJoursRestants: '',
        dateOuvertureDroits: '',
    });

    const [results, setResults] = useState<ArceResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateARCE = () => {
        const allocationJournaliere = parseFloat(formData.allocationJournaliere) || 0;
        const nombreJoursRestants = parseFloat(formData.nombreJoursRestants) || 0;
        const dateOuverture = new Date(formData.dateOuvertureDroits);
        const dateLimite = new Date('2023-07-01');

        // Vérification si les droits sont expirés (plus de 3 ans)
        const threeYearsFromOpening = new Date(dateOuverture);
        threeYearsFromOpening.setFullYear(threeYearsFromOpening.getFullYear() + 3);

        // Détermination du taux selon la date d'ouverture des droits
        const tauxApplique = dateOuverture >= dateLimite ? 0.6 : 0.45;

        // Calcul du montant total de l'ARCE
        const montantARCE = allocationJournaliere * nombreJoursRestants * tauxApplique;

        // Calcul de la déduction retraite (3%)
        const deductionRetraite = montantARCE * 0.03;

        // Calcul du montant net après déduction
        const montantNet = montantARCE - deductionRetraite;

        setResults({
            montantARCE,
            montantNet,
            deductionRetraite,
            tauxApplique,
        });
    };

    const handleSubmit = () => {
        calculateARCE();
        if (!logged) handleOpen();
    };

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Simulateur ARCE" />

                <SimulatorAlert text="Attention : Au-delà de 3 ans après la date de fin de vos droits, vous ne pourrez plus bénéficier de l'ARCE. Rapprochez vous de France Travail pour obtenir des informations précises." />

                <InputDate
                    label="Date d'ouverture de vos droits"
                    value={formData.dateOuvertureDroits}
                    setValue={value => setFormData(prev => ({ ...prev, dateOuvertureDroits: value }))}
                />

                <InputNumber
                    label="Montant brut journalier de votre allocation ARE"
                    value={Number(formData.allocationJournaliere) || null}
                    setValue={value => setFormData(prev => ({ ...prev, allocationJournaliere: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 40"
                />

                <InputNumber
                    label="Nombre de jours d'indemnisation restants"
                    value={Number(formData.nombreJoursRestants) || null}
                    setValue={value => setFormData(prev => ({ ...prev, nombreJoursRestants: String(value) }))}
                    placeholder="Ex : 365"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={
                        !formData.allocationJournaliere ||
                        !formData.nombreJoursRestants ||
                        !formData.dateOuvertureDroits
                    }
                >
                    Calculer l'ARCE
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Montant net ARCE',
                                value: `${Math.round(results.montantNet).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: '1er versement',
                                value: `${Math.round(results.montantNet / 2).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: '2ème versement (6 mois après)',
                                value: `${Math.round(results.montantNet / 2).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Montant brut ARCE',
                                value: `${Math.round(results.montantARCE).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Déduction retraite complémentaire (3%)',
                                value: `- ${Math.round(results.deductionRetraite).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Taux appliqué',
                                value: `${results.tauxApplique * 100}%`,
                            },
                            {
                                description:
                                    "Ceci est une estimation, seuls les montants communiqués par France Travail font foi, nous vous invitons à contacter l'organisme",
                                label: '',
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default AideRepriseCreationEntreprise;
