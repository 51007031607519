import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect } from 'react';

import { useMeetUsContext } from '../../contexts/MeetUsContextProvider';
import { breakpoint, colors } from '../../styles/theme';
import { mixpanelEvent } from '../layout/scripts';
import { wording } from './constants/constants';
import { Steps } from './types/types';

const Wrapper = styled.div`
    text-align: left;
    padding: 64px;
    img {
        width: 142px;
        margin-bottom: 16px;
    }
    @media screen and (max-width: 1024px) {
        padding: 24px;
        img {
            width: 90px;
        }
    }
`;

const Title = styled.div`
    font-size: 24px;
    font-family: Eina-SemiBold, serif;

    color: ${colors.$primary700};
    ${breakpoint['$large-max']} {
        font-size: 20px;
    }
`;

const Paragraph = styled.div`
    color: ${colors.$primary700};
    max-width: 960px;
    font-size: 14px;
`;

type Props = {
    reason: Steps.NotFrance | Steps.BadActivity | Steps.Both;
};

const Image = styled.img`
    max-width: 160px;
    margin-bottom: 18px;

    ${breakpoint['$large-max']} {
        max-width: 62px;
    }
`;

const StepNotEligible = ({ reason }: Props) => {
    const { payload } = useMeetUsContext();
    useEffect(() => {
        mixpanelEvent({ params: 'send,event,contact,click,contact-not-eligible'.split(','), options: payload });
    }, [payload]);

    useEffect(() => {
        if (window && typeof window !== undefined) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <Wrapper>
            <Image src="/images/meetUs/illu-pas-dispo.svg" alt="Illustration pas disponible" />
            <Title dangerouslySetInnerHTML={{ __html: wording[reason].title }} />
            <Paragraph className="mb-4 mt-2">
                <div dangerouslySetInnerHTML={{ __html: wording[reason].firstParagraph }} />
            </Paragraph>
        </Wrapper>
    );
};

export default StepNotEligible;
