import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { usePageContext } from '../../contexts/pageContextProvider';
import useBreakpoints from '../../hooks/useBreakpoints';
import useModal from '../../hooks/useModal';
import { breakpoint, colors, fonts } from '../../styles/theme';
import HubspotForm from '../hubspot/HubspotForm';
import Modal from '../modals/Modal';
import ArrowButton from '../UI/Button/ArrowButton';

const Container = styled.div`
    padding: 24px;
    background: ${colors.$primary700};
    color: #fff;
    border-radius: 16px;

    ${breakpoint.tablet} {
        padding: 40px;
    }
`;

const Content = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;

const Title = styled.div`
    font-family: ${fonts.Eina_SemiBold};
    font-size: 18px;

    ${breakpoint.tablet} {
        font-size: 24px;
    }
`;

const Subtitle = styled.p`
    font-size: 15px;
    ${breakpoint.tablet} {
        font-size: 16px;
    }
`;

const Image = styled.div`
    display: none;

    ${breakpoint.tablet} {
        display: block;
    }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
    height: 220px;
    width: 158px;
`;

const DownloadChecklist = ({
    slice,
}: {
    slice?: {
        primary: Queries.PrismicPostDataBodyDownloadChecklist;
    };
}) => {
    const prismicChecklists = useStaticQuery(query);

    const { isModalOpen, openModal, closeModal } = useModal();
    const pageContext = usePageContext();
    const isSmall = useBreakpoints(['small']);
    const currentTags = pageContext?.tags || [];
    const currentChecklist = slice?.primary?.checklist?.document
        ? slice.primary.checklist.document
        : prismicChecklists.allPrismicChecklist?.nodes.find(checklist =>
              checklist.data.tags.find(tag => currentTags.includes(tag.tag.slug)),
          ) || undefined;

    const defaultChecklist = currentChecklist
        ? currentChecklist.data
        : {
              title: 'Optimisez votre trésorerie',
              description: {
                  text: 'Suivez 18 actions de cette checklist et optimisez dès maintenant votre tréso !',
              },
              cta_text: 'Télécharger la checklist',
              form_hubspot_id: 'c7047c9e-28fa-4903-b325-79926e503c2f',
              keyword: 'default',
          };

          if (isSmall && !!pageContext?.ctaTag) return <div />;
          return (
              <>
                  <Container>
                      <Content>
                          <div>
                              <Title>{defaultChecklist.title}</Title>
                              <Subtitle>{defaultChecklist.description?.text}</Subtitle>
                              <ArrowButton
                                  actionType="MODAL"
                                  trackingId="blog-cta-download-checklist"
                                  onClick={openModal}
                              >
                                  {defaultChecklist.cta_text}
                              </ArrowButton>
                          </div>
                          <Image>
                              {defaultChecklist.image ? (
                                  <StyledGatsbyImage
                                      image={defaultChecklist.image.gatsbyImageData}
                                      alt="Checklist"
                                      imgStyle={{ objectFit: 'contain', height: '220px' }}
                                  />
                              ) : (
                                  <StaticImage
                                      src="../../images/content/checklist-tresorerie.png"
                                      alt="Checklist"
                                      layout="fixed"
                                      height={220}
                                  />
                              )}
                          </Image>
                      </Content>
                  </Container>

                  <Modal isOpen={isModalOpen} close={closeModal} size="small">
                      <div className="h2">{defaultChecklist.cta_text + ' gratuite.'}</div>
                      <HubspotForm
                          trackingId={defaultChecklist.keyword}
                          formId={defaultChecklist.form_hubspot_id}
                      ></HubspotForm>
                  </Modal>
              </>
          );
};

export default DownloadChecklist;

const query = graphql`
    {
        allPrismicChecklist {
            nodes {
                _previewable
                data {
                    keyword
                    cta_text
                    description {
                        text
                    }
                    form_hubspot_id
                    image {
                        gatsbyImageData
                    }
                    title
                    tags {
                        tag {
                            slug
                        }
                    }
                }
            }
        }
    }
`;
