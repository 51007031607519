import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface StockData {
    demandeMensuelle: string;
    delaiLivraison: string;
    coutStockage: string;
    coutCommande: string;
    stockSecurite: string;
    lotReapproMin: string;
    typeGestion: 'quantite' | 'periodicite';
}

interface StockResults {
    stockMoyen: number;
    stockOptimal: number;
    frequenceOptimale: number;
    coutTotal: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const OptimisationStocks: React.FC = () => {
    const [formData, setFormData] = useState<StockData>({
        demandeMensuelle: '',
        delaiLivraison: '',
        coutStockage: '',
        coutCommande: '',
        stockSecurite: '',
        lotReapproMin: '',
        typeGestion: 'quantite',
    });

    const [results, setResults] = useState<StockResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateStock = () => {
        const demandeAnnuelle = (parseFloat(formData.demandeMensuelle) || 0) * 12;
        const coutStockage = parseFloat(formData.coutStockage) || 0;
        const coutCommande = parseFloat(formData.coutCommande) || 0;
        const stockSecurite = parseFloat(formData.stockSecurite) || 0;
        const lotReapproMin = parseFloat(formData.lotReapproMin) || 0;

        // Calcul de la quantité économique de commande (formule de Wilson)
        const qec = Math.sqrt((2 * demandeAnnuelle * coutCommande) / coutStockage);

        // Ajustement selon le lot minimum de réapprovisionnement
        const stockOptimal = Math.max(qec, lotReapproMin);

        // Calcul de la fréquence optimale de commande
        const frequenceOptimale = demandeAnnuelle / stockOptimal;

        // Calcul du stock moyen
        const stockMoyen = stockOptimal / 2 + stockSecurite;

        // Calcul des coûts
        const coutAnnuelStockage = stockMoyen * coutStockage;
        const coutAnnuelCommandes = frequenceOptimale * coutCommande;
        const coutTotal = coutAnnuelStockage + coutAnnuelCommandes;

        // Détails des calculs
        const details = [
            {
                label: 'Quantité économique de commande',
                montant: stockOptimal,
                description: 'Quantité optimale à commander à chaque réapprovisionnement',
            },
            {
                label: 'Stock moyen',
                montant: stockMoyen,
                description: 'Niveau moyen de stock incluant le stock de sécurité',
            },
            {
                label: 'Coût annuel de stockage',
                montant: coutAnnuelStockage,
                description: 'Coût lié au stockage des marchandises',
            },
            {
                label: 'Coût annuel des commandes',
                montant: coutAnnuelCommandes,
                description: 'Coût lié aux passations de commandes',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (stockMoyen > demandeAnnuelle / 4) {
            recommandations.push('Le niveau de stock moyen est élevé, envisagez de réduire les quantités commandées');
        }

        if (frequenceOptimale > 24) {
            recommandations.push(
                "Fréquence de commande très élevée, négociez des conditions d'approvisionnement plus avantageuses",
            );
        }

        if (stockSecurite > stockOptimal * 0.5) {
            recommandations.push(
                'Stock de sécurité important, réévaluez les délais fournisseurs ou la variabilité de la demande',
            );
        }

        if (coutAnnuelStockage > coutTotal * 0.7) {
            recommandations.push('Coûts de stockage élevés, optimisez votre espace de stockage ou négociez les tarifs');
        }

        setResults({
            stockMoyen,
            stockOptimal,
            frequenceOptimale,
            coutTotal,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateStock();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'quantite', label: 'Gestion sur quantité' },
        { value: 'periodicite', label: 'Gestion périodique' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Optimisation des stocks" />

                <RadioButtonGroup
                    label="Type de gestion"
                    options={radioOptions}
                    value={formData.typeGestion}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, typeGestion: value as 'quantite' | 'periodicite' }))
                    }
                    direction="row"
                />

                <InputNumber
                    label="Demande mensuelle moyenne"
                    value={Number(formData.demandeMensuelle) || null}
                    setValue={value => setFormData(prev => ({ ...prev, demandeMensuelle: String(value) }))}
                    endAdornment={<>unités</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                <InputNumber
                    label="Délai de livraison"
                    value={Number(formData.delaiLivraison) || null}
                    setValue={value => setFormData(prev => ({ ...prev, delaiLivraison: String(value) }))}
                    endAdornment={<>jours</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5"
                />

                <InputNumber
                    label="Coût de stockage unitaire annuel"
                    value={Number(formData.coutStockage) || null}
                    setValue={value => setFormData(prev => ({ ...prev, coutStockage: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 10"
                />

                <InputNumber
                    label="Coût de passation de commande"
                    value={Number(formData.coutCommande) || null}
                    setValue={value => setFormData(prev => ({ ...prev, coutCommande: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 50"
                />

                <InputNumber
                    label="Stock de sécurité"
                    value={Number(formData.stockSecurite) || null}
                    setValue={value => setFormData(prev => ({ ...prev, stockSecurite: String(value) }))}
                    endAdornment={<>unités</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 100"
                />

                <InputNumber
                    label="Lot minimum de réapprovisionnement"
                    value={Number(formData.lotReapproMin) || null}
                    setValue={value => setFormData(prev => ({ ...prev, lotReapproMin: String(value) }))}
                    endAdornment={<>unités</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 200"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.demandeMensuelle || !formData.coutStockage}
                >
                    Calculer l'optimisation
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Coût total de gestion',
                                value: `${Math.round(results.coutTotal).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: detail.label.includes('Coût')
                                    ? `${Math.round(detail.montant).toLocaleString('fr-FR')} €`
                                    : `${Math.round(detail.montant).toLocaleString('fr-FR')} unités`,
                                description: detail.description,
                            })),
                            {
                                label: 'Fréquence optimale de commande',
                                value: `${Math.round(results.frequenceOptimale)} commandes/an`,
                            },
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default OptimisationStocks;
