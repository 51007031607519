import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface ISData {
    resultatFiscal: string;
    chiffreAffaires: string;
    regimeIS: 'pme' | 'normal';
}

interface ISResults {
    tauxIS: number;
    montantIS: number;
    resultatNet: number;
    detailIS?: {
        montant15: number;
        montant25: number;
    };
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const ImpotSocietes: React.FC = () => {
    const [formData, setFormData] = useState<ISData>({
        resultatFiscal: '',
        chiffreAffaires: '',
        regimeIS: 'pme',
    });

    const [results, setResults] = useState<ISResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateIS = () => {
        const resultat = parseFloat(formData.resultatFiscal) || 0;
        const ca = parseFloat(formData.chiffreAffaires) || 0;

        let montantIS = 0;
        let detailIS;

        if (formData.regimeIS === 'pme' && ca <= 10000000) {
            if (resultat <= 42500) {
                montantIS = resultat * 0.15;
            } else {
                const montant15 = 42500 * 0.15;
                const montant25 = (resultat - 42500) * 0.25;
                montantIS = montant15 + montant25;
                detailIS = { montant15, montant25 };
            }
        } else {
            montantIS = resultat * 0.25;
        }

        const resultatNet = resultat - montantIS;
        const tauxEffectif = (montantIS / resultat) * 100;

        setResults({
            tauxIS: tauxEffectif,
            montantIS,
            resultatNet,
            detailIS,
        });
    };

    const handleSubmit = () => {
        calculateIS();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'pme', label: 'PME (CA ≤ 10M€)' },
        { value: 'normal', label: 'Régime normal' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur d'impôt sur les sociétés" />

                {formData.regimeIS === 'pme' && (
                    <SimulatorAlert text="Pour bénéficier du taux réduit de 15%, le capital social doit être entièrement libéré et il doit être détenu pour au moins 75% par des personnes physiques (ou par une société elle-même détenue pour au moins 75% par des personnes physiques)." />
                )}

                <RadioButtonGroup
                    label="Régime fiscal"
                    options={radioOptions}
                    value={formData.regimeIS}
                    onChange={value => setFormData(prev => ({ ...prev, regimeIS: value as 'pme' | 'normal' }))}
                    direction="column"
                />

                <InputNumber
                    label="Résultat fiscal"
                    value={Number(formData.resultatFiscal) || null}
                    setValue={value => setFormData(prev => ({ ...prev, resultatFiscal: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 50000"
                />

                {formData.regimeIS === 'pme' && (
                    <InputNumber
                        label="Chiffre d'affaires"
                        value={Number(formData.chiffreAffaires) || null}
                        setValue={value => setFormData(prev => ({ ...prev, chiffreAffaires: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 1000000"
                    />
                )}

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.resultatFiscal || (formData.regimeIS === 'pme' && !formData.chiffreAffaires)}
                >
                    Calculer l'IS
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: "Montant total de l'IS",
                                value: `${Math.round(results.montantIS).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Résultat fiscal',
                                value: `${parseFloat(formData.resultatFiscal).toLocaleString('fr-FR')} €`,
                            },
                            ...(results.detailIS
                                ? [
                                      {
                                          label: "IS à 15% (jusqu'à 42 500€)",
                                          value: `${Math.round(results.detailIS.montant15).toLocaleString('fr-FR')} €`,
                                      },
                                      {
                                          label: 'IS à 25% (au-delà de 42 500€)',
                                          value: `${Math.round(results.detailIS.montant25).toLocaleString('fr-FR')} €`,
                                      },
                                  ]
                                : [
                                      {
                                          label: "Taux d'IS applicable",
                                          value: `${results.tauxIS.toFixed(1)}%`,
                                      },
                                  ]),

                            {
                                label: 'Résultat net après IS',
                                value: `${Math.round(results.resultatNet).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default ImpotSocietes;
