import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface TvaData {
    amount: string;
    direction: 'htToTtc' | 'ttcToHt';
    rate: string;
}

interface TvaResults {
    ht: number;
    tva: number;
    ttc: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CalculateurTva: React.FC = () => {
    const [formData, setFormData] = useState<TvaData>({
        amount: '',
        direction: 'htToTtc',
        rate: '20',
    });

    const [results, setResults] = useState<TvaResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const tvaRates = [
        { value: '20', label: '20% (Taux normal)' },
        { value: '10', label: '10% (Taux intermédiaire)' },
        { value: '5.5', label: '5,5% (Taux réduit)' },
        { value: '2.1', label: '2,1% (Taux particulier)' },
    ];

    const calculateTva = () => {
        const amount = parseFloat(formData.amount);
        if (isNaN(amount)) return;

        const rate = parseFloat(formData.rate) / 100;
        let ht: number, tva: number, ttc: number;

        if (formData.direction === 'htToTtc') {
            ht = amount;
            tva = ht * rate;
            ttc = ht + tva;
        } else {
            ttc = amount;
            ht = ttc / (1 + rate);
            tva = ttc - ht;
        }

        setResults({
            ht: Math.round(ht * 100) / 100,
            tva: Math.round(tva * 100) / 100,
            ttc: Math.round(ttc * 100) / 100,
        });
    };

    const handleSubmit = () => {
        calculateTva();
        if (!logged) handleOpen();
    };

    const directionOptions = [
        { value: 'htToTtc', label: 'HT vers TTC' },
        { value: 'ttcToHt', label: 'TTC vers HT' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de TVA" />

                <RadioButtonGroup
                    label="Direction"
                    options={directionOptions}
                    value={formData.direction}
                    onChange={value => setFormData(prev => ({ ...prev, direction: value as 'htToTtc' | 'ttcToHt' }))}
                    direction="row"
                />

                <InputNumber
                    label={formData.direction === 'htToTtc' ? 'Montant HT' : 'Montant TTC'}
                    value={Number(formData.amount) || null}
                    setValue={value => setFormData(prev => ({ ...prev, amount: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                <RadioButtonGroup
                    label="Taux de TVA"
                    options={tvaRates}
                    value={formData.rate}
                    onChange={value => setFormData(prev => ({ ...prev, rate: value }))}
                    direction="column"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.amount}
                >
                    Calculer
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Montant TTC',
                                value: `${results.ttc.toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Montant HT',
                                value: `${results.ht.toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: `TVA (${formData.rate}%)`,
                                value: `${results.tva.toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CalculateurTva;
