import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface RecrutementData {
    salaireAnnuel: string;
    dureeProcessus: string;
    typeRecrutement: 'interne' | 'cabinet' | 'mixte';
    fraisAnnonce: string;
    fraisTests: string;
    options: {
        cabinet: boolean;
        formation: boolean;
        outplacement: boolean;
        interim: boolean;
    };
}

interface RecrutementResults {
    couts: {
        label: string;
        montant: number;
        description: string;
    }[];
    coutTotal: number;
    coutIndirect: number;
    roi: number;
    recommandations: string[];
}

const CoutRecrutement: React.FC = () => {
    const [formData, setFormData] = useState<RecrutementData>({
        salaireAnnuel: '',
        dureeProcessus: '',
        typeRecrutement: 'interne',
        fraisAnnonce: '',
        fraisTests: '',
        options: {
            cabinet: false,
            formation: false,
            outplacement: false,
            interim: false,
        },
    });

    const [results, setResults] = useState<RecrutementResults | null>(null);

    const calculateCout = () => {
        const salaire = parseFloat(formData.salaireAnnuel) || 0;
        const duree = parseFloat(formData.dureeProcessus) || 0;
        const fraisAnnonce = parseFloat(formData.fraisAnnonce) || 0;
        const fraisTests = parseFloat(formData.fraisTests) || 0;

        // Calcul des coûts directs
        const couts = [];

        // Coût du temps RH (estimation : 15% du temps sur la durée)
        const coutRH = (salaire / 12) * (duree / 30) * 0.15;
        couts.push({
            label: 'Temps RH',
            montant: coutRH,
            description: '15% du temps RH sur la durée du processus',
        });

        // Coût des annonces
        if (fraisAnnonce > 0) {
            couts.push({
                label: "Frais d'annonces",
                montant: fraisAnnonce,
                description: "Publication d'offres d'emploi",
            });
        }

        // Coût des tests et évaluations
        if (fraisTests > 0) {
            couts.push({
                label: 'Tests et évaluations',
                montant: fraisTests,
                description: 'Évaluations et tests de recrutement',
            });
        }

        // Coût cabinet de recrutement
        if (formData.options.cabinet) {
            const fraisCabinet = salaire * 0.2;
            couts.push({
                label: 'Cabinet de recrutement',
                montant: fraisCabinet,
                description: '20% du salaire annuel',
            });
        }

        // Coût formation initiale
        if (formData.options.formation) {
            const fraisFormation = salaire * 0.1;
            couts.push({
                label: 'Formation initiale',
                montant: fraisFormation,
                description: "Formation d'intégration",
            });
        }

        // Coût intérim si applicable
        if (formData.options.interim) {
            const fraisInterim = (salaire / 12) * 1.8 * (duree / 30);
            couts.push({
                label: 'Intérim',
                montant: fraisInterim,
                description: 'Coût intérimaire pendant la recherche',
            });
        }

        // Calcul des coûts indirects (perte de productivité)
        const coutIndirect = (salaire / 12) * (duree / 30) * 0.5;

        // Calcul du coût total
        const coutTotal = couts.reduce((sum, cout) => sum + cout.montant, 0) + coutIndirect;

        // Calcul du ROI estimé
        const roi = ((salaire * 1.5) / coutTotal - 1) * 100;

        // Génération des recommandations
        const recommandations = [];

        if (duree > 90) {
            recommandations.push("La durée du processus est longue, envisagez de l'optimiser");
        }

        if (coutTotal > salaire * 0.3) {
            recommandations.push('Le coût total est élevé, étudiez des alternatives moins coûteuses');
        }

        if (!formData.options.formation) {
            recommandations.push("Prévoyez un budget formation pour optimiser l'intégration");
        }

        if (formData.typeRecrutement === 'interne' && salaire > 50000) {
            recommandations.push('Pour ce niveau de poste, un cabinet spécialisé pourrait être pertinent');
        }

        setResults({
            couts,
            coutTotal,
            coutIndirect,
            roi,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de coût de recrutement
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeRecrutement}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeRecrutement: e.target.value as 'interne' | 'cabinet' | 'mixte',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="interne"
                            control={<Radio />}
                            label="Recrutement interne"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="cabinet"
                            control={<Radio />}
                            label="Cabinet"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="mixte"
                            control={<Radio />}
                            label="Mixte"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Salaire annuel brut (€)"
                        type="number"
                        value={formData.salaireAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, salaireAnnuel: e.target.value }))}
                        placeholder="Ex: 45000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Durée estimée du processus (jours)"
                        type="number"
                        value={formData.dureeProcessus}
                        onChange={e => setFormData(prev => ({ ...prev, dureeProcessus: e.target.value }))}
                        placeholder="Ex: 60"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais d'annonces (€)"
                        type="number"
                        value={formData.fraisAnnonce}
                        onChange={e => setFormData(prev => ({ ...prev, fraisAnnonce: e.target.value }))}
                        placeholder="Ex: 500"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais de tests et évaluations (€)"
                        type="number"
                        value={formData.fraisTests}
                        onChange={e => setFormData(prev => ({ ...prev, fraisTests: e.target.value }))}
                        placeholder="Ex: 300"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Options supplémentaires
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.cabinet}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, cabinet: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Cabinet de recrutement"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.formation}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, formation: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Formation initiale"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.options.interim}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        options: { ...prev.options, interim: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Intérim pendant la recherche"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateCout}
                    disabled={!formData.salaireAnnuel || !formData.dureeProcessus}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le coût
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.couts.map((cout, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{cout.label}</span>
                                <span className="value">{Math.round(cout.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {cout.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row">
                        <span className="label">Coûts indirects</span>
                        <span className="value">{Math.round(results.coutIndirect).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row highlight">
                        <span className="label">Coût total du recrutement</span>
                        <span className="value">{Math.round(results.coutTotal).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">ROI estimé</span>
                        <span className="value">{Math.round(results.roi)}%</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CoutRecrutement;
