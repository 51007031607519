import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import ArrayResults from '../components/ArrayResults';
import InputDate from '../components/InputDate';
import InputNumber from '../components/InputNumber';
import SimulatorHeader from '../components/SimulatorHeader';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface EmpruntData {
    montant: string;
    duree: string;
    tauxInteret: string;
    tauxAssurance: string;
    dateDebut: string;
    fraisDossier: string;
    fraisGarantie: string;
}

interface LigneAmortissement {
    [key: string]: string | number | boolean;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const SimulateurEmprunt: React.FC = () => {
    const [formData, setFormData] = useState<EmpruntData>({
        montant: '',
        duree: '',
        tauxInteret: '',
        tauxAssurance: '',
        dateDebut: '',
        fraisDossier: '',
        fraisGarantie: '',
    });

    const [tableauAmortissement, setTableauAmortissement] = useState<LigneAmortissement[]>([]);
    const [totalGeneraux, setTotalGeneraux] = useState<{
        totalAnnuites: number;
        totalCapital: number;
        totalInterets: number;
        totalAssurance: number;
    } | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculerTableauAmortissement = () => {
        const montant = parseFloat(formData.montant) || 0;
        const dureeAnnees = parseInt(formData.duree) || 0;
        const tauxAnnuel = parseFloat(formData.tauxInteret) || 0;
        const tauxAssurance = parseFloat(formData.tauxAssurance) || 0;
        const fraisDossier = parseFloat(formData.fraisDossier) || 0;
        const fraisGarantie = parseFloat(formData.fraisGarantie) || 0;
        const dateDebut = new Date(formData.dateDebut);
        const moisDebut = dateDebut.getMonth(); // 0-11

        const fraisInitiaux = fraisDossier + fraisGarantie;
        const tauxMensuel = tauxAnnuel / 100 / 12;
        const nombreMois = dureeAnnees * 12;

        const mensualiteHorsAssurance =
            (montant * (tauxMensuel * Math.pow(1 + tauxMensuel, nombreMois))) /
            (Math.pow(1 + tauxMensuel, nombreMois) - 1);
        const mensualiteAssurance = (montant * tauxAssurance) / 100 / 12;

        let capitalRestant = montant;
        const tableau: LigneAmortissement[] = [];
        let totalAnnuites = 0;
        let totalCapital = 0;
        let totalInterets = 0;
        let totalAssurance = 0;

        // Calcul du nombre de mois pour la première année
        const moisPremiereAnnee = 12 - moisDebut;
        // Calcul du nombre de mois pour la dernière année
        const moisDerniereAnnee = (nombreMois - moisPremiereAnnee) % 12 || 12;

        for (let annee = 1; annee <= Math.ceil(nombreMois / 12); annee++) {
            let capitalAnnuel = 0;
            let interetsAnnuels = 0;
            let annuiteAnnuelle = 0;
            let moisDansAnnee;

            // Déterminer le nombre de mois pour cette année
            if (annee === 1) {
                moisDansAnnee = moisPremiereAnnee;
            } else if (annee === Math.ceil(nombreMois / 12)) {
                moisDansAnnee = moisDerniereAnnee;
            } else {
                moisDansAnnee = 12;
            }

            const assuranceAnnuelle = mensualiteAssurance * moisDansAnnee;

            // Calculs mensuels pour l'année
            for (let mois = 1; mois <= moisDansAnnee; mois++) {
                const interetsMois = capitalRestant * tauxMensuel;
                const capitalMois = mensualiteHorsAssurance - interetsMois;

                capitalAnnuel += capitalMois;
                interetsAnnuels += interetsMois;
                annuiteAnnuelle += mensualiteHorsAssurance;
                capitalRestant -= capitalMois;
            }

            const annuiteTotale = annuiteAnnuelle + assuranceAnnuelle;

            tableau.push({
                annee,
                annuite: Math.round(annuiteTotale),
                capitalRembourse: Math.round(capitalAnnuel),
                interetsRembourses: Math.round(interetsAnnuels),
                assurance: Math.round(assuranceAnnuelle),
                capitalRestantDu: Math.round(capitalRestant),
            });

            totalAnnuites += annuiteTotale;
            totalCapital += capitalAnnuel;
            totalInterets += interetsAnnuels;
            totalAssurance += assuranceAnnuelle;
        }

        setTableauAmortissement(tableau);
        setTotalGeneraux({
            totalAnnuites: Math.round(totalAnnuites),
            totalCapital: Math.round(totalCapital),
            totalInterets: Math.round(totalInterets + fraisInitiaux),
            totalAssurance: Math.round(totalAssurance),
        });
    };

    const handleSubmit = () => {
        calculerTableauAmortissement();
        if (!logged) handleOpen();
    };

    return (
        <>
            <SimulatorCard>
                <SimulatorHeader title="Simulateur d'emprunt" />

                <InputNumber
                    label="Montant emprunté"
                    value={Number(formData.montant) || null}
                    setValue={value => setFormData(prev => ({ ...prev, montant: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 150000"
                />

                <InputNumber
                    label="Durée (années)"
                    value={Number(formData.duree) || null}
                    setValue={value => setFormData(prev => ({ ...prev, duree: String(value) }))}
                    placeholder="Ex : 20"
                />

                <InputNumber
                    label="Taux d'intérêt"
                    value={Number(formData.tauxInteret) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxInteret: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 3.45"
                />

                <InputNumber
                    label="Taux d'assurance"
                    value={Number(formData.tauxAssurance) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxAssurance: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 0.36"
                />

                <InputNumber
                    label="Frais de dossier"
                    value={Number(formData.fraisDossier) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisDossier: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                <InputNumber
                    label="Frais de garantie"
                    value={Number(formData.fraisGarantie) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisGarantie: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 2000"
                />

                <InputDate
                    label="Début des remboursements"
                    value={formData.dateDebut}
                    setValue={value => setFormData(prev => ({ ...prev, dateDebut: value }))}
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.montant || !formData.duree || !formData.tauxInteret || !formData.dateDebut}
                >
                    Calculer les annuités
                </ArrowButton>
            </SimulatorCard>

            {logged && tableauAmortissement.length > 0 && (
                <ArrayResults
                    columns={[
                        { id: 'annee', label: 'Année' },
                        { id: 'annuite', label: 'Annuités', align: 'right', showEuroSymbol: true },
                        { id: 'capitalRembourse', label: 'Capital remboursé', align: 'right', showEuroSymbol: true },
                        {
                            id: 'interetsRembourses',
                            label: 'Intérêts remboursés et frais',
                            align: 'right',
                            showEuroSymbol: true,
                        },
                        { id: 'assurance', label: 'Assurance', align: 'right', showEuroSymbol: true },
                        { id: 'capitalRestantDu', label: 'Capital restant dû', align: 'right', showEuroSymbol: true },
                    ]}
                    rows={[
                        ...tableauAmortissement,
                        ...(totalGeneraux
                            ? [
                                  {
                                      annee: 'Total',
                                      annuite: totalGeneraux.totalAnnuites,
                                      capitalRembourse: totalGeneraux.totalCapital,
                                      interetsRembourses: totalGeneraux.totalInterets,
                                      assurance: totalGeneraux.totalAssurance,
                                      capitalRestantDu: '-',
                                      highlightedRow: true,
                                  },
                              ]
                            : []),
                    ]}
                />
            )}
        </>
    );
};

export default SimulateurEmprunt;
