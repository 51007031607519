import { useState } from 'react';
import Cookies, { CookieSetOptions } from 'universal-cookie';

export const useCookie = (key: string, value?: string, options?: CookieSetOptions) => {
    const cookies = new Cookies();
    const [cookie, setCookie] = useState(() => {
        if (cookies.get(key)) {
            return cookies.get(key);
        }
        cookies.set(key, value, options);
        return value;
    });

    const updateCookie = (value: string, options?: CookieSetOptions) => {
        setCookie(value);
        removeItem(value);
        cookies.set(key, value, options);
    };

    const removeItem = (key: string) => {
        cookies.remove(key);
    };

    return { cookie, updateCookie, removeItem };
};

export const useSessionStorage = (key: string, value?: string) => {
    let mySessionStorage = undefined as Storage;
    if (typeof window !== 'undefined') {
        mySessionStorage = sessionStorage;
    }
    const [item, setItem] = useState(() => {
        const myItem = mySessionStorage?.getItem(key);
        if (myItem) {
            return myItem === 'undefined' ? undefined : JSON.parse(myItem);
        }
        mySessionStorage?.setItem(key, value);
    });

    const updateSession = (value: string) => {
        setItem(value);
        mySessionStorage?.setItem(key, JSON.stringify(value));
    };

    return { item, updateSession };
};
