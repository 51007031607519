import styled from '@emotion/styled';
import React, { useRef, useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import CheckboxGroup from '../components/CheckboxGroup';
import InputNumber from '../components/InputNumber';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

// Modifier le type LienAssocie
type LienAssocie = 'famille' | 'ami' | 'business' | 'couple' | 'autre';

// Modifier le type pour les besoins
type Besoin =
    | 'information'
    | 'benefices'
    | 'nondilution'
    | 'inalienabilite'
    | 'preemption'
    | 'sortieconjointe'
    | 'sortiedesaccord'
    | 'sortiealternative'
    | 'sortieforcee'
    | 'nonconcurrence'
    | 'exclusivite'
    | 'proprieteintellectuelle';

interface PacteData {
    nombreAssocies: string;
    lienAssocies: LienAssocie[];
    besoins: Besoin[];
}

interface PacteResults {
    clausesImpact: {
        clause: string;
        impact: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
    opacity: 0;
    animation: fadeIn 0.5s ease-in forwards;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const PacteAssocies: React.FC = () => {
    const [formData, setFormData] = useState<PacteData>({
        nombreAssocies: '2',
        lienAssocies: [],
        besoins: [],
    });

    const [results, setResults] = useState<PacteResults | null>(null);
    const [animationKey, setAnimationKey] = useState(0);
    const { handleOpen, logged } = useSimulatorContext();
    const resultsRef = useRef<HTMLDivElement>(null);

    const calculatePacte = () => {
        const nbAssocies = parseInt(formData.nombreAssocies);
        const isCouple = formData.lienAssocies.includes('couple');
        const nbBesoins = formData.besoins.length;

        // Générer les clauses recommandées
        const clausesRecommandees = [
            formData.besoins.includes('information') &&
                "Clause d'information : droits d'information et d'audit sur la société",
            formData.besoins.includes('benefices') &&
                'Clause de répartition des bénéfices : modalités de distribution des dividendes',
            formData.besoins.includes('nondilution') &&
                'Clause de non-dilution : maintien du pourcentage de détention lors des augmentations de capital',
            formData.besoins.includes('inalienabilite') &&
                "Clause d'inaliénabilité : blocage des parts pendant une durée déterminée",
            formData.besoins.includes('preemption') &&
                'Droit de préemption : priorité de rachat des parts en cas de cession',
            formData.besoins.includes('sortieconjointe') &&
                'Clause de sortie conjointe : vente des parts minoritaires avec le majoritaire',
            formData.besoins.includes('sortiedesaccord') &&
                'Clause de sortie sur désaccord : sortie en cas de désaccord grave',
            formData.besoins.includes('sortiealternative') &&
                'Clause de sortie alternative : mécanisme de rachat ou vente réciproque',
            formData.besoins.includes('sortieforcee') &&
                'Clause de sortie forcée : obligation de vente des minoritaires',
            formData.besoins.includes('nonconcurrence') &&
                "Clause de non-concurrence : interdiction d'activités concurrentes",
            formData.besoins.includes('exclusivite') &&
                "Clause d'exclusivité : développement uniquement au sein de la société",
            formData.besoins.includes('proprieteintellectuelle') &&
                'Clause de propriété intellectuelle : protection des développements',
        ].filter(Boolean);

        // Si c'est un couple marié avec moins de 3 associés et peu de besoins
        if (isCouple && nbAssocies < 3 && nbBesoins <= 2) {
            setResults({
                clausesImpact: [],
                recommandations: ['', ...clausesRecommandees],
            });
            setAnimationKey(prev => prev + 1);
        } else {
            // Sinon, afficher les clauses avec le message pour contacter un juriste
            setResults({
                clausesImpact: [],
                recommandations: clausesRecommandees,
            });
            setAnimationKey(prev => prev + 1);
        }
    };

    const handleSubmit = () => {
        calculatePacte();
        if (!logged) handleOpen();

        // Ajout du scroll
        setTimeout(() => {
            resultsRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }, 100);
    };

    const lienOptions = [
        { value: 'famille', label: 'Famille (hors couple)' },
        { value: 'couple', label: 'Couple marié/pacsé' },
        { value: 'ami', label: 'Ami(s)' },
        { value: 'business', label: 'Relation professionnelle' },
        { value: 'autre', label: 'Autre' },
    ];

    const besoinOptions = [
        { value: 'information', label: 'Obtenir des informations détaillées sur la société' },
        { value: 'benefices', label: 'Définir la répartition des bénéfices' },
        { value: 'nondilution', label: 'Maintenir son pourcentage de capital' },
        { value: 'inalienabilite', label: 'Bloquer la cession des parts' },
        { value: 'preemption', label: 'Racheter en priorité les parts cédées' },
        { value: 'sortieconjointe', label: 'Vendre ses parts avec le majoritaire' },
        { value: 'sortiedesaccord', label: 'Sortir en cas de désaccord' },
        { value: 'sortiealternative', label: 'Proposer un rachat ou une vente' },
        { value: 'sortieforcee', label: 'Forcer la vente des minoritaires' },
        { value: 'nonconcurrence', label: 'Interdire la concurrence' },
        { value: 'exclusivite', label: "Développer l'activité uniquement dans la société" },
        { value: 'proprieteintellectuelle', label: 'Protéger la propriété intellectuelle' },
    ];

    // Ajouter une fonction de validation
    const isFormValid = () => {
        const nbAssocies = parseInt(formData.nombreAssocies);
        return (
            nbAssocies >= 2 && // Au moins 2 associés
            formData.lienAssocies.length > 0 && // Au moins un lien sélectionné
            formData.besoins.length > 0 // Au moins un besoin sélectionné
        );
    };

    const noPacte =
        formData.lienAssocies.includes('couple') &&
        parseInt(formData.nombreAssocies) < 3 &&
        formData.besoins.length <= 2;

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Simulateur de pacte d'associés" />

                <InputNumber
                    label="Nombre d'associés"
                    value={Number(formData.nombreAssocies) || null}
                    setValue={value => setFormData(prev => ({ ...prev, nombreAssocies: String(value) }))}
                    variant="DEFAULT"
                    placeholder="Ex : 2"
                    inputProps={{ min: 2 }}
                />

                <CheckboxGroup
                    label="Lien avec vos associés"
                    options={lienOptions}
                    value={formData.lienAssocies}
                    onChange={values => setFormData(prev => ({ ...prev, lienAssocies: values as LienAssocie[] }))}
                    direction="column"
                />

                <CheckboxGroup
                    label="Vos besoins"
                    options={besoinOptions}
                    value={formData.besoins}
                    onChange={values => setFormData(prev => ({ ...prev, besoins: values as Besoin[] }))}
                    direction="column"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!isFormValid()}
                >
                    Valider
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer ref={resultsRef} key={animationKey}>
                    <SimulatorResults
                        rows={[
                            {
                                value: '',
                                label: noPacte
                                    ? 'Vous pouvez intégrer ces clauses dans les statuts'
                                    : "Nous vous recommandons de vous rapprocher d'un juriste spécialisé pour rédiger votre pacte d'associés. Contactez Dougs !",
                                highlight: true,
                            },
                            !noPacte && {
                                value: '',
                                label: "Voici les clauses à intégrer dans votre pacte d'associés.",
                                highlight: true,
                            },
                            ...results.recommandations.map(recommandation => ({
                                value: '',
                                label: recommandation,
                            })),
                        ].filter(Boolean)}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PacteAssocies;
