import styled from '@emotion/styled';
import { Select } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';

import { breakpoint, colors, fonts } from '../../../../styles/theme';

export const ContainerSimulator = styled.div<{ isInBlog?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: ${colors.$lightOrange};
    width: 100%;
    border-radius: 16px;
    padding: 48px 24px;
    gap: 24px;

    ${breakpoint.laptop} {
        flex-direction: ${props => (props.isInBlog ? 'column' : 'row')};
        gap: ${props => (props.isInBlog ? '40px' : '64px')};
        padding: ${props => (props.isInBlog ? '32px 40px' : '48px 64px')};
    }
`;

export const HeaderSimulator = styled.div<{ isInBlog?: boolean }>`
    max-width: ${props => (props.isInBlog ? '100%' : '320px')};
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: ${props => (props.isInBlog ? '16px' : 'initial')};

    ${breakpoint.laptop} {
        flex-direction: ${props => (props.isInBlog ? 'row-reverse' : 'column')};
    }

    p {
        color: ${colors.$primary700};
        font-family: ${fonts.OpenSans_Regular};
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 0;

        ${breakpoint.laptop} {
            font-size: 15px;
        }
    }
`;

export const ArrowButtonLayout = styled.div<{ isInBlog?: boolean }>`
    display: flex;
    justify-content: ${props => (props.isInBlog ? 'end' : 'start')};
`;

export const HeaderSimulatorForBlog = styled.div`
    max-width: 320px;
    display: flex;
    justify-content: center;

    p {
        color: ${colors.$primary700};
        font-family: ${fonts.OpenSans_Regular};
        font-size: 14px;
        line-height: 150%;

        ${breakpoint.laptop} {
            font-size: 15px;
        }
    }
`;

export const WrapperMobileDivider = styled.div`
    display: initial;
    ${breakpoint.laptop} {
        display: none;
    }
`;

export const WrapperDesktopDivider = styled.div`
    display: none;
    ${breakpoint.laptop} {
        display: initial;
    }
`;

export const FlexOne = styled.div`
    flex: 1;
`;

export const StyledImage = styled(GatsbyImage)<{ isInBlog?: boolean }>`
    width: 114px;
    height: 56px;
    margin-bottom: 12px;

    ${breakpoint.laptop} {
        margin-bottom: 16px;

        width: ${props => (props.isInBlog ? '380px' : '163px')};
        height: 80px;
    }
`;

export const SimulatorTitle = styled.h3`
    color: ${colors.$primary700};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 18px;
    line-height: 125%;
    margin-bottom: 4px !important;

    ${breakpoint.laptop} {
        font-size: 24px;
        margin-bottom: 8px !important;
    }
`;

export const InputsDisplay = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;

    ${breakpoint.laptop} {
        flex-direction: row;
    }
`;

export const FormContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const StyledSelect = styled(Select)`
    border-color: ${colors.$gray300};
    border-radius: 4px;
    width: 100%;

    label {
        color: ${colors.$gray400};
    }
`;

export const ResponseContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    gap: 24px;

    ${breakpoint.laptop} {
        flex-direction: row;
        gap: 70px;
    }
`;

export const LeftContainer = styled.div`
    flex: 1;
`;

export const RecalculateButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 16px;
    display: flex;
    gap: 8px;

    p {
        color: ${colors.$primary700};
        font-family: ${fonts.OpenSans_Bold};
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 0;
    }
`;

export const LeftContainerCustomMade = styled.div`
    max-width: 300px;
    flex-direction: column;
    display: flex;
    gap: 8px;
    align-items: center;

    h2 {
        color: ${colors.$orange};
        text-align: center;
        font-family: ${fonts.Eina_SemiBold};
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%;
    }

    ${breakpoint.laptop} {
        flex-direction: row;
        gap: 0;

        h2 {
            text-align: right;
        }
    }
`;

export const RightContainerCustomMade = styled.div`
    flex: 1;

    p {
        color: var(--Primary-primary-700, #013a51);
        font-family: 'Open Sans';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        padding-bottom: 12px;
    }

    li {
        color: #013a51;
        font-family: 'Open Sans';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;

export const TitleResponse = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 16px;
    margin-bottom: 12px;

    ${breakpoint.laptop} {
        font-size: 18px;
    }
`;

export const TextResponse = styled.div`
    color: ${colors.$primary700};

    font-family: ${fonts.OpenSans_Regular};
    font-size: 14px;
    padding-bottom: 24px;

    p {
        font-family: ${fonts.OpenSans_Regular};
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 12px;
        margin-bottom: 0;
    }

    ${breakpoint.laptop} {
        font-size: 15px;

        p {
            font-size: 15px;
        }
    }
`;

export const DividerWrapper = styled.div`
    display: none;
    ${breakpoint.laptop} {
        display: inherit;
    }
`;

export const ImageWrapper = styled.div`
    width: 163px;
    height: 80px;
`;

export const DisplayPriceContainer = styled.div`
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Details = styled.div`
    color: #516a87;
    font-family: 'Open Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;
    padding-bottom: 24px;

    ${breakpoint.laptop} {
        flex-direction: row;
    }
`;

export const SavingsDisplay = styled.div`
    display: flex;
    color: ${colors.$orange};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    span {
        font-size: 20px;
        margin-top: -16px;
        margin-left: 4px;
    }

    ${breakpoint.laptop} {
        font-size: 56px;

        span {
            font-size: 24px;
        }
    }
`;

export const PriceText = styled.div<{ fontSize: string[] }>`
    color: ${colors.$orange};
    font-family: ${fonts.Eina_SemiBold};
    font-size: ${props => props.fontSize[0]}px;
    line-height: 125%;

    ${breakpoint.laptop} {
        font-size: ${props => props.fontSize[1]}px;
    }
`;

export const RightContainer = styled.div`
    flex: 1;

    li {
        color: #013a51;
        font-family: 'Open Sans';
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
`;
