import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface RotationData {
    stockMoyen: string;
    achatsAnnuels: string;
    ventesAnnuelles: string;
    typeActivite: 'commerce' | 'industrie';
    stockSecurite: string;
    delaiAppro: string;
}

interface RotationResults {
    tauxRotation: number;
    dureeStockage: number;
    couvertureStock: number;
    coutStockage: number;
    details: {
        label: string;
        valeur: number;
        unite: string;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const RotationStocks: React.FC = () => {
    const [formData, setFormData] = useState<RotationData>({
        stockMoyen: '',
        achatsAnnuels: '',
        ventesAnnuelles: '',
        typeActivite: 'commerce',
        stockSecurite: '',
        delaiAppro: '',
    });

    const [results, setResults] = useState<RotationResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateRotation = () => {
        const stockMoyen = parseFloat(formData.stockMoyen) || 0;
        const achatsAnnuels = parseFloat(formData.achatsAnnuels) || 0;
        const ventesAnnuelles = parseFloat(formData.ventesAnnuelles) || 0;
        const stockSecurite = parseFloat(formData.stockSecurite) || 0;
        const delaiAppro = parseFloat(formData.delaiAppro) || 0;

        // Calcul des indicateurs de rotation
        const tauxRotation = achatsAnnuels / stockMoyen;
        const dureeStockage = (stockMoyen / achatsAnnuels) * 365;
        const couvertureStock = (stockMoyen / ventesAnnuelles) * 365;

        // Estimation du coût de stockage (environ 20% de la valeur du stock)
        const coutStockage = stockMoyen * 0.2;

        // Calcul des ratios complémentaires
        const details = [
            {
                label: 'Taux de rotation',
                valeur: tauxRotation,
                unite: 'rotations/an',
                description: "Nombre de fois où le stock se renouvelle dans l'année",
            },
            {
                label: 'Durée moyenne de stockage',
                valeur: dureeStockage,
                unite: 'jours',
                description: 'Temps moyen de présence des articles en stock',
            },
            {
                label: 'Couverture de stock',
                valeur: couvertureStock,
                unite: 'jours',
                description: 'Nombre de jours de vente couverts par le stock',
            },
            {
                label: 'Stock de sécurité',
                valeur: stockSecurite,
                unite: '€',
                description: 'Valeur du stock minimal de sécurité',
            },
            {
                label: 'Coût de stockage annuel',
                valeur: coutStockage,
                unite: '€',
                description: 'Estimation des coûts de détention du stock',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (dureeStockage > 90) {
            recommandations.push('La durée de stockage est élevée, optimisez vos approvisionnements');
        }

        if (tauxRotation < 4) {
            recommandations.push('Le taux de rotation est faible, réduisez vos stocks moyens');
        }

        if (stockSecurite > stockMoyen * 0.3) {
            recommandations.push('Le stock de sécurité est important, réévaluez vos besoins');
        }

        if (delaiAppro > 30) {
            recommandations.push("Les délais d'approvisionnement sont longs, diversifiez vos fournisseurs");
        }

        if (coutStockage > ventesAnnuelles * 0.1) {
            recommandations.push('Les coûts de stockage sont élevés, optimisez votre gestion des stocks');
        }

        setResults({
            tauxRotation,
            dureeStockage,
            couvertureStock,
            coutStockage,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateRotation();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'commerce', label: 'Commerce' },
        { value: 'industrie', label: 'Industrie' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Analyse de la rotation des stocks" />

                <RadioButtonGroup
                    label="Type d'activité"
                    options={radioOptions}
                    value={formData.typeActivite}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, typeActivite: value as 'commerce' | 'industrie' }))
                    }
                    direction="row"
                />

                <InputNumber
                    label="Stock moyen"
                    value={Number(formData.stockMoyen) || null}
                    setValue={value => setFormData(prev => ({ ...prev, stockMoyen: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 100000"
                />

                <InputNumber
                    label="Achats annuels"
                    value={Number(formData.achatsAnnuels) || null}
                    setValue={value => setFormData(prev => ({ ...prev, achatsAnnuels: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 500000"
                />

                <InputNumber
                    label="Ventes annuelles"
                    value={Number(formData.ventesAnnuelles) || null}
                    setValue={value => setFormData(prev => ({ ...prev, ventesAnnuelles: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 750000"
                />

                <InputNumber
                    label="Stock de sécurité"
                    value={Number(formData.stockSecurite) || null}
                    setValue={value => setFormData(prev => ({ ...prev, stockSecurite: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 20000"
                />

                <InputNumber
                    label="Délai d'approvisionnement"
                    value={Number(formData.delaiAppro) || null}
                    setValue={value => setFormData(prev => ({ ...prev, delaiAppro: String(value) }))}
                    endAdornment={<>jours</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 15"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={
                        !formData.stockMoyen ||
                        !formData.achatsAnnuels ||
                        !formData.ventesAnnuelles ||
                        !formData.stockSecurite ||
                        !formData.delaiAppro
                    }
                >
                    Calculer la rotation
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: `${Math.round(detail.valeur * 100) / 100} ${detail.unite}`,
                                description: detail.description,
                            })),
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default RotationStocks;
