import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface DelaisData {
    chiffreAffaires: string;
    achats: string;
    creancesClients: string;
    dettesFournisseurs: string;
    typeClient: 'public' | 'prive';
    delaiContractuel: string;
    retardMoyen: string;
}

interface DelaisResults {
    delaiClientJours: number;
    delaiFournisseurJours: number;
    bfr: number;
    coutFinancement: number;
    retardPenalites: number;
    details: {
        label: string;
        valeur: number;
        unite: string;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const DelaisPaiement: React.FC = () => {
    const [formData, setFormData] = useState<DelaisData>({
        chiffreAffaires: '',
        achats: '',
        creancesClients: '',
        dettesFournisseurs: '',
        typeClient: 'prive',
        delaiContractuel: '30',
        retardMoyen: '0',
    });

    const [results, setResults] = useState<DelaisResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateDelais = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const achats = parseFloat(formData.achats) || 0;
        const creances = parseFloat(formData.creancesClients) || 0;
        const dettes = parseFloat(formData.dettesFournisseurs) || 0;
        const delaiContractuel = parseFloat(formData.delaiContractuel) || 30;
        const retardMoyen = parseFloat(formData.retardMoyen) || 0;

        // Calcul des délais réels
        const delaiClientJours = (creances / ca) * 360;
        const delaiFournisseurJours = (dettes / achats) * 360;

        // Calcul du BFR lié aux délais
        const bfr = creances - dettes;

        // Estimation du coût de financement du BFR (taux de 5%)
        const coutFinancement = bfr * 0.05;

        // Calcul des pénalités de retard potentielles
        const tauxPenalite = formData.typeClient === 'public' ? 0.0825 : 0.12; // 8.25% pour le public, 12% pour le privé
        const retardPenalites = ca * (retardMoyen / 360) * tauxPenalite;

        // Génération des recommandations
        const recommandations = [];

        if (delaiClientJours > delaiContractuel + 15) {
            recommandations.push(
                'Les délais clients sont significativement supérieurs aux délais contractuels. Mettez en place des relances systématiques.',
            );
        }

        if (delaiFournisseurJours < 45) {
            recommandations.push(
                'Les délais fournisseurs sont courts. Négociez des conditions de paiement plus favorables.',
            );
        }

        if (bfr > ca * 0.25) {
            recommandations.push("Le BFR est élevé. Optimisez votre cycle d'exploitation.");
        }

        if (retardMoyen > 15) {
            recommandations.push('Les retards de paiement sont importants. Renforcez votre processus de recouvrement.');
        }

        const details = [
            {
                label: 'Délai client moyen',
                valeur: delaiClientJours,
                unite: 'jours',
                description: 'Durée moyenne de paiement des clients',
            },
            {
                label: 'Délai fournisseur moyen',
                valeur: delaiFournisseurJours,
                unite: 'jours',
                description: 'Durée moyenne de paiement des fournisseurs',
            },
            {
                label: 'BFR lié aux délais',
                valeur: bfr,
                unite: '€',
                description: 'Besoin de financement généré par les délais',
            },
            {
                label: 'Coût de financement',
                valeur: coutFinancement,
                unite: '€',
                description: 'Coût annuel du financement du BFR',
            },
            {
                label: 'Pénalités exigibles',
                valeur: retardPenalites,
                unite: '€',
                description: 'Pénalités de retard potentielles',
            },
        ];

        setResults({
            delaiClientJours,
            delaiFournisseurJours,
            bfr,
            coutFinancement,
            retardPenalites,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateDelais();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'prive', label: 'Clients privés' },
        { value: 'public', label: 'Clients publics' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Analyse des délais de paiement" />

                <RadioButtonGroup
                    label="Type de clients"
                    options={radioOptions}
                    value={formData.typeClient}
                    onChange={value => setFormData(prev => ({ ...prev, typeClient: value as 'public' | 'prive' }))}
                    direction="row"
                />

                <InputNumber
                    label="Chiffre d'affaires annuel"
                    value={Number(formData.chiffreAffaires) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chiffreAffaires: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000000"
                />

                <InputNumber
                    label="Achats annuels"
                    value={Number(formData.achats) || null}
                    setValue={value => setFormData(prev => ({ ...prev, achats: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 600000"
                />

                <InputNumber
                    label="Créances clients"
                    value={Number(formData.creancesClients) || null}
                    setValue={value => setFormData(prev => ({ ...prev, creancesClients: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 250000"
                />

                <InputNumber
                    label="Dettes fournisseurs"
                    value={Number(formData.dettesFournisseurs) || null}
                    setValue={value => setFormData(prev => ({ ...prev, dettesFournisseurs: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 150000"
                />

                <InputNumber
                    label="Délai contractuel"
                    value={Number(formData.delaiContractuel) || null}
                    setValue={value => setFormData(prev => ({ ...prev, delaiContractuel: String(value) }))}
                    endAdornment={<>jours</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 30"
                />

                <InputNumber
                    label="Retard moyen"
                    value={Number(formData.retardMoyen) || null}
                    setValue={value => setFormData(prev => ({ ...prev, retardMoyen: String(value) }))}
                    endAdornment={<>jours</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 15"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.chiffreAffaires || !formData.achats}
                >
                    Calculer les délais
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: `${Math.round(detail.valeur).toLocaleString('fr-FR')} ${detail.unite}`,
                                description: detail.description,
                            })),
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default DelaisPaiement;
