import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface FinancementData {
    // Ressources
    resultatNet: string;
    dotationsAmortissements: string;
    dotationsProvisions: string;
    plusValues: string;
    augmentationCapital: string;
    empruntsNouveaux: string;
    cessionsActifs: string;
    // Emplois
    investissements: string;
    remboursementsEmprunts: string;
    dividendes: string;
    bfrVariation: string;
}

interface FinancementResults {
    ressources: {
        label: string;
        montant: number;
        type: string;
    }[];
    emplois: {
        label: string;
        montant: number;
        type: string;
    }[];
    totalRessources: number;
    totalEmplois: number;
    variation: number;
    equilibre: boolean;
    ratios: {
        label: string;
        valeur: number;
        interpretation: string;
    }[];
    recommandations: string[];
}

const TableauFinancement: React.FC = () => {
    const [formData, setFormData] = useState<FinancementData>({
        resultatNet: '',
        dotationsAmortissements: '',
        dotationsProvisions: '',
        plusValues: '',
        augmentationCapital: '',
        empruntsNouveaux: '',
        cessionsActifs: '',
        investissements: '',
        remboursementsEmprunts: '',
        dividendes: '',
        bfrVariation: '',
    });

    const [results, setResults] = useState<FinancementResults | null>(null);

    const calculateTableau = () => {
        // Calcul des ressources
        const ressources = [
            {
                label: "Résultat net de l'exercice",
                montant: parseFloat(formData.resultatNet) || 0,
                type: 'autofinancement',
            },
            {
                label: 'Dotations aux amortissements',
                montant: parseFloat(formData.dotationsAmortissements) || 0,
                type: 'autofinancement',
            },
            {
                label: 'Dotations aux provisions',
                montant: parseFloat(formData.dotationsProvisions) || 0,
                type: 'autofinancement',
            },
            {
                label: 'Plus-values de cession',
                montant: parseFloat(formData.plusValues) || 0,
                type: 'exceptionnel',
            },
            {
                label: 'Augmentation de capital',
                montant: parseFloat(formData.augmentationCapital) || 0,
                type: 'financement',
            },
            {
                label: 'Nouveaux emprunts',
                montant: parseFloat(formData.empruntsNouveaux) || 0,
                type: 'financement',
            },
            {
                label: "Cessions d'actifs",
                montant: parseFloat(formData.cessionsActifs) || 0,
                type: 'desinvestissement',
            },
        ];

        // Calcul des emplois
        const emplois = [
            {
                label: 'Investissements',
                montant: parseFloat(formData.investissements) || 0,
                type: 'investissement',
            },
            {
                label: "Remboursements d'emprunts",
                montant: parseFloat(formData.remboursementsEmprunts) || 0,
                type: 'financement',
            },
            {
                label: 'Distribution de dividendes',
                montant: parseFloat(formData.dividendes) || 0,
                type: 'distribution',
            },
            {
                label: 'Variation du BFR',
                montant: parseFloat(formData.bfrVariation) || 0,
                type: 'exploitation',
            },
        ];

        // Calcul des totaux
        const totalRessources = ressources.reduce((sum, item) => sum + item.montant, 0);
        const totalEmplois = emplois.reduce((sum, item) => sum + item.montant, 0);
        const variation = totalRessources - totalEmplois;

        // Calcul des ratios
        const caf =
            parseFloat(formData.resultatNet) +
            parseFloat(formData.dotationsAmortissements) +
            parseFloat(formData.dotationsProvisions);
        const investissementsNets = parseFloat(formData.investissements) - parseFloat(formData.cessionsActifs);
        const autofinancement = ressources
            .filter(r => r.type === 'autofinancement')
            .reduce((sum, item) => sum + item.montant, 0);

        const ratios = [
            {
                label: "Taux d'autofinancement",
                valeur: (autofinancement / totalRessources) * 100,
                interpretation: "Part des ressources générées par l'activité",
            },
            {
                label: 'Couverture des investissements',
                valeur: (caf / investissementsNets) * 100,
                interpretation: "Capacité à financer les investissements par l'autofinancement",
            },
            {
                label: 'Indépendance financière',
                valeur: (autofinancement / parseFloat(formData.empruntsNouveaux || '1')) * 100,
                interpretation: 'Autonomie vis-à-vis des financements externes',
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (variation < 0) {
            recommandations.push(
                'Le tableau de financement est déséquilibré, des ressources supplémentaires sont nécessaires',
            );
        }

        if (autofinancement / totalRessources < 0.5) {
            recommandations.push("L'autofinancement est faible, renforcez la capacité d'autofinancement");
        }

        if (parseFloat(formData.empruntsNouveaux) > autofinancement) {
            recommandations.push(
                "Le recours à l'endettement est important, veillez à maintenir une structure financière équilibrée",
            );
        }

        if (parseFloat(formData.dividendes) > caf * 0.5) {
            recommandations.push('La distribution de dividendes est élevée par rapport à la CAF');
        }

        setResults({
            ressources,
            emplois,
            totalRessources,
            totalEmplois,
            variation,
            equilibre: Math.abs(variation) < 1000,
            ratios,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Tableau de financement
                </Typography>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Ressources
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dotations aux amortissements (€)"
                        type="number"
                        value={formData.dotationsAmortissements}
                        onChange={e => setFormData(prev => ({ ...prev, dotationsAmortissements: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dotations aux provisions (€)"
                        type="number"
                        value={formData.dotationsProvisions}
                        onChange={e => setFormData(prev => ({ ...prev, dotationsProvisions: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Plus-values de cession (€)"
                        type="number"
                        value={formData.plusValues}
                        onChange={e => setFormData(prev => ({ ...prev, plusValues: e.target.value }))}
                        placeholder="Ex: 10000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Augmentation de capital (€)"
                        type="number"
                        value={formData.augmentationCapital}
                        onChange={e => setFormData(prev => ({ ...prev, augmentationCapital: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nouveaux emprunts (€)"
                        type="number"
                        value={formData.empruntsNouveaux}
                        onChange={e => setFormData(prev => ({ ...prev, empruntsNouveaux: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Cessions d'actifs (€)"
                        type="number"
                        value={formData.cessionsActifs}
                        onChange={e => setFormData(prev => ({ ...prev, cessionsActifs: e.target.value }))}
                        placeholder="Ex: 25000"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                    Emplois
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Investissements (€)"
                        type="number"
                        value={formData.investissements}
                        onChange={e => setFormData(prev => ({ ...prev, investissements: e.target.value }))}
                        placeholder="Ex: 400000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Remboursements d'emprunts (€)"
                        type="number"
                        value={formData.remboursementsEmprunts}
                        onChange={e => setFormData(prev => ({ ...prev, remboursementsEmprunts: e.target.value }))}
                        placeholder="Ex: 150000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Distribution de dividendes (€)"
                        type="number"
                        value={formData.dividendes}
                        onChange={e => setFormData(prev => ({ ...prev, dividendes: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Variation du BFR (€)"
                        type="number"
                        value={formData.bfrVariation}
                        onChange={e => setFormData(prev => ({ ...prev, bfrVariation: e.target.value }))}
                        placeholder="Ex: 30000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateTableau}
                    disabled={!formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Générer le tableau
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Ressources</span>
                        <span className="value"></span>
                    </div>

                    {results.ressources.map((ressource, index) => (
                        <div key={index} className="result-row">
                            <span className="label">{ressource.label}</span>
                            <span className="value">{Math.round(ressource.montant).toLocaleString('fr-FR')} €</span>
                        </div>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Total des ressources</span>
                        <span className="value">{Math.round(results.totalRessources).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Emplois</span>
                        <span className="value"></span>
                    </div>

                    {results.emplois.map((emploi, index) => (
                        <div key={index} className="result-row">
                            <span className="label">{emploi.label}</span>
                            <span className="value">{Math.round(emploi.montant).toLocaleString('fr-FR')} €</span>
                        </div>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Total des emplois</span>
                        <span className="value">{Math.round(results.totalEmplois).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div
                        className="result-row"
                        style={{
                            backgroundColor: results.equilibre ? '#E8F5E9' : '#FFEBEE',
                            marginTop: '24px',
                        }}
                    >
                        <span className="label">Variation de trésorerie</span>
                        <span
                            className="value"
                            style={{
                                color: results.variation >= 0 ? '#2E7D32' : '#C62828',
                            }}
                        >
                            {Math.round(results.variation).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Ratios d'analyse</span>
                        <span className="value"></span>
                    </div>

                    {results.ratios.map((ratio, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{ratio.label}</span>
                                <span className="value">{ratio.valeur.toFixed(1)}%</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {ratio.interpretation}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default TableauFinancement;
