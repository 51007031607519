import React from 'react';

import useParseContent from '../../../hooks/useParseContent';
import { TableParser } from '../../../utils/TableParser';
import TablePrismic from './Table';

const getContentWithoutSummary = text => {
    const textWithoutSummary = text.replace(/<p>Sommaire&nbsp;:<\/p>\s*<ol>.*?<\/ol>/s, '');
    return useParseContent(textWithoutSummary.replace(/<br \/>/g, '\n'));
};

export const WordpressContent = ({
    slice,
}: {
    slice: {
        primary: {
            content;
        };
    };
}) => {
    if (!slice.primary.content.text) return;
    const content = getContentWithoutSummary(slice.primary.content.text);
    return <div>{content}</div>;
};

export const WordpressContentTable = ({ content }: { content: string }) => {
    const wordPressBlocs = content.split(/(<figure[\s\S]*?<\/figure>)/);
    return (
        <>
            {wordPressBlocs.map((bloc: string) => {
                if (bloc.includes('</table>')) {
                    return <TablePrismic key="table" data={TableParser(bloc)} />;
                }
                const content = getContentWithoutSummary(bloc);
                return <div key="table">{content}</div>;
            })}
        </>
    );
};
