import React from 'react';

import { useMeetUsContext } from '../../../../../../contexts/MeetUsContextProvider';
import ArrowButton from '../../../../../UI/Button/ArrowButton';
import { Buttons, Details, RightContainerCustomMade, TextResponse, TitleResponse } from '../../styles';

const NoSavingsContent = () => {
    const { openModal } = useMeetUsContext();
    return (
        <RightContainerCustomMade>
            <TitleResponse>Êtes-vous certain de votre saisie ?</TitleResponse>
            <TextResponse>
                <p>Il semble que vous n'économiserez pas sur vos honoraires actuels.</p>
                <p>
                    Avec Dougs, vous accédez à une équipe d'expert-comptables, d'avocats en droit des sociétés, droit
                    fiscal et droit social qui vous conseillent au quotidien.
                </p>
                <p>
                    Vous pilotez simplement votre activité à l'aide d'un tableau de bord mis à jour en temps réel. Votre
                    comptabilité est automatisée directement depuis votre smartphone.
                </p>
            </TextResponse>
            <Buttons>
                <ArrowButton
                    actionType="INTERNAL_LINK"
                    color="ORANGE"
                    linkOptions={{
                        href: 'https://app.dougs.fr/signup',
                        rel: 'noopener nofollow',
                        target: '_blank',
                    }}
                    trackingId="calculateur-essayer-dougs"
                >
                    Essayer gratuitement
                </ArrowButton>
                <ArrowButton
                    actionType="MODAL"
                    color="LIGHT_ORANGE"
                    onClick={openModal}
                    trackingId="calculateur-prendre-rendez-vous"
                >
                    Prendre rendez-vous
                </ArrowButton>
            </Buttons>

            <Details>
                * L’économie annuelle estimée repose sur notre tarification et sur les données que vous avez
                renseignées.
            </Details>
        </RightContainerCustomMade>
    );
};

export default NoSavingsContent;
