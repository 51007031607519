enum ACTION_TYPE {
    INTERNAL_LINK = 'INTERNAL_LINK',
    EXTERNAL_LINK = 'EXTERNAL_LINK',
    MODAL = 'MODAL',
    SIMPLE = 'SIMPLE',
}

enum VARIANT {
    DEFAULT = 'DEFAULT',
    OUTLINE = 'OUTLINE',
    LINK = 'LINK',
}

enum ARROW_BUTTON_COLOR {
    PRIMARY = 'PRIMARY',
    PRIMARY_700 = 'PRIMARY_700',
    WHITE = 'WHITE',
    ORANGE = 'ORANGE',
    LIGHT_ORANGE = 'LIGHT_ORANGE',
}

enum SIZE {
    SMALL = 'SMALL',
    NORMAL = 'NORMAL',
}

enum FULL_WIDTH {
    ONLY_MOBILE = 'ONLY_MOBILE',
    BOTH = 'BOTH',
    DISABLED = 'DISABLED',
}

enum ICON_TYPE {
    ARROW = 'ARROW',
    ROTATE = 'ROTATE',
}

type StyledButtonProps = {
    sizes: { height: string; padding: string; paddingOnHover: string; arrowRightAbsolute: string };
    colors: { primary: string; secondary: string };
    fullWidth: keyof typeof FULL_WIDTH;
    isWrap?: boolean;
    haveBorder?: boolean;
};

type StyledLinkProps = {
    colors: { primary: string; secondary: string };
};

export { ACTION_TYPE, ARROW_BUTTON_COLOR, FULL_WIDTH, ICON_TYPE, SIZE, StyledButtonProps, StyledLinkProps, VARIANT };
