import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface DecouvertData {
    montantDecouvert: string;
    dureeJours: string;
    tauxAnnuel: string;
    commissionMouvement: string;
    commissionPlus: string;
    fraisIncidents: string;
    typeCalcul: 'simple' | 'detaille';
}

interface DecouvertResults {
    interetsDebiteurs: number;
    commissionsDebit: number;
    commissionsPlus: number;
    fraisIncidents: number;
    coutTotal: number;
    tauxEffectif: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const FraisDecouvert: React.FC = () => {
    const [formData, setFormData] = useState<DecouvertData>({
        montantDecouvert: '',
        dureeJours: '',
        tauxAnnuel: '',
        commissionMouvement: '',
        commissionPlus: '',
        fraisIncidents: '',
        typeCalcul: 'simple',
    });

    const [results, setResults] = useState<DecouvertResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateDecouvert = () => {
        const montant = parseFloat(formData.montantDecouvert) || 0;
        const duree = parseFloat(formData.dureeJours) || 0;
        const tauxAnnuel = parseFloat(formData.tauxAnnuel) || 0;
        const commissionMvt = parseFloat(formData.commissionMouvement) || 0;
        const commissionPlus = parseFloat(formData.commissionPlus) || 0;
        const fraisIncidents = parseFloat(formData.fraisIncidents) || 0;

        // Calcul des intérêts débiteurs
        const interetsDebiteurs = (montant * tauxAnnuel * duree) / (100 * 365);

        // Calcul des commissions de mouvement
        const commissionsDebit = (montant * commissionMvt) / 100;

        // Calcul des commissions de plus fort découvert
        const commissionsPlus = (montant * commissionPlus) / 100;

        // Coût total
        const coutTotal = interetsDebiteurs + commissionsDebit + commissionsPlus + fraisIncidents;

        // Calcul du TEG (Taux Effectif Global)
        const tauxEffectif = (coutTotal / montant) * (365 / duree) * 100;

        // Détails des calculs
        const details = [
            {
                label: 'Intérêts débiteurs',
                montant: interetsDebiteurs,
                description: `${tauxAnnuel}% sur ${duree} jours`,
            },
            {
                label: 'Commission de mouvement',
                montant: commissionsDebit,
                description: `${commissionMvt}% du montant débiteur`,
            },
            {
                label: 'Commission de plus fort découvert',
                montant: commissionsPlus,
                description: `${commissionPlus}% du plus fort découvert`,
            },
            {
                label: "Frais d'incidents",
                montant: fraisIncidents,
                description: "Frais de rejets et commissions d'intervention",
            },
        ];

        // Recommandations
        const recommandations = [];

        if (tauxEffectif > 15) {
            recommandations.push("Le coût du découvert est très élevé, privilégiez d'autres solutions de financement");
        }

        if (duree > 30) {
            recommandations.push(
                'La durée du découvert est longue, négociez une facilité de caisse ou un crédit court terme',
            );
        }

        if (montant > 10000) {
            recommandations.push('Pour ce montant, un crédit de trésorerie serait plus adapté');
        }

        if (fraisIncidents > 100) {
            recommandations.push(
                "Les frais d'incidents sont importants, mettez en place une meilleure gestion de trésorerie",
            );
        }

        setResults({
            interetsDebiteurs,
            commissionsDebit,
            commissionsPlus,
            fraisIncidents,
            coutTotal,
            tauxEffectif,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateDecouvert();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'simple', label: 'Calcul simplifié' },
        { value: 'detaille', label: 'Calcul détaillé' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de frais de découvert" />

                <RadioButtonGroup
                    label="Type de calcul"
                    options={radioOptions}
                    value={formData.typeCalcul}
                    onChange={value => setFormData(prev => ({ ...prev, typeCalcul: value as 'simple' | 'detaille' }))}
                    direction="row"
                />

                <InputNumber
                    label="Montant du découvert"
                    value={Number(formData.montantDecouvert) || null}
                    setValue={value => setFormData(prev => ({ ...prev, montantDecouvert: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5000"
                />

                <InputNumber
                    label="Durée"
                    value={Number(formData.dureeJours) || null}
                    setValue={value => setFormData(prev => ({ ...prev, dureeJours: String(value) }))}
                    endAdornment={<>jours</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 15"
                />

                <InputNumber
                    label="Taux annuel"
                    value={Number(formData.tauxAnnuel) || null}
                    setValue={value => setFormData(prev => ({ ...prev, tauxAnnuel: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 12.5"
                />

                {formData.typeCalcul === 'detaille' && (
                    <>
                        <InputNumber
                            label="Commission de mouvement"
                            value={Number(formData.commissionMouvement) || null}
                            setValue={value => setFormData(prev => ({ ...prev, commissionMouvement: String(value) }))}
                            endAdornment={<>%</>}
                            variant="ADORNMENT"
                            placeholder="Ex : 0.1"
                        />

                        <InputNumber
                            label="Commission de plus fort découvert"
                            value={Number(formData.commissionPlus) || null}
                            setValue={value => setFormData(prev => ({ ...prev, commissionPlus: String(value) }))}
                            endAdornment={<>%</>}
                            variant="ADORNMENT"
                            placeholder="Ex : 0.05"
                        />

                        <InputNumber
                            label="Frais d'incidents"
                            value={Number(formData.fraisIncidents) || null}
                            setValue={value => setFormData(prev => ({ ...prev, fraisIncidents: String(value) }))}
                            endAdornment={<>€</>}
                            variant="ADORNMENT"
                            placeholder="Ex : 50"
                        />
                    </>
                )}

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.montantDecouvert || !formData.dureeJours || !formData.tauxAnnuel}
                >
                    Calculer les frais
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Coût total du découvert',
                                value: `${Math.round(results.coutTotal * 100) / 100} €`,
                                highlight: true,
                            },
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: `${Math.round(detail.montant * 100) / 100} €`,
                                description: detail.description,
                            })),

                            {
                                label: 'Taux effectif global (TEG)',
                                value: `${Math.round(results.tauxEffectif * 100) / 100}%`,
                            },
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default FraisDecouvert;
