import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface PrevoyanceData {
    revenuAnnuel: string;
    age: string;
    statut: 'artisan' | 'commercant' | 'liberal';
    situationFamiliale: 'celibataire' | 'marie' | 'pacse';
    nombreEnfants: string;
    garanties: {
        deces: boolean;
        invalidite: boolean;
        arretTravail: boolean;
        hospitalisation: boolean;
        perte: boolean;
    };
}

interface PrevoyanceResults {
    garantiesBase: {
        label: string;
        montant: number;
        description: string;
    }[];
    garantiesOptimales: {
        label: string;
        montant: number;
        description: string;
    }[];
    coutTotal: number;
    economieImpot: number;
    recommandations: string[];
}

const PrevoyanceTns: React.FC = () => {
    const [formData, setFormData] = useState<PrevoyanceData>({
        revenuAnnuel: '',
        age: '',
        statut: 'artisan',
        situationFamiliale: 'celibataire',
        nombreEnfants: '0',
        garanties: {
            deces: true,
            invalidite: true,
            arretTravail: true,
            hospitalisation: false,
            perte: false,
        },
    });

    const [results, setResults] = useState<PrevoyanceResults | null>(null);

    const calculatePrevoyance = () => {
        const revenu = parseFloat(formData.revenuAnnuel) || 0;
        const age = parseInt(formData.age) || 0;
        const enfants = parseInt(formData.nombreEnfants) || 0;

        // Calcul des garanties de base (régime obligatoire)
        const garantiesBase = [];

        if (formData.statut === 'artisan') {
            garantiesBase.push({
                label: 'Capital décès',
                montant: 20000,
                description: 'Capital forfaitaire régime de base',
            });
            garantiesBase.push({
                label: 'Indemnités journalières',
                montant: (revenu * 0.5) / 365,
                description: '50% du revenu journalier',
            });
        } else if (formData.statut === 'commercant') {
            garantiesBase.push({
                label: 'Capital décès',
                montant: 15000,
                description: 'Capital forfaitaire régime de base',
            });
            garantiesBase.push({
                label: 'Indemnités journalières',
                montant: (revenu * 0.4) / 365,
                description: '40% du revenu journalier',
            });
        } else {
            garantiesBase.push({
                label: 'Capital décès',
                montant: 25000,
                description: 'Capital forfaitaire régime de base',
            });
            garantiesBase.push({
                label: 'Indemnités journalières',
                montant: (revenu * 0.6) / 365,
                description: '60% du revenu journalier',
            });
        }

        // Calcul des garanties optimales recommandées
        const garantiesOptimales = [];

        if (formData.garanties.deces) {
            const capitalDeces = Math.max(revenu * 3, 100000);
            garantiesOptimales.push({
                label: 'Capital décès optimal',
                montant: capitalDeces,
                description: '3 années de revenus minimum',
            });
        }

        if (formData.garanties.invalidite) {
            const renteInvalidite = revenu * 0.8;
            garantiesOptimales.push({
                label: 'Rente invalidité',
                montant: renteInvalidite,
                description: '80% du revenu annuel',
            });
        }

        if (formData.garanties.arretTravail) {
            const ijOptimales = (revenu * 0.8) / 365;
            garantiesOptimales.push({
                label: 'IJ optimales',
                montant: ijOptimales,
                description: '80% du revenu journalier',
            });
        }

        if (formData.garanties.hospitalisation) {
            garantiesOptimales.push({
                label: 'Allocation hospitalisation',
                montant: 100,
                description: 'Indemnité journalière hospitalisation',
            });
        }

        if (formData.garanties.perte) {
            const garantiePerte = revenu * 0.25;
            garantiesOptimales.push({
                label: "Garantie perte d'exploitation",
                montant: garantiePerte,
                description: '25% du CA annuel',
            });
        }

        // Estimation du coût total
        let coutTotal = 0;
        const tauxAge = age < 40 ? 0.03 : age < 50 ? 0.04 : 0.05;
        coutTotal = revenu * tauxAge;
        if (formData.garanties.hospitalisation) coutTotal += 200;
        if (formData.garanties.perte) coutTotal += revenu * 0.01;

        // Économie d'impôt (loi Madelin)
        const economieImpot = coutTotal * 0.3;

        // Génération des recommandations
        const recommandations = [];

        if (!formData.garanties.deces && (formData.situationFamiliale !== 'celibataire' || enfants > 0)) {
            recommandations.push('La garantie décès est fortement recommandée vu votre situation familiale');
        }

        if (!formData.garanties.invalidite) {
            recommandations.push('La garantie invalidité est essentielle pour protéger vos revenus futurs');
        }

        if (age > 45 && !formData.garanties.arretTravail) {
            recommandations.push('À votre âge, la garantie arrêt de travail est particulièrement importante');
        }

        if (revenu > 50000 && !formData.garanties.perte) {
            recommandations.push("Vu votre niveau de revenus, envisagez une garantie perte d'exploitation");
        }

        setResults({
            garantiesBase,
            garantiesOptimales,
            coutTotal,
            economieImpot,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Simulateur de prévoyance TNS
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.statut}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                statut: e.target.value as 'artisan' | 'commercant' | 'liberal',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="artisan"
                            control={<Radio />}
                            label="Artisan"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="commercant"
                            control={<Radio />}
                            label="Commerçant"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="liberal"
                            control={<Radio />}
                            label="Profession libérale"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Revenu annuel (€)"
                        type="number"
                        value={formData.revenuAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, revenuAnnuel: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Âge"
                        type="number"
                        value={formData.age}
                        onChange={e => setFormData(prev => ({ ...prev, age: e.target.value }))}
                        placeholder="Ex: 45"
                    />
                </FormControl>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.situationFamiliale}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                situationFamiliale: e.target.value as 'celibataire' | 'marie' | 'pacse',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="celibataire"
                            control={<Radio />}
                            label="Célibataire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="marie"
                            control={<Radio />}
                            label="Marié(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="pacse"
                            control={<Radio />}
                            label="Pacsé(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre d'enfants"
                        type="number"
                        value={formData.nombreEnfants}
                        onChange={e => setFormData(prev => ({ ...prev, nombreEnfants: e.target.value }))}
                        placeholder="Ex: 2"
                    />
                </FormControl>

                <Typography variant="subtitle1" gutterBottom sx={{ mt: 3 }}>
                    Garanties souhaitées
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.garanties.deces}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        garanties: { ...prev.garanties, deces: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Garantie décès"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.garanties.invalidite}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        garanties: { ...prev.garanties, invalidite: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Garantie invalidité"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.garanties.arretTravail}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        garanties: { ...prev.garanties, arretTravail: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Indemnités journalières"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.garanties.hospitalisation}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        garanties: { ...prev.garanties, hospitalisation: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Allocation hospitalisation"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.garanties.perte}
                                onChange={e =>
                                    setFormData(prev => ({
                                        ...prev,
                                        garanties: { ...prev.garanties, perte: e.target.checked },
                                    }))
                                }
                            />
                        }
                        label="Garantie perte d'exploitation"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculatePrevoyance}
                    disabled={!formData.revenuAnnuel || !formData.age}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer les garanties
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Garanties du régime obligatoire</span>
                        <span className="value"></span>
                    </div>

                    {results.garantiesBase.map((garantie, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{garantie.label}</span>
                                <span className="value">{Math.round(garantie.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {garantie.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa', marginTop: '24px' }}>
                        <span className="label">Garanties optimales recommandées</span>
                        <span className="value"></span>
                    </div>

                    {results.garantiesOptimales.map((garantie, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{garantie.label}</span>
                                <span className="value">{Math.round(garantie.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {garantie.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût annuel estimé</span>
                        <span className="value">{Math.round(results.coutTotal).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Économie d'impôt (Loi Madelin)</span>
                        <span className="value">{Math.round(results.economieImpot).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default PrevoyanceTns;
