import * as React from 'react';
import { createContext, ReactNode, useContext } from 'react';

import { ctaTagsType } from '../templates/postPrismic';

const PageContext = createContext({
    ctaTag: undefined,
    google_score: '4.5',
    trustpilot_score: '4.5',
    google_number: '350',
    trustpilot_number: '150',
    customer_number: 21000,
});

export default function PageContextProvider({
    context,
    children,
}: Readonly<{
    context: {
        ctaTag: ctaTagsType;
        google_score: string;
        trustpilot_score: string;
        customer_number: number;
        google_number: string;
        trustpilot_number: string;
    };
    children: ReactNode;
}>) {
    return <PageContext.Provider value={context}>{children}</PageContext.Provider>;
}

export function usePageContext() {
    return useContext(PageContext);
}
