import HTMLReactParser, { HTMLReactParserOptions } from 'html-react-parser';
import * as React from 'react';

export const parser = HTMLReactParser as (
    html: string,
    options?: HTMLReactParserOptions,
) =>
    | string
    | React.DetailedReactHTMLElement<object, HTMLElement>
    | Array<React.DetailedReactHTMLElement<object, HTMLElement>>;
