import styled from '@emotion/styled';
import React from 'react';

import { breakpoint, colors, fonts } from '../../../styles/theme';

export interface ResultRow {
    label: string | string[];
    value: string | number;
    highlight?: boolean;
    description?: string;
}

interface SimulatorResultsProps {
    rows: ResultRow[];
}

const StyledLabel = styled.div`
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 8px;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const ResultsCard = styled.div`
    border-radius: 4px;
    border: 1px solid #dfe8f2;
    margin-bottom: 16px;
`;

const Description = styled.span`
    color: #516a87;
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    line-height: 150%;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const RowMainInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Label = styled.span<{ isHighlight?: boolean }>`
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: ${props => (props.isHighlight ? '15px' : '14px')};
    line-height: 150%;

    ${breakpoint.laptop} {
        font-size: ${props => (props.isHighlight ? '16px' : '15px')};
    }
`;

const Value = styled.span<{ isHighlight?: boolean }>`
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: ${props => (props.isHighlight ? '15px' : '14px')};
    line-height: 150%;
    display: flex;
    align-items: center;

    ${breakpoint.laptop} {
        font-size: ${props => (props.isHighlight ? '16px' : '15px')};
    }
`;

const ResultRow = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 4px;

    &:not(:last-child) {
        border-bottom: 1px solid #dfe8f2;
        margin: 0 16px;
        padding: 16px 0;
    }
`;

const ResultRowHighlight = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: #cff6ea;
    gap: 8px;

    &:has(+ &) {
        padding-bottom: 8px;
    }
    & + & {
        padding-top: 8px;
    }

    ${breakpoint.laptop} {
        padding: 24px 16px;

        &:has(+ &) {
            padding-bottom: 8px;
        }
        & + & {
            padding-top: 8px;
        }
    }
`;

const SimulatorResults: React.FC<SimulatorResultsProps> = ({ rows }) => {
    return (
        <>
            <StyledLabel>Résultat</StyledLabel>
            <ResultsCard>
                {rows.map(row =>
                    row.highlight ? (
                        <ResultRowHighlight key={Array.isArray(row.label) ? row.label.join('-') : row.label}>
                            <Label isHighlight>{row.label}</Label>
                            <Value isHighlight>{row.value}</Value>
                        </ResultRowHighlight>
                    ) : (
                        <ResultRow key={Array.isArray(row.label) ? row.label.join('-') : row.label}>
                            {row.description && <Description>{row.description}</Description>}
                            <RowMainInfo>
                                {Array.isArray(row.label) ? (
                                    <LabelContainer>
                                        {row.label.map(item => (
                                            <Label key={item}>{`• ${item}`}</Label>
                                        ))}
                                    </LabelContainer>
                                ) : (
                                    <Label>{row.label}</Label>
                                )}
                                <Value>{row.value}</Value>
                            </RowMainInfo>
                        </ResultRow>
                    ),
                )}
            </ResultsCard>
        </>
    );
};

export default SimulatorResults;
