import styled from '@emotion/styled';

import { breakpoint, colors, fonts } from '../../../styles/theme';

export const ReadingTime = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;

    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    font-weight: 600;

    ${breakpoint.laptop} {
        font-size: 15px;
    }

    img {
        width: 16px;
    }
`;

export const PostHighlightBottom = styled.div`
    padding: 16px 0;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${breakpoint.laptop} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export const TimeInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    ${breakpoint.laptop} {
        justify-content: flex-end;
        width: auto;
        gap: 16px;
    }

    time {
        font-size: 12px;
        font-family: ${fonts.OpenSans_Regular};

        ${breakpoint.laptop} {
            font-size: 14px;
        }
    }
`;

export const TagsList = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 8px;
    margin-bottom: 24px;

    ${breakpoint.laptop} {
        margin-bottom: 32px;
    }
`;

export const Container = styled.div`
    margin: 24px 0;
`;
