import styled from '@emotion/styled';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';

import { colors, fonts } from '../../../styles/theme';
import SimulatorInputLabel from './SimulatorInputLabel';

interface CheckboxOption {
    value: string;
    label: string;
}

interface CheckboxGroupProps {
    options: CheckboxOption[];
    value: string[];
    onChange: (values: string[]) => void;
    label?: string;
    direction?: 'row' | 'column';
    tooltipText?: string;
}

const CheckboxGroupContainer = styled.div`
    border: 1px solid ${colors.$gray300};
    border-radius: 4px;
    padding: 0 16px;
`;

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
    options,
    value,
    onChange,
    label,
    direction = 'row',
    tooltipText,
}) => {
    const handleChange = (optionValue: string) => {
        if (value.includes(optionValue)) {
            onChange(value.filter(v => v !== optionValue));
        } else {
            onChange([...value, optionValue]);
        }
    };

    return (
        <div>
            {!!label && <SimulatorInputLabel text={label} tooltipText={tooltipText} />}
            <CheckboxGroupContainer>
                <FormGroup row={direction === 'row'}>
                    {options.map(option => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={
                                <Checkbox
                                    checked={value.includes(option.value)}
                                    onChange={() => handleChange(option.value)}
                                />
                            }
                            label={option.label}
                            sx={{
                                flex: direction === 'row' ? 1 : 'initial',
                                '& .MuiFormControlLabel-label': {
                                    fontSize: { xs: '13px', sm: '14px' },
                                    color: value.includes(option.value) ? colors.$primary700 : colors.$gray350,
                                    fontFamily: value.includes(option.value)
                                        ? fonts.OpenSans_SemiBold
                                        : fonts.OpenSans_Regular,
                                },
                            }}
                        />
                    ))}
                </FormGroup>
            </CheckboxGroupContainer>
        </div>
    );
};

export default CheckboxGroup;
