import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface RentabiliteData {
    chiffreAffaires: string;
    chargesVariables: string;
    chargesFixes: string;
    typeActivite: 'commerce' | 'service';
    periodicite: 'mensuel' | 'annuel';
}

interface RentabiliteResults {
    margeUnitaire: number;
    tauxMarge: number;
    seuilRentabilite: number;
    pointMort: number;
    margeSecurite: number;
    indiceCouverture: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const SeuilRentabilite: React.FC = () => {
    const [formData, setFormData] = useState<RentabiliteData>({
        chiffreAffaires: '',
        chargesVariables: '',
        chargesFixes: '',
        typeActivite: 'commerce',
        periodicite: 'mensuel',
    });

    const [results, setResults] = useState<RentabiliteResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateRentabilite = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const cv = parseFloat(formData.chargesVariables) || 0;
        const cf = parseFloat(formData.chargesFixes) || 0;
        const multiplicateur = formData.periodicite === 'mensuel' ? 12 : 1;

        // Calculs annualisés
        const caAnnuel = ca * multiplicateur;
        const cvAnnuel = cv * multiplicateur;
        const cfAnnuel = cf * multiplicateur;

        // Calculs des indicateurs
        const margeUnitaire = caAnnuel - cvAnnuel;
        const tauxMarge = (margeUnitaire / caAnnuel) * 100;
        const seuilRentabilite = cfAnnuel / (tauxMarge / 100);
        const pointMort = seuilRentabilite / multiplicateur;
        const margeSecurite = caAnnuel - seuilRentabilite;
        const indiceCouverture = (margeUnitaire / cfAnnuel) * 100;

        setResults({
            margeUnitaire,
            tauxMarge,
            seuilRentabilite,
            pointMort,
            margeSecurite,
            indiceCouverture,
        });
    };

    const handleSubmit = () => {
        calculateRentabilite();
        if (!logged) handleOpen();
    };

    const typeActiviteOptions = [
        { value: 'commerce', label: 'Commerce' },
        { value: 'service', label: 'Service' },
    ];

    const periodiciteOptions = [
        { value: 'mensuel', label: 'Données mensuelles' },
        { value: 'annuel', label: 'Données annuelles' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de seuil de rentabilité" />

                <RadioButtonGroup
                    label="Type d'activité"
                    options={typeActiviteOptions}
                    value={formData.typeActivite}
                    direction="column"
                    onChange={value =>
                        setFormData(prev => ({ ...prev, typeActivite: value as 'commerce' | 'service' }))
                    }
                />

                <RadioButtonGroup
                    direction="column"
                    label="Périodicité"
                    options={periodiciteOptions}
                    value={formData.periodicite}
                    onChange={value => setFormData(prev => ({ ...prev, periodicite: value as 'mensuel' | 'annuel' }))}
                />

                <InputNumber
                    label={`Chiffre d'affaires ${formData.periodicite} (€)`}
                    value={Number(formData.chiffreAffaires) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chiffreAffaires: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 10000"
                />

                <InputNumber
                    label={`Charges variables ${formData.periodicite}s (€)`}
                    value={Number(formData.chargesVariables) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chargesVariables: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 6000"
                />

                <InputNumber
                    label={`Charges fixes ${formData.periodicite}s (€)`}
                    value={Number(formData.chargesFixes) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chargesFixes: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex: 3000"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.chiffreAffaires || !formData.chargesVariables || !formData.chargesFixes}
                >
                    Calculer le seuil
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Seuil de rentabilité annuel',
                                value: `${Math.round(results.seuilRentabilite).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: `Point mort ${formData.periodicite}`,
                                value: `${Math.round(results.pointMort).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Marge sur coûts variables',
                                value: `${Math.round(results.margeUnitaire).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Taux de marge',
                                value: `${results.tauxMarge.toFixed(1)}%`,
                            },
                            {
                                label: 'Marge de sécurité',
                                value: `${Math.round(results.margeSecurite).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Indice de couverture des charges fixes',
                                value: `${results.indiceCouverture.toFixed(1)}%`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default SeuilRentabilite;
