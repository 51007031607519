import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowButton from '../../UI/Button/ArrowButton';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface Option {
    value: string;
    label: string;
    next?: string;
    result?: string;
    information?: string | string[];
}

interface Question {
    id: string;
    question: string;
    options: Option[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const questions: Record<string, Question> = {
    seul: {
        id: 'seul',
        question: "Je m'associe seul ou à plusieurs ?",
        options: [
            { value: 'oui', label: 'Seul', next: 'protection' },
            { value: 'non', label: 'À plusieurs', next: 'are_plusieurs' },
        ],
    },
    protection: {
        id: 'protection',
        question: "Je veux une forme d'entreprise qui protège mon patrimoine personnel",
        options: [
            { value: 'oui', label: 'Oui', next: 'are' },
            { value: 'non', label: 'Non', next: 'evolution' },
        ],
    },
    evolution: {
        id: 'evolution',
        question: 'Mon entreprise doit être évolutive sans changement constant de statut',
        options: [
            { value: 'oui', label: 'Oui', next: 'are' },
            { value: 'non', label: 'Non', next: 'micro' },
        ],
    },
    micro: {
        id: 'micro',
        question: 'Mon activité entre dans les seuils de la micro-entreprise',
        options: [
            {
                value: 'oui',
                label: 'Oui',
                result: 'Micro-entreprise',
                information:
                    "Attention, si vous avez une micro, il n'est pas possible d'en créer une seconde. Dans le cas d'un maintien de l'actuelle micro, la deuxième activité devra être exercée sous forme de SASU par exemple.",
            },
            {
                value: 'non',
                label: 'Non',
                result: 'Entreprise individuelle au régime réel',
                information:
                    "Attention, si vous avez une EI, il n'est pas possible d'en créer une seconde. Dans le cas d'un maintien de l'actuelle EI, la deuxième activité devra être exercée sous forme de Société.",
            },
        ],
    },
    are: {
        id: 'are',
        question: 'Je souhaite bénéficier de mon ARE',
        options: [
            {
                value: 'plus6mois',
                label: "J'ai l'ARE pendant plus de six mois",
                result: 'SASU',
                information:
                    "La SASU vous permet de bénéficiaire pleinement de votre ARE sans risque de remise en cause par Pole emploi. Vous n'aurez aucune cotisation sociale à payer si vous vous ne vous rémunérez pas.",
            },
            { value: 'moins6mois', label: "Il me reste moins de 6 mois d'ARE", next: 'activiteParallele' },
            { value: 'non', label: "Je n'ai pas l'ARE", next: 'activiteParallele' },
        ],
    },
    activiteParallele: {
        id: 'activiteParallele',
        question: 'Je conserve une autre activité en parallèle',
        options: [
            {
                value: 'salarie',
                label: 'Je suis salarié dans une autre entreprise',
                result: 'SASU',
                information: [
                    'En SASU, vous pouvez avoir une micro-entreprise à côté à condition que son activité soit différente de celle de la SASU.',
                    "Si vous avez une activité au titre de laquelle vous cotisez déjà, la SASU peut permettre de se rémunérer sous forme de dividendes pour éviter d'avoir une double cotisation.",
                ],
            },
            {
                value: 'micro',
                label: 'Je conserve une micro-entreprise',
                result: 'SASU',
                information: [
                    'En SASU, vous pouvez avoir une micro-entreprise à côté à condition que son activité soit différente de celle de la SASU.',
                    "Si vous avez une activité au titre de laquelle vous cotisez déjà, la SASU peut permettre de se rémunérer sous forme de dividendes pour éviter d'avoir une double cotisation.",
                ],
            },
            { value: 'non', label: 'Cette entreprise sera ma seule activité', next: 'remuneration' },
        ],
    },
    remuneration: {
        id: 'remuneration',
        question: 'As-tu besoin de te rémunérer tous les mois avec cette nouvelle entreprise ?',
        options: [
            {
                value: 'oui',
                label: 'Oui',
                result: 'EURL',
                information:
                    'La rémunération en EURL fonctionne simplement, sans bulletin de paie. Les cotisations sociales sont assez faibles (environ 40%) et permettent de limiter les prélèvements. Attention, la protection sociale peut être précaire et nécessité des complémentaires.',
            },
            { value: 'non', label: 'Non', next: 'regimeSocial' },
        ],
    },
    regimeSocial: {
        id: 'regimeSocial',
        question: 'Pour ma protection sociale, je préfère avoir des cotisations...',
        options: [
            {
                value: 'elevees',
                label: 'élevées (85% environ) mais un régime protecteur',
                result: 'SASU',
                information:
                    "En SASU, vous êtes 'assimilé-salarié'. Vous êtes affilié à l'URSSAF au régime général. A ce titre vous bénéficiez d'une protection sociale importante. Le taux de cotisation est plus élevé. Vous ne cotisez pas pour l'assurance chômage.",
            },
            {
                value: 'faibles',
                label: 'plus faibles (environ 40%) mais une protection sociale plus précaire',
                result: 'EURL',
                information:
                    "En EURL, vous êtes 'Travailleur non-salarié'. Vous êtes affilié à la Sécurité sociale des indépendants. A ce titre vous bénéficiez d'une protection sociale assez faible. Le taux de cotisation est plus bas. Vous ne cotisez pas pour l'assurance chômage. Il peut être utile de souscrire à des complémentaires.",
            },
        ],
    },
    are_plusieurs: {
        id: 'are_plusieurs',
        question: "Un dirigeant de l'entreprise bénéficie de l'Allocation de Retour à l'Emploi (ARE)",
        options: [
            {
                value: 'plus6mois',
                label: "J'ai l'ARE pendant plus de six mois",
                result: 'SAS',
                information:
                    "La SAS vous permet de bénéficiaire pleinement de votre ARE sans risque de remise en cause par Pole emploi. Vous n'aurez aucune cotisation sociales à payer si vous vous ne vous rémunérez pas.",
            },
            {
                value: 'moins6mois',
                label: "Il me reste moins de 6 mois d'ARE",
                next: 'activite_parallele_plusieurs',
            },
            { value: 'non', label: "Je n'ai pas l'ARE", next: 'activite_parallele_plusieurs' },
        ],
    },
    activite_parallele_plusieurs: {
        id: 'activite_parallele_plusieurs',
        question: 'Un des dirigeants conservent une activité en parallèle',
        options: [
            {
                value: 'oui',
                label: 'Oui',
                result: 'SAS',
                information: [
                    'En SAS, vous pouvez avoir une micro-entreprise à côté à condition que son activité soit différente de celle de la SAS.',
                    "Si vous avez une activité au titre de laquelle vous cotisez déjà, la SAS peut permettre de se rémunérer sous forme de dividendes pour éviter d'avoir une double cotisation.",
                    "Si l'autre activité est réalisée sous forme d'entreprise individuelle au régime réel, la forme de la société pourra être une SARL sous certaines conditions",
                ],
            },
            { value: 'non', label: 'Non', next: 'remuneration_plusieurs' },
        ],
    },
    remuneration_plusieurs: {
        id: 'remuneration_plusieurs',
        question: 'Les dirigeants souhaitent se rémunérer en continue, tous les mois par exemple',
        options: [
            {
                value: 'oui',
                label: 'Oui',
                result: 'SARL',
                information:
                    'La rémunération en EURL fonctionne simplement, sans bulletin de paie. Les cotisations sociales sont assez faibles (environ 40%) et permettent de limiter les prélèvements. Attention, la protection sociale peut être précaire et nécessité des complémentaires.',
            },
            { value: 'non', label: 'Non', next: 'protection_sociale' },
        ],
    },
    protection_sociale: {
        id: 'protection_sociale',
        question: 'Pour ma protection sociale, je préfère avoir des cotisations...',
        options: [
            {
                value: 'elevees',
                label: 'élevées (85% environ) mais un régime protecteur',
                result: 'SAS',
                information:
                    "En SAS, les dirigeants sont 'assimilés-salariés'. Ils sont affiliés au régime général de la sécurité sociale. A ce titre vous bénéficiez d'une protection sociale importante. Le taux de cotisation est plus élevé. Vous ne cotisez pas pour l'assurance chômage.",
            },
            {
                value: 'faibles',
                label: 'plus faibles (environ 40%) mais une protection sociale plus précaire',
                result: 'SARL',
                information: [
                    "En SARL, vous êtes 'Travailleur non-salarié'. Les dirigeants sont affiliés à la Sécurité sociale des indépendants. A ce titre les dirigeants bénéficient d'une protection sociale assez faible. Le taux de cotisation est plus bas. Les dirigeants ne cotisent pas pour l'assurance chômage. Il peut être utile de souscrire à des complémentaires pour les dirigeants.",
                    'Attention : si les dirigeants de la SARL détiennent ensemble plus de la moitié du capital social, ils ont le statut de travailleur non salarié (cotisations sociales plus faibles). Dans le cas contraire, ils sont assimilés salariés (cotisations sociales plus élevées)',
                ],
            },
        ],
    },
};

const ChoixFormeJuridique: React.FC = () => {
    const [answers, setAnswers] = useState<Record<string, string>>({});
    const [result, setResult] = useState('');
    const [questionPath, setQuestionPath] = useState(['seul']);
    const [showResult, setShowResult] = useState(false);
    const [information, setInformation] = useState<string | string[]>('');

    const { handleOpen, logged } = useSimulatorContext();

    const handleAnswer = (questionId: string, answer: string) => {
        const selectedOption = questions[questionId].options.find(opt => opt.value === answer);
        if (!selectedOption) return;

        const questionIndex = questionPath.indexOf(questionId);
        const newQuestionPath = questionPath.slice(0, questionIndex + 1);
        const newAnswers = Object.fromEntries(
            Object.entries(answers).filter(([key]) => questionPath.indexOf(key) <= questionIndex),
        );

        setAnswers({ ...newAnswers, [questionId]: answer });
        setShowResult(false);

        if (selectedOption.result) {
            setResult(selectedOption.result);
            setInformation(selectedOption.information || '');
            setQuestionPath(newQuestionPath);
        } else if (selectedOption.next) {
            setQuestionPath([...newQuestionPath, selectedOption.next]);
            setResult('');
            setInformation('');
        }
    };

    const handleSubmit = () => {
        if (!logged) handleOpen();
        else setShowResult(true);
    };

    const handleReset = () => {
        setResult('');
        setInformation('');
        setAnswers({});
        setQuestionPath(['seul']);
        setShowResult(false);
    };

    return (
        <div className="simulator-container">
            <SimulatorCard>
                {questionPath.map(questionId => (
                    <RadioButtonGroup
                        key={questionId}
                        label={questions[questionId].question}
                        options={questions[questionId].options}
                        onChange={value => handleAnswer(questionId, value)}
                        value={answers[questionId] || ''}
                    />
                ))}
                <ArrowButton
                    actionType="SIMPLE"
                    onClick={showResult ? handleReset : handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!result || (showResult && !logged)}
                >
                    {showResult ? 'Recommencer' : 'Voir la forme juridique recommandée'}
                </ArrowButton>
            </SimulatorCard>
            {logged && showResult && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            { label: 'Recommandation', value: result, highlight: true },
                            { description: 'Informations', label: information, value: '' },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default ChoixFormeJuridique;
