import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React from 'react';

import { colors, fonts } from '../../../styles/theme';

interface SimulatorHeaderProps {
    title: string;
    subtitle?: string;
}

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = styled.h2<{ hasSubtitle: boolean }>`
    color: ${colors.$primary700};
    font-family: ${fonts.Eina_SemiBold};
    font-size: 22px;
    line-height: 125%;
    margin-bottom: ${props => (props.hasSubtitle ? '4px' : '0')};
`;

const SimulatorHeader: React.FC<SimulatorHeaderProps> = ({ title, subtitle }) => {
    return (
        <HeaderContainer>
            <Title hasSubtitle={!!subtitle}>{title}</Title>
            {subtitle && (
                <Typography variant="body2" color="text.secondary">
                    {subtitle}
                </Typography>
            )}
        </HeaderContainer>
    );
};

export default SimulatorHeader;
