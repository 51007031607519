import '../../styles/style.scss';

import { useLocation } from '@reach/router';
import * as React from 'react';
import { ReactNode, useEffect, useRef } from 'react';

import HeaderContextProvider from '../../contexts/headerContextProvider';
import { useMeetUsContext } from '../../contexts/MeetUsContextProvider';
import RootContextProvider from '../../contexts/RootContextProvider';
import TrackingContextProvider from '../../contexts/trackingContextProvider';
import { SeoFragmentFragment } from '../../seo';
import MeetUsModal from '../meetUs/Modal/MeetUsModal';
import Footer from './footer/Footer';
import HeaderConstructor from './header/HeaderConstructor';
import scripts from './scripts';

const windowLocation = typeof window === 'undefined' ? null : window.location;

const Layout = ({
    children,
    hasHeaderMedia = false,
    nolayout = false,
    hasHeaderBanner = true,
    isMinimal = false,
    forceHeaderWhiteBackground = false,
    trackingId,
}: {
    children: ReactNode;
    title?: string;
    author?: any;
    seo?: SeoFragmentFragment;
    hasHeaderMedia?: boolean;
    nolayout?: boolean;
    hasHeaderBanner?: boolean;
    isMinimal?: boolean;
    forceHeaderWhiteBackground?: boolean;
    trackingId?: string;
}) => {
    const location = useLocation();
    const noLayout = useRef(windowLocation?.search.includes('nolayout'));
    useEffect(() => {
        const promise = scripts();
        return () => promise.then(unsubscribers => unsubscribers?.forEach(fn => fn?.()));
    }, [location.pathname]);

    useEffect(() => {
        noLayout.current = windowLocation?.search.includes('nolayout');
    }, []);

    const noLayoutRef = noLayout.current || nolayout;

    const { isModalOpen, closeModal } = useMeetUsContext();

    return (
        <RootContextProvider>
            {/* ATTENTION: We need to wrap children in this provider as well to have the 
            global property stickyHeader.height. We'll use it to calculate the top of sticky elements.  */}
            <TrackingContextProvider
                context={{
                    trackingId: trackingId || '',
                }}
            >
                {!noLayoutRef && (
                    <>
                        <HeaderContextProvider
                            context={{
                                hasHeaderMedia,
                                isMinimal,
                                bannerOptions: {
                                    isVisible: hasHeaderBanner,
                                },
                                forceWhiteBackground: forceHeaderWhiteBackground,
                            }}
                        >
                            <HeaderConstructor />
                            <MeetUsModal isOpen={isModalOpen} close={closeModal} />

                            {children}
                        </HeaderContextProvider>
                        <Footer isMinimal={isMinimal} />
                    </>
                )}
                {noLayoutRef && children}
            </TrackingContextProvider>
        </RootContextProvider>
    );
};

export default Layout;
