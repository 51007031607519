import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';

import Countdown from '../components/countdown/countdown';
import HubspotForm from '../components/hubspot/HubspotForm';
import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import { SeoTitle } from '../components/seo/seoTitle';
import { Button } from '../components/UI/Button/legacy/button/Button';
import { DateTime, formatDate } from '../hooks/useFormatDate';
import { seoFragments } from '../seo';
import { breakpoint, colors } from '../styles/theme';
import { findHashtags } from '../utils/utils';

const getCategoryPretty = category => {
    let name = '';
    if (category === 'all') {
        name = 'Tous';
    }
    if (category === 'créersonentreprise') {
        name = 'Créer son entreprise';
    }
    if (category === 'gestiondentreprise') {
        name = "Gestion d'entreprise";
    }
    return (name || category)[0].toUpperCase() + (name || category).substring(1);
};
const getMinWebinarEstimatedAt = webinar => Math.min(...webinar.sessions.map(session => session.estimated_started_at));
const sortWebinarByEstimatedAt = (webinarA, webinarB) =>
    getMinWebinarEstimatedAt(webinarA) > getMinWebinarEstimatedAt(webinarB) ? 1 : -1;

const SubMenu = styled.div<{ selected?: boolean }>`
    width: fit-content;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    color: ${props => (props.selected ? colors.$primary700 : colors.$gray)};
    padding-bottom: 3px;
    border-bottom: solid 2px ${props => (props.selected ? colors.$primary : 'transparent')};
    transition: ease 0.2s;

    ::first-letter {
        text-transform: uppercase;
    }
`;

const WhatYouNeedWrapper = styled.div``;
const ContactUs = styled.div`
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 32px auto;

    ${breakpoint['$large-max']} {
        padding: 2rem;
    }

    border-radius: 8px;
    max-width: 560px;
`;

const FormWrapper = styled.div`
    max-width: 400px;
    margin: auto;

    label {
        text-align: start;
    }

    p {
        text-align: start;
    }

    .hs-form-booleancheckbox-display {
        display: flex;
        align-items: start;

        .hs-input {
            margin: 7px 6px 0 0;
        }

        span {
            margin: 0 !important;
        }
    }

    .hs-button {
        padding: 10px 32px;
    }
`;

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: ${colors.$primary700};
`;

const Menu = styled.div`
    display: flex;
    gap: 32px;

    ${breakpoint['$medium-max']} {
        gap: 16px;
    }

    justify-content: center;
`;
const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;

    > * {
        width: 100%;
    }

    ${breakpoint['$large-min']} {
        gap: 64px;

        > * {
            width: 50%;
        }
    }

    ${breakpoint['$large-max']} {
        flex-direction: column;
    }
`;
const StyledButton = styled(Button)`
    font-size: 14px;
    font-weight: 700;
    padding: 10px 16px;
`;

const StyledLink = styled(Link)`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.$primary};
    padding-top: 10px;

    &:hover {
        text-decoration: underline;
    }
`;

const SignUpButton = ({ slug, selectedSession }: { slug: string; selectedSession: string }) => {
    return (
        <Link
            className="WebinarSignupBtn"
            state={{ sessionId: selectedSession }}
            to={`/webinars/${slug}/`}
            data-ga-send="event,button,click,HighlightWebinar,upcoming"
            data-ga4="container-level1-cta-topwebinar-click-link"
        >
            <div className="btn-content">Je m'inscris</div>
        </Link>
    );
};
export const SelectSession = ({
    sessions,
    onSelect,
}: {
    sessions: Queries.WebinarSessions[];
    onSelect: Dispatch<SetStateAction<string>>;
}): ReactElement => {
    return (
        <div className="WebinarSelect">
            {sessions.length > 1 ? (
                <>
                    <select onChange={event => onSelect(event.target.value)}>
                        {sessions.map(session => {
                            return (
                                <option key={session.id} value={session.id}>
                                    {formatDate(session.estimated_started_at)}
                                </option>
                            );
                        })}
                    </select>
                    {sessions.length > 2 && <div>{`${sessions.length - 1} autres dates disponibles`}</div>}
                    {sessions.length === 2 && <div>{`${sessions.length - 1} autre date disponible`}</div>}
                </>
            ) : (
                <p>{formatDate(sessions[0].estimated_started_at)}</p>
            )}
        </div>
    );
};
export const WebinarAvatar = ({
    webinar,
    detail,
    center,
}: {
    webinar: Queries.Webinar;
    detail?: boolean;
    center?: boolean;
}): ReactElement => {
    const { avatar_link, first_name, last_name } = webinar.event.owner.attributes;
    return (
        <div className={`WebinarAvatarContainer${center ? ' center' : ''}`}>
            {avatar_link ? (
                <img
                    title={first_name + ' ' + last_name}
                    alt={first_name + ' ' + last_name}
                    className="WebinarAvatar"
                    src={avatar_link}
                />
            ) : (
                <span title="Expert-comptable" className="WebinarNoAvatar">
                    <span className="WebinarNoAvatarText">EC</span>
                </span>
            )}
            {detail && <div>{`${first_name} ${last_name}`} </div>}
        </div>
    );
};
const WebinarRaw = ({ webinar, onDemand }: { webinar?: Queries.Webinar; onDemand?: Queries.Webinar }): ReactElement => {
    if (!webinar.sessions[0]) return null;

    return (
        <div className="WebinarRow container">
            <div className="WebinarInfos">
                <div className="WebinarSubInfos">
                    <WebinarAvatar webinar={webinar} />
                    <div className="WebinarSubInfosText">
                        <div className="WebinarTitle">{webinar.event.title}</div>
                        <div className="WebinarSubTitle">
                            <p>
                                {`le ${formatDate(
                                    webinar.sessions[0].estimated_started_at,
                                    DateTime.DATE_SHORT,
                                )} à ${formatDate(webinar.sessions[0].estimated_started_at, DateTime.TIME_24_SIMPLE)}`}
                            </p>
                            {webinar.sessions.length > 2 && (
                                <div> {`ou ${webinar.sessions.length - 1} autres dates`}</div>
                            )}
                            {webinar.sessions.length === 2 && (
                                <div> {`ou ${webinar.sessions.length - 1} autre date`}</div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="WebinarSelect">
                    <Link
                        state={{ sessionId: webinar.sessions[0].id }}
                        to={`/webinars/${webinar.event.slug}/`}
                        data-ga-send="event,button,click,webinar,upcoming"
                        data-ga4="container-level2-miniature1-upcomming-click-link"
                    >
                        <StyledButton type="white"> Je m'inscris</StyledButton>
                    </Link>
                    {onDemand && (
                        <StyledLink
                            state={{ sessionId: onDemand.sessions[0].id }}
                            to={`/webinars/${onDemand.event.slug}/replay`}
                            data-ga-send="event,button,click,webinar,on-demand"
                            data-ga4="container-level2-miniature1-on-demand-click-link"
                        >
                            ou voir un replay →
                        </StyledLink>
                    )}
                </div>
            </div>
        </div>
    );
};

const FirstWebinarRaw = ({
    webinar,
    webinarOnDemand,
    highlightOnDemand,
}: {
    webinar: Queries.Webinar;
    webinarOnDemand: Queries.Webinar[];
    highlightOnDemand: boolean;
}): ReactElement => {
    const [selectedSession, setSelectedSession] = useState(webinar?.sessions[0]?.id || '');

    if (webinarOnDemand[0] && highlightOnDemand) {
        return (
            <div className="container row row--spaceBetween m-margin">
                <div className="col-md-7 WebinarSpacing">
                    <div className="WebinarSmallText">Notre dernier webinar</div>
                    <div>
                        <div className="WebinarTitle--big">{webinarOnDemand[0].event.title}</div>
                        <div className="WebinarSubTitle">{`Environ ${webinarOnDemand[0].event.estimated_duration} minutes`}</div>
                    </div>
                    <div className="WebinarSubTitle">
                        <WebinarAvatar webinar={webinarOnDemand[0]} detail />
                    </div>
                </div>
                <div className="col-md-1" />
                <div className="col-md-4 WebinarCountdown">
                    <Link
                        className="WebinarSignupBtn"
                        to={`/webinars/${webinarOnDemand[0].event.slug}/replay`}
                        data-ga-send="event,button,click,webinar,on-demand"
                        data-ga4="container-level2-miniature1-upcomming-click-link"
                    >
                        <div className="btn-content">Voir le replay</div>
                    </Link>
                </div>
            </div>
        );
    }
    if (!webinar)
        return (
            <div className="container row row--spaceBetween m-margin">
                <div className="col-md-7 WebinarSpacing">
                    <div className="WebinarTitle--big">Aucun webinar disponible</div>
                </div>
            </div>
        );
    return (
        <div className="container row row--spaceBetween m-margin">
            <div className="col-md-7 WebinarSpacing">
                <div className="WebinarSmallText">Notre prochain webinar</div>
                <div>
                    <div className="WebinarTitle--big">{webinar.event.title}</div>
                    <div className="WebinarSubTitle">{`Environ ${webinar.event.estimated_duration} minutes`}</div>
                </div>
                <div className="WebinarSubTitle">
                    <WebinarAvatar webinar={webinar} detail />
                </div>
            </div>
            <div className="col-md-1" />
            <div className="col-md-4 WebinarCountdown">
                <Countdown sessions={webinar.sessions as Queries.WebinarSessions[]} />
                <SelectSession sessions={[webinar.sessions[0]]} onSelect={setSelectedSession} />
                <SignUpButton slug={webinar.event.slug} selectedSession={selectedSession} />
            </div>
        </div>
    );
};

const WebinarsPage = ({ data }: { data: Queries.WebinarQueryQuery }): ReactElement => {
    const [selectedFilter, setSelectedFilter] = useState('all');
    const filteredWebinar = data.allWebinars.nodes
        .map(webinar => {
            return {
                meta: webinar.meta,
                event: webinar.event,
                sessions: webinar.sessions.filter(session => session.estimated_started_at >= Date.now() / 1000),
            };
        })
        .filter(webinar => webinar.sessions.length > 0)
        .filter(webinar => {
            if (selectedFilter !== 'all') {
                return webinar.event.description.includes('#' + selectedFilter);
            }
            return true;
        })
        .sort(sortWebinarByEstimatedAt);
    const [firstWebinar, ...OtherWebinars] = filteredWebinar;
    const webinarsOnDemand = data.allWebinarsOnDemand.nodes.filter(webinar => {
        if (selectedFilter !== 'all') {
            return webinar.event.description.includes('#' + selectedFilter);
        }
        return true;
    });
    const firstWebinarOnDemand = webinarsOnDemand.find(web => {
        const arr = web.event.title.split(' ');
        arr.shift();
        return arr.join(' ').trim() === firstWebinar?.event.title.trim();
    });
    const categories = [
        ...new Set(
            data.allWebinars.nodes
                .filter(webinar => webinar.event.description)
                .map(webinar => findHashtags(webinar.event.description))
                .flat(),
        ),
    ] as string[];

    const nextSessionDate = firstWebinar?.sessions[0]?.estimated_started_at;
    const now = new Date();
    const highlightOnDemand =
        webinarsOnDemand[0] && nextSessionDate && nextSessionDate * 1000 >= now.setDate(now.getDate() + 2);

    return (
        <Layout hasHeaderMedia>
            <main className="creation">
                <TopPaddingBasedOnHeader id="section-cover" className="mb-2">
                    <div className="wrapper wrapper--thin">
                        <img loading="lazy" className="pattern" src="/images/creation/cover-pattern.svg" alt="" />
                        <img
                            loading="lazy"
                            className="pattern-mobile"
                            src="/images/creation/start-pattern-1.svg"
                            alt=""
                        />
                        <div className="Container m-tiny u-textCenter">
                            <h1 className="pb-2">Webinars Dougs</h1>
                            <p className="m-big">
                                Chaque semaine, Dougs vous propose des webinars pour répondre à vos questions sur la
                                comptabilité, la création d'entreprise et la fiscalité.
                            </p>
                        </div>
                    </div>
                </TopPaddingBasedOnHeader>
                <section className="section">
                    <Menu className="container row">
                        <SubMenu
                            key={'all'}
                            selected={selectedFilter === 'all'}
                            onClick={() => setSelectedFilter('all')}
                            data-ga-send={'event,button,click,webinar-category-all'}
                        >
                            Tous
                        </SubMenu>
                        {categories.map(category => {
                            if (category === 'populaire') return null;
                            return (
                                <SubMenu
                                    key={category}
                                    selected={selectedFilter === category}
                                    onClick={() => setSelectedFilter(category)}
                                    data-ga-send={`event,button,click,webinar-category-${category}`}
                                >
                                    {getCategoryPretty(category)}
                                </SubMenu>
                            );
                        })}
                    </Menu>
                </section>
                <hr />
                <section className="section section--blueLight">
                    <FirstWebinarRaw
                        highlightOnDemand={highlightOnDemand}
                        webinar={firstWebinar as unknown as Queries.Webinar}
                        webinarOnDemand={webinarsOnDemand as Queries.Webinar[]}
                    />
                </section>
                <hr />
                <section className="section m-margin">
                    {(highlightOnDemand || OtherWebinars.length > 0) && (
                        <div className="container h4 mb-2">{getCategoryPretty(selectedFilter)}</div>
                    )}
                    <div>
                        {highlightOnDemand && (
                            <WebinarRaw
                                key={firstWebinar.event.slug}
                                webinar={firstWebinar as unknown as Queries.Webinar}
                                onDemand={firstWebinarOnDemand as Queries.Webinar}
                            />
                        )}
                        {OtherWebinars.map(webinar => {
                            const webinarOnDemand = webinarsOnDemand.find(web => {
                                const arr = web.event.title.split(' ');
                                arr.shift();
                                return arr.join(' ').trim() === webinar.event.title.trim();
                            });
                            return (
                                <WebinarRaw
                                    key={webinar.event.slug}
                                    webinar={webinar as unknown as Queries.Webinar}
                                    onDemand={webinarOnDemand as Queries.Webinar}
                                />
                            );
                        })}
                    </div>
                </section>
                <section className="section m-margin">
                    <StyledWrapper className="container">
                        <ContactUs className={'panel panel--primary u-no-margin'}>
                            <SeoTitle className="mb-2" seoStyle="h4">
                                Ne ratez pas nos prochains
                                <br /> webinars !
                            </SeoTitle>
                            <FormWrapper>
                                <HubspotForm
                                    trackingId="webinar-form-newsletter"
                                    formId={'b99b5221-f6d3-425f-a1a6-3b1180a4b1cb'}
                                />
                            </FormWrapper>
                        </ContactUs>
                        <ContactUs className={'panel panel--white u-no-margin'}>
                            <WhatYouNeedWrapper>
                                <SeoTitle className="mb-2" seoStyle="h4">
                                    Vous n’avez pas trouvé ce dont <br />
                                    vous avez besoin ?
                                </SeoTitle>
                                <Description className="mb-2">
                                    Faites-nous savoir quel sujet de webinar vous
                                    <br /> aimeriez visionner
                                </Description>
                                <div id="IntercomWebinarButton" data-ga-send="event,button,click,webinar-bot-subject">
                                    <StyledButton>Proposer un nouveau sujet</StyledButton>
                                </div>
                            </WhatYouNeedWrapper>
                        </ContactUs>
                    </StyledWrapper>
                </section>
            </main>
        </Layout>
    );
};

export default WebinarsPage;

export const Head = () => {
    return <Seo seo={seoFragments.webinars} />;
};

export const pageQuery = graphql`
    query WebinarQuery {
        allWebinars: allWebinar(
            filter: { event: { show_in_company_page: { eq: true } } }
            sort: { sessions: { estimated_started_at: ASC } }
        ) {
            nodes {
                id
                meta {
                    record_count
                }
                event {
                    title
                    slug
                    registration_link
                    estimated_duration
                    registration_page_enabled
                    everyone_can_speak
                    description
                    status
                    owner {
                        attributes {
                            avatar_link
                            first_name
                            last_name
                        }
                    }
                    light_registration_page_enabled
                    recording_enabled
                    recording_public
                    show_in_company_page
                    chat_enabled
                    polls_enabled
                    questions_enabled
                    language
                    published_at
                    scheduling_status
                    created_at
                    updated_at
                    sessions_count
                }
                sessions {
                    id
                    event_id
                    status
                    timezone
                    room_link
                    attendees_count
                    estimated_started_at
                    started_at
                    ended_at
                    canceled_at
                    created_at
                    updated_at
                    registrants_count
                }
            }
        }
        allWebinarsOnDemand: allWebinar(filter: { event: { scheduling_status: { eq: "on_demand" } } }) {
            nodes {
                id
                meta {
                    record_count
                }
                event {
                    title
                    slug
                    registration_link
                    estimated_duration
                    registration_page_enabled
                    everyone_can_speak
                    description
                    status
                    owner {
                        attributes {
                            avatar_link
                            first_name
                            last_name
                        }
                    }
                    light_registration_page_enabled
                    recording_enabled
                    recording_public
                    show_in_company_page
                    chat_enabled
                    polls_enabled
                    questions_enabled
                    language
                    published_at
                    scheduling_status
                    created_at
                    updated_at
                    sessions_count
                }
                sessions {
                    id
                    event_id
                    status
                    timezone
                    room_link
                    attendees_count
                    estimated_started_at
                    started_at
                    ended_at
                    canceled_at
                    created_at
                    updated_at
                    registrants_count
                }
            }
        }
    }
`;
