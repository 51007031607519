import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface IRData {
    revenuPro: string;
    autresRevenus: string;
    situationFamiliale: 'celibataire' | 'marie' | 'pacse';
    nbParts: string;
    regime: 'micro' | 'reel';
    activity: 'commercial' | 'service' | 'liberal';
}

interface IRResults {
    revenuImposable: number;
    abattement: number;
    quotientFamilial: number;
    tranches: {
        tranche: string;
        taux: number;
        montant: number;
    }[];
    totalIR: number;
    tauxMoyen: number;
    revenuNet: number;
}

const ImpotRevenuTNS: React.FC = () => {
    const [formData, setFormData] = useState<IRData>({
        revenuPro: '',
        autresRevenus: '',
        situationFamiliale: 'celibataire',
        nbParts: '1',
        regime: 'micro',
        activity: 'commercial',
    });

    const [results, setResults] = useState<IRResults | null>(null);

    const calculateIR = () => {
        const revenuPro = parseFloat(formData.revenuPro) || 0;
        const autresRevenus = parseFloat(formData.autresRevenus) || 0;
        const nbParts = parseFloat(formData.nbParts);

        // Calcul de l'abattement selon le régime et l'activité
        let tauxAbattement = 0;
        if (formData.regime === 'micro') {
            switch (formData.activity) {
                case 'commercial':
                    tauxAbattement = 0.71; // 71% pour le commercial
                    break;
                case 'service':
                    tauxAbattement = 0.34; // 34% pour les services
                    break;
                case 'liberal':
                    tauxAbattement = 0.34; // 34% pour les professions libérales
                    break;
            }
        }

        const abattement = formData.regime === 'micro' ? revenuPro * tauxAbattement : 0;
        const revenuImposable = revenuPro - abattement + autresRevenus;
        const quotientFamilial = revenuImposable / nbParts;

        // Calcul par tranches
        const tranches = [
            { limite: 10777, taux: 0 },
            { limite: 27478, taux: 11 },
            { limite: 78570, taux: 30 },
            { limite: 168994, taux: 41 },
            { limite: Infinity, taux: 45 },
        ];

        let montantParPart = 0;
        const tranchesDetail = [];
        let precedente = 0;

        for (const tranche of tranches) {
            const assiette = Math.min(Math.max(quotientFamilial - precedente, 0), tranche.limite - precedente);
            const montant = assiette * (tranche.taux / 100);

            if (assiette > 0) {
                tranchesDetail.push({
                    tranche: `${precedente.toLocaleString('fr-FR')}€ à ${tranche.limite === Infinity ? '+' : tranche.limite.toLocaleString('fr-FR') + '€'}`,
                    taux: tranche.taux,
                    montant: montant * nbParts,
                });
            }

            montantParPart += montant;
            precedente = tranche.limite;
        }

        const totalIR = montantParPart * nbParts;
        const tauxMoyen = (totalIR / revenuImposable) * 100;
        const revenuNet = revenuImposable - totalIR;

        setResults({
            revenuImposable,
            abattement,
            quotientFamilial,
            tranches: tranchesDetail,
            totalIR,
            tauxMoyen,
            revenuNet,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur d'impôt sur le revenu TNS
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.regime}
                        onChange={e => setFormData(prev => ({ ...prev, regime: e.target.value as 'micro' | 'reel' }))}
                    >
                        <FormControlLabel
                            value="micro"
                            control={<Radio />}
                            label="Micro-entreprise"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="reel"
                            control={<Radio />}
                            label="Régime réel"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                {formData.regime === 'micro' && (
                    <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                        <RadioGroup
                            row
                            value={formData.activity}
                            onChange={e =>
                                setFormData(prev => ({
                                    ...prev,
                                    activity: e.target.value as 'commercial' | 'service' | 'liberal',
                                }))
                            }
                        >
                            <FormControlLabel
                                value="commercial"
                                control={<Radio />}
                                label="Commercial"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="service"
                                control={<Radio />}
                                label="Service"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                            <FormControlLabel
                                value="liberal"
                                control={<Radio />}
                                label="Libéral"
                                sx={{ flex: 1, m: 0, p: 2 }}
                            />
                        </RadioGroup>
                    </FormControl>
                )}

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.situationFamiliale}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                situationFamiliale: e.target.value as 'celibataire' | 'marie' | 'pacse',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="celibataire"
                            control={<Radio />}
                            label="Célibataire"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="marie"
                            control={<Radio />}
                            label="Marié(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="pacse"
                            control={<Radio />}
                            label="Pacsé(e)"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Nombre de parts fiscales"
                        type="number"
                        value={formData.nbParts}
                        onChange={e => setFormData(prev => ({ ...prev, nbParts: e.target.value }))}
                        placeholder="Ex: 1"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Revenu professionnel (€)"
                        type="number"
                        value={formData.revenuPro}
                        onChange={e => setFormData(prev => ({ ...prev, revenuPro: e.target.value }))}
                        placeholder="Ex: 50000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Autres revenus du foyer (€)"
                        type="number"
                        value={formData.autresRevenus}
                        onChange={e => setFormData(prev => ({ ...prev, autresRevenus: e.target.value }))}
                        placeholder="Ex: 20000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateIR}
                    disabled={!formData.revenuPro}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer l'impôt
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row">
                        <span className="label">Revenu professionnel</span>
                        <span className="value">{parseFloat(formData.revenuPro).toLocaleString('fr-FR')} €</span>
                    </div>
                    {formData.regime === 'micro' && (
                        <div className="result-row">
                            <span className="label">Abattement forfaitaire</span>
                            <span className="value">{Math.round(results.abattement).toLocaleString('fr-FR')} €</span>
                        </div>
                    )}
                    <div className="result-row">
                        <span className="label">Revenu imposable</span>
                        <span className="value">{Math.round(results.revenuImposable).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row">
                        <span className="label">Quotient familial</span>
                        <span className="value">{Math.round(results.quotientFamilial).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.tranches.map((tranche, index) => (
                        <div key={index} className="result-row">
                            <span className="label">
                                Tranche {tranche.tranche} ({tranche.taux}%)
                            </span>
                            <span className="value">{Math.round(tranche.montant).toLocaleString('fr-FR')} €</span>
                        </div>
                    ))}

                    <div className="result-row">
                        <span className="label">Taux moyen d'imposition</span>
                        <span className="value">{results.tauxMoyen.toFixed(1)}%</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Montant total de l'impôt</span>
                        <span className="value">{Math.round(results.totalIR).toLocaleString('fr-FR')} €</span>
                    </div>
                    <div className="result-row highlight">
                        <span className="label">Revenu net après impôt</span>
                        <span className="value">{Math.round(results.revenuNet).toLocaleString('fr-FR')} €</span>
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ImpotRevenuTNS;
