import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import * as styles from '../card/card-icon-list.module.scss';
import PostThumb from '../media/PostThumb';
import { SeoTitle } from '../seo/seoTitle';

const SectionHighlightPosts = () => {
    const prismicPosts = useStaticQuery(query);
    const posts = prismicPosts.allPrismicPost.nodes as Queries.PrismicPost[];
    if (!posts.length || posts.length <= 0) return null;
    return (
        <section className="section section--light">
            <div>
                <SeoTitle seoStyle="h3 u-textCenter">Nos articles les plus lus</SeoTitle>
                <div className={styles.cardIconSwipperNoMargin}>
                    <Swiper
                        initialSlide={1}
                        centeredSlides
                        slideToClickedSlide
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 3.5,
                                spaceBetween: 15,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            1460: {
                                slidesPerView: 5.5,
                                spaceBetween: 20,
                            },
                        }}
                        spaceBetween={10}
                        slidesPerView={2}
                        modules={[Pagination]}
                    >
                        <div className="row thumb-list blue">
                            {posts.map((post, index) => (
                                <SwiperSlide
                                    key={index}
                                    data-ga4="container-level2-text-articleslespluslus-click-link"
                                    className="u-clickable"
                                >
                                    <PostThumb
                                        index={index + 1}
                                        blue
                                        key={post.id}
                                        post={{
                                            ...post,
                                            img: post.data.cover.gatsbyImageData,
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default SectionHighlightPosts;

const query = graphql`
    {
        allPrismicPost(
            filter: { data: { top_article: { eq: true }, indexed: { eq: true } } }
            limit: 8
            sort: { data: { published_date: DESC } }
        ) {
            nodes {
                ...PrismicPostItem
            }
        }
    }
`;
