import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface AffacturageData {
    chiffreAffaires: string;
    delaiClient: string;
    tauxCommission: string;
    tauxFinancement: string;
    tauxRetenue: string;
    fraisFixe: string;
    typeContrat: 'classique' | 'confidentiel' | 'reverse';
}

interface AffacturageResults {
    coutTotal: number;
    coutFinancement: number;
    coutCommission: number;
    coutFraisFixe: number;
    tauxEffectif: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const CoutAffacturage: React.FC = () => {
    const [formData, setFormData] = useState<AffacturageData>({
        chiffreAffaires: '',
        delaiClient: '',
        tauxCommission: '',
        tauxFinancement: '',
        tauxRetenue: '10',
        fraisFixe: '',
        typeContrat: 'classique',
    });

    const [results, setResults] = useState<AffacturageResults | null>(null);

    const calculateAffacturage = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const delaiClient = parseFloat(formData.delaiClient) || 0;
        const tauxCommission = parseFloat(formData.tauxCommission) || 0;
        const tauxFinancement = parseFloat(formData.tauxFinancement) || 0;
        const tauxRetenue = parseFloat(formData.tauxRetenue) || 0;
        const fraisFixe = parseFloat(formData.fraisFixe) || 0;

        // Calcul de l'encours moyen
        const encoursMoyen = (ca / 360) * delaiClient;

        // Calcul des coûts
        const commission = (ca * tauxCommission) / 100;
        const retenue = encoursMoyen * (tauxRetenue / 100);
        const financement = ((encoursMoyen - retenue) * tauxFinancement * delaiClient) / 36000;
        const fraisAnnuels = fraisFixe * 12;

        // Coût total
        const coutTotal = commission + financement + fraisAnnuels;

        // Taux effectif global
        const tauxEffectif = (coutTotal / encoursMoyen) * 100;

        // Détails des calculs
        const details = [
            {
                label: 'Encours moyen de créances',
                montant: encoursMoyen,
                description: `Basé sur un délai client de ${delaiClient} jours`,
            },
            {
                label: "Commission d'affacturage",
                montant: commission,
                description: `${tauxCommission}% du chiffre d'affaires`,
            },
            {
                label: 'Retenue de garantie',
                montant: retenue,
                description: `${tauxRetenue}% de l'encours`,
            },
            {
                label: 'Coût du financement',
                montant: financement,
                description: `${tauxFinancement}% sur l'encours financé`,
            },
            {
                label: 'Frais fixes annuels',
                montant: fraisAnnuels,
                description: `${fraisFixe}€ par mois`,
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (tauxEffectif > 5) {
            recommandations.push(
                "Le coût global est élevé, négociez les conditions ou étudiez d'autres solutions de financement",
            );
        }

        if (delaiClient > 60) {
            recommandations.push('Vos délais clients sont longs, mettez en place des actions de recouvrement');
        }

        if (tauxRetenue > 15) {
            recommandations.push('La retenue de garantie est importante, négociez sa réduction');
        }

        if (formData.typeContrat === 'classique' && ca < 1000000) {
            recommandations.push(
                "Votre volume est faible pour un contrat classique, étudiez l'affacturage confidentiel",
            );
        }

        setResults({
            coutTotal,
            coutFinancement: financement,
            coutCommission: commission,
            coutFraisFixe: fraisAnnuels,
            tauxEffectif,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de coût d'affacturage
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.typeContrat}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                typeContrat: e.target.value as 'classique' | 'confidentiel' | 'reverse',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="classique"
                            control={<Radio />}
                            label="Affacturage classique"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="confidentiel"
                            control={<Radio />}
                            label="Affacturage confidentiel"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="reverse"
                            control={<Radio />}
                            label="Reverse factoring"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires annuel (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai client moyen (jours)"
                        type="number"
                        value={formData.delaiClient}
                        onChange={e => setFormData(prev => ({ ...prev, delaiClient: e.target.value }))}
                        placeholder="Ex: 60"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux de commission (%)"
                        type="number"
                        value={formData.tauxCommission}
                        onChange={e => setFormData(prev => ({ ...prev, tauxCommission: e.target.value }))}
                        placeholder="Ex: 0.9"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux de financement (%)"
                        type="number"
                        value={formData.tauxFinancement}
                        onChange={e => setFormData(prev => ({ ...prev, tauxFinancement: e.target.value }))}
                        placeholder="Ex: 3.5"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Taux de retenue de garantie (%)"
                        type="number"
                        value={formData.tauxRetenue}
                        onChange={e => setFormData(prev => ({ ...prev, tauxRetenue: e.target.value }))}
                        placeholder="Ex: 10"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Frais fixes mensuels (€)"
                        type="number"
                        value={formData.fraisFixe}
                        onChange={e => setFormData(prev => ({ ...prev, fraisFixe: e.target.value }))}
                        placeholder="Ex: 150"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateAffacturage}
                    disabled={!formData.chiffreAffaires || !formData.delaiClient}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le coût
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Coût total annuel</span>
                        <span className="value">{Math.round(results.coutTotal).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Taux effectif global</span>
                        <span className="value">{results.tauxEffectif.toFixed(2)}%</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default CoutAffacturage;
