import styled from '@emotion/styled';
import { InputAdornment, SliderOwnProps } from '@mui/material';
import Slider from '@mui/material/Slider';
import React from 'react';

import { colors } from '../../../../../styles/theme';
import SimulatorInputLabel from '../../../../UI/SimulatorInputLabel';
import InputNumber from './Inputs/InputNumber';

const DisplayContent = styled.div`
    display: flex;
    gap: 24px;
`;

const DisplayInput = styled.div`
    min-width: 120px;
`;

const DisplaySlider = styled.div`
    padding-left: 8px;
    width: 100%;
`;

const SliderWithInput = ({
    value,
    setValue,
    params,
    valueLabel,
    label,
    tooltipText,
}: {
    value: number;
    setValue: (value: { [key: string]: number }) => void;
    params: SliderOwnProps;
    valueLabel: string;
    label: string;
    tooltipText: string;
}) => {
    const handleSliderChange = (_event: Event, newValue: number) => {
        setValue({ [valueLabel]: newValue });
    };

    return (
        <div>
            <SimulatorInputLabel text={label} tooltipText={tooltipText} />
            <DisplayContent>
                <DisplaySlider>
                    <Slider
                        min={params.min}
                        max={params.max}
                        step={params.step}
                        value={value}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        sx={{
                            color: colors.$orange,
                            '& .MuiSlider-rail': {
                                color: '#E6D8C2',
                                opacity: 1,
                            },
                        }}
                    />
                </DisplaySlider>

                <DisplayInput>
                    <InputNumber
                        value={value}
                        valueLabel={valueLabel}
                        setValue={setValue}
                        endAdornment={<InputAdornment position="end">€</InputAdornment>}
                        variant="ADORNMENT"
                    />
                </DisplayInput>
            </DisplayContent>
        </div>
    );
};

export default SliderWithInput;
