import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface ScoringData {
    chiffreAffaires: string;
    resultatNet: string;
    capitauxPropres: string;
    dettes: string;
    tresorerie: string;
    creancesClients: string;
    dettesFournisseurs: string;
    stocksMoyens: string;
    valeurAjoutee: string;
}

interface ScoringResults {
    score: number;
    notation: string;
    details: {
        categorie: string;
        score: number;
        ratios: {
            nom: string;
            valeur: number;
            score: number;
            interpretation: string;
        }[];
    }[];
    recommandations: string[];
}

const ScoringFinancier: React.FC = () => {
    const [formData, setFormData] = useState<ScoringData>({
        chiffreAffaires: '',
        resultatNet: '',
        capitauxPropres: '',
        dettes: '',
        tresorerie: '',
        creancesClients: '',
        dettesFournisseurs: '',
        stocksMoyens: '',
        valeurAjoutee: '',
    });

    const [results, setResults] = useState<ScoringResults | null>(null);

    const calculateScoring = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rn = parseFloat(formData.resultatNet) || 0;
        const cp = parseFloat(formData.capitauxPropres) || 0;
        const dettes = parseFloat(formData.dettes) || 0;
        const treso = parseFloat(formData.tresorerie) || 0;
        const clients = parseFloat(formData.creancesClients) || 0;
        const fournisseurs = parseFloat(formData.dettesFournisseurs) || 0;
        const stocks = parseFloat(formData.stocksMoyens) || 0;
        const va = parseFloat(formData.valeurAjoutee) || 0;

        // Calcul des ratios de rentabilité
        const rentabiliteCommerciale = (rn / ca) * 100;
        const rentabiliteFinanciere = (rn / cp) * 100;
        const margeVA = (va / ca) * 100;

        // Calcul des ratios de structure
        const autonomieFinanciere = (cp / (cp + dettes)) * 100;
        const capaciteRemboursement = dettes / (rn > 0 ? rn : 1);
        const couvertureFR = ((treso + clients + stocks - fournisseurs) / ca) * 365;

        // Calcul des ratios de gestion
        const rotationStocks = (stocks * 365) / ca;
        const delaiClients = (clients * 365) / ca;
        const delaiFournisseurs = (fournisseurs * 365) / ca;

        // Scoring par catégorie
        const details = [
            {
                categorie: 'Rentabilité',
                score: 0,
                ratios: [
                    {
                        nom: 'Rentabilité commerciale',
                        valeur: rentabiliteCommerciale,
                        score: scoreRentabiliteCommerciale(rentabiliteCommerciale),
                        interpretation: interpretRentabiliteCommerciale(rentabiliteCommerciale),
                    },
                    {
                        nom: 'Rentabilité financière',
                        valeur: rentabiliteFinanciere,
                        score: scoreRentabiliteFinanciere(rentabiliteFinanciere),
                        interpretation: interpretRentabiliteFinanciere(rentabiliteFinanciere),
                    },
                    {
                        nom: 'Marge sur valeur ajoutée',
                        valeur: margeVA,
                        score: scoreMargeVA(margeVA),
                        interpretation: interpretMargeVA(margeVA),
                    },
                ],
            },
            {
                categorie: 'Structure financière',
                score: 0,
                ratios: [
                    {
                        nom: 'Autonomie financière',
                        valeur: autonomieFinanciere,
                        score: scoreAutonomie(autonomieFinanciere),
                        interpretation: interpretAutonomie(autonomieFinanciere),
                    },
                    {
                        nom: 'Capacité de remboursement',
                        valeur: capaciteRemboursement,
                        score: scoreCapaciteRemboursement(capaciteRemboursement),
                        interpretation: interpretCapaciteRemboursement(capaciteRemboursement),
                    },
                    {
                        nom: 'Couverture du BFR',
                        valeur: couvertureFR,
                        score: scoreCouvertureFR(couvertureFR),
                        interpretation: interpretCouvertureFR(couvertureFR),
                    },
                ],
            },
            {
                categorie: 'Gestion opérationnelle',
                score: 0,
                ratios: [
                    {
                        nom: 'Rotation des stocks',
                        valeur: rotationStocks,
                        score: scoreRotationStocks(rotationStocks),
                        interpretation: interpretRotationStocks(rotationStocks),
                    },
                    {
                        nom: 'Délai clients',
                        valeur: delaiClients,
                        score: scoreDelaiClients(delaiClients),
                        interpretation: interpretDelaiClients(delaiClients),
                    },
                    {
                        nom: 'Délai fournisseurs',
                        valeur: delaiFournisseurs,
                        score: scoreDelaiFournisseurs(delaiFournisseurs),
                        interpretation: interpretDelaiFournisseurs(delaiFournisseurs),
                    },
                ],
            },
        ];

        // Calcul des scores par catégorie
        details.forEach(cat => {
            cat.score = Math.round(cat.ratios.reduce((acc, ratio) => acc + ratio.score, 0) / cat.ratios.length);
        });

        // Score global
        const scoreGlobal = Math.round(details.reduce((acc, cat) => acc + cat.score, 0) / details.length);

        // Notation
        const notation = getNotation(scoreGlobal);

        // Recommandations
        const recommandations = generateRecommandations(details);

        setResults({
            score: scoreGlobal,
            notation,
            details,
            recommandations,
        });
    };

    // Fonctions de scoring (retournent une note sur 100)
    const scoreRentabiliteCommerciale = (ratio: number) => Math.min(100, Math.max(0, ratio * 10));
    const scoreRentabiliteFinanciere = (ratio: number) => Math.min(100, Math.max(0, ratio * 5));
    const scoreMargeVA = (ratio: number) => Math.min(100, Math.max(0, ratio * 2));
    const scoreAutonomie = (ratio: number) => Math.min(100, Math.max(0, ratio));
    const scoreCapaciteRemboursement = (ratio: number) => Math.min(100, Math.max(0, 100 - ratio * 10));
    const scoreCouvertureFR = (ratio: number) => Math.min(100, Math.max(0, 100 - Math.abs(ratio - 60)));
    const scoreRotationStocks = (ratio: number) => Math.min(100, Math.max(0, 100 - Math.abs(ratio - 45)));
    const scoreDelaiClients = (ratio: number) => Math.min(100, Math.max(0, 100 - Math.abs(ratio - 45)));
    const scoreDelaiFournisseurs = (ratio: number) => Math.min(100, Math.max(0, 100 - Math.abs(ratio - 60)));

    // Fonctions d'interprétation
    const interpretRentabiliteCommerciale = (ratio: number) =>
        ratio < 2 ? 'Rentabilité insuffisante' : ratio < 5 ? 'Rentabilité correcte' : 'Bonne rentabilité';
    const interpretRentabiliteFinanciere = (ratio: number) =>
        ratio < 8 ? 'Rendement faible' : ratio < 15 ? 'Rendement correct' : 'Bon rendement';
    const interpretMargeVA = (ratio: number) =>
        ratio < 20 ? 'Marge faible' : ratio < 40 ? 'Marge moyenne' : 'Bonne marge';
    const interpretAutonomie = (ratio: number) =>
        ratio < 30 ? 'Dépendance financière' : ratio < 50 ? 'Autonomie moyenne' : 'Bonne autonomie';
    const interpretCapaciteRemboursement = (ratio: number) =>
        ratio > 5 ? 'Endettement excessif' : ratio > 3 ? 'Endettement élevé' : 'Endettement maîtrisé';
    const interpretCouvertureFR = (ratio: number) => (Math.abs(ratio - 60) > 30 ? 'BFR déséquilibré' : 'BFR maîtrisé');
    const interpretRotationStocks = (ratio: number) =>
        Math.abs(ratio - 45) > 30 ? 'Rotation à optimiser' : 'Bonne rotation';
    const interpretDelaiClients = (ratio: number) => (ratio > 60 ? 'Délais trop longs' : 'Délais corrects');
    const interpretDelaiFournisseurs = (ratio: number) =>
        ratio < 30 ? 'Délais courts' : ratio > 90 ? 'Délais très longs' : 'Délais normaux';

    // Fonction de notation
    const getNotation = (score: number): string => {
        if (score >= 90) return 'AAA - Excellent';
        if (score >= 80) return 'AA - Très bon';
        if (score >= 70) return 'A - Bon';
        if (score >= 60) return 'BBB - Satisfaisant';
        if (score >= 50) return 'BB - Moyen';
        if (score >= 40) return 'B - Fragile';
        if (score >= 30) return 'CCC - Risqué';
        return 'CC - Très risqué';
    };

    // Génération des recommandations
    const generateRecommandations = (
        details: {
            categorie: string;
            score: number;
            ratios: { nom: string; valeur: number; score: number; interpretation: string }[];
        }[],
    ): string[] => {
        const recommandations: string[] = [];

        details.forEach(cat => {
            cat.ratios.forEach(ratio => {
                if (ratio.score < 50) {
                    switch (ratio.nom) {
                        case 'Rentabilité commerciale':
                            recommandations.push('Améliorez vos marges en optimisant vos coûts');
                            break;
                        case 'Rentabilité financière':
                            recommandations.push('Renforcez vos fonds propres ou optimisez leur utilisation');
                            break;
                        case 'Autonomie financière':
                            recommandations.push('Réduisez votre dépendance aux financements externes');
                            break;
                        case 'Capacité de remboursement':
                            recommandations.push(
                                "Restructurez votre dette ou améliorez votre capacité d'autofinancement",
                            );
                            break;
                        case 'Rotation des stocks':
                            recommandations.push('Optimisez votre gestion des stocks');
                            break;
                        case 'Délai clients':
                            recommandations.push('Mettez en place des actions de recouvrement plus efficaces');
                            break;
                        case 'Délai fournisseurs':
                            recommandations.push('Négociez de meilleures conditions avec vos fournisseurs');
                            break;
                    }
                }
            });
        });

        return recommandations;
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Scoring financier
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes financières (€)"
                        type="number"
                        value={formData.dettes}
                        onChange={e => setFormData(prev => ({ ...prev, dettes: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Trésorerie (€)"
                        type="number"
                        value={formData.tresorerie}
                        onChange={e => setFormData(prev => ({ ...prev, tresorerie: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Créances clients (€)"
                        type="number"
                        value={formData.creancesClients}
                        onChange={e => setFormData(prev => ({ ...prev, creancesClients: e.target.value }))}
                        placeholder="Ex: 250000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes fournisseurs (€)"
                        type="number"
                        value={formData.dettesFournisseurs}
                        onChange={e => setFormData(prev => ({ ...prev, dettesFournisseurs: e.target.value }))}
                        placeholder="Ex: 150000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stocks moyens (€)"
                        type="number"
                        value={formData.stocksMoyens}
                        onChange={e => setFormData(prev => ({ ...prev, stocksMoyens: e.target.value }))}
                        placeholder="Ex: 400000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Valeur ajoutée (€)"
                        type="number"
                        value={formData.valeurAjoutee}
                        onChange={e => setFormData(prev => ({ ...prev, valeurAjoutee: e.target.value }))}
                        placeholder="Ex: 600000"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateScoring}
                    disabled={!formData.chiffreAffaires || !formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le scoring
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    <div className="result-row highlight">
                        <span className="label">Score global</span>
                        <span className="value">{results.score}/100</span>
                    </div>

                    <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                        <span className="label">Notation</span>
                        <span className="value">{results.notation}</span>
                    </div>

                    {results.details.map((categorie, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label">{categorie.categorie}</span>
                                <span className="value">{categorie.score}/100</span>
                            </div>
                            {categorie.ratios.map((ratio, rIndex) => (
                                <div key={rIndex} className="result-row">
                                    <span className="label">{ratio.nom}</span>
                                    <span className="value">
                                        {ratio.valeur.toFixed(1)}
                                        {ratio.nom.includes('Délai') || ratio.nom.includes('Rotation') ? ' jours' : '%'}
                                    </span>
                                </div>
                            ))}
                        </React.Fragment>
                    ))}

                    <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                        <span className="label">Recommandations</span>
                        {results.recommandations.map((recommandation, index) => (
                            <p key={index} style={{ margin: 0, color: '#666' }}>
                                • {recommandation}
                            </p>
                        ))}
                    </div>
                </Paper>
            )}
        </div>
    );
};

export default ScoringFinancier;
