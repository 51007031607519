import 'swiper/css';
import 'swiper/css/pagination';

import { graphql, Link } from 'gatsby';
import * as React from 'react';

import MiniCardIcon from '../components/card/MiniCardIcon';
import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import PostHightlight from '../components/media/PostHighlight';
import PostThumb from '../components/media/PostThumb';
import PostWithNewsetter from '../components/media/PostWithNewsetter';
import MoreTags from '../components/MoreTags';
import SectionHighlightPosts from '../components/section/SectionHighlightPosts';
import Seo from '../components/seo/Seo';
import { SeoTitle } from '../components/seo/seoTitle';
import { ROUTES } from '../constants/const/routes.const';
import { seoFragments } from '../seo';

export const getExcerptContent = (data: Queries.PrismicPostData) => {
    return data
        ? data.body.reduce((accc, cv) => {
              if ('primary' in cv) {
                  if (!cv.primary) return accc;
                  if ('content' in cv.primary) {
                      return accc + cv.primary.content.text;
                  }
                  if ('content_text' in cv.primary) {
                      return accc + cv.primary.content_text.text;
                  }
              }
              return accc;
          }, '')
        : '';
};

const MediaPage = ({
    data,
}: {
    data: {
        allPrismicTag: { nodes: Queries.PrismicTag[] };
        allPrismicCategory: { nodes: Queries.PrismicCategory[] };
        allPrismicPost: { nodes: Queries.PrismicPost[] };
    };
}) => {
    const categories = data.allPrismicCategory.nodes;
    const tags = data.allPrismicTag.nodes;
    const firstPost = data.allPrismicPost.nodes[0];
    const secondPosts = data.allPrismicPost.nodes.slice(1, 7);
    const firstPostContent: string = getExcerptContent(firstPost?.data);
    const firstPostExcerpt: string = '<p>'.concat(firstPostContent.slice(0, 500).concat('...</p>'));

    return (
        <Layout hasHeaderMedia>
            <main>
                <TopPaddingBasedOnHeader padding={{ desktop: 30 }} />
                <div className="container">
                    <h1 className="u-hidden">Le blog qui aide les entrepreneurs à réussir</h1>
                    <section>
                        <PostHightlight
                            post={{
                                ...firstPost,
                                img: firstPost.data.cover.gatsbyImageData,
                                excerpt: firstPostExcerpt,
                            }}
                        />
                    </section>
                    <section className="section m-no-margin">
                        <PostWithNewsetter />
                    </section>
                    <section className="section m-no-margin">
                        <div className="row thumb-list">
                            {secondPosts.map((post, index) => (
                                <div className="col-md-4 col-sm-6" key={index}>
                                    <PostThumb
                                        index={index + 1}
                                        key={post.id}
                                        post={{
                                            ...post,
                                            img: post.data.cover.gatsbyImageData,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="media-home__blog-link">
                            <Link
                                to={ROUTES.BLOG.uri}
                                data-ga4="container-level1-button-generic-click-link"
                                className="Button m-outline"
                            >
                                Voir tous les contenus
                            </Link>
                        </div>
                    </section>
                </div>
                <SectionHighlightPosts />
                <div className="container">
                    <section className="section m-margin">
                        <div>
                            <SeoTitle seoStyle="h3" className="pt-2 media-title-center">
                                Dougs c'est aussi ...
                            </SeoTitle>
                        </div>
                        <div className="row pb-3">
                            <div className="u-pattern">
                                <img src="/images/blog/line-blog.svg" alt="" />
                            </div>
                            <div className="col-md-3 col-sm-6 pl-2">
                                <MiniCardIcon
                                    data-ga4="container-level3-card1-toptitle-click-link"
                                    card={{
                                        text: 'Pour tout comprendre de l’entrepreneuriat en quelques minutes',
                                        icn: '/images/blog/videos.svg',
                                        title: 'Des videos',
                                        btnLink: '/videos',
                                        btnText: 'Découvrir les vidéos',
                                    }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 pl-2">
                                <MiniCardIcon
                                    data-ga4="container-level3-card2-toptitle-click-link"
                                    card={{
                                        text: 'Pour découvrir des témoignages des utilisateurs de Dougs !',
                                        icn: '/images/blog/user-regular.svg',
                                        title: 'Des cas clients',
                                        btnLink: '/cas-client',
                                        btnText: 'Lire les cas clients',
                                    }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6 pl-2">
                                <MiniCardIcon
                                    data-ga4="container-level3-card3-toptitle-click-link"
                                    card={{
                                        text: 'Pour poser vos questions en direct à nos experts.',
                                        icn: '/images/blog/webinar.svg',
                                        title: 'Des webinars',
                                        btnLink: '/webinars',
                                        btnText: 'Accéder aux webinars',
                                    }}
                                />
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <MiniCardIcon
                                    data-ga4="container-level3-card4-toptitle-click-link"
                                    card={{
                                        text: 'Pour avancer dans la bonne direction sans se tromper !',
                                        icn: '/images/blog/guides.svg',
                                        title: 'Des guides',
                                        btnLink: '/guides',
                                        btnText: 'Suivre les guides',
                                    }}
                                />
                            </div>
                        </div>
                    </section>
                    <MoreTags tags={tags} level={4} />
                    <section className="pt-1 mb-3">
                        <div className="text-bordered__wrapper">
                            <SeoTitle seoStyle="h4" className="text-bordered">
                                Nos Dossiers
                            </SeoTitle>
                            <div className="row py-3">
                                {categories.map((category, index) => (
                                    <div className="col-md-3 col-sm-6" key={index}>
                                        <div className={`category-thumb category-thumb--${category.data.color}`}>
                                            <SeoTitle as={'h2'} seoStyle={'h5'}>
                                                <Link
                                                    data-ga4={`container-level5-miniature${
                                                        index + 1
                                                    }-text-toptitle-click-link`}
                                                    className="category-thumb__title"
                                                    to={category.uri}
                                                >
                                                    {category.data.title}
                                                </Link>
                                            </SeoTitle>
                                            <p className="m-small mt-1">{category.data.short_description?.text}</p>
                                            <Link
                                                className="link-underline"
                                                data-ga4={`container-level5-miniature${
                                                    index + 1
                                                }-text-seemore-click-link`}
                                                to={category.uri}
                                            >
                                                Voir les articles
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </Layout>
    );
};

export default MediaPage;

export const Head = () => {
    return <Seo seo={seoFragments.media} title={'Media - Dougs Compta'} />;
};

export const query = graphql`
    #graphql
    fragment PrismicPostItem on PrismicPost {
        id
        uid
        first_publication_date
        uri
        _previewable
        data {
            meta_description
            published_date
            titre
            cover {
                alt
                gatsbyImageData
            }
            category {
                document {
                    ... on PrismicCategory {
                        id
                        uri
                        data {
                            title
                            slug
                            color
                        }
                    }
                }
            }
            labels {
                tag {
                    document {
                        ... on PrismicTag {
                            id
                            uri
                            data {
                                name
                                slug
                            }
                        }
                    }
                }
            }
            body {
                ...PostQuote
                ...PostImage
                ...PostOverline
                ...PostNormalText
                ...PostLineHorizontal
                ...PostButton
                ...PostCard
                ...PostGuide
                ...PostTVA
                ...PostWordpress
            }
            author {
                document {
                    ...PostAuthor
                }
            }
        }
    }
`;

export const pageQuery = graphql`
    query MediaTemplate {
        allPrismicCategory(sort: { data: { title: ASC } }) {
            nodes {
                uri
                data {
                    color
                    short_description {
                        text
                    }
                    title
                    slug
                }
            }
        }
        allPrismicPost(
            filter: { data: { indexed: { eq: true } } }
            sort: { data: { published_date: DESC } }
            limit: 8
        ) {
            nodes {
                ...PrismicPostItem
            }
        }
        allPrismicTag(filter: { data: { upfront: { eq: true } } }, limit: 6) {
            nodes {
                ...PostTag
            }
        }
    }
`;
