import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface CoutData {
    achatsMatieres: string;
    mainOeuvreDirecte: string;
    fraisProduction: string;
    fraisGeneraux: string;
    quantiteProduite: string;
    typeCalcul: 'complet' | 'partiel';
    margeCommerciale: string;
}

interface CoutResults {
    coutProduction: number;
    coutRevient: number;
    coutUnitaire: number;
    prixVenteHT: number;
    margeUnitaire: number;
    tauxMarge: number;
    details: {
        label: string;
        montant: number;
        pourcentage: number;
        description: string;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CoutRevient: React.FC = () => {
    const [formData, setFormData] = useState<CoutData>({
        achatsMatieres: '',
        mainOeuvreDirecte: '',
        fraisProduction: '',
        fraisGeneraux: '',
        quantiteProduite: '',
        typeCalcul: 'complet',
        margeCommerciale: '30',
    });

    const [results, setResults] = useState<CoutResults | null>(null);

    const { handleOpen, logged } = useSimulatorContext();

    const calculateCout = () => {
        const achatsMatieres = parseFloat(formData.achatsMatieres) || 0;
        const mainOeuvreDirecte = parseFloat(formData.mainOeuvreDirecte) || 0;
        const fraisProduction = parseFloat(formData.fraisProduction) || 0;
        const fraisGeneraux = parseFloat(formData.fraisGeneraux) || 0;
        const quantiteProduite = parseFloat(formData.quantiteProduite) || 1;
        const margeCommerciale = parseFloat(formData.margeCommerciale) / 100;

        // Calcul des coûts directs
        const coutsDirects = achatsMatieres + mainOeuvreDirecte;

        // Calcul du coût de production
        const coutProduction = coutsDirects + fraisProduction;

        // Calcul du coût de revient selon la méthode
        const coutRevient = formData.typeCalcul === 'complet' ? coutProduction + fraisGeneraux : coutProduction;

        // Calcul des coûts unitaires
        const coutUnitaire = coutRevient / quantiteProduite;

        // Calcul du prix de vente et de la marge
        const prixVenteHT = coutUnitaire / (1 - margeCommerciale);
        const margeUnitaire = prixVenteHT - coutUnitaire;
        const tauxMarge = (margeUnitaire / prixVenteHT) * 100;

        // Détail des coûts
        const details = [
            {
                label: 'Achats de matières',
                montant: achatsMatieres,
                pourcentage: (achatsMatieres / coutRevient) * 100,
                description: 'Coûts directs des matières premières',
            },
            {
                label: "Main d'œuvre directe",
                montant: mainOeuvreDirecte,
                pourcentage: (mainOeuvreDirecte / coutRevient) * 100,
                description: "Coûts directs de la main d'œuvre",
            },
            {
                label: 'Frais de production',
                montant: fraisProduction,
                pourcentage: (fraisProduction / coutRevient) * 100,
                description: 'Frais indirects de production',
            },
        ];

        if (formData.typeCalcul === 'complet') {
            details.push({
                label: 'Frais généraux',
                montant: fraisGeneraux,
                pourcentage: (fraisGeneraux / coutRevient) * 100,
                description: "Frais de structure et d'administration",
            });
        }

        // Recommandations
        const recommandations = [];

        if (achatsMatieres / coutRevient > 0.6) {
            recommandations.push('Le coût des matières est très élevé, négociez avec vos fournisseurs');
        }

        if (mainOeuvreDirecte / coutRevient > 0.5) {
            recommandations.push("Optimisez vos processus de production pour réduire les coûts de main d'œuvre");
        }

        if (tauxMarge < 20) {
            recommandations.push("Votre marge est faible, envisagez d'augmenter vos prix ou de réduire vos coûts");
        }

        if (quantiteProduite < 100) {
            recommandations.push('Augmentez votre volume de production pour réduire les coûts unitaires');
        }

        setResults({
            coutProduction,
            coutRevient,
            coutUnitaire,
            prixVenteHT,
            margeUnitaire,
            tauxMarge,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculateCout();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'complet', label: 'Coût complet' },
        { value: 'partiel', label: 'Coût partiel' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de coût de revient" />

                <RadioButtonGroup
                    label="Type de calcul"
                    options={radioOptions}
                    value={formData.typeCalcul}
                    onChange={value => setFormData(prev => ({ ...prev, typeCalcul: value as 'complet' | 'partiel' }))}
                    direction="row"
                />

                <InputNumber
                    label="Achats de matières"
                    value={Number(formData.achatsMatieres) || null}
                    setValue={value => setFormData(prev => ({ ...prev, achatsMatieres: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 10000"
                />

                <InputNumber
                    label="Main d'œuvre directe"
                    value={Number(formData.mainOeuvreDirecte) || null}
                    setValue={value => setFormData(prev => ({ ...prev, mainOeuvreDirecte: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 15000"
                />

                <InputNumber
                    label="Frais de production"
                    value={Number(formData.fraisProduction) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisProduction: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5000"
                />

                {formData.typeCalcul === 'complet' && (
                    <InputNumber
                        label="Frais généraux"
                        value={Number(formData.fraisGeneraux) || null}
                        setValue={value => setFormData(prev => ({ ...prev, fraisGeneraux: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 8000"
                    />
                )}

                <InputNumber
                    label="Quantité produite"
                    value={Number(formData.quantiteProduite) || null}
                    setValue={value => setFormData(prev => ({ ...prev, quantiteProduite: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                <InputNumber
                    label="Marge commerciale"
                    value={Number(formData.margeCommerciale) || null}
                    setValue={value => setFormData(prev => ({ ...prev, margeCommerciale: String(value) }))}
                    endAdornment={<>%</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 30"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.achatsMatieres || !formData.quantiteProduite}
                >
                    Calculer le coût de revient
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Coût de revient total',
                                value: `${Math.round(results.coutRevient).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Coût unitaire',
                                value: `${results.coutUnitaire.toFixed(2)} €`,
                            },
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: `${Math.round(detail.montant).toLocaleString('fr-FR')} € (${detail.pourcentage.toFixed(1)}%)`,
                                description: detail.description,
                            })),
                            {
                                label: 'Prix de vente HT conseillé',
                                value: `${results.prixVenteHT.toFixed(2)} €`,
                            },
                            {
                                label: 'Marge unitaire',
                                value: `${results.margeUnitaire.toFixed(2)} € (${results.tauxMarge.toFixed(1)}%)`,
                            },
                            ...(results.recommandations.length > 0
                                ? [
                                      {
                                          description: 'Recommandations',
                                          label: results.recommandations,
                                          value: '',
                                      },
                                  ]
                                : []),
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CoutRevient;
