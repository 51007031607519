import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React, { useState } from 'react';

import { colors, fonts } from '../../../../../../styles/theme';

const DropLink = styled(Link)`
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 238px;
`;

const DropAnchorLink = styled.a`
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 238px;
`;

const ImageWrapper = styled.div<{ isHover: boolean }>`
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;

    background-color: rgba(1, 58, 81, 0.05);

    border-radius: ${props => (props.isHover ? '8px 0 0 8px' : '8px')};

    img {
        width: 20px;
        height: 20px;
    }
`;

const IconWrapper = styled.div<{ isHover: boolean }>`
    display: flex;
    font-size: 24px;
    font-weight: 400;
    align-items: center;
    justify-content: center;

    background-color: ${props => (props.isHover ? 'rgba(1, 58, 81, 0.05)' : 'transparent')};

    border-radius: ${props => (props.isHover ? '8px 0 0 8px' : '8px')};
`;

const Title = styled.span<{ isHover: boolean }>`
    font-family: ${fonts.OpenSans_SemiBold};
    font-size: 13px;

    width: 100%;

    padding: 8px;
    border-radius: 0 8px 8px 0;
    background-color: ${props => (props.isHover ? 'rgba(1, 58, 81, 0.05)' : 'transparent')};
    height: 40px;

    display: flex;
    align-items: center;

    color: ${colors.$primary700};
    white-space: nowrap;
`;

const Wrapper = styled.div`
    width: 100%;
`;

const DropdownLink = ({
    title,
    trackingId,
    icon,
    url,
    variant,
}: {
    title: string;
    trackingId: string;
    icon: string;
    url: string;
    variant: string;
}) => {
    const [isHover, setIsHover] = useState(false);

    if (url.charAt(0) !== '/') {
        return (
            <Wrapper>
                <DropAnchorLink
                    href={url}
                    data-ga-send={`event,button,click,${trackingId}`}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    {variant === 'primary' ? (
                        <ImageWrapper isHover={isHover}>
                            <img alt={`farfa-${icon} icon`} src={`/images/header/icons/farfa-${icon}.svg`} />
                        </ImageWrapper>
                    ) : (
                        <IconWrapper isHover={isHover}>{icon}</IconWrapper>
                    )}
                    <Title isHover={isHover}>{title}</Title>
                </DropAnchorLink>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <DropLink
                to={url}
                data-ga-send={`event,button,click,${trackingId}`}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {variant === 'primary' ? (
                    <ImageWrapper isHover={isHover}>
                        <img alt={`farfa-${icon} icon`} src={`/images/header/icons/farfa-${icon}.svg`} />
                    </ImageWrapper>
                ) : (
                    <IconWrapper isHover={isHover}>{icon}</IconWrapper>
                )}
                <Title isHover={isHover}>{title}</Title>
            </DropLink>
        </Wrapper>
    );
};

export default DropdownLink;
