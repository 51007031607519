import { Button, FormControl, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface BfrData {
    stockMoyen: string;
    delaiClientJours: string;
    delaiFournisseurJours: string;
    caAnnuel: string;
    achatsAnnuels: string;
}

interface BfrResults {
    bfr: number;
    bfrExploitation: number;
    bfrHorsExploitation: number;
    tresorerie: number;
    details: {
        label: string;
        montant: number;
        description: string;
    }[];
    recommandations: string[];
}

const BesoinFondsRoulement: React.FC = () => {
    const [formData, setFormData] = useState<BfrData>({
        stockMoyen: '',
        delaiClientJours: '',
        delaiFournisseurJours: '',
        caAnnuel: '',
        achatsAnnuels: '',
    });

    const [results, setResults] = useState<BfrResults | null>(null);

    const calculateBFR = () => {
        const stockMoyen = parseFloat(formData.stockMoyen) || 0;
        const delaiClient = parseFloat(formData.delaiClientJours) || 0;
        const delaiFournisseur = parseFloat(formData.delaiFournisseurJours) || 0;
        const ca = parseFloat(formData.caAnnuel) || 0;
        const achats = parseFloat(formData.achatsAnnuels) || 0;

        // Calcul des composantes du BFR
        const creancesClients = (ca * delaiClient) / 365;
        const dettesFournisseurs = (achats * delaiFournisseur) / 365;

        // Calcul du BFR d'exploitation
        const bfrExploitation = stockMoyen + creancesClients - dettesFournisseurs;

        // Estimation du BFR hors exploitation (15% du BFR d'exploitation)
        const bfrHorsExploitation = bfrExploitation * 0.15;

        // BFR total
        const bfrTotal = bfrExploitation + bfrHorsExploitation;

        // Estimation de la trésorerie nécessaire (20% du BFR total)
        const tresorerie = bfrTotal * 0.2;

        // Analyse des ratios
        const rotationStock = (stockMoyen * 365) / achats;
        const creditClient = delaiClient;
        const creditFournisseur = delaiFournisseur;

        // Génération des recommandations
        const recommandations = [];
        if (rotationStock > 60) {
            recommandations.push("Le stock est élevé. Envisagez d'optimiser votre gestion des stocks.");
        }
        if (creditClient > 60) {
            recommandations.push('Les délais clients sont longs. Mettez en place des actions de recouvrement.');
        }
        if (creditFournisseur < 45) {
            recommandations.push('Négociez de meilleurs délais avec vos fournisseurs.');
        }
        if (bfrTotal > ca * 0.25) {
            recommandations.push("Votre BFR est élevé par rapport à votre CA. Optimisez votre cycle d'exploitation.");
        }

        const details = [
            {
                label: 'Stock moyen',
                montant: stockMoyen,
                description: `${Math.round(rotationStock)} jours de rotation`,
            },
            {
                label: 'Créances clients',
                montant: creancesClients,
                description: `${delaiClient} jours de délai client`,
            },
            {
                label: 'Dettes fournisseurs',
                montant: dettesFournisseurs,
                description: `${delaiFournisseur} jours de délai fournisseur`,
            },
            {
                label: "BFR d'exploitation",
                montant: bfrExploitation,
                description: 'Stock + Créances - Dettes',
            },
            {
                label: 'BFR hors exploitation',
                montant: bfrHorsExploitation,
                description: 'Estimation des autres besoins',
            },
        ];

        setResults({
            bfr: bfrTotal,
            bfrExploitation,
            bfrHorsExploitation,
            tresorerie,
            details,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Calculateur de BFR
                </Typography>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires annuel (€)"
                        type="number"
                        value={formData.caAnnuel}
                        onChange={e => setFormData(prev => ({ ...prev, caAnnuel: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Achats annuels (€)"
                        type="number"
                        value={formData.achatsAnnuels}
                        onChange={e => setFormData(prev => ({ ...prev, achatsAnnuels: e.target.value }))}
                        placeholder="Ex: 600000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Stock moyen (€)"
                        type="number"
                        value={formData.stockMoyen}
                        onChange={e => setFormData(prev => ({ ...prev, stockMoyen: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai client (jours)"
                        type="number"
                        value={formData.delaiClientJours}
                        onChange={e => setFormData(prev => ({ ...prev, delaiClientJours: e.target.value }))}
                        placeholder="Ex: 60"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Délai fournisseur (jours)"
                        type="number"
                        value={formData.delaiFournisseurJours}
                        onChange={e => setFormData(prev => ({ ...prev, delaiFournisseurJours: e.target.value }))}
                        placeholder="Ex: 45"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateBFR}
                    disabled={!formData.caAnnuel || !formData.achatsAnnuels}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Calculer le BFR
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.details.map((detail, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{detail.label}</span>
                                <span className="value">{Math.round(detail.montant).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {detail.description}
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">BFR Total</span>
                        <span className="value">{Math.round(results.bfr).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Trésorerie minimale recommandée</span>
                        <span className="value">{Math.round(results.tresorerie).toLocaleString('fr-FR')} €</span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default BesoinFondsRoulement;
