import styled from '@emotion/styled';
import { graphql, Script } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import StickyFooter from '../components/layout/footer/StickyFooter';
import Sticky from '../components/layout/header/utils/Sticky';
import TopPaddingBasedOnHeader from '../components/layout/header/utils/TopPaddingBasedOnHeader';
import Layout from '../components/layout/Layout';
import LegalTerm from '../components/LegalTerm';
import PostAuthorBlock from '../components/Post/PostAuthorBlock';
import PostHighlight from '../components/Post/PostHighlight/PostHighlight';
import RelatedPosts from '../components/Post/RelatedPosts';
import { Summary } from '../components/prismic/slices/Summary';
import { SliceZone } from '../components/prismic/SliceZone';
import { getSummaryItems, isWordpresscontent } from '../components/prismic/utils';
import Seo from '../components/seo/Seo';
import ArrowButton from '../components/UI/Button/ArrowButton';
import Divider from '../components/UI/Divider';
import { Wrapper } from '../components/UI/WrapperV2';
import { breakpoint, colors } from '../styles/theme';

export type ctaTagsType = { tags: string[]; url: string; cta_text: { html: string } };

const Body = styled.div<{ haveRelatedPost: boolean }>`
    display: flex;
    gap: 80px;
    margin-bottom: ${props => (props.haveRelatedPost ? '0px' : '80px')};
`;

const LeftSide = styled.aside`
    width: 320px;
    display: none;

    ${breakpoint.laptop} {
        display: block;
    }
`;

const RightSide = styled.section<{ withCTA: boolean }>`
    width: 320px;
    flex: 1;
    padding-top: ${props => (props.withCTA ? '28px' : '0')};

    p.block-img {
        img {
            width: 100%;
        }
    }
    p {
        img {
            width: 100%;
        }
    }

    ul {
        padding-left: 20px;
    }

    li {
        font-size: 16px;
        line-height: 170%;
        color: ${colors.$primary700};
        margin-bottom: 16px;

        ${breakpoint.laptop} {
            font-size: 18px;
        }
    }

    .faq-row-wrapper {
        margin-top: 0;
    }

    ol li {
        margin-left: 18px;
    }
`;

const ButtonWrapper = styled.div`
    display: none;
    ${breakpoint.laptop} {
        display: block;
    }
`;

const Post = ({
    data,
    pageContext,
}: {
    data: {
        prismicPost: Queries.PrismicPost;
        posts?: { nodes: Queries.PrismicPost[] };
    };
    pageContext: {
        id: string;
        siteUrl: string;
        legalTerm?: Queries.PrismicLegalTermDataBodyLegalTermPrimary;
        ctaTag?: ctaTagsType;
    };
}) => {
    const post = data.prismicPost;
    const image = getImage(post.data.cover.gatsbyImageData) as IGatsbyImageData;

    const author = post.data.author?.document as Queries.PrismicAuteur;
    const body = [...post.data.body];
    const summaryItems = getSummaryItems(body);

    const faq = body.filter(slice => slice.id && slice.id.includes('faq$'))[0] as Queries.PrismicPostDataBodyFaq;

    const structuredDataPost = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: post.data.titre,
        author: {
            '@type': 'Person',
            name: author?.data.name,
        },
        datePublished: post.data.published_date,
        dateModified: post.last_publication_date,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `https://www.dougs.fr${post.uri}`,
        },
        publisher: {
            '@type': 'Organization',
            name: 'Dougs',
            logo: {
                '@type': 'ImageObject',
                url: 'https://www.dougs.fr/images/header/logo-dougs.svg',
            },
        },
        image: {
            '@type': 'ImageObject',
            url: image.images.fallback.src,
            height: image.height,
            width: image.width,
        },

        description: post.data.meta_description,
        articleBody: post.data.body
            .filter(e => e.primary?.content_text?.text)
            .reduce((c, e) => c + e.primary.content_text.text, ''),
    };

    const structuredDataSummary = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        name: "Sommaire de l'article",
        itemListOrder: 'http://schema.org/ItemListOrderAscending',
        itemListElement: summaryItems.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.label,
            url: `https://www.dougs.fr${post.uri}#${item.slug}`,
        })),
    };

    const structuredDataAutor = {
        '@context': 'https://schema.org',
        '@type': 'Person',
        name: author?.data.name,
        url: `https://www.dougs.fr${author.uri}`,
        image: author.data.avatar.url,
        jobTitle: author.data.job,
        worksFor: {
            '@type': 'Organization',
            name: 'Dougs',
            url: 'https://www.dougs.fr',
        },
        sameAs: [author.data.facebookUrl, author.data.twitterUrl, author.data.linkedinUrl],
    };

    const structuredDataFaq = faq
        ? {
              '@context': 'https://schema.org',
              '@type': 'FAQPage',
              mainEntity: faq.items.map(item => ({
                  '@type': 'Question',
                  name: item.question,
                  acceptedAnswer: {
                      '@type': 'Answer',
                      text: item.answer.text,
                  },
              })),
          }
        : null;

    const relatedPosts = data.prismicPost.data.related_posts.map(post => {
        if (!post.post) return null;
        return {
            title: post.post?.document?.data.titre,
            img: post.post?.document?.data.cover.gatsbyImageData,
            uri: post.post?.document?.uri,
        };
    });

    return (
        <Layout
            pageContext={pageContext}
            seo={{ titleText: post.data.titre, metaDesc: post.data.meta_description }}
            hasHeaderMedia
        >
            {post.data.cover && (
                <>
                    <Script type="application/ld+json">{JSON.stringify(structuredDataPost)}</Script>
                    <Script type="application/ld+json">{JSON.stringify(structuredDataSummary)}</Script>
                    <Script type="application/ld+json">{JSON.stringify(structuredDataAutor)}</Script>
                    {!!structuredDataFaq && (
                        <Script type="application/ld+json">{JSON.stringify(structuredDataFaq)}</Script>
                    )}
                </>
            )}
            <main>
                <article role="article" data-post-id={post.prismicId}>
                    <TopPaddingBasedOnHeader padding={{ desktop: 0, tablet: 0, mobile: 0 }} />

                    <Wrapper>
                        <header>
                            <PostHighlight post={post} />
                        </header>

                        <Body haveRelatedPost={!!relatedPosts.filter(e => e).length}>
                            <LeftSide>
                                <Sticky as="div" topPadding={24}>
                                    <Summary items={summaryItems} disable={isWordpresscontent(body)} />
                                </Sticky>
                            </LeftSide>
                            <RightSide withCTA={!!pageContext.ctaTag}>
                                {pageContext.ctaTag && (
                                    <ButtonWrapper>
                                        <ArrowButton
                                            actionType="INTERNAL_LINK"
                                            trackingId={pageContext.ctaTag.tags[0]}
                                            fullWidth="DISABLED"
                                            color="PRIMARY"
                                            linkOptions={{
                                                href: pageContext.ctaTag.url,
                                            }}
                                        >
                                            {pageContext.ctaTag.cta_text.html.replace(/<\/?p>/g, '')}
                                        </ArrowButton>
                                    </ButtonWrapper>
                                )}
                                {pageContext.legalTerm && <LegalTerm legalTerm={pageContext.legalTerm} />}
                                <SliceZone
                                    sliceZone={body as Queries.PrismicPostDataBody[]}
                                    noChecklist={post.data.no_checklist}
                                    image={image}
                                    summaryItems={summaryItems}
                                />
                                <Divider marginY={48} />
                                <PostAuthorBlock author={post.data.author?.document} />
                            </RightSide>
                        </Body>
                        <RelatedPosts posts={relatedPosts} />
                    </Wrapper>
                </article>
            </main>

            <StickyFooter ctaTag={pageContext.ctaTag} />
        </Layout>
    );
};

export default withPrismicPreview(Post);

export const Head = ({ data }: { data: { prismicPost: Queries.PrismicPost } }) => {
    const post = data.prismicPost;

    return (
        <Seo seo={{ titleText: post.data.titre, metaDesc: post.data.meta_description }}>
            {post.data.cover && (
                <>
                    <meta property="og:image" content={post.data.cover.url} />
                    <meta name="twitter:image" content={post.data.cover.url} />
                </>
            )}
        </Seo>
    );
};

export const postQuery = graphql`
    query ($databaseId: ID) {
        prismicPost(prismicId: { eq: $databaseId }) {
            _previewable
            id
            prismicId
            first_publication_date(formatString: "yyyy-MM-DD")
            last_publication_date(formatString: "yyyy-MM-DD")
            uri
            data {
                meta_description
                titre
                published_date
                no_checklist
                cover {
                    alt
                    gatsbyImageData
                    url
                }
                category {
                    document {
                        ...PostCategory
                    }
                }
                labels {
                    tag {
                        document {
                            ...PostTag
                        }
                    }
                }
                related_posts {
                    post {
                        document {
                            ...PostPrismic
                        }
                    }
                }
                body {
                    ...PostQuote
                    ...PostImage
                    ...PostOverline
                    ...PostNormalText
                    ...PostLineHorizontal
                    ...PostButton
                    ...PostCard
                    ...PostGuide
                    ...PostTVA
                    ...PostDownload
                    ...PostWordpress
                    ...PostSummary
                    ...PostFaq
                    ...PostNewsletter
                    ...PostHighlight
                    ...PostDownloadRessource
                    ...PostSectionsimulator
                    ...PostOutilsSimulation
                }
                author {
                    document {
                        ...PostAuthor
                    }
                }
            }
        }
    }
`;

export const postTagFragment = graphql`
    fragment PostTag on PrismicTag {
        id
        uri
        _previewable
        data {
            name
            slug
            supertag
            meta_title
            meta_description
            cta_image {
                alt
                gatsbyImageData
                url
            }
            cta_link
            cta_text
            cta_title
            description {
                html
                text
            }
            faq {
                question
                answer {
                    html
                }
            }
            linked_tag {
                label {
                    document {
                        ... on PrismicTag {
                            id
                            uri
                            data {
                                name
                                slug
                            }
                        }
                    }
                }
            }
            highlighted_posts {
                post {
                    document {
                        ...PrismicPostItem
                    }
                }
            }
            body {
                ...TagGuides
                ...TagWebinars
                ...TagMiseEnAvant
                ...TagVideos
            }
        }
    }
`;

export const postCategoryFragment = graphql`
    fragment PostCategory on PrismicCategory {
        id
        uri
        _previewable
        data {
            title
            slug
            color
            description {
                html
            }
        }
    }
`;

export const postContentFragment = graphql`
    fragment PostPrismic on PrismicPost {
        id
        uid
        uri
        last_publication_date
        _previewable
        data {
            author {
                document {
                    ...PostAuthor
                }
            }
            body {
                ...PostQuote
                ...PostOverline
                ...PostNormalText
                ...PostLineHorizontal
                ...PostButton
                ...PostCard
                ...PostGuide
                ...PostWordpress
                ...PostSummary
                ...PostFaq
            }
            titre
            published_date
            cover {
                gatsbyImageData(width: 600)
            }
            labels {
                tag {
                    document {
                        ...PostTag
                    }
                }
            }
        }
    }
`;
