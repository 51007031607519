import styled from '@emotion/styled';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';

import useModal from '../../../hooks/useModal';
import { breakpoint } from '../../../styles/theme';
import ImageModal from '../../modals/ImageModal';

const FullWidthImage = styled(GatsbyImage)`
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: 184px;

    ${breakpoint.laptop} {
        height: 382px;
    }
`;

const ZoomableImage = ({ image, alt }: GatsbyImageProps) => {
    const { isModalOpen, openModal, closeModal } = useModal();
    return (
        <>
            <div onClick={openModal}>
                <FullWidthImage rel="preload" fetchPriority="high" loading="eager" image={image} alt={alt} />
            </div>
            <ImageModal isOpen={isModalOpen} close={closeModal} src={image.images.fallback.src} alt={alt} />
        </>
    );
};

export default ZoomableImage;
