import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorAlert from '../components/SimulatorAlert';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface EmbaucheData {
    salaireBrut: string;
    statut: 'cadre' | 'non-cadre' | 'apprenti';
    avantages: {
        mutuelle: boolean;
        ticketsRestaurant: boolean;
        transport: boolean;
    };
    duree: 'cdi' | 'cdd';
    tempsTravail: 'complet' | 'partiel';
}

interface EmbaucheResults {
    chargesPatronales: number;
    chargesSalariales: number;
    avantagesEnNature: number;
    coutTotalAnnuel: number;
    coutTotalMensuel: number;
    salaireNetMensuel: number;
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CoutEmbauche: React.FC = () => {
    const [formData, setFormData] = useState<EmbaucheData>({
        salaireBrut: '',
        statut: 'non-cadre',
        avantages: {
            mutuelle: false,
            ticketsRestaurant: false,
            transport: false,
        },
        duree: 'cdi',
        tempsTravail: 'complet',
    });

    const [results, setResults] = useState<EmbaucheResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateCout = () => {
        const salaireBrut = parseFloat(formData.salaireBrut) || 0;

        // Taux de charges selon le statut
        const tauxChargesPatronales = formData.statut === 'cadre' ? 0.45 : 0.42;
        const tauxChargesSalariales = 0.22;

        // Calculs principaux
        const chargesPatronales = salaireBrut * 12 * tauxChargesPatronales;
        const chargesSalariales = salaireBrut * 12 * tauxChargesSalariales;
        const coutTotalAnnuel = salaireBrut * 12 + chargesPatronales;
        const salaireNetMensuel = salaireBrut * (1 - tauxChargesSalariales);

        setResults({
            chargesPatronales,
            chargesSalariales,
            avantagesEnNature: 0,
            coutTotalAnnuel,
            coutTotalMensuel: coutTotalAnnuel / 12,
            salaireNetMensuel,
        });
    };

    const handleSubmit = () => {
        calculateCout();
        if (!logged) handleOpen();
    };

    const statutOptions = [
        { value: 'cadre', label: 'Cadre' },
        { value: 'non-cadre', label: 'Non cadre' },
        { value: 'apprenti', label: 'Apprenti' },
    ];

    const dureeOptions = [
        { value: 'cdi', label: 'CDI' },
        { value: 'cdd', label: 'CDD' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur coût d'embauche" />
                <SimulatorAlert text="Ce simulateur ne prend pas en compte les avantages tels que la mutuelle, les titres restaurants ou le remboursement des frais de transport." />

                <RadioButtonGroup
                    label="Statut du salarié"
                    options={statutOptions}
                    value={formData.statut}
                    onChange={value =>
                        setFormData(prev => ({ ...prev, statut: value as 'cadre' | 'non-cadre' | 'apprenti' }))
                    }
                    direction="column"
                />

                <RadioButtonGroup
                    label="Type de contrat"
                    options={dureeOptions}
                    value={formData.duree}
                    onChange={value => setFormData(prev => ({ ...prev, duree: value as 'cdi' | 'cdd' }))}
                    direction="column"
                />

                <InputNumber
                    label="Salaire brut mensuel"
                    value={Number(formData.salaireBrut) || null}
                    setValue={value => setFormData(prev => ({ ...prev, salaireBrut: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 2500"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.salaireBrut}
                >
                    Calculer le coût total
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Coût total annuel',
                                value: `${Math.round(results.coutTotalAnnuel).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Coût total mensuel',
                                value: `${Math.round(results.coutTotalMensuel).toLocaleString('fr-FR')} €`,
                                highlight: true,
                            },
                            {
                                label: 'Salaire brut annuel',
                                value: `${(parseFloat(formData.salaireBrut) * 12).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Charges patronales',
                                value: `${Math.round(results.chargesPatronales).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Charges salariales',
                                value: `${Math.round(results.chargesSalariales).toLocaleString('fr-FR')} €`,
                            },
                            {
                                label: 'Salaire net mensuel',
                                value: `${Math.round(results.salaireNetMensuel).toLocaleString('fr-FR')} €`,
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CoutEmbauche;
