import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { ElementType } from 'react';

import { breakpoint } from '../../styles/theme';
import { SeoTitle } from '../seo/seoTitle';
import * as styles from './post-highlight.module.scss';
import PostInfos from './PostInfos';
import PostTags from './PostTags';

export type PostDatas = Queries.PrismicPost &
    Queries.PrismicVideo & {
        excerpt?: string;
        img?: IGatsbyImageData | Record<string, unknown>;
        alt?: string;
        author?: Queries.PrismicLinkField;
        title?: string;
        date?: string;
        content?: string;
    };

const Container = styled.div`
    margin-left: 0 !important ;
    ${breakpoint.laptop} {
        margin-left: 24px !important ;
    }
`;

const PostHighlight = ({
    post,
    cta,
    seo,
    noLink = false,
}: {
    post: PostDatas;
    cta?: any;
    seo?: string;
    noLink?: boolean;
}) => {
    const selectedSeo = seo || 'h3';
    const link = post.uri;
    const LinkOrText = noLink ? 'div' : Link;
    return (
        <Link data-ga4="container-level1-img-topimage-click-link" to={!noLink ? link : undefined}>
            <Container className={`row ${styles.postHighlight}`}>
                <div className={`u-align-self-center col-md-6 ${styles.postHighlightText}`}>
                    <PostTags className="pb-1" post={post} />
                    <LinkOrText
                        to={!noLink ? link : undefined}
                        data-ga4="container-level1-text-toptitle-click-link"
                        className={!noLink ? 'link-dark-primary' : ''}
                    >
                        {post.title && (
                            <SeoTitle
                                as={selectedSeo as ElementType}
                                seoStyle={'h3'}
                                dangerouslySetInnerHTML={{ __html: post.title }}
                            />
                        )}
                        {post.data?.titre && (
                            <SeoTitle as={selectedSeo as ElementType} seoStyle={'h3'}>
                                {post.data.titre}
                            </SeoTitle>
                        )}
                    </LinkOrText>
                    <div
                        className={styles.postHighlightExcerpt}
                        dangerouslySetInnerHTML={{ __html: post.excerpt.replace('undefined', ' ') }}
                    />
                    <div className={styles.postHighlightFooter}>
                        {post.data?.author?.document && (
                            <PostInfos author={post.data.author?.document as Queries.PrismicAuteur} />
                        )}
                    </div>
                    <div className={styles.postHighlightFooter + ' mt-3'}>
                        {cta && (
                            <a
                                className="Button m-xsmall m-green m-reversable mr-2"
                                href={cta.url}
                                target="_blank"
                                rel="noopener nofollow"
                                data-ga-send={`event,button,click,blog-cta-header-${cta.tags[0]}`}
                                data-ga4="container-level1-cta-prismic-click-link"
                            >
                                <div className="btn-content" dangerouslySetInnerHTML={{ __html: cta.cta_text.html }} />
                            </a>
                        )}
                    </div>
                </div>
                <div className={`col-md-6 ${styles.postHighlightImage}`}>
                    <LinkOrText data-ga4="container-level1-img-topimage-click-link" to={!noLink ? link : undefined}>
                        <div className="img-ratio-wrapper">
                            <GatsbyImage
                                image={post.img}
                                alt={post.alt || post.data?.titre || ''}
                                loading="eager"
                                className="img-ratio"
                            />
                        </div>
                    </LinkOrText>
                </div>
            </Container>
        </Link>
    );
};

export default PostHighlight;
