import React from 'react';

import { useMeetUsContext } from '../../../../../../contexts/MeetUsContextProvider';
import ArrowButton from '../../../../../UI/Button/ArrowButton';
import { RightContainerCustomMade, TextResponse, TitleResponse } from '../../styles';

const CustomMadeContent = () => {
    const { openModal } = useMeetUsContext();
    return (
        <RightContainerCustomMade>
            <TitleResponse>Prenez rendez-vous avez notre équipe pour un devis personnalisé</TitleResponse>
            <TextResponse>
                Votre profil nécessite une analyse plus approfondie de vos besoins, afin de vous proposer une estimation
                sur mesure.
            </TextResponse>
            <ArrowButton
                actionType="MODAL"
                color="ORANGE"
                onClick={openModal}
                trackingId="calculateur-prendre-rendez-vous"
            >
                Prendre rendez-vous
            </ArrowButton>
        </RightContainerCustomMade>
    );
};

export default CustomMadeContent;
