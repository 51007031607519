import styled from '@emotion/styled';
import React from 'react';

import { breakpoint, colors, fonts } from '../../../styles/theme';
import { TableParsed } from '../../../utils/TableParser';

interface TableProps {
    data: TableParsed;
}

const Container = styled.div`
    overflow-x: auto;
    margin-bottom: 16px;
`;

const StyledTable = styled.table`
    min-width: 530px;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    border-radius: 16px;

    td {
        padding: 12px;
    }
`;

const StyledTbody = styled.tbody`
    tr:last-of-type th:first-of-type {
        border-bottom-left-radius: 16px;
    }
    tr:last-of-type th:last-of-type {
        border-bottom-right-radius: 16px;
    }
`;

const HeadCell = styled.th`
    :first-of-type {
        border-top-left-radius: 16px;
    }

    :last-of-type {
        border-top-right-radius: 16px;
    }

    padding: 12px;
    background-color: ${colors.$primary100};
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;

    border-bottom: none;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const BodyCell = styled.th`
    padding: 12px;
    color: ${colors.$primary700};
    font-family: ${fonts.OpenSans_Regular};
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;

    ${breakpoint.laptop} {
        font-size: 14px;
    }
`;

const TablePrismic = ({ data }: TableProps) => {
    return (
        <Container>
            <StyledTable>
                <thead>
                    <tr>
                        {data.firstLine.map(cell => (
                            <HeadCell key={cell}>{cell}</HeadCell>
                        ))}
                    </tr>
                </thead>
                <StyledTbody>
                    {data.body.map((line, idx) => (
                        <tr key={line[idx]}>
                            {line.map(cell => (
                                <BodyCell key={cell}>{cell}</BodyCell>
                            ))}
                        </tr>
                    ))}
                </StyledTbody>
            </StyledTable>
        </Container>
    );
};

export default TablePrismic;
