import { FormControl, MenuItem, Select as MuiSelect, SelectChangeEvent } from '@mui/material';
import React from 'react';

import SimulatorInputLabel from './SimulatorInputLabel';

interface SelectOption {
    value: string;
    label: string;
}

interface SelectProps {
    label?: string;
    value: string;
    setValue: (value: string) => void;
    options: SelectOption[];
    placeholder?: string;
    tooltipText?: string;
}

const getStyles = (value: string) => ({
    backgroundColor: 'white',
    '& .MuiInputBase-input': {
        fontSize: '14px',
        color: '#013A51',
        fontWeight: value ? '600' : '400',
    },
});

const Select = ({
    label,
    value,
    setValue,
    options,
    placeholder = 'Sélectionnez une option',
    tooltipText,
}: SelectProps) => {
    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value);
    };

    return (
        <div>
            {!!label && <SimulatorInputLabel text={label} tooltipText={tooltipText} />}
            <FormControl fullWidth size="small">
                <MuiSelect
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    sx={getStyles(value)}
                    placeholder={placeholder}
                >
                    <MenuItem value="" disabled sx={{ fontWeight: '400' }}>
                        {placeholder}
                    </MenuItem>
                    {options.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{ fontWeight: value === option.value ? '600' : '400' }}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </MuiSelect>
            </FormControl>
        </div>
    );
};

export default Select;
