import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface CvaeData {
    chiffreAffaires: string;
    valeurAjoutee: string;
    effectif: string;
    territoires: string;
    exoneration: boolean;
}

interface CvaeResults {
    tauxEffectif: number;
    montantCvae: number;
    degrevement: number;
    montantFinal: number;
    acomptes: {
        juin: number;
        septembre: number;
        solde: number;
    };
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const CotisationValeurAjoutee: React.FC = () => {
    const [formData, setFormData] = useState<CvaeData>({
        chiffreAffaires: '',
        valeurAjoutee: '',
        effectif: '',
        territoires: '1',
        exoneration: false,
    });

    const [results, setResults] = useState<CvaeResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculateCVAE = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const va = parseFloat(formData.valeurAjoutee) || 0;

        // Calcul du taux effectif
        let tauxEffectif = 0;
        if (ca > 50000000) {
            tauxEffectif = 0.0075; // 0.75%
        } else if (ca > 500000) {
            // Formule progressive entre 500K€ et 50M€
            tauxEffectif = (0.0075 * (ca - 500000)) / 49500000;
        }

        // Calcul de la CVAE brute
        const montantCvae = va * tauxEffectif;

        // Calcul du dégrèvement
        let degrevement = 0;
        if (ca < 50000000) {
            degrevement = montantCvae * 0.5; // Dégrèvement de 50% pour les entreprises < 50M€
        }

        // Calcul du montant final
        const montantFinal = formData.exoneration ? 0 : montantCvae - degrevement;

        // Calcul des acomptes
        const acompteJuin = montantFinal * 0.5;
        const acompteSeptembre = montantFinal * 0.5;
        const solde = montantFinal - (acompteJuin + acompteSeptembre);

        setResults({
            tauxEffectif,
            montantCvae,
            degrevement,
            montantFinal,
            acomptes: {
                juin: acompteJuin,
                septembre: acompteSeptembre,
                solde,
            },
        });
    };

    const handleSubmit = () => {
        calculateCVAE();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'true', label: 'Entreprise exonérée' },
        { value: 'false', label: 'Entreprise imposable' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de CVAE" />

                <RadioButtonGroup
                    label="Votre situation"
                    options={radioOptions}
                    value={formData.exoneration}
                    onChange={value => setFormData(prev => ({ ...prev, exoneration: value === 'true' }))}
                    direction="column"
                />

                <InputNumber
                    label="Chiffre d'affaires"
                    value={Number(formData.chiffreAffaires) || null}
                    setValue={value => setFormData(prev => ({ ...prev, chiffreAffaires: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000000"
                />

                <InputNumber
                    label="Valeur ajoutée"
                    value={Number(formData.valeurAjoutee) || null}
                    setValue={value => setFormData(prev => ({ ...prev, valeurAjoutee: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 500000"
                />

                <InputNumber
                    label="Effectif"
                    value={Number(formData.effectif) || null}
                    setValue={value => setFormData(prev => ({ ...prev, effectif: String(value) }))}
                    placeholder="Ex : 10"
                />

                <InputNumber
                    label="Nombre de territoires d'exploitation"
                    value={Number(formData.territoires) || 1}
                    setValue={value => setFormData(prev => ({ ...prev, territoires: String(value) }))}
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.chiffreAffaires || !formData.valeurAjoutee || !formData.effectif}
                >
                    Calculer la CVAE
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={
                            formData.exoneration
                                ? [
                                      {
                                          label: 'Statut',
                                          value: 'Entreprise exonérée de CVAE',
                                          highlight: true,
                                      },
                                  ]
                                : [
                                      {
                                          label: 'CVAE à payer',
                                          value: `${Math.round(results.montantFinal).toLocaleString('fr-FR')} €`,
                                          highlight: true,
                                      },
                                      {
                                          label: "Taux effectif d'imposition",
                                          value: `${(results.tauxEffectif * 100).toFixed(3)}%`,
                                      },
                                      {
                                          label: 'CVAE brute',
                                          value: `${Math.round(results.montantCvae).toLocaleString('fr-FR')} €`,
                                      },
                                      results.degrevement > 0 && {
                                          label: 'Dégrèvement',
                                          value: `-${Math.round(results.degrevement).toLocaleString('fr-FR')} €`,
                                      },
                                      {
                                          label: 'Acompte de juin',
                                          value: `${Math.round(results.acomptes.juin).toLocaleString('fr-FR')} €`,
                                      },
                                      {
                                          label: 'Acompte de septembre',
                                          value: `${Math.round(results.acomptes.septembre).toLocaleString('fr-FR')} €`,
                                      },
                                      {
                                          label: 'Solde à payer',
                                          value: `${Math.round(results.acomptes.solde).toLocaleString('fr-FR')} €`,
                                      },
                                  ].filter(Boolean)
                        }
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default CotisationValeurAjoutee;
