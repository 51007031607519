import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import React from 'react';

import { colors, fonts } from '../../../../../../styles/theme';
import SimulatorInputLabel from '../../../../../UI/SimulatorInputLabel';
import { StyledSelect } from '../../styles';

interface InputSelectProps {
    label: string;
    value: string;
    valueLabel: string;
    setValue: (value: Partial<Record<string, string | number>>) => void;
    tooltipText?: string;
    disabled?: boolean;
    itemsList: { label: string; value: string; disabled?: boolean }[];
    placeholder?: string;
}

const PlaceHolder = styled.span`
    color: #a8bfd7;
    text-overflow: ellipsis;
    font-family: ${fonts.OpenSans_Regular};
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
`;

const InputSelect = ({
    label,
    value,
    valueLabel,
    setValue,
    tooltipText,
    disabled,
    itemsList,
    placeholder,
}: InputSelectProps) => {
    return (
        <>
            <SimulatorInputLabel text={label} tooltipText={tooltipText} disabled={disabled} />
            <StyledSelect
                fullWidth
                value={value}
                displayEmpty
                onChange={e => setValue({ [valueLabel]: e.target.value as string })}
                disabled={disabled}
                sx={{
                    backgroundColor: disabled ? colors.$gray200 : 'white',
                    '& .MuiInputBase-input': {
                        fontSize: '14px',
                        padding: '7px 14px',
                        color: '#013A51',
                        fontWeight: '600',
                    },
                }}
            >
                {itemsList.map(item => (
                    <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                        {item.disabled ? <PlaceHolder>{disabled ? '-' : placeholder}</PlaceHolder> : item.label}
                    </MenuItem>
                ))}
            </StyledSelect>
        </>
    );
};

export default InputSelect;
