import styled from '@emotion/styled';
import React, { useState } from 'react';

import ArrowButton from '../../UI/Button/ArrowButton/ArrowButton';
import InputNumber from '../components/InputNumber';
import RadioButtonGroup from '../components/RadioButtonGroup';
import Select from '../components/Select';
import SimulatorHeader from '../components/SimulatorHeader';
import SimulatorResults from '../components/SimulatorResults';
import { useSimulatorContext } from '../context/SimulatorProvider';

interface PrixData {
    coutAchat: string;
    coutProduction: string;
    fraisGeneraux: string;
    fraisCommercialisation: string;
    margeObjectif: string;
    methodeCalcul: 'cout' | 'marche';
    prixConcurrence: string;
    volumeEstime: string;
    tauxTva: string;
}

interface PrixResults {
    coutRevient: number;
    prixVenteHT: number;
    prixVenteTTC: number;
    margeUnitaire: number;
    margePourcentage: number;
    seuilRentabilite: number;
    details: {
        label: string;
        montant: number;
        pourcentage: number;
    }[];
    recommandations: string[];
}

const SimulatorCard = styled.div`
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ResultsContainer = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 24px;
`;

const PrixVente: React.FC = () => {
    const [formData, setFormData] = useState<PrixData>({
        coutAchat: '',
        coutProduction: '',
        fraisGeneraux: '',
        fraisCommercialisation: '',
        margeObjectif: '',
        methodeCalcul: 'cout',
        prixConcurrence: '',
        volumeEstime: '',
        tauxTva: '20',
    });

    const [results, setResults] = useState<PrixResults | null>(null);
    const { handleOpen, logged } = useSimulatorContext();

    const calculatePrix = () => {
        const coutAchat = parseFloat(formData.coutAchat) || 0;
        const coutProduction = parseFloat(formData.coutProduction) || 0;
        const fraisGeneraux = parseFloat(formData.fraisGeneraux) || 0;
        const fraisCommercialisation = parseFloat(formData.fraisCommercialisation) || 0;
        const margeObjectif = parseFloat(formData.margeObjectif) || 0;
        const prixConcurrence = parseFloat(formData.prixConcurrence) || 0;
        const volumeEstime = parseFloat(formData.volumeEstime) || 1;
        const tauxTva = parseFloat(formData.tauxTva) || 20;

        // Calcul du coût de revient
        const coutRevient = coutAchat + coutProduction + fraisGeneraux / volumeEstime + fraisCommercialisation;

        // Calcul du prix de vente selon la méthode choisie
        let prixVenteHT = 0;
        switch (formData.methodeCalcul) {
            case 'cout':
                // Méthode du coût majoré avec taux de marge
                prixVenteHT = coutRevient * (1 + margeObjectif / 100);
                break;
            case 'marche':
                // Méthode alignée sur le marché
                prixVenteHT = prixConcurrence;
                break;
        }

        // Calculs complémentaires
        const margeUnitaire = prixVenteHT - coutRevient;
        const margePourcentage = (margeUnitaire / coutRevient) * 100;
        const prixVenteTTC = prixVenteHT * (1 + tauxTva / 100);
        const seuilRentabilite = fraisGeneraux / (prixVenteHT - (coutAchat + coutProduction + fraisCommercialisation));

        // Détail des composantes du prix
        const details = [
            {
                label: "Coût d'achat/matières premières",
                montant: coutAchat,
                pourcentage: (coutAchat / prixVenteHT) * 100,
            },
            {
                label: 'Coût de production',
                montant: coutProduction,
                pourcentage: (coutProduction / prixVenteHT) * 100,
            },
            {
                label: 'Frais généraux unitaires',
                montant: fraisGeneraux / volumeEstime,
                pourcentage: (fraisGeneraux / volumeEstime / prixVenteHT) * 100,
            },
            {
                label: 'Frais de commercialisation',
                montant: fraisCommercialisation,
                pourcentage: (fraisCommercialisation / prixVenteHT) * 100,
            },
            {
                label: 'Taux de marge',
                montant: margeUnitaire,
                pourcentage: margePourcentage,
            },
        ];

        // Génération des recommandations
        const recommandations = [];

        if (margePourcentage < 20) {
            recommandations.push("La marge est faible, envisagez d'optimiser vos coûts ou d'augmenter le prix");
        }

        if (formData.methodeCalcul === 'marche' && prixVenteHT < coutRevient * 1.1) {
            recommandations.push(
                'Le prix du marché ne permet pas une rentabilité suffisante, différenciez votre offre',
            );
        }

        if (seuilRentabilite > volumeEstime) {
            recommandations.push('Le volume de ventes estimé est inférieur au seuil de rentabilité');
        }

        if (prixVenteHT > prixConcurrence * 1.2 && formData.methodeCalcul === 'marche') {
            recommandations.push(
                'Votre prix est significativement supérieur au marché, justifiez votre positionnement',
            );
        }

        setResults({
            coutRevient,
            prixVenteHT,
            prixVenteTTC,
            margeUnitaire,
            margePourcentage,
            seuilRentabilite,
            details,
            recommandations,
        });
    };

    const handleSubmit = () => {
        calculatePrix();
        if (!logged) handleOpen();
    };

    const radioOptions = [
        { value: 'cout', label: 'Coût majoré' },
        { value: 'marche', label: 'Prix du marché' },
    ];

    const tvaOptions = [
        { value: '0', label: '0% - Exonéré' },
        { value: '2.1', label: '2.1% - Taux super réduit' },
        { value: '5.5', label: '5.5% - Taux réduit' },
        { value: '10', label: '10% - Taux intermédiaire' },
        { value: '20', label: '20% - Taux normal' },
    ];

    return (
        <div className="simulator-content">
            <SimulatorCard>
                <SimulatorHeader title="Calculateur de prix de vente" />

                <RadioButtonGroup
                    label="Méthode de calcul"
                    options={radioOptions}
                    value={formData.methodeCalcul}
                    onChange={value => setFormData(prev => ({ ...prev, methodeCalcul: value as 'cout' | 'marche' }))}
                    direction="row"
                />

                <InputNumber
                    label="Coût d'achat/matières premières (€ HT)"
                    value={Number(formData.coutAchat) || null}
                    setValue={value => setFormData(prev => ({ ...prev, coutAchat: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 10"
                />

                <InputNumber
                    label="Coût de production unitaire (€ HT)"
                    value={Number(formData.coutProduction) || null}
                    setValue={value => setFormData(prev => ({ ...prev, coutProduction: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 15"
                />

                <InputNumber
                    label="Frais généraux totaux (€ HT)"
                    value={Number(formData.fraisGeneraux) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisGeneraux: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5000"
                />

                <InputNumber
                    label="Frais de commercialisation unitaires (€ HT)"
                    value={Number(formData.fraisCommercialisation) || null}
                    setValue={value => setFormData(prev => ({ ...prev, fraisCommercialisation: String(value) }))}
                    endAdornment={<>€</>}
                    variant="ADORNMENT"
                    placeholder="Ex : 5"
                />

                <InputNumber
                    label="Volume estimé (unités)"
                    value={Number(formData.volumeEstime) || null}
                    setValue={value => setFormData(prev => ({ ...prev, volumeEstime: String(value) }))}
                    variant="ADORNMENT"
                    placeholder="Ex : 1000"
                />

                {formData.methodeCalcul === 'cout' && (
                    <InputNumber
                        label="Taux de marge souhaité"
                        value={Number(formData.margeObjectif) || null}
                        setValue={value => setFormData(prev => ({ ...prev, margeObjectif: String(value) }))}
                        endAdornment={<>%</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 30"
                    />
                )}

                {formData.methodeCalcul === 'marche' && (
                    <InputNumber
                        label="Prix moyen concurrence (€ HT)"
                        value={Number(formData.prixConcurrence) || null}
                        setValue={value => setFormData(prev => ({ ...prev, prixConcurrence: String(value) }))}
                        endAdornment={<>€</>}
                        variant="ADORNMENT"
                        placeholder="Ex : 50"
                    />
                )}

                <Select
                    label="Taux de TVA"
                    value={formData.tauxTva}
                    setValue={value => setFormData(prev => ({ ...prev, tauxTva: value }))}
                    options={tvaOptions}
                    placeholder="Sélectionnez un taux de TVA"
                />

                <ArrowButton
                    actionType="SIMPLE"
                    onClick={handleSubmit}
                    fullWidth="BOTH"
                    trackingId="cta-resultat-simulateur"
                    icon="ROTATE"
                    disabled={!formData.coutAchat || !formData.volumeEstime}
                >
                    Calculer le prix de vente
                </ArrowButton>
            </SimulatorCard>

            {logged && results && (
                <ResultsContainer>
                    <SimulatorResults
                        rows={[
                            {
                                label: 'Prix de vente HT conseillé',
                                value: `${results.prixVenteHT.toFixed(2)} €`,
                                highlight: true,
                            },
                            {
                                label: 'Coût de revient unitaire',
                                value: `${results.coutRevient.toFixed(2)} €`,
                            },
                            {
                                label: `Prix de vente TTC (${formData.tauxTva}%)`,
                                value: `${results.prixVenteTTC.toFixed(2)} €`,
                            },
                            {
                                label: 'Seuil de rentabilité',
                                value: !isFinite(results.seuilRentabilite)
                                    ? 'N/A'
                                    : `${Math.ceil(results.seuilRentabilite)} unités`,
                            },
                            ...results.details.map(detail => ({
                                label: detail.label,
                                value: `${detail.montant.toFixed(2)} € (${detail.pourcentage.toFixed(1)}%)`,
                            })),
                            {
                                description: 'Recommandations',
                                label: results.recommandations,
                                value: '',
                            },
                        ]}
                    />
                </ResultsContainer>
            )}
        </div>
    );
};

export default PrixVente;
