import { Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

interface EvaluationData {
    chiffreAffaires: string;
    resultatNet: string;
    ebe: string;
    capitauxPropres: string;
    actifNet: string;
    dettes: string;
    tresorerie: string;
    secteurActivite: 'commerce' | 'service' | 'industrie';
    anneesCrees: string;
}

interface EvaluationResults {
    methodes: {
        label: string;
        valeur: number;
        description: string;
        poids: number;
    }[];
    valeurPonderee: number;
    fourchette: {
        min: number;
        max: number;
    };
    recommandations: string[];
}

const EvaluationEntreprise: React.FC = () => {
    const [formData, setFormData] = useState<EvaluationData>({
        chiffreAffaires: '',
        resultatNet: '',
        ebe: '',
        capitauxPropres: '',
        actifNet: '',
        dettes: '',
        tresorerie: '',
        secteurActivite: 'commerce',
        anneesCrees: '',
    });

    const [results, setResults] = useState<EvaluationResults | null>(null);

    const calculateEvaluation = () => {
        const ca = parseFloat(formData.chiffreAffaires) || 0;
        const rn = parseFloat(formData.resultatNet) || 0;
        const ebe = parseFloat(formData.ebe) || 0;
        const cp = parseFloat(formData.capitauxPropres) || 0;
        const an = parseFloat(formData.actifNet) || 0;
        const dettes = parseFloat(formData.dettes) || 0;
        const tresorerie = parseFloat(formData.tresorerie) || 0;
        const annees = parseInt(formData.anneesCrees) || 0;

        // Coefficients multiplicateurs selon le secteur
        const coefficients = {
            commerce: {
                ca: 0.8,
                ebe: 6,
                rn: 8,
            },
            service: {
                ca: 1.2,
                ebe: 7,
                rn: 9,
            },
            industrie: {
                ca: 1.0,
                ebe: 5,
                rn: 7,
            },
        };

        const coef = coefficients[formData.secteurActivite];

        // Calcul selon différentes méthodes
        const methodes = [
            {
                label: "Multiple du chiffre d'affaires",
                valeur: ca * coef.ca,
                description: `${coef.ca}x le CA`,
                poids: 0.1,
            },
            {
                label: "Multiple de l'EBE",
                valeur: ebe * coef.ebe,
                description: `${coef.ebe}x l'EBE`,
                poids: 0.3,
            },
            {
                label: 'Multiple du résultat net',
                valeur: rn * coef.rn,
                description: `${coef.rn}x le résultat net`,
                poids: 0.2,
            },
            {
                label: 'Actif net corrigé',
                valeur: an + tresorerie - dettes,
                description: 'Actif net + trésorerie - dettes',
                poids: 0.2,
            },
            {
                label: 'Goodwill',
                valeur: cp + (rn - cp * 0.1) * 5,
                description: 'Capitaux propres + super-bénéfices',
                poids: 0.2,
            },
        ];

        // Calcul de la valeur pondérée
        const valeurPonderee = methodes.reduce((acc, m) => acc + m.valeur * m.poids, 0);

        // Calcul de la fourchette (+/- 15%)
        const fourchette = {
            min: valeurPonderee * 0.85,
            max: valeurPonderee * 1.15,
        };

        // Génération des recommandations
        const recommandations = [];

        if (rn / ca < 0.05) {
            recommandations.push(
                "La rentabilité est faible, travaillez sur l'amélioration des marges avant la cession",
            );
        }

        if (dettes / cp > 1) {
            recommandations.push("L'endettement est élevé, envisagez un désendettement pour optimiser la valorisation");
        }

        if (tresorerie / ca < 0.1) {
            recommandations.push('La trésorerie est tendue, renforcez-la pour améliorer la valorisation');
        }

        if (annees < 3) {
            recommandations.push("L'entreprise est jeune, la valorisation pourrait être sous-estimée");
        }

        setResults({
            methodes,
            valeurPonderee,
            fourchette,
            recommandations,
        });
    };

    return (
        <div className="simulator-content">
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Évaluation d'entreprise
                </Typography>

                <FormControl component="fieldset" fullWidth sx={{ mb: 3 }}>
                    <RadioGroup
                        row
                        value={formData.secteurActivite}
                        onChange={e =>
                            setFormData(prev => ({
                                ...prev,
                                secteurActivite: e.target.value as 'commerce' | 'service' | 'industrie',
                            }))
                        }
                    >
                        <FormControlLabel
                            value="commerce"
                            control={<Radio />}
                            label="Commerce"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="service"
                            control={<Radio />}
                            label="Service"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                        <FormControlLabel
                            value="industrie"
                            control={<Radio />}
                            label="Industrie"
                            sx={{ flex: 1, m: 0, p: 2 }}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Chiffre d'affaires (€)"
                        type="number"
                        value={formData.chiffreAffaires}
                        onChange={e => setFormData(prev => ({ ...prev, chiffreAffaires: e.target.value }))}
                        placeholder="Ex: 1000000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Résultat net (€)"
                        type="number"
                        value={formData.resultatNet}
                        onChange={e => setFormData(prev => ({ ...prev, resultatNet: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="EBE (€)"
                        type="number"
                        value={formData.ebe}
                        onChange={e => setFormData(prev => ({ ...prev, ebe: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Capitaux propres (€)"
                        type="number"
                        value={formData.capitauxPropres}
                        onChange={e => setFormData(prev => ({ ...prev, capitauxPropres: e.target.value }))}
                        placeholder="Ex: 300000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Actif net comptable (€)"
                        type="number"
                        value={formData.actifNet}
                        onChange={e => setFormData(prev => ({ ...prev, actifNet: e.target.value }))}
                        placeholder="Ex: 500000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Dettes financières (€)"
                        type="number"
                        value={formData.dettes}
                        onChange={e => setFormData(prev => ({ ...prev, dettes: e.target.value }))}
                        placeholder="Ex: 200000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Trésorerie (€)"
                        type="number"
                        value={formData.tresorerie}
                        onChange={e => setFormData(prev => ({ ...prev, tresorerie: e.target.value }))}
                        placeholder="Ex: 100000"
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 3 }}>
                    <TextField
                        fullWidth
                        label="Années d'existence"
                        type="number"
                        value={formData.anneesCrees}
                        onChange={e => setFormData(prev => ({ ...prev, anneesCrees: e.target.value }))}
                        placeholder="Ex: 5"
                    />
                </FormControl>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={calculateEvaluation}
                    disabled={!formData.chiffreAffaires || !formData.resultatNet}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    Évaluer l'entreprise
                </Button>
            </Paper>

            {results && (
                <Paper elevation={0} sx={{ p: 0, mt: 3 }} className="results-card">
                    {results.methodes.map((methode, index) => (
                        <React.Fragment key={index}>
                            <div className="result-row">
                                <span className="label">{methode.label}</span>
                                <span className="value">{Math.round(methode.valeur).toLocaleString('fr-FR')} €</span>
                            </div>
                            <div className="result-row" style={{ backgroundColor: '#f8f9fa' }}>
                                <span className="label" style={{ fontSize: '0.9em', color: '#666' }}>
                                    {methode.description} (poids: {methode.poids * 100}%)
                                </span>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="result-row highlight">
                        <span className="label">Valeur estimée</span>
                        <span className="value">{Math.round(results.valeurPonderee).toLocaleString('fr-FR')} €</span>
                    </div>

                    <div className="result-row">
                        <span className="label">Fourchette de valorisation</span>
                        <span className="value">
                            {Math.round(results.fourchette.min).toLocaleString('fr-FR')} € -{' '}
                            {Math.round(results.fourchette.max).toLocaleString('fr-FR')} €
                        </span>
                    </div>

                    {results.recommandations.length > 0 && (
                        <div className="result-row" style={{ flexDirection: 'column', gap: '12px' }}>
                            <span className="label">Recommandations</span>
                            {results.recommandations.map((recommandation, index) => (
                                <p key={index} style={{ margin: 0, color: '#666' }}>
                                    • {recommandation}
                                </p>
                            ))}
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default EvaluationEntreprise;
